import { createAction } from '@reduxjs/toolkit';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { initialValue } from '../../helperContext';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const updateOrderWorkunit = createAction(
  OrderWorkunitAffectationType.UpdateOrderWorkunit,
  (orderWorkunit: OrderWorkunit | Partial<OrderWorkunit>) => ({
    payload: { orderWorkunit },
  })
);
export const bulkUpdateOrderWorkunitAction = createAction(
  OrderWorkunitAffectationType.BulkUpdateOrderWorkunit,
  (change: Partial<OrderWorkunit>) => ({
    payload: change,
  })
);

export default (state = initialValue, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.UpdateOrderWorkunit:
      return {
        ...state,
        orderWorkunits: state.orderWorkunits.map((ow) =>
          ow.id !== action.payload.orderWorkunit?.id ? ow : { ...ow, ...action.payload.orderWorkunit }
        ),
      };
    case OrderWorkunitAffectationType.BulkUpdateOrderWorkunit:
      return {
        ...state,
        orderWorkunits: updateBulkOrderWorkunit(state.orderWorkunits, state.selectedItems, action.payload),
      };
    default:
      return state;
  }
};

function updateBulkOrderWorkunit(
  orderworkunits: OrderWorkunit[],
  selectedItems: OrderWorkunit['id'][],
  changes: Partial<OrderWorkunit>
) {
  return orderworkunits.map((owu) => {
    const isOrderWorkunitInListToBeUpdated = selectedItems.findIndex((s) => s === owu.id);
    if (isOrderWorkunitInListToBeUpdated === -1) return owu;
    return { ...owu, ...changes };
  });
}
