import { Tooltip, Typography } from '@mui/material';
import { trim } from 'lodash';
import React, { useMemo } from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const ConsultantCell = React.memo((row: OrderWorkunit) => {
  const getName = useMemo(
    () =>
      row.consultant_fullname ??
      trim(` ${row.consultant?.last_name.toUpperCase() || ''} ${row.consultant?.first_name || ''}`),
    [row]
  );

  return (
    <Tooltip title={getName}>
      <Typography variant="body2" noWrap>
        {getName}
      </Typography>
    </Tooltip>
  );
});

export default ConsultantCell;
