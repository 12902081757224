import { createAction } from '@reduxjs/toolkit';
import { initialValue } from '../../intialValue';
import { PossibleSheetActions, SheetActions } from '../../SheetContext.helper';

export const setShouldRefreshAction = createAction(SheetActions.ShouldRefreshAction, (shouldRefresh: boolean) => ({
  payload: shouldRefresh,
}));

export default (state = initialValue, action: PossibleSheetActions) => {
  switch (action.type) {
    case SheetActions.ShouldRefreshAction:
      return {
        ...state,
        shouldRefresh: action.payload,
      };
    default:
      return state;
  }
};
