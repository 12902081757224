/* eslint-disable class-methods-use-this */
import RessourceApi, { Resource } from 'api/ResourceAPI';
import Affair from 'types/entities/Affair';
import InMemoryAffairApiService from './InMemory/InMemoryAffairApiService';

type AffairKeys = keyof Affair;

export interface IAffairApiService {
  fetchByCustomerId(customerId: number, props: AffairKeys[], size: string): Promise<Affair[]>;
}

class AffairApiService {
  public static instance: AffairApiService;

  public static baseUrl = 'affair' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return AffairApiService.instance || (AffairApiService.instance = new AffairApiService());
  }

  async fetchByCustomerId(customerId: number, props: AffairKeys[] = [], size = '-1') {
    try {
      const res = await RessourceApi.get('affairs', {
        customer_id: customerId,
        size,
        attr: props.join('.'),
      });
      return res.data.datas;
    } catch (error: unknown) {
      return [];
    }
  }
}

const isTestMode = process.env.REACT_APP_NODE_ENV === 'test';
const affairApiService = isTestMode ? new InMemoryAffairApiService() : new AffairApiService();

export default affairApiService;
