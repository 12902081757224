import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, DialogContentText, Stack, Typography } from '@mui/material';
import ModalCustom from 'components/Modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useAppState } from 'store/Provider';
import { setActiveOrderIdAction } from '../../../store/actions/managementActions';
import useManagementService from '../services/useManagementService';

const ManagementOpenDialog = () => {
  const { state, dispatch } = useAppState();
  const { dialogs, management, loadings } = state;
  const isOpen = dialogs.managementOpenDialog;
  const [isConfirm, setIsConfirm] = useState<boolean | null>(null);
  const { openOrder, navigateToDeliverables } = useManagementService();
  const [t] = useTranslation();
  const confirmText = 'Reopen order';
  const cancelText = 'Cancel';
  const loading = () => !!loadings[`open_${management.activeOrderId}`] || !!loadings.orders;

  const onConfirm = async () => {
    const reponse = await openOrder(management.activeOrderId);
    setIsConfirm(!!reponse);
  };

  const onClose = () => {
    dispatch(closeDialogAction('managementOpenDialog'));
    dispatch(setActiveOrderIdAction(null));
    setTimeout(() => {
      setIsConfirm(null);
    }, 1000);
  };

  const onClick = () => {
    if (management.activeOrderId) {
      onClose();
      navigateToDeliverables(management.activeOrderId);
    }
  };

  const bodyIsConfimed = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <CheckCircleIcon color="success" fontSize="large" sx={{ mb: 1 }} />
      <Typography color="textPrimary">{t('This order is reopen')}</Typography>
    </Box>
  );

  const bodyIsNotConfimed = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <ErrorIcon color="error" fontSize="large" sx={{ mb: 1 }} />
      <Typography color="error">{t('An error occured !')}</Typography>
    </Box>
  );

  const bodyDefault = (
    <Box>
      <Typography color="textPrimary">{t('Do you want to reopen this order ?')}</Typography>
    </Box>
  );

  const bodyContent = () => {
    switch (isConfirm) {
      case true:
        return bodyIsConfimed;
      case false:
        return bodyIsNotConfimed;
      default:
        return bodyDefault;
    }
  };

  const body = (
    <DialogContent>
      <DialogContentText>{bodyContent()}</DialogContentText>
    </DialogContent>
  );

  const actionsIsConfimed = (
    <Stack direction="row" spacing={2} sx={{ flex: 'auto', justifyContent: 'center' }}>
      <Button variant="text" onClick={onClose}>
        {t('Back to closed orders tab')}
      </Button>
      <Button variant="contained" onClick={onClick}>
        {t('Enter in order')}
      </Button>
    </Stack>
  );

  const actionsDefault = (
    <Stack direction="row" spacing={2}>
      <Button variant="text" onClick={onClose} disabled={loading()}>
        {cancelText}
      </Button>
      <LoadingButton variant="contained" onClick={onConfirm} loading={loading()}>
        {confirmText}
      </LoadingButton>
    </Stack>
  );

  const actionsContent = () => {
    switch (isConfirm) {
      case true:
        return actionsIsConfimed;
      case false:
      default:
        return actionsDefault;
    }
  };

  const actions = <DialogActions>{actionsContent()}</DialogActions>;

  return (
    <ModalCustom
      open={isOpen}
      modalName="managementOpenDialog"
      onClose={onClose}
      onConfirm={onConfirm}
      disabledConfirm={isConfirm !== null}
      titleText="Reopen"
      body={body}
      actions={actions}
      size="md"
    />
  );
};

export default ManagementOpenDialog;
