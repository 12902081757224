import { DialogContent } from '@mui/material';
import ModalCustom from 'components/Modal/Modal';
import useDeliverableReportService from 'features/Deliverables/DeliverableReports/hooks/useDeliverableReportService';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { Rating } from 'types/enums/Rating';
import DeliverableSheet from '../../../../../types/entities/DeliverableSheet';
import AssessConfirmation from './AssessConfirmation/AssessConfirmation';
import './AssessSignModal.scss';
import AssessStep from './AssessStep/AssessStep';
import CommentStep from './CommentStep/CommentStep';
import SignStep from './SignStep/SignStep';

const AssessSignModal = () => {
  const { t } = useTranslation();
  const dialogs = useSelector((state) => state.dialogs);
  const report: DeliverableSheet | undefined = dialogs.data?.report || undefined;
  const { dispatch } = useAppState();
  const { clientSigned } = useDeliverableReportService();
  const [signature, setSignature] = useState<string | null>(null);
  const [paramsForSignature, setParamsForSignature] = useState<{
    assesment: Rating | null;
    comment: string;
  }>({
    assesment: null,
    comment: '',
  });

  const [currentStep, setCurrentStep] = useState<number>(1);

  const cancelModal = () => {
    handleCloseDialog();
    setCurrentStep(1);
    setParamsForSignature({
      assesment: null,
      comment: '',
    });
  };

  const nextStep = () => {
    if (paramsForSignature.assesment === Rating.D && currentStep === 1) setCurrentStep(currentStep + 2);
    else setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    if (paramsForSignature.assesment === Rating.D && currentStep === 3) setCurrentStep(currentStep - 2);
    else setCurrentStep(currentStep - 1);
  };

  const handleCloseDialog = () => {
    dispatch(closeDialogAction('assessSignModal'));
  };

  const returnConfirmButtonText = () => {
    switch (currentStep) {
      case 1:
      case 2:
        return t('Next');
      case 3:
        return t('Sign and send to Delivery Manager');
      case 4:
        return t('Close');
      default:
        return t('Confirm');
    }
  };

  const getTitleText = () => {
    switch (currentStep) {
      case 1:
      case 2:
      case 3:
        return t('Assess_and_sign');
      case 4:
        return t('Assessment sent');
      default:
        return t('Assess_and_sign');
    }
  };

  const handleOnCloseCustomDialogAction = () => {
    switch (currentStep) {
      case 1:
        cancelModal();
        break;
      case 2:
        setParamsForSignature({
          ...paramsForSignature,
          comment: '',
        });
        nextStep();
        break;
      case 3:
        previousStep();
        break;
      case 4:
      default:
        cancelModal();
    }
  };

  const handleSetRatingComment = (comment: string) => {
    setParamsForSignature({
      ...paramsForSignature,
      comment: comment.slice(0, 700),
    });
  };

  const setRating = (rating: Rating) => {
    setParamsForSignature({
      ...paramsForSignature,
      assesment: rating,
    });
  };

  const dialogBody = (
    <DialogContent>
      {currentStep === 1 && (
        <AssessStep
          averageNote={report?.average_note}
          rating={paramsForSignature.assesment}
          ratingComment={paramsForSignature.comment}
          setRating={setRating}
          setRatingComment={handleSetRatingComment}
        />
      )}
      {currentStep === 2 && <CommentStep comment={paramsForSignature.comment} setComment={handleSetRatingComment} />}
      {currentStep === 3 && <SignStep setSignature={setSignature} />}
      {currentStep === 4 && <AssessConfirmation />}
    </DialogContent>
  );

  const handleConfirm = () => {
    switch (currentStep) {
      case 1:
        return () => nextStep();
      case 2:
        return () => nextStep();
      case 3:
        return () => handleSendSignature();
      case 4:
        return () => cancelModal();
      default:
        return undefined;
    }
  };

  const handleSendSignature = async () => {
    if (signature && report?.id) {
      const reportSigned = await clientSigned(
        report.id,
        {
          ...paramsForSignature,
          comment: paramsForSignature.comment.length ? paramsForSignature.comment : ' ',
          signing_date: new Date().toISOString(),
        },
        signature
      );
      if (reportSigned) {
        nextStep();
      }
    }
  };

  const getConfirmDisabledStep = () => {
    switch (currentStep) {
      case 1:
        return !paramsForSignature.assesment || (paramsForSignature.assesment === 'D' && !paramsForSignature.comment);
      case 2:
        return !paramsForSignature.comment;
      case 3:
        return false;
      case 4:
        return false;
      default:
        return false;
    }
  };

  const getCancelTextStep = () => {
    if (currentStep === 1) return t('Cancel');
    if (currentStep === 2) return t('Skip');
    if (currentStep === 3) return t('Back');
    return undefined;
  };

  const getModalSize = () => {
    switch (currentStep) {
      case 1:
      case 2:
      case 3:
        return 'md';
      case 4:
        return 'sm';
      default:
        return 'md';
    }
  };

  return (
    <ModalCustom
      size={getModalSize()}
      titleText={getTitleText()}
      modalName="assessSignModal"
      onCloseEvent={cancelModal}
      open={dialogs.assessSignModal}
      onClose={handleOnCloseCustomDialogAction}
      onConfirm={handleConfirm()}
      confirmText={returnConfirmButtonText()}
      body={dialogBody}
      cancelText={getCancelTextStep()}
      cancelButtonVariant="text"
      confirmLoad={currentStep === 3}
      dialogActionsCenter={currentStep === 4}
      disabledConfirm={getConfirmDisabledStep()}
    />
  );
};

export default AssessSignModal;
