import { Typography } from '@mui/material';
import React from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const DescriptionCell = React.memo((row: OrderWorkunit) => (
  <Typography noWrap variant="body2">
    {row.workunit_desc ?? ''}
  </Typography>
));

export default DescriptionCell;
