import { createAction } from '@reduxjs/toolkit';
import User from 'types/entities/User';
import { initialValue } from '../../helperContext';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const setAffectationDMAction = createAction(
  OrderWorkunitAffectationType.SetAffectationDM,
  (deliveryManagers: User[]) => ({
    payload: { deliveryManagers },
  })
);

export default (state = initialValue, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.SetAffectationDM:
      return {
        ...state,
        deliveryManagers: action?.payload?.deliveryManagers,
      };
    default:
      return state;
  }
};
