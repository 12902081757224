import { IconButton } from '@mui/material';
import { CheckIcon, SendIcon } from 'components/CustomIcons';
import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import React from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import EmptyCell from '../EmptyCell/EmptyCell';

const ReviewCell = React.memo((row: OrderWorkunit) => {
  const { canReview } = useDeliverableRights([row]);
  const isReviewDone = () => row.review === 'done';
  const onClick = () => {
    // TODO open dialog review
  };

  return (
    <>
      {canReview ? (
        <>
          {isReviewDone() ? (
            <CheckIcon color="success" />
          ) : (
            <IconButton onClick={onClick} size="large">
              <SendIcon />
            </IconButton>
          )}
        </>
      ) : (
        <EmptyCell />
      )}
    </>
  );
});

export default ReviewCell;
