import { GridColDef } from '@mui/x-data-grid-pro';
import { TenantMonthsWorkload } from 'features/Admin/AdminTenantSettings/AdminTenantSettings';
import {
  ComputedWorkunits,
  WorkunitComplexityMonths,
} from 'features/Orders/OrderWorkunits/OrderWorkunitsDataGrids/SummaryDataGrid/SummaryDataGrid';
import CatalogComplexity from 'types/entities/CatalogComplexity';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import { SelectedWorkunit } from 'types/entities/SelectedWorkunit';
import { DisplaySummaryMode } from 'types/enums/DisplaySummaryMode';
import { Month } from 'types/models/Months';
import { Quarters } from 'types/models/Quarters';

export const CatalogToOrderActions = {
  INJECT_ORDER: 'INJECT_ORDER',
  INJECT_WORKUNITS: 'INJECT_WORKUNITS',
  INJECT_SELECTED_WORKUNITS: 'INJECT_SELECTED_WORKUNITS',
  INJECT_ORDER_WORKUNITS: 'INJECT_ORDER_WORKUNITS',
  INJECT_SCOPES: 'INJECT_SCOPES',
  INJECT_QUARTERS: 'INJECT_QUARTERS',
  INJECT_MONTHS: 'INJECT_MONTHS',
  INJECT_SCOPES_IN_ORDER: 'INJECT_SCOPES_IN_ORDER',
  INJECT_TENANT_WORKLOAD: 'INJECT_TENANT_WORKLOAD',
  INJECT_CATALOG_COMPLEXITIES: 'INJECT_CATALOG_COMPLEXITIES',
  INJECT_SUMMARY_MONTHS: 'INJECT_SUMMARY_MONTHS',
  INJECT_SUMMARY_QUARTERS: 'INJECT_SUMMARY_QUARTERS',
  ADD_SCOPE: 'ADD_SCOPE',
  ADD_WORKUNIT_TO_ORDER: 'ADD_WORKUNIT_TO_ORDER',
  CHANGE_IS_WORKUNITS_LOADED: 'CHANGE_IS_WORKUNITS_LOADED',
  UPDATE_WORKUNIT: 'UPDATE_WORKUNIT',
  UPDATE_SELECTED_WORKUNIT: 'UPDATE_SELECTED_WORKUNIT',
  CHANGE_WORKUNIT_SCOPE: 'CHANGE_WORKUNIT_SCOPE',
  CLEAR_WORKUNIT_SCOPE: 'CLEAR_WORKUNIT_SCOPE',
  RESET_ADDED_WORKUNIT: 'RESET_ADDED_WORKUNIT',
  CHANGE_MONTH_QUANTITY_WORKUNIT: 'CHANGE_MONTH_QUANTITY_WORKUNIT',
  CHANGE_MONTH_QUANTITY_ORDER_WORKUNIT: 'CHANGE_MONTH_QUANTITY_ORDER_WORKUNIT',
  INCREASE_MONTH_SLICE_CATALOG: 'INCREASE_MONTH_SLICE_CATALOG',
  DECREASE_MONTH_SLICE_CATALOG: 'DECREASE_MONTH_SLICE_CATALOG',
  INCREASE_MONTH_SLICE_SELECTED: 'INCREASE_MONTH_SLICE_SELECTED',
  DECREASE_MONTH_SLICE_SELECTED: 'DECREASE_MONTH_SLICE_SELECTED',
  INCREASE_MONTH_SLICE_SUMMARY: 'INCREASE_MONTH_SLICE_SUMMARY',
  DECREASE_MONTH_SLICE_SUMMARY: 'DECREASE_MONTH_SLICE_SUMMARY',
  INCREASE_QUARTER_SLICE_SUMMARY: 'INCREASE_QUARTER_SLICE_SUMMARY',
  DECREASE_QUARTER_SLICE_SUMMARY: 'DECREASE_QUARTER_SLICE_SUMMARY',
  INCREASE_CATALOG_CONTENTS_NUMBER: 'INCREASE_CATALOG_CONTENTS_NUMBER',
  DECREASE_CATALOG_CONTENTS_NUMBER: 'DECREASE_CATALOG_CONTENTS_NUMBER',
  TOGGLE_IS_SUMMARY_DEPLOYED: 'TOGGLE_IS_SUMMARY_DEPLOYED',
  SET_SUMMARY_COLUMNS_MONTHS: 'SET_SUMMARY_COLUMNS_MONTHS',
  SET_SUMMARY_VALUES_MONTHS: 'SET_SUMMARY_VALUES_MONTHS',
  SET_SUMMARY_COLUMNS_QUARTERS: 'SET_SUMMARY_COLUMNS_QUARTERS',
  SET_SUMMARY_VALUES_QUARTERS: 'SET_SUMMARY_VALUES_QUARTERS',
  CHANGE_SUMMARY_DISPLAY_MODE: 'CHANGE_SUMMARY_DISPLAY_MODE',
  RESET_CATALOG_TO_ORDER_STATE: 'RESET_CATALOG_TO_ORDER_STATE',
  INCREASE_SELECTED_CONTENTS_NUMBER: 'INCREASE_SELECTED_CONTENTS_NUMBER',
  DECREASE_SELECTED_CONTENTS_NUMBER: 'DECREASE_SELECTED_CONTENTS_NUMBER',
  SET_SELECTED_WORKUNIT_EDIT: 'SET_SELECTED_WORKUNIT_EDIT',
  REMOVE_SELECTED_WORKUNIT_EDIT: 'REMOVE_SELECTED_WORKUNIT_EDIT',
  DELETE_SELECTED_WORKUNIT: 'DELETE_SELECTED_WORKUNIT',
  UPDATE_SEARCH_PARAMS: 'UPDATE_SEARCH_PARAMS',
} as const;

export const injectOrderAction = (order: Order) => ({
  payload: { order },
  type: CatalogToOrderActions.INJECT_ORDER,
});

export const injectWorkunitsAction = (workunits: CatalogWorkunit[]) => ({
  payload: { workunits },
  type: CatalogToOrderActions.INJECT_WORKUNITS,
});

export const injectSelectedWorkunitsAction = (selectedWorkunits: SelectedWorkunit[]) => ({
  payload: { selectedWorkunits },
  type: CatalogToOrderActions.INJECT_SELECTED_WORKUNITS,
});

export const injectOrderWorkunitsAction = (orderWorkunits: OrderWorkunit[]) => ({
  payload: { orderWorkunits },
  type: CatalogToOrderActions.INJECT_ORDER_WORKUNITS,
});

export const injectScopesAction = (scopes: Scope[]) => ({
  payload: { scopes },
  type: CatalogToOrderActions.INJECT_SCOPES,
});

export const injectQuartersAction = (quarters: Quarters[]) => ({
  payload: { quarters },
  type: CatalogToOrderActions.INJECT_QUARTERS,
});

export const injectTenantWorkloadAction = (tenantWorkload: TenantMonthsWorkload[]) => ({
  payload: { tenantWorkload },
  type: CatalogToOrderActions.INJECT_TENANT_WORKLOAD,
});

export const injectMonthsAction = (months: Month[]) => ({
  payload: { months },
  type: CatalogToOrderActions.INJECT_MONTHS,
});

export const injectCatalogComplexities = (catalogComplexities: CatalogComplexity[]) => ({
  payload: { catalogComplexities },
  type: CatalogToOrderActions.INJECT_CATALOG_COMPLEXITIES,
});

export const injectSummaryMonthAction = (summaryMonth: WorkunitComplexityMonths[]) => ({
  payload: { summaryMonth },
  type: CatalogToOrderActions.INJECT_SUMMARY_MONTHS,
});

export const injectSummaryQuarterAction = (summaryQuarter: WorkunitComplexityMonths[]) => ({
  payload: { summaryQuarter },
  type: CatalogToOrderActions.INJECT_SUMMARY_QUARTERS,
});

export const injectScopesInOrderAction = (scopesInOrder: Scope[]) => ({
  payload: { scopesInOrder },
  type: CatalogToOrderActions.INJECT_SCOPES_IN_ORDER,
});

export const addScopeAction = (scope: Scope) => ({
  payload: scope,
  type: CatalogToOrderActions.ADD_SCOPE,
});

export const addWorkunitToOrderAction = (workunit: CatalogWorkunit) => ({
  payload: { workunit },
  type: CatalogToOrderActions.ADD_WORKUNIT_TO_ORDER,
});

export const changeIsWorkunitsLoadedAction = (isWorkunitsLoaded: boolean) => ({
  payload: { isWorkunitsLoaded },
  type: CatalogToOrderActions.CHANGE_IS_WORKUNITS_LOADED,
});

export const changeWorkunitsScopeAction = (scope: Scope, workunitId: number, target: 'catalog' | 'selected') => ({
  payload: { workunitId, scope, target },
  type: CatalogToOrderActions.CHANGE_WORKUNIT_SCOPE,
});

export const clearWorkunitScopeAction = (workunitId: number, target: 'catalog' | 'selected') => ({
  payload: { workunitId, target },
  type: CatalogToOrderActions.CLEAR_WORKUNIT_SCOPE,
});

export const resetAddedWorkunitAction = (workunitId: number) => ({
  payload: { workunitId },
  type: CatalogToOrderActions.RESET_ADDED_WORKUNIT,
});

export const changeMonthQuantityWorkunitAction = (workunitId: number, monthDate: string, quantity: number) => ({
  payload: { workunitId, monthDate, quantity },
  type: CatalogToOrderActions.CHANGE_MONTH_QUANTITY_WORKUNIT,
});

export const changeMonthQuantityOrderWorkunitAction = (workunitId: number, monthDate: string, quantity: number) => ({
  payload: { workunitId, monthDate, quantity },
  type: CatalogToOrderActions.CHANGE_MONTH_QUANTITY_ORDER_WORKUNIT,
});

export const updateWorkunitAction = (workunit: Partial<CatalogWorkunit>) => ({
  payload: { workunit },
  type: CatalogToOrderActions.UPDATE_WORKUNIT,
});

export const updateSelectedWorkunitAction = (workunit: Partial<SelectedWorkunit>) => ({
  payload: { workunit },
  type: CatalogToOrderActions.UPDATE_SELECTED_WORKUNIT,
});

export const increaseCatalogMonthSliceAction = () => ({
  type: CatalogToOrderActions.INCREASE_MONTH_SLICE_CATALOG,
  payload: { target: 'catalog' },
});

export const decreaseCatalogMonthSliceAction = () => ({
  type: CatalogToOrderActions.DECREASE_MONTH_SLICE_CATALOG,
  payload: { target: 'catalog' },
});

export const increaseSelectedMonthSliceAction = () => ({
  type: CatalogToOrderActions.INCREASE_MONTH_SLICE_SELECTED,
  payload: { target: 'selected' },
});

export const decreaseSelectedMonthSliceAction = () => ({
  type: CatalogToOrderActions.DECREASE_MONTH_SLICE_SELECTED,
  payload: { target: 'selected' },
});

export const increaseMonthSliceSummaryAction = () => ({
  type: CatalogToOrderActions.INCREASE_MONTH_SLICE_SUMMARY,
  payload: { target: 'summary' },
});
export const decreaseMonthSliceSummaryAction = () => ({
  type: CatalogToOrderActions.DECREASE_MONTH_SLICE_SUMMARY,
  payload: { target: 'summary' },
});

export const increaseQuarterSliceSummaryAction = () => ({
  type: CatalogToOrderActions.INCREASE_QUARTER_SLICE_SUMMARY,
});

export const decreaseQuarterSliceSummaryAction = () => ({
  type: CatalogToOrderActions.DECREASE_QUARTER_SLICE_SUMMARY,
});

export const increaseCatalogContentsNumberAction = () => ({
  type: CatalogToOrderActions.INCREASE_CATALOG_CONTENTS_NUMBER,
  payload: { target: 'catalog' },
});

export const decreaseCatalogContentsNumberAction = () => ({
  type: CatalogToOrderActions.DECREASE_CATALOG_CONTENTS_NUMBER,
  payload: { target: 'catalog' },
});

export const increaseSelectedContentsNumberAction = () => ({
  type: CatalogToOrderActions.INCREASE_SELECTED_CONTENTS_NUMBER,
  payload: { target: 'selected' },
});

export const decreaseSelectedContentsNumberAction = () => ({
  type: CatalogToOrderActions.DECREASE_SELECTED_CONTENTS_NUMBER,
  payload: { target: 'selected' },
});

export const setSelectedWorkunitEditAction = (selectedWorkunitId: number) => ({
  type: CatalogToOrderActions.SET_SELECTED_WORKUNIT_EDIT,
  payload: { selectedWorkunitId },
});

export const removeSelectedWorkunitEditAction = (selectedWorkunitId: number) => ({
  type: CatalogToOrderActions.REMOVE_SELECTED_WORKUNIT_EDIT,
  payload: { selectedWorkunitId },
});

export const deleteSelectedWorkunitAction = (selectedWorkunitId: number) => ({
  type: CatalogToOrderActions.DELETE_SELECTED_WORKUNIT,
  payload: { selectedWorkunitId },
});

export const toggleIsSummaryDeployedAction = () => ({
  type: CatalogToOrderActions.TOGGLE_IS_SUMMARY_DEPLOYED,
});

export const setSummaryColumnsMonths = (columns: GridColDef[]) => ({
  payload: { columns },
  type: CatalogToOrderActions.SET_SUMMARY_COLUMNS_MONTHS,
});

export const setSummaryColumnsQuarters = (columns: GridColDef[]) => ({
  payload: { columns },
  type: CatalogToOrderActions.SET_SUMMARY_COLUMNS_QUARTERS,
});

export const setSummaryValuesMonths = (values: ComputedWorkunits[]) => ({
  payload: { values },
  type: CatalogToOrderActions.SET_SUMMARY_VALUES_MONTHS,
});

export const setSummaryValuesQuarters = (values: ComputedWorkunits[]) => ({
  payload: { values },
  type: CatalogToOrderActions.SET_SUMMARY_VALUES_QUARTERS,
});

export const changeDisplayModeAction = (displayMode: DisplaySummaryMode) => ({
  payload: { displayMode },
  type: CatalogToOrderActions.CHANGE_SUMMARY_DISPLAY_MODE,
});

export const resetCatalogToOrderStateAction = () => ({
  type: CatalogToOrderActions.RESET_CATALOG_TO_ORDER_STATE,
});

export const updateSearchParamsAction = (
  newSearchValue: { [key: string]: string | number | null },
  target: 'catalog' | 'selected'
) => ({
  type: CatalogToOrderActions.UPDATE_SEARCH_PARAMS,
  payload: { newSearchValue, target },
});

export const catalogToOrderActions = {
  injectOrderAction,
  injectWorkunitsAction,
  injectSelectedWorkunitsAction,
  injectOrderWorkunitsAction,
  injectScopesAction,
  injectMonthsAction,
  injectQuartersAction,
  injectTenantWorkloadAction,
  injectCatalogComplexities,
  injectSummaryMonthAction,
  injectSummaryQuarterAction,
  injectScopesInOrderAction,
  addScopeAction,
  addWorkunitToOrderAction,
  changeIsWorkunitsLoadedAction,
  changeWorkunitsScopeAction,
  clearWorkunitScopeAction,
  resetAddedWorkunitAction,
  changeMonthQuantityWorkunitAction,
  changeMonthQuantityOrderWorkunitAction,
  updateWorkunitAction,
  updateSelectedWorkunitAction,
  increaseCatalogMonthSliceAction,
  decreaseCatalogMonthSliceAction,
  increaseSelectedMonthSliceAction,
  decreaseSelectedMonthSliceAction,
  increaseMonthSliceSummaryAction,
  decreaseMonthSliceSummaryAction,
  increaseQuarterSliceSummaryAction,
  decreaseQuarterSliceSummaryAction,
  increaseCatalogContentsNumberAction,
  decreaseCatalogContentsNumberAction,
  toggleIsSummaryDeployedAction,
  setSummaryColumnsMonths,
  setSummaryColumnsQuarters,
  setSummaryValuesMonths,
  setSummaryValuesQuarters,
  changeDisplayModeAction,
  resetCatalogToOrderStateAction,
  increaseSelectedContentsNumberAction,
  decreaseSelectedContentsNumberAction,
  setSelectedWorkunitEditAction,
  removeSelectedWorkunitEditAction,
  deleteSelectedWorkunitAction,
  updateSearchParamsAction,
};
