import { Box, Tab, Tabs } from '@mui/material';
import { DraftIcon } from 'components/CustomIcons';
import React, { useCallback } from 'react';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { changeCurrentTabAction } from '../../../store/actions/documentsActions';
import { DocumentsTabState } from '../../../types/enums/DocumentsTabState';

const DocumentsTabs: React.FC = () => {
  const { state, dispatch } = useAppState();
  const currentTab = useSelector((state) => state.documents.currentTab);
  const currentTabs = useCallback(
    () => [
      {
        label: DocumentsTabState.TCS,
        value: DocumentsTabState.TCS,
        icon: <DraftIcon fontSize="small" />,
      },
    ],
    []
  );

  const handleTabChange = (value: DocumentsTabState) => {
    dispatch(changeCurrentTabAction(value));
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
      {currentTab && (
        <Tabs value={currentTab}>
          {currentTabs().map((tab) => (
            <Tab
              data-testid={`documents_tab_${tab.value}`}
              label={tab.label}
              value={tab.value}
              icon={tab.icon}
              iconPosition="start"
              onClick={() => handleTabChange(tab.value)}
              disabled={state.documents.currentTab === tab.value}
            />
          ))}
        </Tabs>
      )}
    </Box>
  );
};

export default DocumentsTabs;
