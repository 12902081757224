/* eslint-disable max-len */
import { handleApiError } from 'api/errors';
import ResourceApi, { Resource, SearchParams } from 'api/ResourceAPI';
/* eslint-disable class-methods-use-this */
import MissionComment from 'types/entities/MissionComment';
import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { SelectedFiltersList } from '../types/models/SelectedFiltersList';
import BaseApiService from './BaseApiService';
import InMemoryMissionApiService from './InMemory/InMemoryMissionApiService';

export type OrderWorkunitKeys = keyof OrderWorkunit;

export interface IMissionApiService {
  baseUrl: string;
  fetchAllFrequencies(): Promise<any[]>;
  fetchAllStatus(): Promise<any[]>;
  archiveComments(comment_id: MissionComment['id'], orderWorkunit_id: OrderWorkunit['id']): Promise<any | undefined>;
  archiveMissions(missionsIds: OrderWorkunit['id'][]): Promise<void>;
  fetchForAffectationMissions(searched: SearchParams): Promise<any[]>;
  resetWorkunit(id: number): Promise<any>;
  addCommentToMission(
    comment: Partial<MissionComment>,
    orderWorkunit: Partial<OrderWorkunit>
  ): Promise<MissionComment | undefined>;
  fetchAllMissions(
    orderId: string,
    customerId: number,
    additionalParams?: SearchParams,
    props?: OrderWorkunitKeys[]
  ): Promise<{
    data: {
      datas: OrderWorkunit[];
      totalPages: number;
      totalItems: number;
      currentPage: number;
    };
  }>;
  bulkUpdate(
    orderId: number,
    missionIds: number[],
    updatedContent: Partial<OrderWorkunit>,
    selectAll: boolean,
    filters?: SelectedFiltersList,
    modificationReason?: string
  ): Promise<any>;
  updateMission(id: number, missionEditParams: Partial<OrderWorkunit>): Promise<any>;
  rateMission(id: number, missionEditParams: Partial<OrderWorkunit>): Promise<any>;
  duplicateMission(mission: Partial<OrderWorkunit>): Promise<Partial<OrderWorkunit> | boolean>;
  submitMission(id: number): Promise<any>;
  validateMission(
    id: number,
    missionAcceptStatus:
      | MissionAdvancementPossibilities.ACCEPTATION_TL
      | MissionAdvancementPossibilities.REFUSED_TL
      | MissionAdvancementPossibilities.RESERVE_TL
  ): Promise<OrderWorkunit>;
  acceptMission(
    id: number,
    missionAcceptStatus:
      | MissionAdvancementPossibilities.ACCEPTATION_CLIENT
      | MissionAdvancementPossibilities.REFUSED_CLIENT
      | MissionAdvancementPossibilities.RESERVE_CLIENT
  ): Promise<OrderWorkunit>;
  deleteMissionById(mission: OrderWorkunit): Promise<any | undefined>;
  restoreDeliverable(mission: OrderWorkunit): Promise<any | undefined>;
}
class MissionApiService extends BaseApiService implements IMissionApiService {
  static instance: MissionApiService;

  baseUrlFrequencies = 'mission-frequencies' as Resource;

  baseUrlStatus = 'mission-status' as Resource;

  baseUrl: string;

  constructor(private url = '/deliverables') {
    super();
    this.baseUrl = `${process.env.REACT_APP_SERVER_URL}${url}`;
  }

  get Instance() {
    // eslint-disable-next-line no-return-assign
    return MissionApiService.instance || (MissionApiService.instance = new MissionApiService());
  }

  async fetchAllFrequencies() {
    try {
      const { data } = await ResourceApi.get(this.baseUrlFrequencies);
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  async fetchAllStatus() {
    try {
      const { data } = await ResourceApi.get(this.baseUrlStatus);
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  async archiveComments(comment_id: MissionComment['id'], orderWorkunit_id: OrderWorkunit['id']) {
    try {
      return await ResourceApi.delete(`order-workunits/${orderWorkunit_id}/mission-comments` as Resource, comment_id);
    } catch (error) {
      handleApiError(error);
      return undefined;
    }
  }

  async archiveMissions(missionsIds: OrderWorkunit['id'][]) {
    try {
      await ResourceApi.post('order-workunits/delete', { ressources: missionsIds });
    } catch (error) {
      handleApiError(error);
    }
  }

  async fetchForAffectationMissions(searched: SearchParams) {
    try {
      const { data } = await ResourceApi.get('order-workunits', searched);
      return data;
    } catch (error) {
      handleApiError(error);
      return [];
    }
  }

  async resetWorkunit(id: number) {
    try {
      const { data } = await ResourceApi.patchById('order-workunits', id, {
        mission_advancement_id: null,
        client_id: null,
        consultant_id: null,
      });
      return data;
    } catch (error) {
      handleApiError(error);
      return {};
    }
  }

  // eslint-disable-next-line consistent-return
  async addCommentToMission(
    comment: Partial<MissionComment>,
    orderWorkunit: Partial<OrderWorkunit>
  ): Promise<MissionComment | undefined> {
    try {
      const { data } = await ResourceApi.post('mission-comments', comment);

      return data.mission_comment;
    } catch (error) {
      handleApiError(error);
      return undefined;
    }
  }

  async bulkUpdate(
    orderId: number,
    missionIds: number[],
    updatedContent: Partial<OrderWorkunit>,
    selectAll: boolean,
    filters?: SelectedFiltersList,
    modificationReason?: string
  ) {
    try {
      const toSend: {
        ressources?: number[];
        data: Partial<OrderWorkunit>;
        modification_reason: string | null;
        order_id: Order['id'];
        filters?: {
          select_all: boolean;
        };
      } = {
        data: updatedContent,
        modification_reason: modificationReason ?? ' ',
        order_id: orderId,
        filters: {
          ...filters,
          select_all: selectAll,
        },
      };
      if (missionIds.length > 0) {
        toSend.ressources = missionIds;
      }
      const { data } = await ResourceApi.patchWithAdditionalPath('orders', orderId, 'order-workunits/bulk', toSend);
      return data;
    } catch (error) {
      handleApiError(error);
      return {};
    }
  }

  async fetchAllMissions(
    orderId: string,
    customerId: number,
    additionalParams?: SearchParams,
    props: OrderWorkunitKeys[] = []
  ): Promise<{ data: { datas: OrderWorkunit[]; totalPages: number; totalItems: number; currentPage: number } }> {
    const params = {
      customer_id: customerId,
      is_archived: 0,
      order_id: orderId,
      join: [
        'scope',
        'workunit',
        'mission-frequency',
        'mission-status',
        'mission-comments',
        'mission-comments.author',
        'mission-advancement',
        'consultant',
        'client',
        'last_validator',
        'last_reviewer',
      ],
      attr: props.join('.'),
      ...additionalParams,
    };

    return ResourceApi.get('order-workunits', params);
  }

  async updateMission(id: number, missionEditParams: Partial<OrderWorkunit>) {
    const res = await ResourceApi.put('order-workunits', id, missionEditParams);

    return res;
  }

  async rateMission(id: number, missionEditParams: Partial<OrderWorkunit>) {
    try {
      const { data } = await ResourceApi.patchById('order-workunits', id as number, {
        rating: missionEditParams.rating,
      });
      return data;
    } catch (error) {
      handleApiError(error);
      return {};
    }
  }

  async duplicateMission(mission: Partial<OrderWorkunit>): Promise<Partial<OrderWorkunit> | boolean> {
    try {
      const {
        data: { order_workunits },
      } = await ResourceApi.post('order-workunits', {
        ...mission,
        mission_advancement_id: null,
        label: mission?.workunit_id ? 'added' : 'custom',
        origin: mission?.workunit_id ? 'added' : 'custom',
      });
      return { ...mission, ...order_workunits, 'mission-comments': [], mission_advancement_id: null };
    } catch (error) {
      handleApiError(error);
      return false;
    }
  }

  async submitMission(id: number) {
    try {
      const {
        data: { order_workunit },
      } = await ResourceApi.patchById('order-workunits', id as number, {
        mission_advancement_id: MissionAdvancementPossibilities.SUBMITTED,
      });

      return order_workunit;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  }

  async validateMission(
    id: number,
    missionAcceptStatus:
      | MissionAdvancementPossibilities.ACCEPTATION_TL
      | MissionAdvancementPossibilities.REFUSED_TL
      | MissionAdvancementPossibilities.RESERVE_TL
  ): Promise<OrderWorkunit> {
    try {
      const {
        data: { order_workunit },
      } = await ResourceApi.patchById('order-workunits', id as number, {
        mission_advancement_id: missionAcceptStatus,
      });
      return order_workunit;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  }

  async acceptMission(
    id: number,
    missionAcceptStatus:
      | MissionAdvancementPossibilities.ACCEPTATION_CLIENT
      | MissionAdvancementPossibilities.REFUSED_CLIENT
      | MissionAdvancementPossibilities.RESERVE_CLIENT
  ): Promise<OrderWorkunit> {
    try {
      const {
        data: { order_workunit },
      } = await ResourceApi.patchById('order-workunits', id as number, {
        mission_advancement_id: missionAcceptStatus,
      });
      return order_workunit;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  }

  async deleteMissionById(mission: OrderWorkunit) {
    try {
      // to comment when mission:delete is implemented for BM and DM.
      return await ResourceApi.delete(`order-workunits`, mission.id);
    } catch (error) {
      handleApiError(error);
      return undefined;
    }
  }

  async restoreDeliverable(mission: OrderWorkunit) {
    try {
      const data = await ResourceApi.put(`order-workunits`, mission.id, {
        label: mission.label?.replaceAll('cancelled', '') !== '' ? mission.label?.replaceAll('cancelled', '') : null,
      });
      return data.data.order_workunit;
    } catch (error) {
      handleApiError(error);
      return undefined;
    }
  }
}

const isTestMode = process.env.REACT_APP_NODE_ENV === 'test';
const missionApiService = isTestMode ? new InMemoryMissionApiService() : new MissionApiService();

export default missionApiService;
