import { useEffect, useState } from 'react';
import { selectFilterAction } from '../store/actions/filterActions';
import { useSelector } from '../store/hooks';
import { useAppState } from '../store/Provider';
import useDebounce from './useDebounce';

/**
 * React hook to delay state update for each text input changes and prevent freezes due to multiple api calls
 */
export default function useTextSearch() {
  const { dispatch } = useAppState();
  const { filter } = useSelector((state) => state);
  const [textSearch, setTextSearch] = useState('');

  const onChangeTextSearch = () => {
    dispatch(
      selectFilterAction({
        text_search: textSearch ? [textSearch] : [],
      })
    );
  };
  const debouncedOnChange = useDebounce(onChangeTextSearch);

  useEffect(() => {
    if (filter.selected.text_search && filter.selected.text_search.length > 0) {
      setTextSearch(filter.selected.text_search[0]);
    } else {
      setTextSearch('');
    }
  }, [filter.selected.text_search]);

  return { textSearch, setTextSearch, debouncedOnChange };
}
