import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { getColumnsParamsDef } from '../../../helpersOrderWorkunits';
import NotEditableCellContent from '../../Cells/NotEditableCellContent/NotEditableCellContent';
import ContentCell from './ContentCell';
import ContentHeader from './ContentHeader';

export const contentsColumns = (
  contentColumnsNumber: number,
  apiRef: React.MutableRefObject<GridApiPro>,
  type: 'catalog' | 'order',
  activeSelectedWorkunitEditIds?: number[]
) => {
  const contentColumnsDef: GridColDef[] = [];
  for (let i = 1; i <= contentColumnsNumber; i += 1) {
    contentColumnsDef.push({
      ...(getColumnsParamsDef({
        headerName: i === 1 ? 'content' : `content${i}`,
        field: i === 1 ? 'content' : `content${i}`,
        align: 'center',
        editable: true,
        cellClassName: 'clickable',
        minWidth: 300,
      }) as GridColDef),
      renderCell: (params: GridCellParams) =>
        type === 'catalog' ? (
          <ContentCell apiRef={apiRef} params={params} />
        ) : (
          <NotEditableCellContent params={params} />
        ),
      renderHeader: () => <ContentHeader number={i} type={type} />,
    });
  }
  return contentColumnsDef;
};
