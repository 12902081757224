import useUserRoles from 'hooks/useUserRoles';
import { useMemo } from 'react';
import { useSelector } from 'store/hooks';
import { Rating } from 'types/enums/Rating';
import { FilterItem, FilterItemOption, FilterParams, FilterParamsDeliverable } from 'types/models/FilterItem';
import useDeliverableService from './useDeliverableService';

/**
 * Method to redefined options' object structure according with frontend select components
 */
const loadOptions = (filterParams: FilterParams, userRoles: any) => {
  type OptionName = 'missionAdvancement' | 'scope' | 'consultant' | 'client' | 'delivery_managers' | 'purchase_order';
  const missionAdvancementPossibles = !userRoles.isCustomer
    ? [
        { value: 'NOT_SUBMITTED', label: 'Not submitted' },
        { value: 'SUBMITTED', label: 'Submitted' },
        { value: 'NOT_VALIDATED', label: 'Not validated' },
        { value: 'VALIDATED', label: 'Validated' },
        { value: 'NOT_ACCEPTED', label: 'Not accepted' },
        { value: 'ACCEPTED', label: 'Accepted' },
        { value: 'ACCEPTED_RESERVES', label: 'Accepted with reservations' },
        { value: 'REFUSED_CLIENT', label: 'Refused (by Client)' },
      ]
    : [
        { value: 'NOT_VALIDATED', label: 'Not validated' },
        { value: 'VALIDATED', label: 'Validated' },
        { value: 'NOT_ACCEPTED', label: 'Not accepted' },
        { value: 'ACCEPTED', label: 'Accepted' },
        { value: 'ACCEPTED_RESERVES', label: 'Accepted with reservations' },
        { value: 'REFUSED_CLIENT', label: 'Refused (by Client)' },
      ];
  const options: Record<OptionName, FilterItemOption[]> = {
    missionAdvancement: missionAdvancementPossibles,
    scope: [],
    consultant: [],
    delivery_managers: [],
    client: [],
    purchase_order: [],
  };

  if (!userRoles.isCustomer) {
    options.missionAdvancement.splice(5, 0, { value: 'REFUSED_DM', label: 'Refused (by Delivery Manager)' });
  }

  function typeGuard(filterParams: FilterParams): filterParams is FilterParamsDeliverable {
    return !!filterParams && !('business_managers' in filterParams);
  }
  if (typeGuard(filterParams)) {
    if (filterParams.scopes) {
      filterParams.scopes.forEach((scope) => {
        options.scope.push({ label: scope.name, value: scope.id });
      });
    }
    if (filterParams.consultants) {
      filterParams.consultants.forEach((consultant) => {
        options.consultant.push({
          label: `${consultant.last_name} ${consultant.first_name}`,
          value: consultant.id,
        });
      });
    }
    if (filterParams.clients) {
      filterParams.clients.forEach((client) => {
        options.client.push({
          label: `${client.last_name} ${client.first_name}`,
          value: client.id,
        });
      });
    }
    if (filterParams.delivery_managers) {
      filterParams.delivery_managers.forEach((dm) => {
        options.delivery_managers.push({
          label: `${dm.last_name} ${dm.first_name}`,
          value: dm.id,
        });
      });
    }

    if (filterParams.purchase_orders) {
      filterParams.purchase_orders.forEach((po) => {
        options.purchase_order.push({ label: po, value: po });
      });
    }
  }

  return options;
};

/**
 * React hook to build filter form with items according to api response for option available for current user logged
 */
export const useDeliverableFilterItems = () => {
  const filterState = useSelector((state) => state.filter);
  const userRoles = useUserRoles();
  const { isTnM } = useDeliverableService();

  return useMemo<FilterItem[]>(() => {
    const options = loadOptions(filterState.params, userRoles);

    return [
      {
        field: 'label',
        filterName: 'Tag',
        inputLabel: 'Select label',
        inputType: 'select',
        options: [
          {
            value: 'quote',
            label: 'Quote',
          },
          {
            value: 'custom',
            label: 'Custom',
          },
          {
            value: 'added',
            label: 'Added',
          },
          {
            value: 'updated',
            label: 'Updated',
          },
          {
            value: 'cancelled',
            label: 'Cancelled',
          },
        ],
        isVisible: true,
      },
      {
        field: 'delivery_date',
        filterName: 'Initial delivery date',
        inputType: 'date',
        rangeDate: true,
        optionsDisplayDirection: 'column',
        isVisible: true,
      },
      {
        field: 'forecast_date',
        filterName: 'New expected delivery date',
        rangeDate: true,
        inputType: 'date',
        isVisible: true,
      },
      {
        field: 'consultant_id',
        filterName: 'Consultant',
        inputLabel: 'Select consultant',
        inputType: 'select',
        options: options.consultant,
        isVisible: !userRoles.isClient && !userRoles.isConsultant,
      },
      {
        field: 'delivery_manager',
        filterName: 'Delivery manager',
        inputLabel: 'Select delivery manager',
        inputType: 'select',
        options: options.delivery_managers,
        uniq: true,
        isVisible: true,
      },
      {
        field: 'scope_id',
        filterName: 'Scope',
        inputLabel: 'Select scope',
        inputType: 'select',
        options: options.scope,
        isVisible: true,
      },
      {
        field: 'client_id',
        filterName: 'Client',
        inputLabel: 'Select client',
        inputType: 'select',
        options: options.client,
        isVisible: true,
      },
      {
        field: 'mission_advancement_id',
        filterName: 'Deliverable Status',
        inputType: 'checkbox',
        options: options.missionAdvancement,
        isVisible: true,
      },
      {
        field: 'rating',
        filterName: 'Note',
        inputType: 'checkbox',
        options: [
          { value: Rating.A, label: 'A' },
          { value: Rating.B, label: 'B' },
          { value: Rating.C, label: 'C' },
          { value: Rating.D, label: 'D' },
          { value: Rating.S, label: 'Skip' },
        ],

        isVisible: true,
      },
      {
        field: 'purchase_order',
        filterName: 'Purchase order',
        inputLabel: 'Select purchase order',
        inputType: 'select',
        options: options.purchase_order,
        isVisible: isTnM() === true,
      },
    ];
  }, [filterState.params, userRoles]);
};
