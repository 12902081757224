import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import EmptyState from './EmptyState';

type DisplayLoadingOrNotResultProps = {
  items: any[] | null;
};
const DisplayLoadingOrNotResult = (props: DisplayLoadingOrNotResultProps) => {
  const { items } = props;
  const [rendered, setRendered] = useState(false);
  const loadings = useSelector((state) => state.loadings);
  const [t] = useTranslation();
  useEffect(() => {
    if (items && items?.length === 0) setRendered(true);
    else setRendered(false);
    return () => setRendered(false);
  }, [items]);
  if (rendered && !loadings.orders && !loadings.api) {
    return <EmptyState title={t('Nothing')} />;
  }
  return <> </>;
};
export default DisplayLoadingOrNotResult;
