/* eslint-disable class-methods-use-this */
import { OrderWorkunitKeys } from 'api/MissionApiService';
import { IOrderWorkunitApiService, PostBulkOrderWorkunitsWorkunits } from 'api/OrderWorkunitApiService';
import { SearchParams } from 'api/ResourceAPI';
import moment from 'moment';
import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import OrderWorkunitModification from 'types/entities/OrderWorkunitModification';
import MissionForCreation from 'types/models/MissionForCreation';
import { orderWorkunitA } from '../../tests/_fixtures/orderWorkunits';
import { SelectedFiltersList } from '../../types/models/SelectedFiltersList';

export default class InMemoryOrderWorkunitApiService implements IOrderWorkunitApiService {
  static baseUrl = 'order-workunits' as const;

  static baseUrlBulk = `${process.env.REACT_APP_SERVER_URL}/order-workunits/bulk` as const;

  static resource = 'order-workunits' as const;

  static baseUrlXlsx = 'order-workunits/export/xlsx' as const;

  static resourcebulk = 'order-workunits/bulk' as const;

  static axios = {} as any;

  public static Instance: InMemoryOrderWorkunitApiService;

  get Instance() {
    // eslint-disable-next-line no-return-assign
    return InMemoryOrderWorkunitApiService || new InMemoryOrderWorkunitApiService();
  }

  getAllOrderWorkUnitByOrderId(id: number, additionalParams?: SearchParams | undefined): Promise<OrderWorkunit[]> {
    throw new Error('Method not implemented.');
  }

  async postBulkOrderWorkunits(body: {
    order_id: Order['id'];
    order_workunits: PostBulkOrderWorkunitsWorkunits;
  }): Promise<[] | null> {
    return Promise.resolve([]);
  }

  async updateWorkunit(workunitId: number, workunit: Partial<OrderWorkunit>): Promise<OrderWorkunit> {
    return Promise.resolve({
      id: workunitId,
      ...workunit,
    } as OrderWorkunit);
  }

  async sendModificationWithReason(
    orderId: number,
    modificationBody: Omit<OrderWorkunitModification, 'id' | 'created_at' | 'updated_at'>
  ): Promise<void | undefined> {
    throw new Error('Method not implemented.');
  }

  async getClientOrderWorkunitsByDateAndScope(
    order_id: number,
    params: { scope_ids: number[]; dates: { start: string; end: string }[] }
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async massCancelOrderWorkunits(
    orderId: number,
    missionIds: number[],
    cancellation_reason: string,
    selectAll: boolean,
    filters?: SelectedFiltersList
  ): Promise<OrderWorkunitModification> {
    return Promise.resolve({
      id: 0,
      order_workunit_id: missionIds[0],
      created_at: '2023-01-16T09:40:41.000Z',
      updated_at: '2023-02-24T16:33:56.000Z',
      comment: 'comment',
      user_id: 1,
      old_value: 'coment',
      new_value: 'comment',
      attribute: 'mission_advancement_id',
    });
  }

  async duplicateManyOrderWorkunit(orderIds: number[]): Promise<OrderWorkunit[]> {
    const returned: OrderWorkunit[] = [];
    orderIds.forEach((id) => {
      returned.push({ id: id + 12 } as OrderWorkunit);
    });
    return Promise.resolve(returned);
  }

  updateManyOrderWorkunit(body: OrderWorkunit[]): Promise<OrderWorkunit[]> {
    throw new Error('Method not implemented.');
  }

  bulkUpdateOrderWorkunits(ids: number[], dataToUpdate: Partial<OrderWorkunit>): Promise<OrderWorkunit[]> {
    return Promise.resolve(
      ids.map(
        (id) =>
          ({
            id,
            ...dataToUpdate,
          } as OrderWorkunit)
      )
    );
  }

  archiveWorkunits(missionsIds: number[], selectAll: boolean, filters?: SelectedFiltersList): Promise<void> {
    return Promise.resolve();
  }

  async getOrderWorkunitByOrderId(
    _id: number,
    _props: OrderWorkunitKeys[],
    _params?: SearchParams
  ): Promise<OrderWorkunit> {
    // Return fake data
    return orderWorkunitA as unknown as OrderWorkunit;
  }

  async createOrderWorkunits(
    owus: Partial<OrderWorkunit> | Partial<OrderWorkunit>[] | MissionForCreation[]
  ): Promise<OrderWorkunit[]> {
    const processOrderWorkunit = (
      orderWorkunit: MissionForCreation,
      startDate: moment.Moment,
      endDate: moment.Moment
    ): MissionForCreation => ({
      ...orderWorkunit,
      start_frequency_date: startDate.format('YYYY-MM-DD'),
      end_frequency_date: endDate.format('YYYY-MM-DD'),
    });

    const generateOrderWorkunits = (
      orderWorkunit: MissionForCreation,
      startDate: moment.Moment,
      endDate: moment.Moment
    ): OrderWorkunit[] => {
      const diffWeeks = endDate.diff(startDate, 'weeks');
      const orderWorkunits: MissionForCreation[] = [];

      if (orderWorkunit.mission_frequency_id === 2 && startDate && endDate) {
        const workunit = processOrderWorkunit(orderWorkunit, startDate, endDate);
        orderWorkunits.push(workunit);

        if (diffWeeks > 0) {
          const nextStartDate = startDate.clone().add(1, 'weeks').endOf('isoWeek');
          const nextEndDate = endDate.clone().endOf('isoWeek');

          orderWorkunits.push(...generateOrderWorkunits(workunit, nextStartDate, nextEndDate));
        }
      } else {
        orderWorkunits.push(orderWorkunit as MissionForCreation);
      }

      return orderWorkunits as unknown as OrderWorkunit[];
    };

    if (Array.isArray(owus)) {
      return owus.flatMap((owu) =>
        generateOrderWorkunits(
          owu as MissionForCreation,
          moment((owu as MissionForCreation).start_frequency_date),
          moment((owu as MissionForCreation).end_frequency_date)
        )
      );
    }
    return generateOrderWorkunits(
      owus as MissionForCreation,
      moment((owus as MissionForCreation).start_frequency_date),
      moment((owus as MissionForCreation).end_frequency_date)
    );
  }

  async exportXlsx(_params?: {
    customer_id?: number;
    order_workunit_ids: number[];
    order_id?: number;
    filters?: {
      select_all: boolean;
    };
  }): Promise<undefined> {
    // Return fake data
    return Promise.resolve(undefined);
  }
}
