export const LoadingsActions = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  RESET: 'RESET',
} as const;

export const addLoadingAction = (payload: string) => ({
  type: LoadingsActions.ADD,
  payload,
});

export const removeLoadingAction = (payload: string) => ({
  type: LoadingsActions.REMOVE,
  payload,
});

export const resetLoadingAction = () => ({
  type: LoadingsActions.RESET,
});

export const loadingsActions = {
  addLoadingAction,
  removeLoadingAction,
  resetLoadingAction,
};
