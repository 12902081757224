import React from 'react';
import ApproveSignModal from './ApproveSignModal/ApproveSignModal';
import AssessSignModal from './AssessSignModal/AssessSignModal';
import DialogSendToClient from './DialogSendToClient/DialogSendToClient';

export default function Dialogs() {
  return (
    <>
      <DialogSendToClient />
      <ApproveSignModal />
      <AssessSignModal />
    </>
  );
}
