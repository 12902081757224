import { createAction } from '@reduxjs/toolkit';
import Scope from 'types/entities/Scope';
import { initialValue } from '../../helperContext';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const injectScopes = createAction(OrderWorkunitAffectationType.InjectScopes, (scopes: Scope[]) => ({
  payload: { scopes },
}));

export default (state = initialValue, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.InjectScopes:
      return {
        ...state,
        scopes: action.payload.scopes,
      };
    default:
      return state;
  }
};
