import { intersection, pick, union, values } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'store/hooks';
import { getPermissions } from '../helpers/permissions';

/**
 * React hook and test method to know if the user has permission on an action according to his role
 */
export default function useUserPermissions() {
  const { roles } = useSelector((state) => state.app);
  return useMemo((): string[] => (roles ? union(...values(pick(getPermissions, roles))) : []), [roles]);
}

export const hasPermissions = (permissions: string[], userPermissions: string[]): boolean =>
  !!intersection(permissions, userPermissions).length;
