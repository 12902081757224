import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectActiveDeliverable } from 'store/selectors/deliverableSelectors';
import { Rating } from 'types/enums/Rating';

const DeliverableDialogRate: React.FC = () => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const activeDeliverable = useSelector(selectActiveDeliverable);
  const dialogs = useSelector((state) => state.dialogs);
  const loadings = useSelector((state) => state.loadings);
  const [comment, setComment] = useState<string>('');

  const { addCommentDeliverable, setRatingDeliverables } = useDeliverableService();

  const close = () => {
    dispatch(closeDialogAction('deliverableRate'));
  };

  const handleCommentRating = async () => {
    const rate: string | undefined = dialogs?.data?.rate;
    if (comment !== '' && activeDeliverable) {
      dispatch(addLoadingAction('deliverableCommentRate'));
      const constructComment = () =>
        !rate ? { comment, is_private: false } : { comment, is_private: false, rating: rate };
      await addCommentDeliverable(activeDeliverable, constructComment());
      await setRatingDeliverables([activeDeliverable], Rating.D, null);
      setComment('');
      dispatch(removeLoadingAction('deliverableCommentRate'));
      close();
    }
  };

  return (
    <>
      <Dialog open={dialogs.deliverableRate || false} fullWidth maxWidth="xs" id="dialog-comment-rate">
        <DialogTitle className="">{t('Leave_a_comment')}</DialogTitle>
        <DialogContent style={{ width: '100%' }}>
          <TextField
            value={comment || ''}
            onChange={(e) => setComment(e.currentTarget.value)}
            rows={5}
            fullWidth
            multiline
            sx={{ mb: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close} disabled={!!loadings.deliverableCommentRate}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={!!loadings.deliverableCommentRate}
            disabled={comment === ''}
            className="submit-comment-button"
            onClick={handleCommentRating}
            color={!loadings.deliverableCommentRate ? 'success' : 'error'}
          >
            {t('Send_rating')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeliverableDialogRate;
