import { createAction } from '@reduxjs/toolkit';
import { initialValue } from '../../intialValue';
import { PossibleSheetActions, SheetActions } from '../../SheetContext.helper';

export const setPdfFileNameAction = createAction(SheetActions.SetPdfFileName, (pdfFileName: string) => ({
  payload: pdfFileName,
}));

export default (state = initialValue, action: PossibleSheetActions) => {
  switch (action.type) {
    case SheetActions.SetPdfFileName:
      return {
        ...state,
        pdfFileName: action.payload,
      };
    default:
      return state;
  }
};
