import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import styled from 'styled-components/macro';
import Divider from './Divider';
import { Card, Text } from './index';

interface Props {
  title: string[];
  titlefontsize?: number[];
  titlefontcolor?: string[];
  titlefontweight?: string[];
  icon?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  contentfontsize?: number[];
  contentfontcolor?: string[];
  contentfontweight?: string[];
  width?: number;
  height?: number;
}

const CardContainer: React.FC<Props> = ({
  title,
  titlefontsize,
  titlefontcolor,
  icon,
  content,
  contentfontsize,
  contentfontcolor,
  width,
  height,
}) => {
  const { currentWidth } = useWindowDimensions();

  return (
    <>
      <Card
        childComp={
          <Container>
            <Column>
              <Text
                value={title[0] || 'NONE'}
                color={titlefontcolor ? titlefontcolor[0] : '#A4A4A4'}
                size={titlefontsize ? titlefontsize[0] : 13}
              />
              <Text
                value={content[0]}
                color={contentfontcolor ? contentfontcolor[0] : '#616161'}
                size={contentfontsize ? contentfontsize[0] : 16}
              />
            </Column>

            <Divider vertical={currentWidth > 1000} size={currentWidth > 1000 ? 30 : 229} color="#D8D8D8" />

            <Row>
              <Column>
                <Text
                  value={title[1]}
                  color={titlefontcolor ? titlefontcolor[1] : '#A4A4A4'}
                  size={titlefontsize ? titlefontsize[1] : 13}
                />
                <Text
                  value={content[1]}
                  color={contentfontcolor ? contentfontcolor[1] : '#616161'}
                  size={contentfontsize ? contentfontsize[1] : 16}
                />
                <Text
                  value={content[2]}
                  color={contentfontcolor ? contentfontcolor[1] : '#616161'}
                  size={contentfontsize ? contentfontsize[1] : 16}
                />
              </Column>
            </Row>

            <Divider vertical={currentWidth > 1000} size={currentWidth > 1000 ? 30 : 229} color="#D8D8D8" />

            <Row>
              <Column>
                <Text
                  value={title[2]}
                  color={titlefontcolor ? titlefontcolor[2] : '#A4A4A4'}
                  size={titlefontsize ? titlefontsize[2] : 13}
                />
                <Text
                  value={content[3]}
                  color={contentfontcolor ? contentfontcolor[2] : '#616161'}
                  size={contentfontsize ? contentfontsize[2] : 16}
                />
              </Column>
            </Row>
          </Container>
        }
      />
    </>
  );
};
export default CardContainer;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.9rem;
  padding: 1rem 0px;

  @media (min-width: 1000px) {
    flex-direction: row;
    margin: 1.4rem 2.7rem;
    /* padding: 0.8rem 2.6rem; */
    gap: 3.7rem;
  }

  @media (min-width: 1900px) {
    flex-direction: row;
    margin: 1.4rem 2.7rem;
    /* padding: 0.8rem 1.6rem; */
    gap: 3.7rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: 18px;
`;
