import { MonthType, WorkunitMonth } from 'features/Orders/OrderWorkunits/helpersOrderWorkunits';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'store/hooks';
import { WorkunitComplexityMonths } from '../SummaryDataGrid';

type DisplayCellSummaryDataGridProps = {
  usedWorkunits: WorkunitComplexityMonths[];
  month: MonthType;
  propertyName: keyof WorkunitMonth;
  randomId: number;
};

export function DisplayCellMonthSummaryDataGrid(props: DisplayCellSummaryDataGridProps): JSX.Element {
  const {
    month: { date: workunitMonthDate },
    propertyName,
    randomId,
  } = props;
  const monthToUse = useSelector((state) =>
    state.catalogToOrder.summary.usedMonthsWorkunits.find(
      (uw) => moment(uw?.date).format('MM-YYYY') === moment(workunitMonthDate).format('MM-YYYY')
    )
  );

  if (monthToUse?.[randomId] || monthToUse?.[randomId] === 0) {
    const value = (monthToUse[randomId] / monthToUse.workload) * 100;
    return <div className="bold-darker-blue">{!Number.isNaN(value) ? value.toFixed(2) : 0}</div>;
  }
  return (
    <div className="bold-darker-blue">
      {!monthToUse && 0}

      {(monthToUse || monthToUse === 0) &&
        typeof monthToUse[propertyName] === 'number' &&
        ((monthToUse[propertyName] as number).toFixed(2) ?? 0)}
      {monthToUse && typeof monthToUse[propertyName] !== 'number' && monthToUse[propertyName]}
    </div>
  );
}

export function DisplayCellQuarterSummaryDataGrid(props: DisplayCellSummaryDataGridProps): JSX.Element {
  const {
    month: { date: workunitMonthDate },
    usedWorkunits,
    propertyName,
    randomId,
  } = props;
  const monthToUse = usedWorkunits.find((uw) =>
    moment(uw?.date).isBetween(
      moment(workunitMonthDate).startOf('quarter'),
      moment(workunitMonthDate).endOf('quarter'),
      'day',
      '[]'
    )
  );
  if (monthToUse?.[randomId] || monthToUse?.[randomId] === 0) {
    const value = (monthToUse[randomId] / monthToUse.workload) * 100;
    return <div className="bold-darker-blue">{!Number.isNaN(value) ? value.toFixed(2) : 0}</div>;
  }

  return (
    <div className="bold-darker-blue">
      {!monthToUse && 0}

      {(monthToUse || monthToUse === 0) &&
        typeof monthToUse[propertyName] === 'number' &&
        ((monthToUse[propertyName] as number).toFixed(2) ?? 0)}
      {monthToUse && typeof monthToUse[propertyName] !== 'number' && monthToUse[propertyName]}
    </div>
  );
}
