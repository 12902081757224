import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { changeCurrentTab } from '../SheetContext/_reducers/CurrentTab/CurrentTab';
import { useSheetContext } from '../SheetContext/SheetContext';
import { TabSheet } from '../SheetContext/SheetContext.helper';
import { BoxIcon, ClipboardIcon } from 'components/CustomIcons';

const SheetTab: React.FC = () => {
  const { state, dispatch } = useSheetContext();
  const handleChange = (event: React.SyntheticEvent, newValue: TabSheet) => {
    dispatch(changeCurrentTab(newValue));
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: '#dae0ee', mb: 4 }}>
      <Tabs value={state.currentTab} onChange={handleChange} aria-label="basic tabs example">
        <Tab label={TabSheet.DELIVERABLE} value={TabSheet.DELIVERABLE} iconPosition="start" icon={<BoxIcon />} />
        <Tab icon={<ClipboardIcon />} value={TabSheet.PREVIEW} label={TabSheet.PREVIEW} iconPosition="start" />
      </Tabs>
    </Box>
  );
};

export default SheetTab;
