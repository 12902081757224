import LockIcon from '@mui/icons-material/Lock';
import { Tooltip } from '@mui/material';
import { GridCellParams, GridColDef, GridColumnHeaderParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import SearchHeaderCell from 'components/SearchHeaderCell/SearchHeaderCell';
import { TFunction } from 'i18next';
import moment from 'moment';
import React from 'react';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import { isWorkunitResetable } from '../__helper';
import CellUserNotEditMode from './AffectationGridComponents/CellUserNotEditMode/CellUserNotEditMode';
import OrderAffectationUserCell from './AffectationGridComponents/OrderAffectationUserCell/OrderAffectationUserCell';

export function createColumnsDatagridAffectation(
  t: TFunction,
  consultants: User[],
  customer: User[],
  deliveryManagers: User[],
  scopes: Scope[]
): GridColDef<any, any, any>[] {
  const userLocale = navigator.language || 'fr-FR';
  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  return [
    {
      field: 'workunit_reference',
      headerName: t('Reference'),
      sortable: false,
      flex: 0.8,
      cellClassName: 'deliverable-name',
      headerClassName: 'table-row-header',
      renderCell: (params) => TooltipNotEditableCell(params?.row?.workunit_reference, t),
      renderHeader: (params: GridColumnHeaderParams) => <SearchHeaderCell {...params} />,
    },
    {
      field: 'workunit_name',
      headerName: t('Name'),
      sortable: false,
      flex: 1.4,
      cellClassName: 'deliverable-name',
      headerClassName: 'table-row-header',
      renderCell: (params) => TooltipNotEditableCell(params?.row?.workunit_name, t),
      renderHeader: (params: GridColumnHeaderParams) => <SearchHeaderCell {...params} />,
    },
    {
      field: 'complexity_name',
      headerName: t('Complexity'),
      sortable: false,
      flex: 0.5,
      cellClassName: 'deliverable-name',
      headerClassName: 'table-row-header',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.complexity_name,
      renderCell: (params) => TooltipNotEditableCell(params?.row?.complexity_name, t),
      renderHeader: (params: GridColumnHeaderParams) => <SearchHeaderCell {...params} />,

      align: 'center',
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, 'complexity') - param2.api.getCellValue(param2.id, 'complexity'),
    },
    {
      field: 'scope_id',
      headerName: t('Scope'),
      sortable: false,
      flex: 0.7,
      cellClassName: 'deliverable-name ',
      headerClassName: 'table-row-header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => TooltipNotEditableCell(params?.row?.scope?.name ?? t('General'), t),
      renderHeader: (params: GridColumnHeaderParams) => <SearchHeaderCell field={params.field} options={scopes} />,

      // renderHeader: () => (
      //   <SearchHeaderCell
      //     headerName={t('Scope')}
      //     field="scope_id"
      //     searchType="select"
      //     selectOptions={state.scopes}
      //     onUpdate={onSearchUpdate}
      //     searchValue={state.search.scope_id}
      //   />
      // ),
    },
    {
      field: 'delivery_date',
      headerName: t('Deliverable_date'),
      sortable: false,
      flex: 0.7,
      cellClassName: 'deliverable-name',
      headerClassName: 'table-row-header',
      headerAlign: 'left',
      valueGetter: (params: GridValueGetterParams) => moment(params.row.delivery_date).format(dateFormat),
      align: 'left',
      renderHeader: (params: GridColumnHeaderParams) => <SearchHeaderCell {...params} />,
    },
    // {
    //   field: 'forecast_date',
    //   headerName: t('Forecasted_date'),
    //   sortable: false,
    //   flex: 0.7,
    //   cellClassName: 'deliverable-name clickable',
    //   headerClassName: 'table-row-header',
    //   headerAlign: 'left',
    //   editable: true,
    //   type: 'date',
    //   align: 'left',
    //   valueGetter: (params: GridValueGetterParams) => moment(params.row.forecast_date).format('DD/MM/yyyy').toString(),
    //   valueParser: (value: any, params: any) => parseDate(value, params),
    //   renderHeader: () => (
    //     <RenderHeaderCell headerName={t('Forecasted_date')} field="forecast_date" searchType="date" />
    //   ),
    //   renderCell: (params) => (
    //     <>
    //       <EventRepeatSharpIcon />
    //       {params.value}
    //     </>
    //   ),
    // },

    {
      field: 'consultant_fullname',
      headerName: t('Consultant'),
      sortable: false,
      flex: 1,
      cellClassName: 'deliverable-name clickable',
      headerClassName: 'table-row-header',
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderHeader: (params: GridColumnHeaderParams) => <SearchHeaderCell {...params} />,
      renderCell: (params) => (
        <CellUserNotEditMode params={params} propertyTarget="consultant_fullname" addUserText="Add consultant" />
      ),
      renderEditCell: (params) =>
        consultants && consultants.length ? (
          <OrderAffectationUserCell
            params={params}
            options={consultants}
            multiple={false}
            getOptionLabel={(parameter) => `${parameter.last_name.toLocaleUpperCase()} ${parameter.first_name} `}
            propertyTarget="consultant"
          />
        ) : (
          <></>
        ),
    },
    {
      field: 'delivery_manager_fullname',
      headerName: t('delivery_manager'),
      sortable: false,
      flex: 1,
      cellClassName: 'deliverable-name clickable',
      headerClassName: 'table-row-header',
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderCell: (params) => (
        <CellUserNotEditMode
          params={params}
          propertyTarget="delivery_manager_fullname"
          addUserText="Add delivery manager"
        />
      ),
      renderHeader: (params: GridColumnHeaderParams) => <SearchHeaderCell {...params} />,
      renderEditCell: (params) =>
        deliveryManagers && deliveryManagers.length ? (
          <OrderAffectationUserCell
            params={params}
            options={deliveryManagers}
            multiple={false}
            getOptionLabel={(parameter) => `${parameter.first_name} ${parameter.last_name.toUpperCase()} `}
            propertyTarget="delivery_manager"
          />
        ) : (
          <></>
        ),
    },
    {
      field: 'client_fullname',
      headerName: t('Client'),
      sortable: false,
      flex: 1,
      cellClassName: 'deliverable-name clickable',
      headerClassName: 'table-row-header',
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderCell: (params) => (
        <CellUserNotEditMode params={params} propertyTarget="client_fullname" addUserText="Add customer" />
      ),
      renderHeader: (params: GridColumnHeaderParams) => <SearchHeaderCell {...params} />,
      renderEditCell: (params) =>
        customer && customer.length ? (
          <OrderAffectationUserCell
            params={params}
            options={customer}
            multiple={false}
            getOptionLabel={(parameter) => `${parameter.first_name} ${parameter.last_name.toUpperCase()} `}
            propertyTarget="client"
          />
        ) : (
          <></>
        ),
    },
    {
      field: 'Action',
      headerName: t(''),
      sortable: false,
      flex: 0.2,
      headerAlign: 'left',
      cellClassName: 'deliverable-name',
      headerClassName: 'table-row-header',
      align: 'center',
      renderCell: (params: GridCellParams) =>
        isWorkunitResetable(params) ? (
          <div className={`action-button-container `}>
            <Tooltip
              title={t<string>(
                'The deliverable is already submitted please cancel submission to enable the assignement modification'
              )}
            >
              <LockIcon />
            </Tooltip>
          </div>
        ) : (
          <></>
        ),
    },
  ];
}

// function parseDate(value: any, params: any): any {
//   const newValue = moment(value).format('yyyy-MM-DD').toString();
//   if (!newValue) return params.value;
//   return newValue;
// }

function TooltipNotEditableCell(value: string | null, t: TFunction): React.ReactNode {
  return React.useMemo(
    () => (
      <Tooltip arrow title={value ?? t<string>('Not defined')}>
        <div className="params_row_name">{value ?? t('Not defined')}</div>
      </Tooltip>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );
}
