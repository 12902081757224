import { Tooltip, Typography } from '@mui/material';
import React from 'react';

const ReferenceCell = React.memo(({ reference }: { reference: string }) => (
  <Tooltip arrow title={reference}>
    <Typography variant="body2" noWrap>
      {reference}
    </Typography>
  </Tooltip>
));

export default ReferenceCell;
