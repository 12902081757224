import { SnackbarActions } from '../actions/snackbarActions';
import { RootActions } from '../redux';
import { snackbarState, SnackbarState } from '../states/snackbarState';

const snackbarReducer = (state = snackbarState, action: RootActions): SnackbarState => {
  switch (action.type) {
    case SnackbarActions.SET_SNACKBAR:
      return action.payload;
    default:
      return state;
  }
};

export default snackbarReducer;
