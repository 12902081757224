import { AddCircle, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Link } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Month } from 'types/models/Months';
import useOrderWorkunitsService from '../../../../services/useOrderWorkunitsService';
import './SelectWorkunitButton.scss';

type SelectWorkunitButtonMode = 'catalog' | 'order';

type SelectWorkunitButtonProps = {
  workunitId: number;
  month: Month;
  mode: SelectWorkunitButtonMode;
  quantity: number;
};
const SelectWorkunitButton = (props: SelectWorkunitButtonProps) => {
  const { workunitId, month, mode, quantity } = props;
  const searchInput = useRef<HTMLInputElement>(null);
  const [localQuantity, setLocalQuantity] = React.useState(quantity);
  const { updateWorkunitMonthQuantity, updateOrderWorkunitMonthQuantity } = useOrderWorkunitsService();

  function handleFocus() {
    searchInput?.current?.focus();
  }

  useEffect(() => {
    if (quantity === 0 && localQuantity > 0) {
      setLocalQuantity(quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (localQuantity !== quantity) {
        if (mode === 'catalog') updateWorkunitMonthQuantity(workunitId, month.date, localQuantity);
        if (mode === 'order') updateOrderWorkunitMonthQuantity(workunitId, month.date, localQuantity);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localQuantity]);

  const decrementQuantity = () => {
    if (localQuantity > 0) {
      setLocalQuantity((prev) => prev - 1);
    }
  };

  const incrementQuantity = () => {
    setLocalQuantity((prev) => prev + 1);
  };

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    handleFocus();
    setLocalQuantity(Number(e.target.value));
  }

  return localQuantity > 0 ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box>
        <Link onClick={incrementQuantity} component="button" sx={{ display: 'flex' }}>
          <ExpandLess color="primary" />
        </Link>
      </Box>
      <Box sx={{ color: 'text.primary' }}>
        <input
          type="number"
          value={localQuantity}
          className="quantity"
          dir="rtl"
          min={1}
          key={`${workunitId}${month.date}`}
          ref={searchInput}
          onClick={(e) => e.stopPropagation()}
          onChange={handleInputChange}
        />
      </Box>
      <Box>
        <Link onClick={decrementQuantity} component="button" sx={{ display: 'flex' }}>
          <ExpandMore color="primary" />
        </Link>
      </Box>
    </Box>
  ) : (
    <IconButton onClick={incrementQuantity}>
      <AddCircle className="button_add_workunit" color="primary" />{' '}
    </IconButton>
  );
};

export default SelectWorkunitButton;
