/* eslint-disable class-methods-use-this */
import { IAuthApiService } from 'api/AuthApiService';
import Token from 'types/models/Token';

export default class InMemoryAuthApiService implements IAuthApiService {
  private token: Token | null = null;

  public async getAuthAuthorization(): Promise<string> {
    return Promise.resolve('fake-auth-url');
  }

  public async getAuthCode(authUri: string): Promise<{ tokenUri: string }> {
    return Promise.resolve({ tokenUri: 'fake-token-uri' });
  }

  public async getAuthToken(tokenUri: string): Promise<Token> {
    const token: Token = {
      access_token: 'fake-access-token',
      id_token: 'fake-id-token',
      refresh_token: 'fake-refresh-token',
      expires_in: 3600,
      token_type: 'Bearer',
      expires_at: Date.now() + 3600 * 1000,
    };

    this.setLocalStorageToken(token);
    this.token = token;

    return Promise.resolve(token);
  }

  public async getTokenUri(code: string): Promise<{ tokenUri: string }> {
    return Promise.resolve({ tokenUri: 'fake-token-uri' });
  }

  public async getRefreshTokenUri(refresh_token: string): Promise<{ tokenUri: string }> {
    return Promise.resolve({ tokenUri: 'fake-refresh-token-uri' });
  }

  // eslint-disable-next-line class-methods-use-this
  public setLocalStorageToken(data: Token): void {
    const tokenAlreadyPresent = localStorage.getItem('cognito-token');
    let dataToStock = data;
    if (tokenAlreadyPresent) {
      dataToStock = { ...JSON.parse(tokenAlreadyPresent), ...data };
    }
    dataToStock.expires_at = Date.now() + (dataToStock.expires_in as number) * 1000;
    localStorage.setItem('cognito-token', JSON.stringify(dataToStock));
  }
}
