/* eslint-disable class-methods-use-this */
import { IMissionApiService } from 'api/MissionApiService';
import { SearchParams } from 'api/ResourceAPI';
import MissionComment from 'types/entities/MissionComment';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { SelectedFiltersList } from '../../types/models/SelectedFiltersList';

export default class InMemoryMissionApiService implements IMissionApiService {
  baseUrl: string;

  constructor() {
    this.baseUrl = 'http://localhost:3000';
  }

  fetchAllFrequencies(): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  fetchAllStatus(): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  archiveComments(comment_id: number, orderWorkunit_id: number): Promise<any> {
    return Promise.resolve({});
  }

  archiveMissions(missionsIds: number[]): Promise<void> {
    throw new Error('Method not implemented.');
  }

  fetchForAffectationMissions(searched: SearchParams): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  resetWorkunit(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }

  addCommentToMission(
    comment: Partial<MissionComment>,
    orderWorkunit: Partial<OrderWorkunit>
  ): Promise<MissionComment | undefined> {
    return Promise.resolve(comment as MissionComment);
  }

  fetchAllMissions(
    orderId: string,
    customerId: number,
    additionalParams?: SearchParams | undefined,
    props?: (keyof OrderWorkunit)[] | undefined
  ): Promise<{ data: { datas: OrderWorkunit[]; totalPages: number; totalItems: number; currentPage: number } }> {
    throw new Error('Method not implemented.');
  }

  bulkUpdate(
    orderId: number,
    missionIds: number[],
    updatedContent: Partial<OrderWorkunit>,
    selectAll: boolean,
    filters?: SelectedFiltersList,
    modificationReason?: string
  ): Promise<any> {
    return Promise.resolve({
      updatedContent,
    });
  }

  updateMission(id: number, missionEditParams: Partial<OrderWorkunit>): Promise<any> {
    throw new Error('Method not implemented.');
  }

  rateMission(id: number, missionEditParams: Partial<OrderWorkunit>): Promise<any> {
    throw new Error('Method not implemented.');
  }

  duplicateMission(mission: Partial<OrderWorkunit>): Promise<boolean | Partial<OrderWorkunit>> {
    throw new Error('Method not implemented.');
  }

  submitMission(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }

  validateMission(
    id: number,
    missionAcceptStatus:
      | MissionAdvancementPossibilities.REFUSED_TL
      | MissionAdvancementPossibilities.RESERVE_TL
      | MissionAdvancementPossibilities.ACCEPTATION_TL
  ): Promise<OrderWorkunit> {
    throw new Error('Method not implemented.');
  }

  acceptMission(
    id: number,
    missionAcceptStatus:
      | MissionAdvancementPossibilities.REFUSED_CLIENT
      | MissionAdvancementPossibilities.RESERVE_CLIENT
      | MissionAdvancementPossibilities.ACCEPTATION_CLIENT
  ): Promise<OrderWorkunit> {
    throw new Error('Method not implemented.');
  }

  deleteMissionById(mission: OrderWorkunit): Promise<any> {
    throw new Error('Method not implemented.');
  }

  restoreDeliverable(mission: OrderWorkunit): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
