import { Box, Button, Typography } from '@mui/material';
import ApiAuthService from 'api/AuthApiService';
import { Layout } from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadTokenAction } from 'store/actions/appActions';
import { useAppState } from 'store/Provider';

const NotAffilied = () => {
  const { dispatch } = useAppState();
  const [t] = useTranslation();
  const [isTimeoutFinished, setIsTimeoutFinished] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(() => setIsTimeoutFinished(true), 10000);
    }
    return () => {
      setIsTimeoutFinished(false);
      mounted = false;
    };
  }, []);

  const handleDisconnect = async () => {
    localStorage.removeItem('cognito-token');
    localStorage.removeItem('active_customer');
    dispatch(loadTokenAction());
    const getUri = async () => {
      const uri = await ApiAuthService.getAuthAuthorization();
      window.location.href = uri;
    };
    await getUri();
  };
  return (
    <Layout name="" path="/not_affilied">
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        {/* {!isTimeoutFinished && <Loader />} */}
        {isTimeoutFinished && (
          <>
            <Typography variant="h1" color="neutral.main" sx={{ fontSize: '5rem', fontWeight: 600 }}>
              404
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
              <Typography>{t('Text_not_affilied_1')}</Typography>
              <Typography sx={{ mb: 3 }}> {t('Text_not_affilied_2')}</Typography>
            </Box>
            <Button variant="contained" onClick={handleDisconnect}>
              {t('Disconnect')}
            </Button>
          </>
        )}
      </Box>
    </Layout>
  );
};
export default NotAffilied;
