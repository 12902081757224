import { createAction } from '@reduxjs/toolkit';
import { deliverableState } from 'store/states/deliverableState';
import Order from 'types/entities/Order';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const injectOrderAction = createAction(OrderWorkunitAffectationType.InjectOrder, (order: Order) => ({
  payload: { order },
}));

export default (state = deliverableState, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.InjectOrder:
      return {
        ...state,
        order: {
          ...action?.payload?.order,
        },
      };
    default:
      return state;
  }
};
