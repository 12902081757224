import React, { useEffect, useState } from 'react';
import { useSelector } from 'store/hooks';

declare global {
  interface Window {
    FreshworksWidget: any;
  }
}

const FreshdeskWidget: React.FC = () => {
  const userState = useSelector((state) => state.app.user);
  const name = `${userState?.first_name || ''} ${userState?.last_name || ''}`.trim();
  const email = userState?.mail;
  const business_unit = userState?.department;

  useEffect(() => {
    // Call identify
    window.FreshworksWidget('identify', 'ticketForm', {
      name,
      email,
    });

    // Prefill and disable fields
    window.FreshworksWidget('prefill', 'ticketForm', {
      custom_fields: {
        cf_user_business_unit: business_unit,
      },
    });

    window.FreshworksWidget('disable', 'ticketForm', ['name', 'email']);
  }, []);

  return null;
};

export default FreshdeskWidget;
