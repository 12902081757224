import { Menu, Tooltip, IconButton, Badge, Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import NotificationItem from './NotificationItem';
// import './Notifications.scss';
import useApi from 'hooks/useApi';
import NotificationApiService from 'api/NotificationApiService';
import { theme } from 'styles/theme';
import { BellIcon } from 'components/CustomIcons';
import EmptyState from 'components/EmptyState';

type NotificationProps = {
  alerts: any[];
  setAlerts: (id: number) => void;
  markAllAsReads: () => void;
  handleOnScroll: () => void;
  listRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
};

const NotificationButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 3px 12px #B2BCD571',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  '& .MuiBadge-badge': {
    top: '-8px',
    right: '-8px',
  },
}));

export const Notification = (props: NotificationProps) => {
  const { alerts, setAlerts, markAllAsReads, handleOnScroll, listRef, isLoading } = props;
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [loading, setIsLoading] = useState(false);
  const [alertsNotReadNumber, setAlertNotReadNumber] = useState(0);
  const { makeCall } = useApi();

  useEffect(() => {
    if (alerts.length) {
      setAlertNotReadNumber(alerts.filter((alert) => !alert.is_read).length);
    } else {
      setAlertNotReadNumber(0);
    }
  }, [alerts]);

  const markAllAsRead = async () => {
    await makeCall(NotificationApiService.markAllRead(), 'Unable to mark all notifications as read', setIsLoading);
    markAllAsReads();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t('Notifications') as string}>
        <NotificationButton onClick={handleClick} size="large" sx={{ mr: 4 }}>
          <Badge badgeContent={alertsNotReadNumber} color="primary">
            <BellIcon />
          </Badge>
        </NotificationButton>
      </Tooltip>
      <Menu
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        PaperProps={{
          sx: { maxWidth: '550px', minWidth: { md: '350px' } },
        }}
      >
        <Box
          sx={{ display: 'flex', alignItems: 'center', p: 2, borderBottom: '1px solid', borderColor: 'neutral.light' }}
        >
          <Typography variant="h4" component="div">
            {t('Notifications')}
          </Typography>
          {loading ? (
            <Loader size={10} />
          ) : (
            alerts.length > 0 && (
              <Link onClick={() => markAllAsRead()} sx={{ ml: 'auto', cursor: 'pointer' }}>
                {t('Mark_all_as_read')}
              </Link>
            )
          )}
        </Box>
        <Box sx={{ maxHeight: '40vh', overflow: 'auto' }} onScroll={handleOnScroll} ref={listRef}>
          {alerts.length > 0 ? (
            alerts
              .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at))
              .map((alert) => (
                <NotificationItem alert={alert} setAlerts={setAlerts} key={alert.id} closeMenu={handleClose} />
              ))
          ) : (
            <EmptyState title={t('no_alerts')} />
          )}
          {isLoading && <Loader size={30} />}
        </Box>
      </Menu>
    </>
  );
};
