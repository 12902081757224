import './Kpi.scss';
import React from 'react';
// import IconComponent from 'components/IconComponent';
import Rating from 'components/Rating/Rating';
import { Typography, Box } from '@mui/material';

interface Props {
  number: number;
  content: string;
  icon?: string;
  rating?: string;
  className?: string;
  children?: React.ReactElement<any, any>;
}

const KeyNumbers: React.FC<Props> = ({ number, content, icon, rating, className, children }) => (
  <div className={`kpi ${className}`}>
    {children ? <Box className="kpi__icon">{children}</Box> : null}
    {rating ? (
      <Box className="kpi__rating">
        <Rating rating={rating} />
      </Box>
    ) : null}
    <Box className="kpi__content">
      <Typography sx={{ fontWeight: 700 }}>{number}</Typography>
      <Typography sx={{ color: 'text.secondary', fontWeight: 500 }}>{content}</Typography>
    </Box>
  </div>
);

export default KeyNumbers;
