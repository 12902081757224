import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectActiveDeliverable, selectSelectedDeliverables } from 'store/selectors/deliverableSelectors';
import useDeliverableService from '../../hooks/useDeliverableService';

const DeliverableDialogDelete: React.FC = () => {
  const [t] = useTranslation();

  const { deleteDeliverables } = useDeliverableService();
  const { dispatch } = useAppState();
  const selectedDeliverables = useSelector(selectSelectedDeliverables);
  const activeDeliverable = useSelector(selectActiveDeliverable);
  const deliverables = activeDeliverable ? [activeDeliverable] : selectedDeliverables;
  const dialogs = useSelector((state) => state.dialogs);
  const close = () => {
    dispatch(closeDialogAction('deliverableDelete'));
  };
  const remove = () => {
    const orderWorkunitsIds = deliverables.map((orderWorkunit) => orderWorkunit.id);
    deleteDeliverables(orderWorkunitsIds);
    close();
  };

  return (
    <>
      <Dialog style={{ maxHeight: 435 }} maxWidth="xs" open={dialogs.deliverableDelete || false}>
        <DialogTitle>{t('Delete deliverables')}</DialogTitle>
        <DialogContent>
          <div>{t('Confirm_delete_deliverables')}</div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={close}>
            {t('Cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={remove}>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeliverableDialogDelete;
