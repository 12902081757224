import { createAction } from '@reduxjs/toolkit';
import User from 'types/entities/User';
import { initialValue } from '../../helperContext';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const setAffectationConsultantsAction = createAction(
  OrderWorkunitAffectationType.SetAffectationConsultants,
  (consultants: User[]) => ({
    payload: { consultants },
  })
);

export default (state = initialValue, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.SetAffectationConsultants:
      return {
        ...state,
        consultants: action?.payload?.consultants,
      };
    default:
      return state;
  }
};
