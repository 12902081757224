import { createAction } from '@reduxjs/toolkit';
import { PossibleSheetActions, SheetActions } from 'features/Sheet/SheetContext/SheetContext.helper';
import { initialValue } from '../../intialValue';

export const setPdfBlobUrlAction = createAction(SheetActions.SetPdfBlobUrl, (pdfBlobUrl: string) => ({
  payload: { pdfBlobUrl },
}));

export default (state = initialValue, action: PossibleSheetActions) => {
  switch (action.type) {
    case SheetActions.SetPdfBlobUrl:
      return {
        ...state,
        pdfBlobUrl: action.payload.pdfBlobUrl,
      };
    default:
      return state;
  }
};
