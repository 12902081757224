import { Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type ProtectedLinkProps = {
  pathname: string;
  right?: boolean;
  children?: React.ReactNode;
};

const ProtectedLink: React.FC<ProtectedLinkProps> = ({ pathname, right = true, children }) =>
  right ? (
    <Link
      component={RouterLink}
      to={{
        pathname,
      }}
      underline="none"
      noWrap
    >
      {children}
    </Link>
  ) : (
    <div>{children}</div>
  );

export default ProtectedLink;
