export enum OrdersTabState {
  PENDING = 'pending',
  DRAFTED = 'drafted',
  ARCHIVED = 'archived',
  ON_GOING = 'production',
  TO_ASSIGN = 'validated',
  CLOSED = 'closed',
  NULL = '',
  MATERIAL_DRAFT = 'material_drafts',
  MATERIAL_PRODUCTION = 'material_production',
  MATERIAL_CLOSED = 'material_closed',
  MATERIAL_DELETED = 'material_deleted',
}
