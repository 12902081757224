/* eslint-disable react-hooks/exhaustive-deps */
import { Add, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import WorkunitApiService from 'api/WorkunitApiService';
import { Loader } from 'components';
import useApi from 'hooks/useApi';
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import Devise from 'types/entities/Devise';
import Workunit from 'types/entities/Workunit';

type WorkUnitWithDeliverable = Omit<Workunit, 'id'>;

const WorkunitCreation = (props: {
  catalog_id: number;
  catalogDevises: Devise[];
  dispatchOpen: () => void;
  createWorkunit: (workunit: CatalogWorkunit) => void;
}) => {
  const { dispatch } = useAppState();
  const [t] = useTranslation();
  const { makeCall } = useApi();
  const { catalog_id, catalogDevises, dispatchOpen, createWorkunit } = props;
  const [valuesSelected, setValuesSelected] = React.useState<Partial<WorkUnitWithDeliverable>>({
    catalog_id,
    price: 0,
    charge: 0,
  });
  const complexities = useSelector((state) => state.app.catalogComplexities);

  const [isWorkunitValidationDisabled, setIsWorkunitValidationDisabled] = useState<boolean>(true);

  // const [deliverableName] = useState<string>('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValuesSelected({ ...valuesSelected, [e.currentTarget.name]: e.currentTarget.value });
  };
  const handleDeviseChange = (e: any) => {
    setValuesSelected({
      ...valuesSelected,
      devise_id: e.currentTarget.value,
    });
  };

  // eslint-disable-next-line consistent-return
  const submit = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault();
    try {
      setIsLoading(true);

      const workunitCreated = await makeCall(WorkunitApiService.create(valuesSelected));

      if (workunitCreated) {
        createWorkunit({
          ...workunitCreated,
          complexity: complexities.find((complexity) => complexity.id === workunitCreated.complexity_id),
          devise: devises.find((devise) => devise.id === workunitCreated.devise_id),
        });

        setIsLoading(false);
      }
    } catch (error) {
      const result = (error as Error).message;

      if (result) {
        dispatch(setSnackbarAction({ message: `Unable to edit workunit: ${result}`, open: true, severity: 'error' }));
      } else {
        dispatch(setSnackbarAction({ message: `Unable to edit workunit : ${error}`, open: true, severity: 'error' }));
      }
    } finally {
      dispatchOpen();
    }
  };

  useEffect(() => {
    const isFormOk =
      !!valuesSelected.devise_id &&
      !!valuesSelected.input &&
      !!valuesSelected.level_standard &&
      !!valuesSelected.accept_standard &&
      !!valuesSelected.name &&
      !!valuesSelected.output &&
      !!valuesSelected.reference &&
      !!valuesSelected.desc;
    if (isFormOk) setIsWorkunitValidationDisabled(false);
  }, [
    valuesSelected,
    valuesSelected.desc,
    valuesSelected.devise_id,
    valuesSelected.input,
    valuesSelected.level_standard,
    valuesSelected.name,
    valuesSelected.output,
    valuesSelected.reference,
  ]);

  const [devises] = useState<Devise[]>(catalogDevises);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (complexities && complexities?.length > -1) {
      setValuesSelected({
        ...valuesSelected,
        complexity_id: complexities?.[0].id,
      });
    }
  }, [complexities, complexities?.length]);

  // const handleDeliverableNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === 'Enter' && deliverableName !== '') {
  //     addDeliverable();
  //     setDeliverableName('');
  //   }
  // };

  const handleSliderChange = (event: React.ChangeEvent<unknown>, value: number | number[]) => {
    const selectedComplexity = complexities?.[value as number];
    if (selectedComplexity) {
      setValuesSelected({
        ...valuesSelected,
        complexity_id: selectedComplexity.id,
      });
    }
  };

  const handleEstimationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValuesSelected({
      ...valuesSelected,
      charge: Math.abs(Number(e.currentTarget.value)),
    });
    setSelectedEstimation(Math.abs(Number(e.currentTarget.value)));
  };
  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value.replace(',', '.');
    setValuesSelected({
      ...valuesSelected,
      price: Math.abs(Number(e.currentTarget.value)),
    });
    setSelectedPrice(Math.abs(Number(e.currentTarget.value)));
  };
  const slidersValues = complexities?.map((complexity, index) => ({
    label: complexity.name,
    value: index,
  }));

  const [selectedEstimation, setSelectedEstimation] = useState<number>(0);
  const [selectedPrice, setSelectedPrice] = useState<number>(0);

  const [currentSymbol, setCurrentSymbol] = useState<string>('');

  useEffect(() => {
    const currentDevise = devises.find((devise) => Number(valuesSelected?.devise_id) === devise.id);
    if (currentDevise) {
      if (currentDevise.symbol === 'E') {
        setCurrentSymbol('€');
        return;
      }
      setCurrentSymbol(currentDevise.symbol);
    }
  }, [devises, valuesSelected?.devise_id]);

  return (
    <form onSubmit={submit}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
        <Typography variant="h2">{t('Create_workunit')}</Typography>
        <IconButton sx={{ ml: 'auto' }} color="primary" onClick={dispatchOpen}>
          <Close />
        </IconButton>
      </Box>
      {!isLoading ? (
        <>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_ref">{t('Reference')}</InputLabel>
            <TextField
              id="workunit_ref"
              onChange={(e) => handleChange(e)}
              placeholder={t('REFERENCE_OF_WORKUNIT')}
              name="reference"
              fullWidth
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_name">{t('Name')}</InputLabel>
            <TextField
              id="workunit_name"
              onChange={(e) => handleChange(e)}
              placeholder={t('NAME_OF_NEW_WORKUNIT')}
              name="name"
              fullWidth
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_desc">{t('Description')}</InputLabel>
            <TextField
              id="workunit_desc"
              onChange={(e) => handleChange(e)}
              placeholder={t('DESCRIPTION_NEW_WORKUNIT')}
              name="desc"
              fullWidth
              multiline
              rows={3}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_devise">{t('Devise')}</InputLabel>
            <RadioGroup
              aria-labelledby="workunit_devise"
              id="workunit_devise"
              name="devise_id"
              row
              onChange={(e) => handleDeviseChange(e)}
            >
              {devises.map((devise) => (
                <FormControlLabel
                  key={devise.id}
                  value={devise.id.toString()}
                  className={`wrapped ${Number(valuesSelected?.devise_id) === devise.id ? 'checked' : ''}`}
                  control={<Radio color="primary" />}
                  label={t(devise.name) as string}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel>{t('Complexity')}</InputLabel>
            <Box sx={{ px: 2 }}>
              <ComplexitySlider
                onChange={(e: React.ChangeEvent<unknown>, value: number | number[]) => handleSliderChange(e, value)}
                min={0}
                max={complexities ? complexities?.length - 1 : 0}
                marks={slidersValues}
                step={1}
              />
            </Box>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="workunit_load_estimation">{t('Load_Estimation')}</InputLabel>
                <TextField
                  id="workunit_load_estimation"
                  value={selectedEstimation}
                  fullWidth
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: <InputAdornment position="end">{t('Days')}</InputAdornment>,
                  }}
                  type="number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEstimationChange(e)}
                  sx={{ '& .MuiInputBase-input': { textAlign: 'right' } }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="workunit_price">{t('Price')}</InputLabel>
                <TextField
                  id="workunit_price"
                  value={selectedPrice}
                  fullWidth
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 0.01,
                    },
                    endAdornment: <InputAdornment position="end">{currentSymbol}</InputAdornment>,
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePriceChange(e)}
                  type="number"
                  sx={{ '& .MuiInputBase-input': { textAlign: 'right' } }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_input">{t('INPUTS')}</InputLabel>
            <TextField
              id="workunit_input"
              onChange={(e) => handleChange(e)}
              placeholder={t('INPUT_NEW_WORKUNIT')}
              name="input"
              fullWidth
              multiline
              rows={3}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_output">{t('OUTPUTS')}</InputLabel>
            <TextField
              id="workunit_output"
              onChange={(e) => handleChange(e)}
              placeholder={t('OUTPUT_NEW_WORKUNIT')}
              name="output"
              fullWidth
              multiline
              rows={3}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_accept_standard">{t('Accept_standard')}</InputLabel>
            <TextField
              id="workunit_accept_standard"
              onChange={(e) => handleChange(e)}
              placeholder={t('ACCEPT_STANDARD_WORKUNIT')}
              name="accept_standard"
              fullWidth
              multiline
              rows={3}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_level_standard">{t('Level_standard')}</InputLabel>
            <TextField
              id="workunit_level_standard"
              onChange={(e) => handleChange(e)}
              placeholder={t('LEVEL_STANDARD_WORKUNIT')}
              name="level_standard"
              fullWidth
              multiline
              rows={3}
            />
          </Box>
          <Button
            startIcon={<Add />}
            fullWidth
            variant="contained"
            onClick={submit}
            disabled={isWorkunitValidationDisabled}
          >
            Validate
          </Button>
        </>
      ) : (
        <Loader />
      )}
    </form>
  );
};

export default WorkunitCreation;

const ComplexitySlider = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
    },
  })
)(Slider);
