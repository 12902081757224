import { Add, FileCopy, FilterAltOff, GetApp, Search } from '@mui/icons-material';
import { Badge, Box, Button, CircularProgress, IconButton, TextField, Tooltip } from '@mui/material';
import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { openDialogAction } from 'store/actions/dialogsActions';
import { openDrawerAction } from 'store/actions/drawerActions';
import { clearSelectedFilterAction } from 'store/actions/filterActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectSelectedDeliverables } from 'store/selectors/deliverableSelectors';
import useTextSearch from '../../../hooks/useTextSearch';
import { selectFilterSelectedSize } from '../../../store/selectors/filterSelectors';
import useDeliverableService from '../hooks/useDeliverableService';

const DeliverableActionBar: React.FC = () => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const { loadings, filter } = useSelector((state) => state);
  const { generateExcelExtract, duplicateDeliverables, isTnM } = useDeliverableService();
  const { canAdd, canDuplicate } = useDeliverableRights();
  const selectedDeliverables = useSelector(selectSelectedDeliverables);
  const filterSelectedSize = useSelector(selectFilterSelectedSize);
  const isInReportMode = useSelector((state) => state.deliverable.isTableInReportMode);
  const selectModeAllDeliverables = useSelector((state) => state.deliverable.selectedAllDeliverables);
  const isThereDuplicableDeliverables = selectedDeliverables.some((deliv) => !deliv.workunit_id);
  const isEveryDeliverableDuplicable = selectedDeliverables.every((deliv) => !deliv.workunit_id);
  const { textSearch, setTextSearch, debouncedOnChange } = useTextSearch();
  function openDrawerCreation() {
    if (isInReportMode) {
      dispatch(
        openDialogAction({
          name: 'deliverableCancelReportMode',
          data: { confirm: () => openCreateDeliverableDrawer() },
        })
      );
    } else {
      openCreateDeliverableDrawer();
    }

    function openCreateDeliverableDrawer() {
      dispatch(openDrawerAction({ name: isTnM() ? 'tnmdeliverableCreation' : 'deliverableCreation' }));
    }
  }
  function renderButtons() {
    return (
      <>
        {canAdd && (
          <Button
            onClick={() => {
              openDrawerCreation();
            }}
            startIcon={<Add />}
            variant="contained"
          >
            {t('add_deliverable')}
          </Button>
        )}
        {/* {selectedDeliverables.length || selectModeAllDeliverables ? (
          <>
            {canDuplicate && !selectModeAllDeliverables && (
              <Tooltip title={(!isEveryDeliverableDuplicable ? t('PARTIAL_DUPLICATE') : '') as string}>
                <Button
                  onClick={() => {
                    duplicateDeliverables(selectedDeliverables);
                  }}
                  disabled={!isThereDuplicableDeliverables}
                  variant="contained"
                  startIcon={<FileCopy />}
                  sx={{ mr: 1 }}
                >
                  {t('Duplicate')}
                </Button>
              </Tooltip>
            )}
            <Button onClick={generateExcelExtract} startIcon={<GetApp />}>
              {loadings.downloadExtract ? <CircularProgress size="1rem" color="inherit" /> : <>{t('Generate_sheet')}</>}
            </Button>
          </>
        ) : (
          canAdd && (
            <Button
              onClick={() => {
                openDrawerCreation();
              }}
              startIcon={<Add />}
              variant="contained"
            >
              {t('add_deliverable')}
            </Button>
          )
        )} */}
      </>
    );
  }

  return (
    <Box sx={{ display: 'flex', mb: 3 }}>
      {renderButtons()}
      {filter.items.length > 0 && (
        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
          {filterSelectedSize > 0 && (
            <Box>
              <Tooltip title={t('Clear_filters') as string}>
                <IconButton
                  onClick={() => {
                    dispatch(clearSelectedFilterAction());
                  }}
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  <FilterAltOff />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <TextField
            placeholder={t('Search')}
            variant="standard"
            InputProps={{
              endAdornment: <Search />,
            }}
            value={textSearch}
            onChange={(e) => {
              debouncedOnChange();
              setTextSearch(e.target.value);
            }}
            sx={{ mr: 2 }}
          />
          <Badge badgeContent={filterSelectedSize} color="primary">
            <Button
              onClick={(e) => {
                dispatch(openDrawerAction({ name: 'drawerFilters' }));
              }}
              variant="contained"
            >
              {t('Filters')}
            </Button>
          </Badge>
        </Box>
      )}
    </Box>
  );
};

export default DeliverableActionBar;
