import { Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';

type TargetContent = 'content' | 'content2' | 'content3' | 'content4' | 'content5';

type ContentsCellProps = {
  row: OrderWorkunit;
  target: TargetContent;
};

const ContentsCell = (props: ContentsCellProps) => {
  const { row, target } = props;
  return (
    <Tooltip title={row[target] ?? ''}>
      <Typography variant="body2" noWrap>
        {row[target] ?? ''}
      </Typography>
    </Tooltip>
  );
};

export default (props: ContentsCellProps) => useMemo(() => <ContentsCell {...props} />, [props]);
