import { GridCellParams, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import useOrderWorkunitsService from '../../services/useOrderWorkunitsService';
import { contentsColumns } from '../Columns/ContentsColumns/contentsColumnsHelper';
import { monthsColumns } from '../Columns/MonthsColumns/monthsColumnsHelper';
import AddButton from './AddButton/AddButton';
import { CatalogWorkunitDatagridColumnField } from './services/catalogWorkunitDatagridService';
// eslint-disable-next-line import/no-extraneous-dependencies
import HeaderCell from '../Cells/HeaderCell/HeaderCell';

type CatalogWorkunitDataGridColumnsProps = {
  columnsDef: GridColDef[];
  apiRef: React.MutableRefObject<GridApiPro>;
};

const CatalogWorkunitDataGridColumns = ({ columnsDef, apiRef }: CatalogWorkunitDataGridColumnsProps): GridColDef[] => {
  const [t] = useTranslation();

  const state = useSelector((state) => state.catalogToOrder.catalogHandler);
  const monthsSlice = useSelector((state) => state.catalogToOrder.catalogHandler.monthSlice);
  const months = useSelector((state) => state.catalogToOrder.months);

  const { addWorkunitToOrder } = useOrderWorkunitsService();

  const defaultColumnParams: GridColDef = useMemo(
    () => ({
      field: '',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      minWidth: 0,
    }),
    []
  );

  type SearchHeaderFields = keyof CatalogWorkunit;

  const searchHeader: SearchHeaderFields[] = useMemo(() => ['reference', 'name'], []);

  const isASearchHeader = useCallback(
    (value: string): value is SearchHeaderFields => searchHeader.includes(value as SearchHeaderFields),
    [searchHeader]
  );

  const generateMonthsColumns = useMemo(
    (): GridColDef[] => monthsColumns(months, monthsSlice, true, 'catalog'),
    [monthsSlice, months]
  );

  const generateContentColumns = useMemo(
    (): GridColDef[] => contentsColumns(state.contentsNumber as number, apiRef, 'catalog'),
    [apiRef, state.contentsNumber]
  );

  const generateBaseColumns = useMemo(
    () =>
      columnsDef.map((col) => {
        if (isASearchHeader(col.field)) {
          return {
            ...defaultColumnParams,
            ...col,
            ...{
              headerName: col.headerName ? t(col.headerName) : t(col.field),
              renderHeader: (params: GridColumnHeaderParams) => <HeaderCell params={params} target="catalog" />,
            },
          };
        }
        return { ...defaultColumnParams, ...col, ...{ headerName: col.headerName ? t(col.headerName) : t(col.field) } };
      }),
    [columnsDef, defaultColumnParams, isASearchHeader, t]
  );

  const addWorkunitColumn = useMemo(
    (): GridColDef => ({
      align: 'right',
      minWidth: 64,
      field: '',
      headerName: '',
      disableReorder: true,
      renderCell: (params: GridCellParams) => (
        <AddButton params={params} handleAddWorkunitToOrder={addWorkunitToOrder} />
      ),
    }),
    [addWorkunitToOrder]
  );

  const generateColumns = useCallback(
    (colDef: GridColDef[]): GridColDef[] => {
      const columns: GridColDef[] = [];
      columns.push(...generateBaseColumns);
      const monthsColumns = generateMonthsColumns;
      columns.splice(
        columns.findIndex((col) => col.field === CatalogWorkunitDatagridColumnField.MONTHS),
        1,
        ...monthsColumns
      );
      columns.splice(
        columns.findIndex((col) => col.field === CatalogWorkunitDatagridColumnField.CONTENTS),
        1,
        ...generateContentColumns
      );
      columns.push(addWorkunitColumn);
      return columns;
    },
    [addWorkunitColumn, generateBaseColumns, generateContentColumns, generateMonthsColumns]
  );

  return useMemo(() => generateColumns(columnsDef), [columnsDef, generateColumns]);
};

export default CatalogWorkunitDataGridColumns;
