import { Box } from '@mui/material';
import { Layout, Loader } from 'components';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TermsOfUseApiService from '../../api/TermsOfUseApiService';
import { loadDocumentsAction } from '../../store/actions/documentsActions';
import { useAppState } from '../../store/Provider';
import './Documents.scss';
import DocumentsTabs from './DocumentsTabs/DocumentsTabs';

const Documents: React.FC<RouteComponentProps> = ({ match }) => {
  const { state, dispatch } = useAppState();
  const { documents } = state;

  const fetchTermsOfUse = async () => {
    const { data } = await TermsOfUseApiService.get();
    dispatch(loadDocumentsAction([data.presignedUri]));
  };

  useEffect(() => {
    fetchTermsOfUse();
  }, []);

  return (
    <Layout name="Docs" path="/documents">
      <Box className="documents-page">
        <DocumentsTabs />
        <Box className="iframe-container">
          {documents.documents && documents.documents?.length > 0 ? (
            <>
              {documents.documents?.map((document) => (
                <iframe id="iframe" className="iframe" src={document} title="cgi" />
              ))}
            </>
          ) : (
            <Loader />
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default Documents;
