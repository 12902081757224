import { BottomNavigation, Typography, IconButton, Button } from '@mui/material';
import { Box } from '@mui/system';
import { theme } from 'styles/theme';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { openDialogAction } from 'store/actions/dialogsActions';
import { useAppState } from 'store/Provider';
import { cancelSelectByHand, confirmSelectByHand } from 'store/actions/deliverableActions';
import { Cancel } from '@mui/icons-material';

const DeliverableCreateReportByHand = () => {
  const [t] = useTranslation();
  const state = useSelector((state) => state.deliverable);
  const { dispatch } = useAppState();
  const cancelReportMode = () => {
    dispatch(cancelSelectByHand());
    dispatch(openDialogAction({ name: 'deliverableCreateReport' }));
  };

  const handleSave = () => {
    dispatch(confirmSelectByHand());
    dispatch(openDialogAction({ name: 'deliverableCreateReport' }));
  };
  return state.isTableInReportMode ? (
    <BottomNavigation
      sx={{
        borderRadius: '12px',
        backgroundColor: theme.palette.success.main,
        position: 'fixed',
        flexDirection: { xs: 'column', md: 'row' },
        bottom: '10px',
        right: '25px',
        left: { xs: '25px', md: '170px' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        color: theme.palette.success.dark,
        height: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <IconButton sx={{ fontWeight: 500, mb: { xs: 1, md: 0 }, color: 'white' }} onClick={cancelReportMode}>
          <Cancel />
        </IconButton>
        <Typography sx={{ fontWeight: 500, mb: { xs: 1, md: 0 }, color: 'white' }}>
          {state.selectedDeliverablesModel.length} {t('items_selected')}
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{ backgroundColor: 'white', ':hover': { backgroundColor: 'neutral.light' } }}
        onClick={handleSave}
      >
        <Typography sx={{ fontWeight: 500, mb: { xs: 1, md: 0 }, color: 'success.main' }}>Save</Typography>{' '}
      </Button>
    </BottomNavigation>
  ) : (
    <></>
  );
};

export default DeliverableCreateReportByHand;
