import { GridRowId, GridSelectionModel, GridSortModel } from '@mui/x-data-grid-pro';
import { SearchParams } from 'api/ResourceAPI';
import MissionComment from 'types/entities/MissionComment';
import MissionFrequency from 'types/entities/MissionFrequency';
import MissionStatus from 'types/entities/MissionStatus';
import Order from 'types/entities/Order';
import OrderScope from 'types/entities/OrderScope';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import User from 'types/entities/User';
import Workunit from 'types/entities/Workunit';
import { DeliverableTableType } from 'types/enums/DeliverableTableType';
import { DeliverableTabPage } from 'types/enums/DeliverableTabPage';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { Rating } from 'types/enums/Rating';

export const DeliverableActions = {
  CREATE_DELIVERABLE: 'CREATE_DELIVERABLE',
  DELETE_DELIVERABLE: 'DELETE_DELIVERABLE',
  CANCEL_DELIVERABLE: 'CANCEL_DELIVERABLE',
  RESTORE_DELIVERABLE: 'RESTORE_DELIVERABLE',
  CHANGE_MISSION_ADVANCEMENT: 'CHANGE_MISSION_ADVANCEMENT',
  CHANGE_RATING: 'CHANGE_RATING',
  RESET_RATING: 'RESET_RATING',
  ADD_COMMENT_DELIVERABLE: 'ADD_COMMENT_DELIVERABLE',
  UPDATE_COMMENT_DELIVERABLE: 'UPDATE_COMMENT_DELIVERABLE',
  DELETE_COMMENT_DELIVERABLE: 'DELETE_COMMENT_DELIVERABLE',
  CHANGE_TABLE_TYPE: 'CHANGE_TABLE_TYPE',
  CHANGE_TAB: 'CHANGE_TAB',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CHANGE_FINANCIAL_PAGE: 'CHANGE_FINANCIAL_PAGE',
  SHOW_DETAIL_DELIVERABLE: 'SHOW_DETAIL_DELIVERABLE',
  EDIT_DELIVERABLES: 'EDIT_DELIVERABLES',
  ADD_DELIVERABLE_SHEET: 'ADD_DELIVERABLE_SHEET',
  CHANGE_PAGE_SIZE: 'CHANGE_PAGE_SIZE',
  LOAD_DELIVERABLES: 'LOAD_DELIVERABLES',
  RESET_DELIVERABLES: 'RESET_DELIVERABLES',
  LOAD_ORDER: 'LOAD_ORDER',
  LOAD_FREQUENCIES: 'LOAD_FREQUENCIES',
  LOAD_STATUS: 'LOAD_STATUS',
  DELIVERABLE_LOAD_WORKUNITS: 'DELIVERABLE_LOAD_WORKUNITS',
  LOAD_DELIVERABLE_CUSTOMERS: 'LOAD_DELIVERABLE_CUSTOMERS',
  LOAD_DELIVERABLE_CONSULTANTS: 'LOAD_DELIVERABLE_CONSULTANTS',
  LOAD_DELIVERABLE_DM: 'LOAD_DELIVERABLE_DM',
  LOAD_ORDER_SCOPES: 'LOAD_ORDER_SCOPES',
  SELECT_DELIVERABLE: 'SELECT_DELIVERABLE',
  SELECT_DELIVERABLES: 'SELECT_DELIVERABLES',
  SELECT_ALL_DELIVERABLES: 'SELECT_ALL_DELIVERABLES',
  CHANGE_SELECTED_DELIVERABLES_COUNT: 'CHANGE_SELECTED_DELIVERABLES_COUNT',
  EXCLUDE_DELIVERABLES_COUNT: 'EXCLUDE_DELIVERABLES_COUNT',
  SORT_DELIVERABLES: 'SORT_DELIVERABLES',
  CHANGE_SEARCH_PARAMS_DELIVERABLE: 'CHANGE_SEARCH_PARAMS_DELIVERABLE',
  START_SELECT_BY_HAND_CREATE_REPORT_MODAL: 'START_SELECT_BY_HAND_CREATE_REPORT_MODAL',
  CONFIRM_SELECT_BY_HAND_CREATE_REPORT_MODAL: 'CONFIRM_SELECT_BY_HAND_CREATE_REPORT_MODAL',
  CANCEL_SELECT_BY_HAND_CREATE_REPORT_MODAL: 'CANCEL_SELECT_BY_HAND_CREATE_REPORT_MODAL',
} as const;

export const createDeliverableAction = (payload: OrderWorkunit[]) => ({
  type: DeliverableActions.CREATE_DELIVERABLE,
  payload,
});

export const deleteDeliverableAction = (payload: OrderWorkunit['id'] | GridRowId) => ({
  type: DeliverableActions.DELETE_DELIVERABLE,
  payload,
});

export const cancelDeliverableAction = (payload: {
  ids: OrderWorkunit['id'][];
  cancellationReason: string | null;
}) => ({
  type: DeliverableActions.CANCEL_DELIVERABLE,
  payload,
});

export const restoreDeliverableAction = (payload: OrderWorkunit['id']) => ({
  type: DeliverableActions.RESTORE_DELIVERABLE,
  payload,
});

export const changeMissionAdvancementAction = (payload: {
  ids: OrderWorkunit['id'][];
  mission_advancement_id: MissionAdvancementPossibilities | null;
  validator?: User;
}) => ({
  type: DeliverableActions.CHANGE_MISSION_ADVANCEMENT,
  payload,
});

export const changeRatingAction = (payload: {
  ids: OrderWorkunit['id'][];
  rating: Rating;
  comment: MissionComment | null;
  reviewer?: User;
}) => ({
  type: DeliverableActions.CHANGE_RATING,
  payload,
});

export const resetRatingAction = (payload: OrderWorkunit['id']) => ({
  type: DeliverableActions.RESET_RATING,
  payload,
});

export const addCommentDeliverableAction = (payload: { id: OrderWorkunit['id']; comment: MissionComment }) => ({
  type: DeliverableActions.ADD_COMMENT_DELIVERABLE,
  payload,
});

export const updateCommentDeliverableAction = (payload: {
  deliverableId: OrderWorkunit['id'];
  id: MissionComment['id'];
  comment: MissionComment;
}) => ({
  type: DeliverableActions.UPDATE_COMMENT_DELIVERABLE,
  payload,
});

export const deleteCommentDeliverableAction = (payload: {
  deliverableId: OrderWorkunit['id'];
  ids: MissionComment['id'][];
}) => ({
  type: DeliverableActions.DELETE_COMMENT_DELIVERABLE,
  payload,
});

export const changeTableTypeAction = (payload: DeliverableTableType) => ({
  type: DeliverableActions.CHANGE_TABLE_TYPE,
  payload,
});

export const changeTabAction = (payload: DeliverableTabPage) => ({
  type: DeliverableActions.CHANGE_TAB,
  payload,
});

export const changePageAction = (payload: number) => ({
  type: DeliverableActions.CHANGE_PAGE,
  payload,
});

export const changePageSizeAction = (payload: number) => ({
  type: DeliverableActions.CHANGE_PAGE_SIZE,
  payload,
});

export const showDetailDeliverableAction = (payload: OrderWorkunit['id'] | null) => ({
  type: DeliverableActions.SHOW_DETAIL_DELIVERABLE,
  payload,
});

export const editDeliverablesAction = (payload: {
  orderWorkunitIds: OrderWorkunit['id'][];
  changes: Partial<OrderWorkunit>;
}) => ({
  type: DeliverableActions.EDIT_DELIVERABLES,
  payload,
});

export const loadDeliverablesAction = (payload: {
  deliverables: OrderWorkunit[] | null;
  totalItems: number;
  totalPages: number;
}) => ({
  type: DeliverableActions.LOAD_DELIVERABLES,
  payload,
});

export const resetDeliverablesAction = () => ({
  type: DeliverableActions.RESET_DELIVERABLES,
});

export const loadOrderAction = (payload: Order) => ({
  type: DeliverableActions.LOAD_ORDER,
  payload,
});

export const loadFrequenciesAction = (payload: MissionFrequency[]) => ({
  type: DeliverableActions.LOAD_FREQUENCIES,
  payload,
});

export const loadStatusAction = (payload: MissionStatus[]) => ({
  type: DeliverableActions.LOAD_STATUS,
  payload,
});

export const loadWorkunitsAction = (payload: Workunit[]) => ({
  type: DeliverableActions.DELIVERABLE_LOAD_WORKUNITS,
  payload,
});

export const loadCustomersAction = (payload: User[]) => ({
  type: DeliverableActions.LOAD_DELIVERABLE_CUSTOMERS,
  payload,
});

export const loadConsultantsAction = (payload: User[]) => ({
  type: DeliverableActions.LOAD_DELIVERABLE_CONSULTANTS,
  payload,
});

export const loadDeliveryManagersAction = (payload: User[]) => ({
  type: DeliverableActions.LOAD_DELIVERABLE_DM,
  payload,
});

export const selectDeliverableAction = (payload: OrderWorkunit['id'] | null) => ({
  type: DeliverableActions.SELECT_DELIVERABLE,
  payload,
});

export const selectDeliverablesAction = (payload: GridSelectionModel) => ({
  type: DeliverableActions.SELECT_DELIVERABLES,
  payload,
});

export const selectAllDeliverablesAction = (payload: boolean) => ({
  type: DeliverableActions.SELECT_ALL_DELIVERABLES,
  payload,
});

export const changeSelectedDeliverablesCountAction = (payload: number) => ({
  type: DeliverableActions.CHANGE_SELECTED_DELIVERABLES_COUNT,
  payload,
});

export const excludeDeliverablesAction = (payload: GridRowId[]) => ({
  type: DeliverableActions.EXCLUDE_DELIVERABLES_COUNT,
  payload,
});

export const sortDeliverablesAction = (payload: GridSortModel) => ({
  type: DeliverableActions.SORT_DELIVERABLES,
  payload,
});

export const changeSearchParamsAction = (payload: SearchParams | null) => ({
  type: DeliverableActions.CHANGE_SEARCH_PARAMS_DELIVERABLE,
  payload,
});

export const loadOrderScopesAction = (payload: OrderScope[]) => ({
  type: DeliverableActions.LOAD_ORDER_SCOPES,
  payload,
});

export const startSelectByHand = () => ({
  type: DeliverableActions.START_SELECT_BY_HAND_CREATE_REPORT_MODAL,
});

export const confirmSelectByHand = () => ({
  type: DeliverableActions.CONFIRM_SELECT_BY_HAND_CREATE_REPORT_MODAL,
});

export const cancelSelectByHand = () => ({
  type: DeliverableActions.CANCEL_SELECT_BY_HAND_CREATE_REPORT_MODAL,
});

export const deliverableActions = {
  createDeliverableAction,
  deleteDeliverableAction,
  cancelDeliverableAction,
  restoreDeliverableAction,
  changeMissionAdvancementAction,
  changeRatingAction,
  resetRatingAction,
  addCommentDeliverableAction,
  updateCommentDeliverableAction,
  deleteCommentDeliverableAction,
  changeTableTypeAction,
  changeTabAction,
  changePageAction,
  changePageSizeAction,
  showDetailDeliverableAction,
  editDeliverablesAction,
  loadDeliverablesAction,
  resetDeliverablesAction,
  loadOrderAction,
  loadFrequenciesAction,
  loadStatusAction,
  loadWorkunitsAction,
  loadCustomersAction,
  selectDeliverableAction,
  selectDeliverablesAction,
  selectAllDeliverablesAction,
  changeSelectedDeliverablesCountAction,
  excludeDeliverablesAction,
  sortDeliverablesAction,
  changeSearchParamsAction,
  loadConsultantsAction,
  loadDeliveryManagersAction,
  loadOrderScopesAction,
  startSelectByHand,
  confirmSelectByHand,
  cancelSelectByHand,
};
