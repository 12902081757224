import { LocalizationProvider } from '@mui/lab';
import MomentUtils from '@mui/lab/AdapterMoment';
import { LinearProgress } from '@mui/material';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRowModel,
  GridRowParams,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import OrderWorkunitApiService from 'api/OrderWorkunitApiService';
import { OrderWorkunitAffectationContext } from 'features/Affectation/__context/OrderWorkunitAffectationContext';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { changePage, changePageSize } from '../__context/_reducers/GridHandler/GridHandler';
import { selectAffectationItemsAction } from '../__context/_reducers/SelectItems/SelectItems';
import { updateOrderWorkunit } from '../__context/_reducers/UpdateOrderWorkunit/UpdateOrderWorkunit';
import { isWorkunitResetable } from '../__helper';
import { createColumnsDatagridAffectation } from './__affectationGridColumnHelper';

type AffectationGridProps = {
  setWorkunitsSaving: React.Dispatch<React.SetStateAction<number>>;
  workunitSaving: number;
};

const AffectationGrid = (props: AffectationGridProps) => {
  const { setWorkunitsSaving, workunitSaving } = props;
  const { state, dispatch: dispatchOrderWorkunitAffectationContext } = useContext(OrderWorkunitAffectationContext);
  const { dispatch } = useAppState();
  const [t] = useTranslation();
  const isAffectationGridLoading = useSelector((state) => state.loadings.affectationGrid) > 0;
  const saveWorkunit = React.useCallback(
    async (orderWorkunit: OrderWorkunit) => {
      setWorkunitsSaving(workunitSaving + 1);

      try {
        const res = await OrderWorkunitApiService.updateWorkunit(orderWorkunit.id, {
          ...orderWorkunit,
          order_id: state.order?.id ?? orderWorkunit?.order_id,
        });
        orderWorkunit.delivery_manager_fullname = res.delivery_manager_fullname;
        dispatchOrderWorkunitAffectationContext(updateOrderWorkunit(orderWorkunit));
        dispatch(setSnackbarAction({ message: 'Saved', open: true, severity: 'success', duration: 500 }));
      } catch (error) {
        const result = (error as Error).message;
        if (result) {
          dispatch(
            setSnackbarAction({ message: `Unable to save affectations: ${result}`, open: true, severity: 'error' })
          );
        } else {
          dispatch(
            setSnackbarAction({ message: `Unable to save affectations: ${error}`, open: true, severity: 'error' })
          );
        }
      } finally {
        setWorkunitsSaving((previousCount) => previousCount - 1);
      }
    },
    [setWorkunitsSaving, state.order?.id, workunitSaving]
  );

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel<OrderWorkunit>, oldRow: GridRowModel<OrderWorkunit>) => {
      await saveWorkunit(newRow);
      return newRow;
    },
    [saveWorkunit]
  );

  const columns: GridColDef[] = useMemo(
    () => createColumnsDatagridAffectation(t, state.consultants, state.clients, state.deliveryManagers, state.scopes),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.scopes, state.consultants, state.clients, state.deliveryManagers]
  );

  const handleProcessRowUpdateError = React.useCallback(
    (error: Error) => {
      dispatch(setSnackbarAction({ message: error.message, severity: 'error', open: true }));
    },
    [dispatch]
  );
  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      {state.orderWorkunits && state.orderWorkunits.length > -1 && (
        <DataGridPro
          components={{
            LoadingOverlay: LinearProgress,
          }}
          componentsProps={{
            loadingOverlay: { style: { zIndex: 9999 } },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          className="order_workunit_affectation_table"
          rows={state.orderWorkunits}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
          autoHeight
          headerHeight={100}
          disableColumnMenu
          disableColumnResize
          onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
            dispatchOrderWorkunitAffectationContext(selectAffectationItemsAction(newSelectionModel as number[]));
          }}
          isCellEditable={(params) => !isWorkunitResetable(params)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pageSize={state.pageSize}
          page={state.currentPage}
          paginationMode="server"
          rowCount={state.totalItems}
          loading={isAffectationGridLoading}
          pagination
          keepNonExistentRowsSelected
          filterMode="server"
          onPageChange={(newPage) => dispatchOrderWorkunitAffectationContext(changePage(newPage))}
          onPageSizeChange={(newPageSize) => dispatchOrderWorkunitAffectationContext(changePageSize(newPageSize))}
          selectionModel={state.selectedItems}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          isRowSelectable={(params: GridRowParams) => !isWorkunitResetable(params as unknown as GridCellParams)}
          getRowClassName={(params) => {
            if (isWorkunitResetable(params as unknown as GridCellParams)) {
              return 'disabled_row';
            }
            return '';
          }}
          processRowUpdate={processRowUpdate}
          sx={{ mb: 4 }}
        />
      )}
    </LocalizationProvider>
  );
};
export default (props: AffectationGridProps) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useMemo(() => <AffectationGrid {...props} />, [props]);
