import { Tooltip, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const ScopeCell = React.memo((row: OrderWorkunit) => {
  const [t] = useTranslation();

  return (
    <Tooltip title={row?.scope ? (capitalize(row?.scope?.name) as string) : (t('N/A') as string)}>
      <Typography variant="body2" noWrap>
        {row?.scope ? capitalize(row?.scope?.name) : t('N/A')}
      </Typography>
    </Tooltip>
  );
});

export default ScopeCell;
