import DeliverableSheet from 'types/entities/DeliverableSheet';
import DeliverableSheetWorkunit from 'types/entities/DeliverableSheetWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { Ratings } from 'types/enums/Ratings';
import DeliverableSheetWorkunitDetailsList from 'types/models/DeliverableSheetWorkunitDetailsList';
import DeliverableSheetWorkunitFormatted from 'types/models/DeliverableSheetWorkunitFormatted';
import { OrderInformationData } from './PdfComponents/PdfDocument';

export function formatOrderWorkunitForPdf(deliverableSheetData: DeliverableSheet) {
  if (!deliverableSheetData?.['deliverable-sheet-workunits'])
    return {
      deliveredList: [],
      deviationList: [],
      detailsList: {},
    };
  return {
    deliveredList: getDeliveredList(deliverableSheetData['deliverable-sheet-workunits']),
    deviationList: getDeviationList(deliverableSheetData['deliverable-sheet-workunits']),
    detailsList: getDetailsList(deliverableSheetData['deliverable-sheet-workunits']),
  };
}

const getDeliveredList = (deliverableSheetWUData: DeliverableSheetWorkunit[]) => {
  let deliveredList: DeliverableSheetWorkunitFormatted[] = [];
  deliverableSheetWUData.forEach((deliverableSheetWU) => {
    const indexToUpdate = deliveredList.findIndex(
      (deliverable) =>
        deliverable.workunit_id === deliverableSheetWU.workunit_id &&
        deliverable.scope_id === deliverableSheetWU.scope_id
    );
    if (indexToUpdate < 0) {
      insertFirstDeliverable(deliveredList, deliverableSheetWU);
    }

    if (indexToUpdate >= 0) {
      deliveredList = updateDeliverableQuantities(deliveredList, indexToUpdate, deliverableSheetWU);
    }
  });
  deliveredList = deliveredList.map((deliverable) => ({
    ...deliverable,
    averageNoteFormatted: deliverable.averageNote ? Ratings[Math.round(deliverable.averageNote)] : undefined,
  }));
  return deliveredList;
};

export function getDeviationList(deliverableSheetWUData: DeliverableSheetWorkunit[]) {
  const deviationList: DeliverableSheetWorkunit[] = [];
  deliverableSheetWUData.forEach((deliverableSheetWU) => {
    incrementDeviationList(deliverableSheetWU, deviationList);
  });

  return deviationList;
}

export function incrementDeviationList(
  deliverableSheetWU: DeliverableSheetWorkunit,
  deviationList: DeliverableSheetWorkunit[]
) {
  if (
    (deliverableSheetWU.origin === 'quote' &&
      deliverableSheetWU.labels &&
      (deliverableSheetWU.labels.includes('updated') || deliverableSheetWU.labels.includes('cancelled'))) ||
    deliverableSheetWU.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_CLIENT
  ) {
    deviationList.push(deliverableSheetWU);
  }
}

export const getDetailsList = (
  deliverableSheetWUData: DeliverableSheetWorkunit[]
): DeliverableSheetWorkunitDetailsList => {
  const detailsList: DeliverableSheetWorkunitDetailsList = {};
  deliverableSheetWUData.forEach((deliverableSheetWU) => {
    if (!(deliverableSheetWU.scope.name in detailsList)) {
      detailsList[deliverableSheetWU.scope.name] = [deliverableSheetWU];
    } else {
      detailsList[deliverableSheetWU.scope.name].push(deliverableSheetWU);
    }
  });
  return detailsList;
};

export function updateDeliverableQuantities(
  deliveredList: DeliverableSheetWorkunitFormatted[],
  indexToUpdate: number,
  deliverableSheetWU: DeliverableSheetWorkunit
) {
  const deliveredListCopy = [...deliveredList];
  if (isFromOrder(deliverableSheetWU)) deliveredListCopy[indexToUpdate].quantityOrdered += 1;
  if (
    deliverableSheetWU.mission_advancement_id &&
    deliverableSheetWU.mission_advancement_id >= MissionAdvancementPossibilities.SUBMITTED
  )
    deliveredListCopy[indexToUpdate].quantityDelivered += 1;

  if (
    deliverableSheetWU.mission_advancement_id &&
    deliverableSheetWU.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_CLIENT
  )
    deliveredListCopy[indexToUpdate].quantityAccepted += 1;

  if (deliverableSheetWU.rating) {
    deliveredListCopy[indexToUpdate].averageNote = deliveredListCopy[indexToUpdate].averageNote
      ? averageRatingCalculation(
          deliveredListCopy[indexToUpdate].averageNote as number,
          convertRatingToNumber(deliverableSheetWU.rating)
        )
      : convertRatingToNumber(deliverableSheetWU.rating);
  }

  return deliveredListCopy;
}

export function insertFirstDeliverable(
  deliveredList: DeliverableSheetWorkunitFormatted[],
  deliverableSheetWU: DeliverableSheetWorkunit
) {
  deliveredList.push({
    ...deliverableSheetWU,
    averageNote: deliverableSheetWU.rating ? convertRatingToNumber(deliverableSheetWU.rating) : undefined,
    quantityOrdered: isFromOrder(deliverableSheetWU) ? 1 : 0,
    quantityAccepted:
      deliverableSheetWU.mission_advancement_id &&
      deliverableSheetWU.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_CLIENT
        ? 1
        : 0,
    quantityDelivered:
      deliverableSheetWU.mission_advancement_id &&
      deliverableSheetWU.mission_advancement_id >= MissionAdvancementPossibilities.SUBMITTED
        ? 1
        : 0,
  });
}

export function getOrderInformationData(currentDeliverableSheet: DeliverableSheet | null): OrderInformationData {
  if (currentDeliverableSheet && currentDeliverableSheet['deliverable-sheet-workunits']) {
    const allDmDeliverableSheet = currentDeliverableSheet['deliverable-sheet-workunits']
      .map(
        (deliverableWu) =>
          deliverableWu.accountable &&
          `${deliverableWu.accountable.last_name.toLocaleUpperCase()} ${deliverableWu.accountable.first_name}`
      )
      .filter((deliverableWu) => deliverableWu);

    const allReferenceList = currentDeliverableSheet.orderscope_refs_order;

    const dmListSet = [...new Set(allDmDeliverableSheet)];
    const allReferenceListSet = [...new Set(allReferenceList)];

    return {
      dmList: dmListSet as string[],
      referenceList: allReferenceListSet as string[],
      bm: currentDeliverableSheet.bm
        ? `${currentDeliverableSheet.bm?.first_name} ${currentDeliverableSheet.bm?.last_name}`
        : '',
    };
  }
  return {
    dmList: [],
    referenceList: [],
    bm: '',
  };
}

function isFromOrder(wu: DeliverableSheetWorkunit) {
  return wu.origin.includes('quote');
}

function convertRatingToNumber(rating: string) {
  return Ratings[rating as keyof typeof Ratings];
}

export function averageRatingCalculation(rating1: number, rating2: number) {
  /*   const rating1Int = convertRatingToNumber(rating1);
  const rating2Int = convertRatingToNumber(rating2); */
  const averageRatingInt = (rating1 + rating2) / 2;
  return averageRatingInt;
  // return Ratings[Math.round(averageRatingInt)];
}
