import { IconButton, Stack, TextField, Typography } from '@mui/material';
import Rating from 'components/Rating/Rating';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rating as Rate } from 'types/enums/Rating';
import './AssessStep.scss';

interface AssessStepProps {
  averageNote?: string;
  rating: string | null;
  ratingComment: string | null;
  setRating: (rate: Rate) => void;
  setRatingComment: (comment: string) => void;
}

const AssessStep = ({ averageNote, rating, ratingComment, setRating, setRatingComment }: AssessStepProps) => {
  const { t } = useTranslation();
  const rateList = [Rate.A, Rate.B, Rate.C, Rate.D];
  return (
    <Stack alignItems="flex-start">
      <Stack width="100%">
        <Typography className="text-modal">{t('Please assess the service performance')}</Typography>
        <Stack direction="row" justifyContent="flex-start">
          {rateList.map((rate) => (
            <IconButton
              key={rate}
              onClick={() => {
                setRating(rate);
              }}
              size="large"
            >
              <span className="rate-assess-container small">
                <div className={`rate small ${rating === rate ? 'active' : ''}`}>{rate}</div>
              </span>
            </IconButton>
          ))}
        </Stack>

        {averageNote && (
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2 }}>
            <Typography fontWeight={700} fontStyle="italic">
              {t('Reminder')}:
            </Typography>
            <Typography fontStyle="italic">{t('average assessment for this order is')}</Typography>
            <Rating size="small" rating={averageNote} />
          </Stack>
        )}
        {rating === 'D' && (
          <Stack sx={{ mt: 2 }}>
            <Typography fontWeight={500}>
              {t('You entered a D assessment. Please comment on your dissatifaction below')}:
            </Typography>
            <TextField
              name="rating_comment"
              id="rating_comment"
              rows={11}
              className="active-blue no-border deliverables-input "
              value={ratingComment}
              onChange={(e) => setRatingComment(e.currentTarget.value)}
              placeholder={t('Your text here')}
              multiline
              fullWidth
            />
            <Typography sx={{ color: '#b2bcd5' }}>{`${t('Number of characters')}: ${
              ratingComment ? ratingComment.length : 0
            }/700`}</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default AssessStep;
