import ResourceAPI, { Resource } from 'api/ResourceAPI';
import { AxiosResponse } from 'axios';
import Scope from 'types/entities/Scope';

export default class ScopeApiService {
  public static instance: ScopeApiService;

  public static baseUrl = 'scopes' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return ScopeApiService.instance || (ScopeApiService.instance = new ScopeApiService());
  }

  public static async fetchByCustomerId(customerId: number) {
    try {
      const { data } = await ResourceAPI.get(this.baseUrl, { customer_id: customerId, size: '-1' });
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async postScope(body: Partial<Scope>): Promise<AxiosResponse<{ scope: Scope }> | null> {
    try {
      return await ResourceAPI.post('scopes', body);
    } catch (error) {
      return null;
    }
  }
}
