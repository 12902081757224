export enum OrderStatus {
  DRAFTED = 'drafted',
  PENDING = 'pending',
  VALIDATED = 'validated',
  PRODUCTION = 'production',
  CLOSED = 'closed',
  MATERIAL_DRAFT = 'material_drafts',
  MATERIAL_PRODUCTION = 'material_production',
  MATERIAL_CLOSED = 'material_closed',
  MATERIAL_DELETED = 'material_deleted',
}
