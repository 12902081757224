export const DialogsActions = {
  OPEN_DIALOG: 'OPEN_DIALOG',
  CLOSE_DIALOG: 'CLOSE_DIALOG',
} as const;

export const openDialogAction = (payload: { name: string; data?: any }) => ({
  type: DialogsActions.OPEN_DIALOG,
  payload,
});

export const closeDialogAction = (payload: string) => ({
  type: DialogsActions.CLOSE_DIALOG,
  payload,
});

export const dialogsActions = {
  openDialogAction,
  closeDialogAction,
};
