import { Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress, Slide } from '@mui/material';
import React, { useMemo } from 'react';
import { TransitionProps } from 'react-transition-group/Transition';
import './ProcessModal.scss';

type ProcessModalProps = {
  open: boolean;
};
const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const ProcessModal = (props: ProcessModalProps) => {
  const { open } = props;
  return (
    <Dialog open={open} TransitionComponent={Transition} className="process_modal">
      <DialogTitle className="process_modal-title">Processing...</DialogTitle>
      <DialogContent>
        <DialogContentText className="process_modal-text">This may take a while</DialogContentText>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  );
};

export default (props: ProcessModalProps) => useMemo(() => <ProcessModal {...props} />, [props]);
