import { Add, Remove } from '@mui/icons-material';
import { Box, Button, IconButton, InputLabel, TextField, Typography } from '@mui/material';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import TextAreaLabel from '../TextaeraLabel';

type ContentMultipleProps = {
  orderWorkunit: OrderWorkunit;
  onchange: (orderWorkunit: OrderWorkunit) => void;
  baseContentNumber?: number;
};

const ContentMultiple = (props: ContentMultipleProps) => {
  const { orderWorkunit, onchange, baseContentNumber = 0 } = props;
  const [contentDisplayed, setContentDisplayed] = useState<number>(baseContentNumber);
  const [t] = useTranslation();
  return (
    <Box sx={{ mb: 3 }}>
      {contentDisplayed > 0 &&
        Array.from(Array(contentDisplayed).keys()).map((contentNumber) => (
          <Fragment key={contentNumber}>
            <Box sx={{ mb: 3 }}>
              <InputLabel htmlFor={`content${contentNumber + 2}`}>{t(`Content ${contentNumber + 2}`)}</InputLabel>
              <TextField
                name={`content${contentNumber + 2}`}
                id={`content${contentNumber + 2}`}
                rows={2}
                value={
                  (orderWorkunit[`content${contentNumber + 2}` as keyof Omit<OrderWorkunit, 'id'>] as string) ?? ''
                }
                onChange={(e) => onchange({ ...orderWorkunit, [e.target.name]: e.target.value ? e.target.value : ' ' })}
                multiline
                fullWidth
              />
            </Box>
          </Fragment>
        ))}
      {contentDisplayed < 4 && (
        <Button
          sx={{ mr: 1 }}
          variant="outlined"
          onClick={() => setContentDisplayed(contentDisplayed + 1)}
          color="primary"
          startIcon={<Add />}
          size="small"
        >
          {t('More content')}
        </Button>
      )}
      {contentDisplayed > 0 && (
        <Button
          variant="outlined"
          onClick={() => setContentDisplayed(contentDisplayed - 1)}
          color="error"
          startIcon={<Remove />}
          size="small"
        >
          {t('Less content')}
        </Button>
      )}
    </Box>
  );
};

export default (props: ContentMultipleProps) => useMemo(() => <ContentMultiple {...props} />, [props]);
