import { Tooltip, Typography } from '@mui/material';
import React from 'react';

const PriceCell = React.memo(({ price }: { price: number }) => (
  <Tooltip arrow title={price}>
    <Typography variant="body2" noWrap>
      {price}
    </Typography>
  </Tooltip>
));

export default PriceCell;
