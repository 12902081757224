/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar } from '@mui/material';
import { stringToColor } from 'helpers/format';
import React from 'react';
import styled from 'styled-components/macro';
import User from 'types/entities/User';
import { Card, Text } from './index';

interface Props {
  isEditable?: boolean;
  title: string;
  titlefontsize?: number;
  titlefontcolor?: string;
  icon?: string;
  content: any;
  contentfontsize?: number;
  contentfontcolor?: string;
  width?: number;
  height?: number;
  className?: string;
  user?: User;
  dispatchOpen?: React.DispatchWithoutAction;
}

const getAvatar = (user: User | undefined) => {
  if (user)
    return user?.avatar_uri ? (
      <Avatar src={user.avatar_uri} alt="Active_user_avatar" />
    ) : (
      <Avatar
        style={{
          backgroundColor: stringToColor(user.first_name),
          color: stringToColor(user.first_name, 'color'),
        }}
      >{`${user.last_name.charAt(0).toUpperCase()}${user.first_name.charAt(0).toUpperCase()}`}</Avatar>
    );
  return <Avatar />;
};

const CardDetail: React.FC<Props> = ({
  isEditable = false,
  title,
  titlefontsize,
  titlefontcolor,
  icon,
  content,
  contentfontsize,
  contentfontcolor,
  width,
  height,
  className,
  user,
  dispatchOpen,
}) => (
  <button onClick={dispatchOpen} type="button" className="no-style-button">
    <Card
      isEditable={isEditable}
      childComp={
        <Container onClick={() => dispatchOpen} className={className}>
          <Row>
            {user && <IconContainer>{getAvatar(user)}</IconContainer>}
            <Column>
              <Text value={title} color={titlefontcolor || '#A4A4A4'} size={titlefontsize || 13} />
              <Text value={content} color={contentfontcolor || '#616161'} size={contentfontsize || 16} />
            </Column>
          </Row>
        </Container>
      }
      width={width}
      height={height}
    />
  </button>
);
export default CardDetail;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 150px;
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: 18px;
  align-items: center;
`;
