import {
  DataGridPro as DataGrid,
  GridColumns,
  GridLinkOperator,
  GridOverlay,
  GridRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { useSelector } from 'store/hooks';
import { selectedWorkunitDatagridColumnsParams } from './service/selectedWorkunitDatagridColumnsService';
import SelectedWorkunitDatagridColumns from './SelectedWorkunitDatagridColumns';
import useOrderWorkunitsService from '../../services/useOrderWorkunitsService';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SelectedWorkunitsDataGrid = () => {
  const [t] = useTranslation();
  const selectedWorkunits = useSelector((state) => state.catalogToOrder.selectedWorkunits);
  const activeEditRows = useSelector((state) => state.catalogToOrder.selectedWorkunitsEditIds);
  const searchFilter = useSelector((state) => state.catalogToOrder.orderHandler.searchParams);
  const selectedScope: number | null = useSelector(
    (state) => (state.catalogToOrder.orderHandler.searchParams?.scope as number) ?? null
  );

  const { updateSelectedWorkunit } = useOrderWorkunitsService();

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      updateSelectedWorkunit(newRow);
      return newRow;
    },
    [updateSelectedWorkunit]
  );

  const apiRef = useGridApiRef();
  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    console.log(error);
  }, []);
  const selectedWorkunitColumns = () =>
    SelectedWorkunitDatagridColumns({ columnsDef: selectedWorkunitDatagridColumnsParams, apiRef });

  return (
    <Box sx={{ height: '420px', mb: 4 }}>
      <DataGrid
        rows={selectedWorkunits}
        columns={selectedWorkunitColumns() as GridColumns}
        checkboxSelection={false}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        hideFooter
        headerHeight={70}
        rowHeight={70}
        apiRef={apiRef}
        experimentalFeatures={{ newEditingApi: true }}
        disableColumnReorder
        editMode="cell"
        isCellEditable={(params) => activeEditRows.includes(params.row.randomId)}
        getRowId={(row) => {
          if (!row) throw new Error('Row is undefined');
          return row.randomId;
        }}
        filterModel={{
          items: [
            { id: 1, columnField: 'reference', operatorValue: 'contains', value: searchFilter?.reference },
            { id: 2, columnField: 'name', operatorValue: 'contains', value: searchFilter?.name },
            { id: 3, columnField: 'scope', operatorValue: 'isSame', value: selectedScope || 0 },
          ],
          linkOperator: GridLinkOperator.And,
        }}
        components={{
          NoRowsOverlay: () => (
            <GridOverlay>
              <Typography variant="h6" color="primary.main">
                {t('select_wu_catalog')}
              </Typography>
            </GridOverlay>
          ),
        }}
        processRowUpdate={processRowUpdate}
        /*         onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
          if (params.reason === GridCellEditStopReasons.cellFocusOut) {
            // eslint-disable-next-line no-param-reassign
            event.defaultMuiPrevented = true;
          }
        }} */
        onProcessRowUpdateError={handleProcessRowUpdateError}
        sx={{
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          overflow: 'auto',
        }}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd')}
      />
    </Box>
  );
};
export default SelectedWorkunitsDataGrid;
