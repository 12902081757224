import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { WarningIcon } from 'components/CustomIcons';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectActiveDeliverable } from 'store/selectors/deliverableSelectors';
import './DeliverableDialogCancelAssessment.scss';

const DeliverableDialogCancelAssessment = () => {
  const [t] = useTranslation();

  const { dispatch } = useAppState();
  const activeDeliverable = useSelector(selectActiveDeliverable);
  const loadings = useSelector((state) => state.loadings);
  const dialogs = useSelector((state) => state.dialogs);
  const { handleDeliverableCancelRating, handleDeliverableCancelComment } = useDeliverableService();

  const handleConfirmClick = async () => {
    if (activeDeliverable) {
      dispatch(addLoadingAction('cancelAssessment'));
      await Promise.allSettled([
        handleDeliverableCancelComment(activeDeliverable),
        handleDeliverableCancelRating(activeDeliverable),
      ]);
      dispatch(removeLoadingAction('cancelAssessment'));
    }
    handleClose();
  };

  const handleClose = () => {
    dispatch(closeDialogAction('deliverableCancelAssessment'));
  };

  return (
    <Dialog id="modal-cancel-assessment" open={dialogs.deliverableCancelAssessment}>
      <DialogTitle>{t('Cancel assessment')} ?</DialogTitle>
      <DialogContent>
        <DialogContentText className="create_report_modal-title-content ">
          Do you want to confirm the assessment&apos;s cancellation of this deliverable ?
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <DialogContentText className="warning">
          <span className="warning-content">
            <WarningIcon /> Warning: associated comment will be deleted
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={!!loadings.cancelAssessment} variant="contained" onClick={handleClose}>
          {t('Cancel')}
        </LoadingButton>
        <LoadingButton loading={!!loadings.cancelAssessment} variant="contained" onClick={handleConfirmClick}>
          {t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default DeliverableDialogCancelAssessment;
