/* eslint-disable max-len */
import { handleApiError } from 'api/errors';
import ResourceApi, { Resource } from 'api/ResourceAPI';
import User from 'types/entities/User';
import AlertMessage from 'types/models/AlertMessage';
/* eslint-disable class-methods-use-this */
import BaseApiService from './BaseApiService';

export default class NotificationApiService extends BaseApiService {
  public static instance: NotificationApiService;

  public static baseUrl = 'alerts' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return NotificationApiService.instance || (NotificationApiService.instance = new NotificationApiService());
  }

  public static async markAllRead() {
    try {
      const res = await ResourceApi.getWithAdditionalPath(this.baseUrl, 'reads');
      return res.data;
    } catch (error: unknown) {
      return null;
    }
  }

  // Fetch customer's orders.
  async postNotification(alert: AlertMessage): Promise<void> {
    try {
      await ResourceApi.post('alerts', alert);
      return;
    } catch (e) {
      handleApiError(e);
    }
  }

  async getNotifications(userId: User['id'], page: number): Promise<any> {
    try {
      const data = await ResourceApi.get('alerts', {
        size: '10',
        page,
      });
      return data;
    } catch (e) {
      handleApiError(e);
      return e;
    }
  }

  async setAlertRead(alert: { id: number; resource_wording?: string }): Promise<void> {
    await ResourceApi.put('alerts', alert.id, {
      ...alert,
      is_read: true,
      resource_wording: alert.resource_wording ?? 'empty  ',
    });
  }
}
