import React from 'react';
import { Avatar, Chip, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps, Theme } from '@mui/system';
import { capitalize, numberWithSpaces, stringToColor } from 'helpers/format';
import { ForkLeft } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';

interface CustomAvatarProps {
  displayAvatar: boolean;
  name: string; // Define the type for 'name'
  onClose: () => void; // Define the type for 'onClose' function
  sx?: SxProps<Theme>;
}

const CustomAvatar: React.FC<CustomAvatarProps> = ({ displayAvatar, name, onClose, sx }) => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-start', ...sx }}>
    <Chip
      variant="outlined"
      avatar={
        displayAvatar ? (
          <Avatar
            sx={{
              bgcolor: displayAvatar ? '#d074f7 !important' : '#ffddf4 !important', // Set the background color
              color: 'white !important', // Set the text color to white for contrast
              width: 24, // Optional: Control the size to fit your design
              height: 24,
              fontSize: '0.875rem', // Adjust font size as needed
              border: 'none !important',
            }}
          >
            {' '}
            {name.charAt(0)}
          </Avatar>
        ) : undefined
      }
      label={name}
      onDelete={onClose}
      sx={{
        justifyContent: 'flex-start',
        display: 'flex',
        maxWidth: 230, // Set a maximum width for the chip
        overflow: 'hidden', // Hide overflow
        textOverflow: 'ellipsis', // Add ellipsis when text overflows
        whiteSpace: 'nowrap', // Keep the text on one line
        border: displayAvatar ? `1px solid #a202e6` : `1px solid #AA336A`,
        bgcolor: displayAvatar ? '#e9dcf2' : '#ffddf4', // Light pink background for the chip
        color: '#333', // Text color
        '& .MuiChip-deleteIcon': {
          color: '#333', // White delete icon
          '&:hover': {
            backgroundColor: 'transparent', // Ensure no background on hover
          },
          '&:focus': {
            backgroundColor: 'transparent', // Ensure no background on focus
          },
        },
        '&:hover': {
          backgroundColor: displayAvatar ? '#e9dcf2' : '#ffddf4', // Ensure the chip background doesn't change on hover
        },
        '&:focus': {
          outline: 'none', // Remove focus outline
          backgroundColor: displayAvatar ? '#e9dcf2' : '#ffddf4', // Keep the background consistent on focus
        },
        margin: '4px', // Space around each chip
      }}
      deleteIcon={<CancelIcon />} // Use a custom delete icon
    />
  </Box>
);

export default CustomAvatar;
