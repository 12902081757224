import moment from 'moment';
import React, { useMemo } from 'react';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import { SelectedWorkunit } from 'types/entities/SelectedWorkunit';
import { Month } from 'types/models/Months';
import SelectWorkunitButton from '../SelectWorkunitButton/SelectWorkunitButton';

type SelectWorkunitButtonCellProps = {
  workunit: CatalogWorkunit | SelectedWorkunit;
  month: Month;
  mode: 'catalog' | 'order';
  isEdit?: boolean;
};

const SelectWorkunitButtonCell = ({
  workunit,
  month,
  mode,
  isEdit = true,
}: SelectWorkunitButtonCellProps): JSX.Element => {
  const { date } = month;
  const { months, id } = workunit;
  const { randomId } = workunit as SelectedWorkunit;
  const quantity = months.find(
    (m: { quantity: number; date: string }) => moment(m.date).format('MM-YYYY') === moment(date).format('MM-YYYY')
  )?.quantity;
  return (
    <>
      {useMemo(
        () =>
          isEdit ? (
            <SelectWorkunitButton workunitId={id ?? randomId} month={month} quantity={quantity ?? 0} mode={mode} />
          ) : (
            <div className="bold-blue quantity">{quantity ?? 0} </div>
          ),
        [id, isEdit, mode, month, quantity, randomId]
      )}
    </>
  );
};

export default SelectWorkunitButtonCell;
