/* eslint-disable class-methods-use-this */
import Affair from 'types/entities/Affair';
import { IAffairApiService } from '../AffairApiService';

const data = {
  totalItems: 4,
  datas: [
    {
      id: 1,
      name: 'affair test 1',
      otp: '0000001',
      start_date: '2022-01-01T00:00:00.000Z',
      end_date: '2027-01-01T00:00:00.000Z',
      customer_id: 1,
      bm_id: 2,
      status: 'active',
      is_archived: false,
      created_at: null,
      updated_at: null,
      bm: undefined,
      customer: undefined,
    },
    {
      id: 2,
      name: 'affair test 2',
      otp: '0000002',
      start_date: '2022-01-01T00:00:00.000Z',
      end_date: '2027-01-01T00:00:00.000Z',
      customer_id: 1,
      bm_id: 2,
      status: 'active',
      is_archived: false,
      created_at: null,
      updated_at: null,
      bm: undefined,
      customer: undefined,
    },
    {
      id: 3,
      name: 'affair test 3',
      otp: '0000003',
      start_date: '2022-01-01T00:00:00.000Z',
      end_date: '2027-01-01T00:00:00.000Z',
      customer_id: 1,
      bm_id: 2,
      status: 'close',
      is_archived: false,
      created_at: null,
      updated_at: null,
      bm: undefined,
      customer: undefined,
    },
    {
      id: 8,
      name: 'affair test 8',
      otp: 'CHOCOLAT009',
      start_date: null,
      end_date: null,
      customer_id: 1,
      bm_id: null,
      status: 'active',
      is_archived: false,
      created_at: null,
      updated_at: null,
      bm: undefined,
      customer: undefined,
    },
  ],
  totalPages: 0,
  currentPage: 0,
};

export default class TestAffairApiService implements IAffairApiService {
  public async fetchByCustomerId(customerId: number, props: string[] = [], size = '-1'): Promise<Affair[]> {
    return Promise.resolve(data.datas);
  }
}
