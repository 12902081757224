import { RootActions } from 'store/redux';
import { OrdersTabParentState } from 'types/enums/OrdersTabParentState';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { ManagementActions } from '../actions/managementActions';
import { ManagementState, managementState } from '../states/managementState';

const managementReducer = (state = managementState, action: RootActions): ManagementState => {
  switch (action.type) {
    case ManagementActions.CHANGE_PARENT_TAB: {
      const newState = {
        ...state,
        orders: null,
        pageHandler: {
          ...state.pageHandler,
          parentTab: action.payload.tab !== '' ? action.payload.tab : state.pageHandler.parentTab,
        },
      };
      localStorage.setItem('activeTab', newState.pageHandler.parentTab);
      return newState;
    }
    case ManagementActions.CHANGE_CURRENT_TAB:
      return {
        ...state,
        orders: null,
        pageHandler: {
          ...state.pageHandler,
          currentTab: action.payload.tab !== '' ? action.payload.tab : state.pageHandler.currentTab,
          currentPage: 1,
        },
      };
    case ManagementActions.EDIT_ORDER:
      return {
        ...state,
        orders: (state?.orders || []).map((order) =>
          order?.id !== action.payload.order.id ? order : action.payload.order
        ),
      };
    case ManagementActions.INJECT_CLIENTS:
      return {
        ...state,
        clients: action.payload.clients,
      };
    case ManagementActions.SET_ACTIVE_ORDERID:
      return {
        ...state,
        activeOrderId: action.payload.orderId,
      };
    case ManagementActions.CHANGE_PAGE_MANAGEMENT:
      return {
        ...state,
        pageHandler: {
          ...state.pageHandler,
          currentPage: action.payload.newPage,
        },
      };
    case ManagementActions.DUPLICATE_ORDER: {
      const newState = {
        ...state,
        orders: state?.orders ? [action.payload, ...state.orders] : [action.payload],
      };
      return newState;
    }
    case ManagementActions.DELETE_ORDER_MANAGEMENT:
      return {
        ...state,
        orders: (state?.orders || [])?.filter((order) => order.id !== action.payload.orderId),
      };
    case ManagementActions.INJECT_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
        pageHandler: {
          ...state.pageHandler,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage || state.pageHandler.currentPage,
        },
      };
    case ManagementActions.RESET_ORDERS:
      return {
        orders: null,
        clients: null,
        searchParams: null,
        pageHandler: {
          parentTab: OrdersTabParentState.NULL,
          currentTab: OrdersTabState.NULL,
          currentPage: 1,
          totalPages: 0,
        },
        activeOrderId: null,
      };
    case ManagementActions.CHANGE_SEARCH_PARAMS_MANAGEMENT:
      return {
        ...state,
        searchParams: action.payload,
      };
    default:
      return state;
  }
};

export default managementReducer;
