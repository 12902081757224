import { PERMISSIONS } from 'helpers/permissions';
import useUserPermissions, { hasPermissions } from 'hooks/useUserPermissions';

/**
 * React hook that defines rights specific to the Dashboard feature
 */
export default function useDashboardRights() {
  const userPermissions = useUserPermissions();
  return {
    canSeePrice: hasPermissions([PERMISSIONS.SEE_PRICE_DASHBOARD], userPermissions),
  };
}
