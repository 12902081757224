import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import { AffectationSearch } from './OrderWorkunitAffectationContext';

export type InitialStateType = {
  orderWorkunits: OrderWorkunit[];
  scopes: Scope[];
  isEditDrawerOpen: boolean;
  isModalConfirmDeleteOpen: boolean;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  order: Order | null;
  search: AffectationSearch;
  isGridLoading: boolean;
  selectedItems: OrderWorkunit['id'][];
  consultants: User[];
  clients: User[];
  deliveryManagers: User[];
};

export const initialValue: InitialStateType = {
  orderWorkunits: [],
  scopes: [],
  isEditDrawerOpen: false,
  isModalConfirmDeleteOpen: false,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  pageSize: 10,
  order: null,
  search: {
    workunit_name: '',
    workunit_reference: '',
    complexity_name: '',
    consultant_fullname: '',
    client_fullname: '',
    forecast_date: '',
    delivery_date: '',
    scope_id: undefined,
  },
  isGridLoading: false,
  consultants: [],
  clients: [],
  deliveryManagers: [],
  selectedItems: [],
};
