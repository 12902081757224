import useUserRoles from 'hooks/useUserRoles';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import FinancialReport from 'types/entities/FinancialReport';
import { ReportStatus } from 'types/enums/ReportStatus';

export default function useDeliverableReportsRights(report: DeliverableSheet | FinancialReport) {
  const userRoles = useUserRoles();
  const rights = {
    canArchiveReport: false,
    canSendToClient: false,
    canMarkAsSigned: false,
    canDownloadReport: false,
    canCancelSending: false,
  };

  if (report) {
    rights.canSendToClient = !(
      report.state !== ReportStatus.TO_BE_SENT ||
      userRoles.isCustomer ||
      userRoles.isConsultant
    );
    rights.canArchiveReport = !(
      ![ReportStatus.TO_BE_SENT].includes(report.state) ||
      userRoles.isCustomer ||
      userRoles.isConsultant
    );
    rights.canMarkAsSigned = !(
      ![ReportStatus.TO_BE_SENT, ReportStatus.WAITING_FOR_SIGNATURE].includes(report.state) ||
      userRoles.isCustomer ||
      userRoles.isConsultant
    );
    rights.canCancelSending = !(
      report.state !== ReportStatus.WAITING_FOR_SIGNATURE ||
      userRoles.isCustomer ||
      userRoles.isConsultant
    );
    rights.canDownloadReport = !userRoles.isCustomer;
  }

  return rights;
}
