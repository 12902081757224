import { GridColDef } from '@mui/x-data-grid-pro';
import { TenantMonthsWorkload } from 'features/Admin/AdminTenantSettings/AdminTenantSettings';
import { generateRandomId } from 'features/Orders/OrderWorkunits/helpersOrderWorkunits';
import {
  ComputedWorkunits,
  WorkunitComplexityMonths,
} from 'features/Orders/OrderWorkunits/OrderWorkunitsDataGrids/SummaryDataGrid/SummaryDataGrid';
import CatalogComplexity from 'types/entities/CatalogComplexity';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import Slice from 'types/entities/MonthSlice';
import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import { SelectedWorkunit } from 'types/entities/SelectedWorkunit';
import { DisplaySummaryMode } from 'types/enums/DisplaySummaryMode';
import { Month } from 'types/models/Months';
import { Quarters } from 'types/models/Quarters';

export type CatalogToOrderState = {
  order: Order | null;
  workunits: CatalogWorkunit[];
  selectedWorkunits: SelectedWorkunit[];
  selectedWorkunitsEditIds: number[];
  orderWorkunits: OrderWorkunit[];
  scopes: Scope[];
  scopesInOrder: Scope[];
  selectedScope: Scope | null;
  isWorkunitsLoaded: boolean;
  months: Month[];
  catalogHandler: DatagridHandler;
  orderHandler: DatagridHandler;
  summaryHandler: DatagridHandler;
  quarters: Quarters[];
  summary: {
    displayMode: DisplaySummaryMode;
    tenantWorkload: TenantMonthsWorkload[];
    catalogComplexities: CatalogComplexity[];
    usedMonthsWorkunits: WorkunitComplexityMonths[];
    usedQuartersWorkunits: WorkunitComplexityMonths[];
    columnsMonths: GridColDef[];
    columnsQuarter: GridColDef[];
    valuesMonths: ComputedWorkunits[];
    valuesQuarters: ComputedWorkunits[];
  };
};

const defaultMonthSlice: Slice = {
  startSlice: 0,
  endSlice: 6,
};
const defaultQuarterSlice: Slice = {
  startSlice: 0,
  endSlice: 4,
};

const defaultHandler: DatagridHandler = {
  monthSlice: defaultMonthSlice,
};

type SearchParams = {
  [key: string]: string | number | null;
};

type DatagridHandler = {
  monthSlice: Slice;
  quarterSlice?: Slice;
  contentsNumber?: number;
  isOpen?: boolean;
  searchParams?: SearchParams;
};

export const catalogToOrderState: CatalogToOrderState = {
  workunits: [],
  order: null,
  selectedWorkunits: [],
  selectedWorkunitsEditIds: [],
  orderWorkunits: [],
  scopes: [],
  scopesInOrder: [],
  selectedScope: null,
  isWorkunitsLoaded: false,
  months: [],
  catalogHandler: { ...defaultHandler, contentsNumber: 1, searchParams: { reference: '', name: '' } },
  orderHandler: { ...defaultHandler, contentsNumber: 1, searchParams: { reference: '', name: '' } },
  summaryHandler: { ...defaultHandler, quarterSlice: defaultQuarterSlice, isOpen: false },
  quarters: [],
  summary: {
    displayMode: DisplaySummaryMode.MONTHLY,
    tenantWorkload: [],
    catalogComplexities: [],
    usedMonthsWorkunits: [],
    usedQuartersWorkunits: [],
    columnsMonths: [],
    columnsQuarter: [],
    valuesMonths: [
      {
        title: 'Price',
        total: 0,
        randomId: generateRandomId(),
      },
      {
        title: 'dailyRate'.toLocaleUpperCase(),
        total: 0,
        randomId: generateRandomId(),
      },
      {
        title: 'Workload',
        total: 0,
        randomId: generateRandomId(),
      },
      {
        title: 'FTE',
        total: 0,
        randomId: generateRandomId(),
      },
    ],
    valuesQuarters: [
      {
        title: 'Price',
        total: 0,
        randomId: generateRandomId(),
      },
    ],
  },
};
