import { Close, Save } from '@mui/icons-material';
import { Box, Button, Drawer, IconButton, InputLabel, TextField, Typography } from '@mui/material';
import UserApiService from 'api/UserApiService';
import { Loader } from 'components';
import useClientsRights from 'hooks/rights/useClientsRights';
import useApi from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import Customer from 'types/entities/Customer';
import User from 'types/entities/User';

type FormData = {
  customer_id: number;
  department: string;
  direction: string;
  first_name: string;
  last_name: string;
  mail: string;
  phone: string;
  position: string;
  have_cognito_account: boolean;
};

type Props = {
  activeCustomer: Customer;
  activeClient: User;
  open: boolean;
  dispatchOpen: React.DispatchWithoutAction;
  setClient: (client: User) => void;
};

const ClientEdition: React.FC<Props> = ({ activeClient, activeCustomer, open, dispatchOpen, setClient }: Props) => {
  const appState = useSelector((state) => state.app);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [client, setClientToUpdate] = useState<User>(activeClient);
  const { makeCall } = useApi();
  const { canEdit } = useClientsRights();
  const [t] = useTranslation();

  useEffect(() => {
    setClientToUpdate(activeClient);
  }, [activeClient]);

  const onSubmit = async (data: FormData) => {
    if (activeCustomer && activeClient) {
      setIsLoading(true);
      const { user_client } = await makeCall(
        UserApiService.updateClient(client.id, {
          ...data,
          customer_id: appState.customer?.id || activeCustomer.id,
        }),
        'Unable to update client'
      );
      if (user_client !== undefined) {
        setClient(user_client);
        setIsLoading(false);
        dispatchOpen();
      } else {
        setIsLoading(false);
      }
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: client,
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={dispatchOpen}
      PaperProps={{
        sx: {
          width: {
            xs: '100%',
            lg: 450,
          },
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
          <Typography variant="h2">{canEdit ? t('edit_client') : t('client')}</Typography>
          <IconButton sx={{ ml: 'auto' }} color="primary" onClick={dispatchOpen} data-testid="close_client_edition">
            <Close />
          </IconButton>
        </Box>
        {!isLoading ? (
          <Box>
            <Controller
              name="first_name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_first_name" error={errors.first_name && true}>
                    First name{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="client_first_name"
                    onChange={onChange}
                    value={value || ''}
                    fullWidth
                    disabled={!canEdit}
                    helperText={errors.first_name?.message}
                    error={errors.first_name && true}
                    data-testid="client_first_name"
                  />
                </Box>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Your must enter a first name',
                },
              }}
            />
            <Controller
              name="last_name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_last_name" error={errors.last_name && true}>
                    Last name{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="client_last_name"
                    onChange={onChange}
                    value={value || ''}
                    fullWidth
                    disabled={!canEdit}
                    helperText={errors.last_name?.message}
                    error={errors.last_name && true}
                    data-testid="client_last_name"
                  />
                </Box>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Your must enter a last name',
                },
              }}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_phone" error={errors.phone && true}>
                    Phone{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="client_phone"
                    onChange={onChange}
                    value={value || ''}
                    fullWidth
                    disabled={!canEdit}
                    helperText={errors.phone?.message}
                    error={errors.phone && true}
                    type="tel"
                    data-testid="client_phone"
                  />
                </Box>
              )}
              rules={{
                maxLength: 20,
                minLength: {
                  value: 10,
                  message: 'Your must enter at least 10 number',
                },
                required: {
                  value: true,
                  message: 'Your must enter a phone with 10 number',
                },
                pattern: {
                  value:
                    /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/i,
                  message: 'invalid phone number',
                },
              }}
            />
            <Controller
              name="position"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_position" error={errors.position && true}>
                    Position{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="client_position"
                    onChange={onChange}
                    value={value || ''}
                    fullWidth
                    disabled={!canEdit}
                    helperText={errors.position?.message}
                    error={errors.position && true}
                    data-testid="client_position"
                  />
                </Box>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Your must enter a position',
                },
              }}
            />
            <Controller
              name="mail"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_mail" error={errors.mail && true}>
                    Mail{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="client_mail"
                    onChange={(e) => onChange(e.target.value.toLowerCase())}
                    value={value || ''}
                    fullWidth
                    disabled={!canEdit}
                    helperText={errors.mail?.message}
                    error={errors.mail && true}
                    data-testid="client_mail"
                  />
                </Box>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Your must enter an email',
                },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, // eslint-disable-line max-len
                  message: 'Please enter a valid email',
                },
              }}
            />
            <Controller
              name="department"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_department" error={errors.department && true}>
                    Department{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="client_department"
                    onChange={onChange}
                    value={value || ''}
                    fullWidth
                    disabled={!canEdit}
                    helperText={errors.department?.message}
                    error={errors.department && true}
                    data-testid="client_department"
                  />
                </Box>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Your must enter a department',
                },
              }}
            />
            <Controller
              name="direction"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_direction" error={errors.direction && true}>
                    Direction{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="client_direction"
                    onChange={onChange}
                    value={value || ''}
                    fullWidth
                    disabled={!canEdit}
                    helperText={errors.direction?.message}
                    error={errors.direction && true}
                    data-testid="client_direction"
                  />
                </Box>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Your must enter a direction',
                },
              }}
            />
            {canEdit && (
              <Button
                startIcon={<Save />}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="success"
                fullWidth
                data-testid="client_submit"
              >
                Update client
              </Button>
            )}
          </Box>
        ) : (
          <Loader />
        )}
      </form>
    </Drawer>
  );
};
export default ClientEdition;
