import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const PoReferenceCell = React.memo((row: OrderWorkunit) => (
  <Tooltip title={row.purchase_order ?? ''}>
    <Typography variant="body2" noWrap>
      {row.purchase_order ?? ''}
    </Typography>
  </Tooltip>
));

export default PoReferenceCell;
