import ResourceAPI from 'api/ResourceAPI';
import { CancelTokenSource } from 'axios';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

type CancelTokensPath = {
  [key: string]: CancelTokenSource;
};

let cancelTokens: CancelTokensPath = {};
/**
 * React hook that allows to cancel api calls
 */
export default function useCancelApi() {
  const history = useHistory();

  const initCancelToken = useCallback(async () => {
    if (history) {
      const { pathname } = history.location;
      if (!(pathname in cancelTokens)) {
        const cancelToken = ResourceAPI.updateCancelToken();
        cancelTokens = {
          ...cancelTokens,
          [pathname]: cancelToken,
        };
      }
    }
  }, [history]);

  const addCancelToken = useCallback((name: string) => {
    if (!(name in cancelTokens)) {
      const cancelToken = ResourceAPI.updateCancelToken();
      cancelTokens = {
        ...cancelTokens,
        [name]: cancelToken,
      };
    }
  }, []);

  const cancelRequests = useCallback((name: string) => {
    if (name in cancelTokens) {
      cancelTokens[name].cancel();
      const { [name]: _, ...rest } = cancelTokens;
      cancelTokens = rest;
    }
  }, []);

  return { cancelTokens, initCancelToken, cancelRequests, addCancelToken };
}
