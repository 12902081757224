import Workunit from 'types/entities/Workunit';

export const workunitA: Workunit = {
  id: 1,
  name: 'Workunit A Fixture',
  desc: 'This is a sample workunit fixture.',
  accept_standard: 'Standard',
  level_standard: 'Standard',
  is_archived: false,
  catalog_id: 1,
  scope_id: 1,
  scope: [],
  created_at: '2023-05-30T10:00:00Z',
  updated_at: '2023-05-30T12:00:00Z',
  charge: 8,
  devise_id: 1,
  devise: {
    id: 1,
    created_at: '2023-05-30T10:00:00Z',
    is_archived: false,
    symbol: '€',
    updated_at: '2023-05-30T12:00:00Z',
    name: 'Euro',
  },
  reference: 'WRK-001',
  input: 'Sample input data',
  output: 'Sample output data',
  price: 100,
  skill: 3,
  business_unit: 'Sample Business Unit',
  comment: 'This is a sample comment.',
  complexity_id: 1,
};

export const workunitB: Workunit = {
  id: 2,
  name: 'Workunit B Fixture',
  desc: 'This is another example workunit fixture.',
  accept_standard: 'Standard',
  level_standard: 'Standard',
  is_archived: false,
  catalog_id: 2,
  scope_id: 2,
  scope: [],
  created_at: '2023-05-29T14:30:00Z',
  updated_at: '2023-05-29T16:45:00Z',
  charge: 5,
  devise_id: 2,
  devise: {
    id: 2,
    name: 'Dollar',
    symbol: '$',
    is_archived: false,
    created_at: '2023-05-29T14:30:00Z',
    updated_at: '2023-05-29T16:45:00Z',
  },
  reference: 'WRK-002',
  input: 'Input data for another workunit',
  output: 'Output data for another workunit',
  price: 150,
  skill: null,
  business_unit: 'Another Business Unit',
  comment: 'This workunit requires additional information.',
  complexity_id: 2,
};

export const WorkunitC: Workunit = {
  id: 3,
  name: 'Archived Workunit',
  desc: 'This is an archived workunit fixture.',
  accept_standard: 'Standard',
  level_standard: 'Standard',
  is_archived: true,
  catalog_id: 3,
  scope_id: 3,
  scope: [],
  created_at: '2023-05-28T09:15:00Z',
  updated_at: '2023-05-29T11:30:00Z',
  charge: 10,
  devise_id: 1,
  devise: {
    id: 3,
    name: 'Livre',
    symbol: '£',
    is_archived: false,
    created_at: '2023-05-28T09:15:00Z',
    updated_at: '2023-05-29T11:30:00Z',
  },
  reference: 'WRK-003',
  input: 'Input data for archived workunit',
  output: 'Output data for archived workunit',
  price: 80,
  skill: 2,
  business_unit: 'Archived Business Unit',
  comment: 'This workunit has been archived.',
  complexity_id: 3,
};
