import { LoadingsActions } from '../actions/loadingsActions';
import { RootActions } from '../redux';
import { loadingsState, LoadingsState } from '../states/loadingsState';

const loadingsReducer = (state = loadingsState, action: RootActions): LoadingsState => {
  switch (action.type) {
    case LoadingsActions.ADD:
      return {
        ...state,
        [action.payload]: state[action.payload] ? state[action.payload] + 1 : 1,
      };
    case LoadingsActions.REMOVE:
      return {
        ...state,
        [action.payload]: state[action.payload] > 0 ? state[action.payload] - 1 : 0,
      };
    case LoadingsActions.RESET:
      return {};
    default:
      return state;
  }
};

export default loadingsReducer;
