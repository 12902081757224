import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason, TextField } from '@mui/material';
import { GridRenderEditCellParams, GridRowModel, MuiEvent, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import User from 'types/entities/User';

interface OrderAffectationUserCellProps {
  params: GridRenderEditCellParams;
  options: any[] | undefined;
  freeSolo?: boolean;
  multiple?: boolean;
  getOptionLabel?: (option: any) => string;
  propertyTarget: 'consultant' | 'client' | 'delivery_manager';
}

const OrderAffectationUserCell = (props: OrderAffectationUserCellProps) => {
  const { params, options, freeSolo, getOptionLabel, propertyTarget } = props;
  const apiRef = useGridApiContext();
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(true);
  const handleChange = React.useCallback(
    async (
      event: MuiEvent<React.SyntheticEvent<Element, Event>>,
      newValue: User | string | null,
      reason: AutocompleteChangeReason | AutocompleteInputChangeReason
    ) => {
      if (typeof newValue === 'string') throw Error('please select an option');
      setIsAutocompleteOpen(false);
      const rowData = params.row;
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
      const row = getRow(rowData, newValue, propertyTarget, reason);

      apiRef.current.setEditCellValue({
        id: params.id,
        field: params.field,
        value: row[params.field as keyof OrderWorkunit],
        /* propertyTarget !== 'delivery_manager'
            ? row[params.field as keyof OrderWorkunit]
            : row[params.field as keyof OrderWorkunit], */
      });
      apiRef.current.updateRows([row]);
    },
    [apiRef, params.field, params.id, params.row, propertyTarget]
  );
  const getRow = (
    row: GridRowModel<OrderWorkunit>,
    newValue: User | null,
    target: 'consultant' | 'client' | 'delivery_manager',
    reason: AutocompleteChangeReason | AutocompleteInputChangeReason
  ) => {
    const newRow = { ...row };
    if (!newValue && reason !== 'clear') {
      return newRow;
    }
    if (target === 'consultant') {
      if (reason === 'clear' || !newValue) {
        newRow.consultant = null;
        newRow.consultant_fullname = '';
        newRow.consultant_id = null;
      }
      if (reason !== 'clear' && newValue) {
        newRow.consultant = newValue;
        newRow.consultant_fullname = `${newValue.last_name.toLocaleUpperCase()} ${newValue.first_name}`;
        newRow.consultant_id = newValue.id;
      }
    }
    if (target === 'client') {
      if (reason === 'clear') {
        newRow.client = null;
        newRow.client_fullname = ``;
        newRow.client_id = null;
      }
      if (reason !== 'clear' && newValue) {
        newRow.client = newValue;
        newRow.client_fullname = `${newValue.last_name.toLocaleUpperCase()} ${newValue.first_name}`;
        newRow.client_id = newValue.id;
      }
    }
    if (target === 'delivery_manager') {
      if (reason === 'clear') {
        newRow.delivery_manager = [];
        newRow.delivery_manager_ids = [];
      }
      if (reason !== 'clear' && newValue) {
        newRow.delivery_manager = [newValue];
        newRow.delivery_manager_ids = [newValue.id];
      }
    }
    return newRow;
  };

  const checkEquality = (option: any, value: any) => option?.id === value.id;
  return (
    <Autocomplete
      onChange={handleChange}
      value={
        propertyTarget !== 'delivery_manager' ? params.row[`${propertyTarget}`] : params.row[`${propertyTarget}`][0]
      }
      open={isAutocompleteOpen}
      renderOption={(renderProps, option) => (
        <li {...renderProps} key={option.id}>
          {`${option.first_name} ${option.last_name.toUpperCase()}`}
        </li>
      )}
      fullWidth
      disableClearable={false}
      freeSolo={freeSolo}
      isOptionEqualToValue={checkEquality}
      multiple={false}
      options={options ?? []}
      autoHighlight
      getOptionLabel={getOptionLabel}
      renderInput={(parameter) => <TextField {...parameter} error={params.error} />}
    />
  );
};

export default (props: OrderAffectationUserCellProps) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useMemo(() => <OrderAffectationUserCell {...props} />, [props.params.value]);
