import RemoveIcon from '@mui/icons-material/Remove';
import { Box, CardContent, Grid, Tooltip, Typography } from '@mui/material';
import ProtectedLink from 'components/ProtectedLink';
import { trim } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useManagementRights from '../../../hooks/rights/useManagementRights';
import { useSelector } from '../../../store/hooks';
import OrderMenu from '../OrderMenu/OrderMenu';
import { OrderCardProps } from './OrderCard';
import useOrderCardService from './useOrderCardService';

const OrderDraftedCardContent: React.FC<OrderCardProps> = ({ order, menu = true }) => {
  const [t] = useTranslation();
  const { app } = useSelector((state) => state);
  const { canCreateWorkunitOrder } = useManagementRights(order);
  const { orderClient, engagementDate, finalAmountPrice } = useOrderCardService(order);
  const pathname = `/${app.customer?.slug}/catalog/${order.catalog_id}/order/${order.id}`;

  return (
    <CardContent>
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item xs={12} md>
          <Grid
            container
            spacing={4}
            justifyContent="space-between"
            alignItems="center"
            sx={{ '& .MuiGrid-item': { width: 0 } }}
          >
            <Grid item xs={12} md={6} lg={2}>
              <ProtectedLink pathname={pathname} right={canCreateWorkunitOrder}>
                {order.affair ? (
                  <Tooltip title={trim([order.affair?.otp, order.affair?.name].join(' - '))}>
                    <div>
                      <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                        {order.affair?.otp}
                      </Typography>
                      <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                        {order.affair?.name}
                      </Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <Typography noWrap>{t('Affair_not_indicated')} </Typography>
                )}
              </ProtectedLink>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Typography noWrap>{t('Client')}</Typography>
              <Tooltip title={orderClient()}>
                <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                  {orderClient()}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Typography>{t('Project_name')}</Typography>
              <Tooltip title={order?.name_spec}>
                <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                  {order?.name_spec || 'Not defined'}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Typography>{t('Chrono reference')}</Typography>
              <Tooltip title={order.draft_reference ?? t<string>('Not_defined')}>
                {order.draft_reference ? (
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {order.draft_reference}
                  </Typography>
                ) : (
                  <RemoveIcon color="disabled" />
                )}
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Typography>{t('Engagement_date')}</Typography>
              <Tooltip title={engagementDate()}>
                <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                  {engagementDate()}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Box>
                <Typography>{t('Price')}</Typography>
                <Tooltip title={finalAmountPrice()}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {finalAmountPrice()}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ml: 'auto' }}
        >
          {menu && <OrderMenu order={order} />}
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default OrderDraftedCardContent;
