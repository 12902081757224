import { Box, Tab, Tabs } from '@mui/material';
import useUserRoles from 'hooks/useUserRoles';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { changeCurrentTabAction, changeParentTabAction } from 'store/actions/managementActions';
import { OrdersTabParentState } from 'types/enums/OrdersTabParentState';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import useManagementHelper from '../../services/useManagementHelper';
import { useHistory, useLocation } from 'react-router-dom';
import { appState } from 'store/states/appState';

const ManagementParentTabs: React.FC = () => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const { isPathForProduction, isPathForQuotes, handleManagementTabChange } = useManagementHelper();
  const { state } = useAppState();
  const { search } = useLocation();
  const userRoles = useUserRoles();
  const history = useHistory();
  let parentTab = useSelector((state) => state.management.pageHandler.parentTab);
  const currentTab = useSelector((state) => state.management.pageHandler.currentTab);
  const appState = useSelector((state) => state.app);

  useEffect(() => {
    if (userRoles && (userRoles.isConsultant || userRoles.isClient)) {
      if (parentTab === OrdersTabParentState.MATERIAL) {
        parentTab = OrdersTabParentState.CATALOG;
        dispatch(changeParentTabAction(parentTab));
      }
    }
  }, [dispatch, userRoles]);

  useEffect(() => {
    if (userRoles && !userRoles.isConsultant && !userRoles.isClient) {
      const searchParams = new URLSearchParams(search);
      const status = searchParams.get('status');
      const url = new URL(window.location.href);
      if (
        parentTab !== OrdersTabParentState.CATALOG &&
        (status === OrdersTabState.ON_GOING || status === OrdersTabState.MATERIAL_PRODUCTION) &&
        !url.href.includes('material_production')
      ) {
        history.push(`/${appState.customer?.slug}/orders/production?status=material_production`);
      }
    }
  }, []);

  const parentTabs = useCallback(() => {
    const tabs = [
      {
        label: OrdersTabParentState.CATALOG,
        value: OrdersTabParentState.CATALOG,

        disabled: !(userRoles.isMigsoManager && isPathForProduction()),
      },
      {
        label: OrdersTabParentState.MATERIAL,
        value: OrdersTabParentState.MATERIAL,

        disabled: !(userRoles.isMigsoManager && isPathForProduction()),
      },
    ];

    return tabs.filter((tab) => !tab.disabled);
  }, [isPathForProduction, isPathForQuotes, userRoles.isMigsoManager]);

  const badgeContent = (tab: {
    label: OrdersTabParentState;
    value: OrdersTabParentState;
    disabled: boolean;
    badge?: number | undefined;
  }) =>
    tab.badge ? (
      <>
        <Box sx={{ mr: 1 }}>{t(tab.label)}</Box>
      </>
    ) : (
      t(tab.label)
    );
  if (!userRoles.isMigsoManager) {
    return null;
  }
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
      {parentTab && (
        <Tabs value={parentTab}>
          {parentTabs().map((tab) => (
            <Tab
              label={badgeContent(tab)}
              value={tab.value}
              onClick={() => {
                dispatch(changeParentTabAction(tab.value));
                if (tab.value === OrdersTabParentState.CATALOG) {
                  handleManagementTabChange(OrdersTabState.ON_GOING);
                } else if (tab.value === OrdersTabParentState.MATERIAL) {
                  handleManagementTabChange(OrdersTabState.MATERIAL_PRODUCTION);
                }
              }}
              disabled={state.management.pageHandler.parentTab === tab.value}
            />
          ))}
        </Tabs>
      )}
    </Box>
  );
};

export default ManagementParentTabs;
