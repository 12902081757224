import ChangeCircleTwoToneIcon from '@mui/icons-material/ChangeCircle';
import { Box } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OrderWorkunit from 'types/entities/OrderWorkunit';

type CellUserNotEditModeProps = {
  params: GridCellParams<any, OrderWorkunit>;
  propertyTarget: 'consultant_fullname' | 'client_fullname' | 'delivery_manager_fullname';
  addUserText: 'Add consultant' | 'Add customer' | 'Add delivery manager';
};
const CellUserNotEditMode = (props: CellUserNotEditModeProps) => {
  const { params, propertyTarget, addUserText } = props;
  const [t] = useTranslation();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ChangeCircleTwoToneIcon sx={{ mr: 1 }} />
      {!params?.row?.[propertyTarget] ? t(addUserText) : params?.row?.[propertyTarget]}
    </Box>
  );
};

export default (props: CellUserNotEditModeProps) => useMemo(() => <CellUserNotEditMode {...props} />, [props]);
