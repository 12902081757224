import { createAction } from '@reduxjs/toolkit';
import { initialValue } from '../../helperContext';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const changePageSize = createAction(OrderWorkunitAffectationType.ChangePageSize, (newPageSize: number) => ({
  payload: { newPageSize },
}));
export const changePage = createAction(OrderWorkunitAffectationType.ChangePage, (newPage: number) => ({
  payload: { newPage },
}));

export const changeTotalItems = createAction(OrderWorkunitAffectationType.ChangeTotalItems, (totalItems: number) => ({
  payload: { totalItems },
}));

export const changeTotalPages = createAction(OrderWorkunitAffectationType.ChangeTotalPages, (totalPages: number) => ({
  payload: { totalPages },
}));

export const gridIsLoadingAction = createAction(OrderWorkunitAffectationType.GridIsLoading);
export const gridFinishedLoadingAction = createAction(OrderWorkunitAffectationType.GridNotLoading);
export default (state = initialValue, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.ChangePageSize:
      return {
        ...state,
        pageSize: action.payload.newPageSize,
      };
    case OrderWorkunitAffectationType.GridIsLoading:
      return {
        ...state,
        isGridLoading: true,
      };
    case OrderWorkunitAffectationType.GridNotLoading:
      return {
        ...state,
        isGridLoading: false,
      };
    case OrderWorkunitAffectationType.ChangePage:
      return {
        ...state,
        currentPage: action.payload.newPage,
      };
    case OrderWorkunitAffectationType.ChangeTotalItems:
      return {
        ...state,
        totalItems: action.payload.totalItems,
      };
    case OrderWorkunitAffectationType.ChangeTotalPages:
      return {
        ...state,
        totalPages: action.payload.totalPages,
      };

    default:
      return state;
  }
};
