import { Card } from '@mui/material';
import { getOrderStatusColor } from 'helpers/utils';
import React from 'react';
import Order from '../../../types/entities/Order';
import { OrderStatus } from '../../../types/enums/OrderStatus';
import OrderDraftedCardContent from './OrderDraftedCardContent';
import OrderPendingCardContent from './OrderPendingCardContent';
import OrderProductionCardContent from './OrderProductionCardContent';
import OrderValidatedCardContent from './OrderValidatedCardContent';
import MaterialOrderDraftedCardContent from './MaterialOrderDraftedCardContent';
import MaterialOrderProductionCardContent from './MaterialOrderProductionCardContent';

export type OrderCardProps = {
  order: Order;
  action?: boolean;
  menu?: boolean;
  stats?: boolean;
  graph?: boolean;
  kpi?: boolean;
  isFromMain?: boolean;
};
const OrderCard = (props: OrderCardProps) => {
  const { order } = props;
  const content = () => {
    let component;
    switch (order.status) {
      case OrderStatus.PENDING:
        component = OrderPendingCardContent;
        break;
      case OrderStatus.VALIDATED:
        component = OrderValidatedCardContent;
        break;
      case OrderStatus.PRODUCTION:
      case OrderStatus.CLOSED:
        component = OrderProductionCardContent;
        break;
      case OrderStatus.MATERIAL_DRAFT:
        component = MaterialOrderDraftedCardContent;
        break;
      case OrderStatus.MATERIAL_PRODUCTION:
      case OrderStatus.MATERIAL_CLOSED:
        component = MaterialOrderProductionCardContent;
        break;

      case OrderStatus.DRAFTED:
      default:
        component = OrderDraftedCardContent;
    }

    return React.createElement(component, props);
  };

  return <Card sx={{ borderLeft: '10px solid', borderColor: getOrderStatusColor(order), mb: 4 }}>{content()}</Card>;
};

export default OrderCard;
