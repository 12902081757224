// utils.ts
export interface HasId {
  id: number;
}

export const updateIds = <T extends HasId>(
  deliveryItems: T[],
  archivedItems: T[]
): { ids: number[] | null; archived: boolean[] } => {
  const idsSet = new Set<number>();
  const isArchivedMap = new Map<number, boolean>();

  // Add deliveryItems ids and mark as false
  deliveryItems.forEach((item) => {
    idsSet.add(item.id);
    isArchivedMap.set(item.id, false);
  });

  // Add archivedItems ids and mark as true
  archivedItems.forEach((item) => {
    idsSet.add(item.id);
    isArchivedMap.set(item.id, true);
  });

  const ids: number[] = Array.from(idsSet);
  const archived: boolean[] = ids.map((id) => isArchivedMap.get(id) as boolean);

  return {
    ids: ids.length > 0 ? ids : null,
    archived,
  };
};
