import { Avatar, AvatarGroup, Box, CardContent, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { trim } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, stringToColor } from '../../../helpers/format';
import useUserRoles from '../../../hooks/useUserRoles';
import OrderMenu from '../OrderMenu/OrderMenu';
import { OrderCardProps } from './OrderCard';
import useOrderCardService from './useOrderCardService';
import moment from 'moment';

const MaterialOrderDraftedCardContent: React.FC<OrderCardProps> = ({ order, menu = true }) => {
  const [t] = useTranslation();
  const userRoles = useUserRoles();
  const { engagementDate, orderConsultantTooltipTitle, orderClient } = useOrderCardService(order);

  const userLocale = navigator.language || 'fr-FR';
  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
  const userTimeZone = moment.tz.guess();
  const formattedCreatedDate = moment.utc(order.created_at).tz(userTimeZone).format(dateFormat);
  const formatedUpdatedDate = moment.utc(order.updated_at).tz(userTimeZone).format(dateFormat);

  return (
    <>
      <CardContent sx={{ position: 'relative' }}>
        <Grid item xs={12} md={6} lg={3}>
          <Tooltip title={trim([order.name_spec, order.ref_spec].join(' - ')) ?? t<string>('Not_defined')}>
            <div>
              <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                ID {order?.id} {order?.name_spec}
              </Typography>
            </div>
          </Tooltip>
          <Typography>{t('Project_name')}</Typography>
        </Grid>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12} md>
            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
              <Grid item xs={12} md={6} lg={!userRoles.isConsultant && !userRoles.isCustomer ? 1.5 : 3}>
                {order.affair ? (
                  <Tooltip title={trim([order.affair?.otp, order.affair?.name].join(' - '))}>
                    <div>
                      <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                        {order.affair?.name}
                      </Typography>
                      <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                        {order.affair?.otp}
                      </Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <div>
                    <Typography noWrap>{t('Affair_not_indicated')} </Typography>
                    <Box sx={{ height: '1.6rem' }} />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={!userRoles.isConsultant && !userRoles.isCustomer ? 1.5 : 3}>
                <Tooltip title={orderClient()}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {orderClient()}
                  </Typography>
                </Tooltip>
                <Typography noWrap>{t('Client')}</Typography>
              </Grid>
              {!userRoles.isConsultant && !userRoles.isCustomer && (
                <Grid item xs={12} md={6} lg={1.5}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Tooltip placement="bottom-start" title={orderConsultantTooltipTitle}>
                      {order.consultants && order.consultants[0] ? (
                        <AvatarGroup max={3}>
                          {order.consultants.map((c) =>
                            c.avatar_uri ? (
                              <Avatar src={c.avatar_uri} key={c.id} />
                            ) : (
                              <Avatar
                                sx={{
                                  backgroundColor: stringToColor(c.first_name),
                                  color: stringToColor(c.first_name, 'color'),
                                  width: 30,
                                  height: 30,
                                }}
                                key={c.id}
                              >{`${capitalize(c.first_name.charAt(0))}${capitalize(c.last_name.charAt(0))}`}</Avatar>
                            )
                          )}
                        </AvatarGroup>
                      ) : (
                        <div>
                          <Typography sx={{ color: 'primary.main', fontWeight: 500 }}>-</Typography>
                        </div>
                      )}
                    </Tooltip>
                    <Typography>{t('Consultants')}</Typography>
                    <Box sx={{ height: '0.4rem' }} />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md={6} lg={3}>
                <Tooltip title={engagementDate()}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {engagementDate()}
                  </Typography>
                </Tooltip>
                <Typography>{t('Engagement_date')}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={1.5}>
                <Tooltip title={formattedCreatedDate}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {formattedCreatedDate}
                  </Typography>
                </Tooltip>
                <Typography>{t('Created on')}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={1.5}>
                <Tooltip title={formatedUpdatedDate}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {formatedUpdatedDate}
                  </Typography>
                </Tooltip>
                <Typography>{t('Modified on')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md="auto"
            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ml: 'auto' }}
          >
            <Stack spacing={2} direction="row" alignItems="center">
              {menu && <OrderMenu order={order} />}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default MaterialOrderDraftedCardContent;
