import ResourceAPI, { Resource, SearchParams } from 'api/ResourceAPI';
import OrderScope from 'types/entities/OrderScope';

export default class OrderScopesApiService {
  public static instance: OrderScopesApiService;

  public static baseUrl = 'order-scopes' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return OrderScopesApiService.instance || (OrderScopesApiService.instance = new OrderScopesApiService());
  }

  public static async fetchOrderScopeById(orderId: number, searchParams?: SearchParams) {
    try {
      const { data } = await ResourceAPI.get(this.baseUrl, {
        order_id: orderId,
        size: '-1',
        ...searchParams,
      });
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async updateOrderScope(orderScopeId: number, body: Partial<OrderScope>) {
    try {
      const { data } = await ResourceAPI.patch(this.baseUrl, body, orderScopeId);
      return data;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async createOrderScope(body: Partial<OrderScope>) {
    try {
      const { data } = await ResourceAPI.post(this.baseUrl, body);
      return data;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async getOrderScopes(order_id: number, searchParams?: SearchParams) {
    try {
      const { data } = await ResourceAPI.get(this.baseUrl, {
        order_id,
        size: '-1',
        ...searchParams,
      });
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }
}
