import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import UserApiService from 'api/UserApiService';
import { WarningIcon } from 'components/CustomIcons';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import User from 'types/entities/User';

type ClientsDialogResetPasswordProps = {
  activeUser: User;
};

const ClientsDialogResetPassword: React.FC<ClientsDialogResetPasswordProps> = ({ activeUser }) => {
  const [t] = useTranslation();
  const dialogs = useSelector((state) => state.dialogs);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAppState();
  const { makeCall } = useApi();
  const close = () => {
    dispatch(closeDialogAction('clientResetPassword'));
  };

  const resetPassword = async () => {
    setLoading(true);
    const res = await makeCall(UserApiService.resetPassword(activeUser.id), 'Error resetting password');
    if (res) {
      dispatch(setSnackbarAction({ message: 'Password reset successfully', open: true, severity: 'success' }));
      dispatch(closeDialogAction('clientResetPassword'));
    }
    setLoading(false);
  };

  return (
    <>
      <Dialog style={{ maxHeight: 435 }} maxWidth="sm" open={dialogs.clientResetPassword || false}>
        <DialogTitle>{t('reset_password')}</DialogTitle>
        <DialogContent>
          <div>{t('confirm_client_reset_password')}</div>
          <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 2 }}>
            <WarningIcon color="error" />
            <Typography color="error">{t('remember_check_spams')}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={close}>
            {t('cancel')}
          </Button>
          <LoadingButton variant="contained" loading={loading} onClick={resetPassword} color="primary">
            {t('confirm_reset_password')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientsDialogResetPassword;
