import { Add, FilterList, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import CustomersApiService from 'api/CustomersApiService';
import RolesApiService from 'api/RolesApiService';
import UserApiService from 'api/UserApiService';
import { Layout } from 'components/index';
import { stringToColor } from 'helpers/format';
import useApi from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Customer from 'types/entities/Customer';
import Role from 'types/entities/Role';
import User from 'types/entities/User';
import UserRole from 'types/entities/UserRole';
import './AdminUsersPermissions.scss';
import UserRoleCellAdmin from './UserRowAdmin/UserRowAdmin';

const AdminUserPermissions: React.FC<RouteComponentProps> = ({ match }) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const loadings = useSelector((state) => state.loadings);
  const [t] = useTranslation();
  const [users, setUsers] = useState<User[]>([]);
  const [foundUsers, setFoundUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [roleSearch, setRoleSearched] = useState<Role | null>(null);
  const [openDialogAddUser, setOpenDialogAddUser] = React.useState(false);
  const { makeCall } = useApi();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsFilterOpen(() => !isFilterOpen);
  };
  const handleClose = (event: any, value: Role) => {
    if (roleSearch && roleSearch.name === value.name) setRoleSearched(null);
    else setRoleSearched(value);
    setAnchorEl(null);
    setIsFilterOpen(() => !isFilterOpen);
  };

  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  useEffect(() => {
    const getUsersInCustomer = async () => {
      dispatch(addLoadingAction('getUsersInCustomer'));
      const res = await makeCall(RolesApiService.fetchAll(), 'Unable to retrieve roles');
      setRoles(res.filter((role: Role) => role.name !== 'customer'));

      const usersRes = await makeCall(
        CustomersApiService.fetchAllUsers({ size: '-1' }),
        'Unable to retrieve users on customer'
      );

      setUsers(usersRes.map((result: User) => ({ ...result, roles: result['user-roles'][0] })));
      setFoundUsers(usersRes.map((result: User) => ({ ...result, roles: result['user-roles'][0] })));

      if (usersRes.results) {
        setFoundUserLength(usersRes.results.length);
      }

      setPage(0);
      dispatch(removeLoadingAction('getUsersInCustomer'));
    };
    if (appState.customer?.id) getUsersInCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer?.id]);
  const [contacts, setContacts] = useState<User[]>([]);
  const [inputValueContact, setInputValueContact] = useState<string>('');

  const handleClickRole = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, user: User, value: Omit<UserRole, 'id'>) => {
    setUsers(users.map((u) => (u.id !== user.id ? u : { ...u, 'user-roles': [value] })));
    setFoundUsers(foundUsers.map((u) => (u.id !== user.id ? u : { ...u, 'user-roles': [value] })));
    validatePermissionsUser({ ...user, 'user-roles': [value] });
  };
  const [isContactLoading, setIsContactLoading] = useState<boolean>(false);

  const validatePermissionsUser = async (user: User) => {
    setIsLoadingButton(true);
    try {
      await UserApiService.updateUserRole(
        user.id,
        (roles.find((r) => r.name === user['user-roles'][0].role_name) as Role).id as number
      );
    } catch (error) {
      const result = (error as Error).message;
      if (result) {
        dispatch(
          setSnackbarAction({ message: `Unable to update user on customer: ${result}`, open: true, severity: 'error' })
        );
      } else {
        dispatch(
          setSnackbarAction({ message: `Unable to update user on customer : ${error}`, open: true, severity: 'error' })
        );
      }
    } finally {
      setIsLoadingButton(false);
    }
  };
  const handleChangeNewUser = (checked: boolean, role: Role) => {
    if (checked) setNewUser({ ...newUser, roles: role });
  };

  const [searched, setSearched] = useState<string>('');

  useEffect(() => {
    searchFunction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searched, roleSearch]);
  const [foundUsersLength, setFoundUserLength] = React.useState(0);

  useEffect(() => {
    setFoundUserLength(foundUsersLength);
  }, [foundUsers, foundUsersLength]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function searchFunction() {
    const searchParam = searched.toLowerCase();
    if (searched !== '' && !roleSearch) {
      setFoundUsers(users.filter((user) => searchByName(user)));
    } else if (roleSearch && roleSearch.name && searched === '') {
      setFoundUsers(users.filter((user) => searchByRole(user)));
    } else if (roleSearch && roleSearch.name && searched !== '') {
      setFoundUsers(users.filter((user) => searchByRole(user) && searchByName(user)));
    } else if (searched === '' && roleSearch === null) {
      setFoundUsers(users);
    }
    setPage(0);

    function searchByRole(user: User) {
      return user['user-roles'].some((role) => role.role_name === roleSearch?.name);
    }

    function searchByName(user: User): unknown {
      return user.first_name.toLowerCase().includes(searchParam) || user.last_name.toLowerCase().includes(searchParam);
    }
  }

  const handleCloseDialogAddUser = () => {
    setOpenDialogAddUser(false);
  };
  const handleOpenDialogAddUser = () => {
    setOpenDialogAddUser(true);
  };

  const saveNewUser = async () => {
    setIsLoadingButton(true);
    try {
      const res = await UserApiService.updateUserRole(newUser?.user_id as number, newUser?.roles?.id as number);
      if (res) {
        const userWasAlreadyAssignedToCustomer = users.find((user) => user.id === newUser?.user_id);
        if (userWasAlreadyAssignedToCustomer && newUser?.roles && newUser?.roles?.id)
          setUsers(
            users.map((user) =>
              user.id !== userWasAlreadyAssignedToCustomer.id
                ? user
                : { ...user, roles: newUser?.roles ? [newUser?.roles] : [] }
            )
          );
        if (userWasAlreadyAssignedToCustomer && !newUser?.roles && !newUser?.roles?.id)
          setUsers(users.filter((user) => user.id !== userWasAlreadyAssignedToCustomer.id));
        if (!userWasAlreadyAssignedToCustomer) {
          setUsers([...users, { ...(newUser?.user as User), roles: newUser?.roles ? [newUser?.roles] : [] }]);
        }
      }
    } catch (error) {
      const result = (error as Error).message;

      if (result) {
        dispatch(
          setSnackbarAction({ message: `Unable to update user on customer: ${result}`, open: true, severity: 'error' })
        );
      } else {
        dispatch(
          setSnackbarAction({ message: `Unable to update user on customer : ${error}`, open: true, severity: 'error' })
        );
      }
    } finally {
      setOpenDialogAddUser(false);
      setIsLoadingButton(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setIsContactLoading(true);
      setContacts([]);
      try {
        if (inputValueContact !== '' && inputValueContact.length > 1) {
          const res = await makeCall(
            UserApiService.get({ 'last_name,first_name': `:${inputValueContact}:`, is_archived: 0, size: -1 })
          );

          setContacts(res.datas);
        }
      } catch (e) {
        const result = (e as Error).message;
        if (result) {
          dispatch(
            setSnackbarAction({ message: `Failed to retrieve users : ${result}`, open: true, severity: 'error' })
          );
        } else {
          dispatch(setSnackbarAction({ message: `Failed to retrieve users :${e}`, open: true, severity: 'error' }));
        }
      } finally {
        setIsContactLoading(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueContact]);

  function setNewUserSelected(
    newUser: { roles?: Role | undefined; user_id?: number | undefined; customer_id?: number | undefined } | undefined,
    value: User | null,
    reason: string
  ): void {
    if (reason === 'clear') return setNewUser(undefined);
    const userIsAlreadyAssignedToCustomer = users.find((user) => user.id === value?.id);
    if (userIsAlreadyAssignedToCustomer) {
      return setNewUser({
        user_id: userIsAlreadyAssignedToCustomer.id,
        roles: userIsAlreadyAssignedToCustomer.roles?.[0],
      });
    }
    return setNewUser({ ...newUser, user_id: value?.id as User['id'], user: value as User });
  }

  const [newUser, setNewUser] =
    useState<{ roles?: Role; user_id?: User['id']; customer_id?: Customer['id']; user?: User }>();

  return (
    <Layout name="Admin" path="/administration">
      <div className="admin-container">
        <div className="filters-container">
          <div className="filters">
            <button type="button" className="submit-button" onClick={handleOpenDialogAddUser}>
              <Add />
              {t('Add user')}
            </button>
            <Dialog
              open={openDialogAddUser}
              onClose={handleCloseDialogAddUser}
              className="dialog_add_user"
              maxWidth="md"
            >
              <DialogTitle>{t('New_user')}</DialogTitle>
              <DialogContent style={{ minWidth: '480px' }}>
                <div className="label-input">{t('User')}</div>

                <Autocomplete
                  id="contact-autocomplete"
                  className="autocomplete-card-client"
                  options={contacts}
                  autoHighlight
                  getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                  renderOption={(boxprops, option, { selected }) => (
                    <Box component="li" {...boxprops} className="box-consultant">
                      {option?.avatar_uri ? (
                        <Avatar src={option.avatar_uri} alt={t('Active_user_avatar')} />
                      ) : (
                        <Avatar
                          style={{
                            backgroundColor: `${stringToColor(option?.first_name)}`,
                            color: `${stringToColor(option?.first_name, 'color')}`,
                          }}
                        >{`${option?.first_name.charAt(0)}${option?.last_name.charAt(0)}`}</Avatar>
                      )}
                      {option.last_name} {option.first_name}
                    </Box>
                  )}
                  inputValue={inputValueContact}
                  onInputChange={(event, valueContact) => {
                    setInputValueContact(valueContact);
                  }}
                  // open={contacts.length > 1}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value, reason) => setNewUserSelected(newUser, value as User, reason)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                  noOptionsText={
                    inputValueContact.length > 1 && contacts.length === 0
                      ? t('No contacts found')
                      : t('2_characters_Minimun')
                  }
                  loading={!contacts.length && isContactLoading}
                />

                <FormGroup>
                  {roles.map((role) => (
                    <FormControlLabel
                      key={role.name}
                      control={
                        <Checkbox
                          className="container_name_admin"
                          color="primary"
                          checked={newUser?.roles && newUser?.roles?.name === role.name}
                          onChange={(e) => handleChangeNewUser(e.currentTarget.checked, role)}
                        />
                      }
                      label={t(role.name) as string}
                    />
                  ))}
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <button type="button" className="submit-button" onClick={saveNewUser}>
                  {!isLoadingButton ? (
                    <div className="submit-button-icon-container">
                      <Add />
                      {t('Save_user')}
                    </div>
                  ) : (
                    <CircularProgress size="1rem" color="inherit" />
                  )}
                </button>
              </DialogActions>
            </Dialog>
            <button
              aria-controls="simple-menu"
              aria-haspopup="true"
              className="submit-button"
              onClick={handleClick}
              type="button"
            >
              <FilterList />
              {roleSearch && roleSearch.name ? roleSearch.name : t('Filter')}
            </button>
            <Popper open={isFilterOpen} anchorEl={anchorEl} transition>
              {({
                TransitionProps,
                placement = 'auto-start',
              }: {
                TransitionProps: { in: boolean; onEnter: () => void; onExited: () => void; id: string };
                placement: string;
              }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper className="menu-paper-container">
                    <MenuList>
                      {roles.map((role) => (
                        <MenuItem>
                          <button
                            type="button"
                            onClick={(e) => handleClose(e, role)}
                            className="filter-content-item-container"
                          >
                            {t(role?.name)}
                          </button>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <TextField
              placeholder={t('Search')}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <IconButton size="large">
                    <Search />
                  </IconButton>
                ),
              }}
              value={searched}
              onChange={(e) => setSearched(e.currentTarget.value)}
              className="search_textfield"
            />
          </div>
        </div>
        <div className="table_container">
          {!loadings.getUsersInCustomer ? (
            <Table style={{ width: '100%', overflow: 'auto', tableLayout: 'fixed' }}>
              <TableBody style={{ width: '100%' }}>
                {foundUsers
                  .sort((a, b) => a.last_name.localeCompare(b.last_name))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => {
                    if (!user.is_client) {
                      return (
                        <TableRow key={user?.id}>
                          <TableCell component="td" style={{ width: '30%' }}>
                            <Box component="div" className="users_box">
                              {user?.avatar_uri ? (
                                <Avatar src={user.avatar_uri} alt={t('Active_user_avatar')} />
                              ) : (
                                <Avatar
                                  style={{
                                    backgroundColor: `${stringToColor(user?.first_name)}`,
                                    color: `${stringToColor(user?.first_name, 'color')}`,
                                  }}
                                >{`${user?.first_name.charAt(0)}${user?.last_name.charAt(0)}`}</Avatar>
                              )}
                              <Tooltip arrow title={`${user.last_name} ${user.first_name}` as string}>
                                <div className="container_name_admin"> {`${user.last_name} ${user.first_name}`}</div>
                              </Tooltip>{' '}
                            </Box>
                          </TableCell>

                          <UserRoleCellAdmin
                            user={user}
                            users={users}
                            roles={roles}
                            handleClickRole={handleClickRole}
                            setUsers={setUsers}
                            /* validatePermissionsUser={validatePermissionsUser} */
                          />
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                <TablePagination
                  component={TableRow}
                  count={foundUsers.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableBody>
            </Table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdminUserPermissions;
