import { Close } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import { Box, Drawer as MuiDrawer, IconButton, Typography } from '@mui/material';
import { EditIcon } from 'components/CustomIcons';
import React from 'react';
import { closeDrawerAction } from 'store/actions/drawerActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { DeliverableTabOption } from 'types/enums/DeliverableTabOption';
import DeliverableActions from './DeliverableActions/DeliverableActions';
import DeliverableCancel from './DeliverableCancel/DeliverableCancel';
import DeliverableCreation from './DeliverableCreation/DeliverableCreation';
import DeliverableDetails from './DeliverableDetails/DeliverableDetails';
import DeliverableEdit from './DeliverableEdit/DeliverableEdit';
import TnMDeliverableCreation from './DeliverableCreation/TnMDeliverableCreation';

const Drawer: React.FC = () => {
  const { dispatch } = useAppState();
  const drawerState = useSelector((state) => state.drawer);
  const onClose = () => {
    dispatch(closeDrawerAction());
  };

  // TODO ICON TITLE

  const drawers: {
    [name: string]: {
      title: string;
      titleColor?: string;
      titleIcon?: JSX.Element;
      content?: React.FC<any>;
      props?: any;
    };
  } = {
    deliverableDetails: {
      title: 'Details',
      content: DeliverableDetails,
      props: {
        id: drawerState?.data?.id,
        tab: drawerState?.data?.tab || DeliverableTabOption.DETAIL,
      },
    },
    deliverableCreation: {
      title: 'Creation',
      content: DeliverableCreation,
    },
    tnmdeliverableCreation: {
      title: 'Creation',
      content: TnMDeliverableCreation,
    },
    deliverableEdit: {
      title: 'Modify deliverable',
      titleIcon: <EditIcon />,
      content: DeliverableEdit, // TODO edit or modify component ?
      props: {
        ids: drawerState.data,
      },
    },
    deliverableActions: {
      title: 'Quick actions',
      content: DeliverableActions,
    },
    deliverableCancel: {
      title: 'Cancel deliverables',
      titleColor: 'red',
      titleIcon: <BlockIcon color="error" />,
      content: DeliverableCancel,
    },
  };

  const content = () =>
    drawerState.name && drawers[drawerState.name] !== undefined && drawers[drawerState.name].content
      ? React.createElement(drawers[drawerState.name].content || '', drawers[drawerState.name].props)
      : null;

  return (
    <>
      {drawerState.name && content() && (
        <MuiDrawer
          anchor="right"
          open={!!drawerState.name}
          onClose={onClose}
          PaperProps={{
            sx: {
              width: {
                xs: '100%',
                lg: 650,
              },
            },
          }}
        >
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
              <Typography variant="h2">{drawers[drawerState.name].title}</Typography>
              <IconButton sx={{ ml: 'auto' }} color="primary" onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            {content()}
          </>
        </MuiDrawer>
      )}
    </>
  );
};

export default Drawer;
