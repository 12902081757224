import Order from 'types/entities/Order';
import User from 'types/entities/User';
import { OrdersTabParentState } from 'types/enums/OrdersTabParentState';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { SearchParams } from '../../api/ResourceAPI';

const savedParentTab = localStorage.getItem('activeTab') as OrdersTabParentState | null;

const initialParentTab = savedParentTab || OrdersTabParentState.CATALOG;

const initialCurrentTab =
  initialParentTab === OrdersTabParentState.MATERIAL ? OrdersTabState.MATERIAL_PRODUCTION : OrdersTabState.NULL;

export interface ManagementState {
  orders: Order[] | null;
  clients: User[] | null;
  searchParams: SearchParams | null;
  pageHandler: {
    parentTab: OrdersTabParentState;
    currentTab: OrdersTabState;
    currentPage: number;
    totalPages: number;
  };
  activeOrderId: Order['id'] | null;
}

export const managementState: ManagementState = {
  orders: null,
  clients: null,
  searchParams: null,
  pageHandler: {
    parentTab: initialParentTab,
    currentTab: initialCurrentTab,
    currentPage: 1,
    totalPages: 0,
  },
  activeOrderId: null,
};
