import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import InputLabel, { InputLabelOnChangeHandlerType } from 'components/Input';
import useOrderWorkunitsService from 'features/Orders/OrderWorkunits/services/useOrderWorkunitsService';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'store/hooks';

type HeaderCellProps = {
  params: GridColumnHeaderParams;
  target: 'catalog' | 'selected';
};

const HeaderCell = ({ params, target }: HeaderCellProps) => {
  const { colDef } = params;
  const [search, setSearch] = useState('');

  const searchValue = useSelector((state) => {
    if (target === 'catalog') {
      return state.catalogToOrder.catalogHandler.searchParams?.[colDef.field] ?? '';
    }
    if (target === 'selected') {
      return state.catalogToOrder.orderHandler.searchParams?.[colDef.field] ?? '';
    }
    return '';
  });
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue !== search) {
        updateSearchFilters({ [colDef.field]: search }, target);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { updateSearchFilters } = useOrderWorkunitsService();

  const onChange = (e: InputLabelOnChangeHandlerType) => {
    if (e.target.value !== search) setSearch(e.target.value);
  };

  return (
    <>
      <div className="column_head_container">
        <InputLabel
          label={colDef.headerName || ''}
          name={colDef.field}
          id={`$current_${colDef.field}`}
          placeholder={colDef.headerName || ''}
          onchange={onChange}
          value={search}
        />
      </div>
    </>
  );
};

export default HeaderCell;
