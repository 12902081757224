import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import NameCell from '../../Cells/NameCell/NameCell';
import OrderAffectationScopeCell from '../../Cells/DataGridScopeCell/OrderAffectationScopeCell';
import ReferenceCell from '../../Cells/ReferenceCell/ReferenceCell';
import WorkloadCell from '../../Cells/WorkloadCell/WorkloadCell';
import PriceCell from '../../Cells/PriceCell/PriceCell';
import TotalCell from '../../Cells/TotalCell/TotalCell';

type CatalogWorkunitDatagridCellParams<T> = GridCellParams<T, CatalogWorkunit, any>;

export const CatalogWorkunitDatagridColumnField = {
  REFERENCE: 'reference',
  NAME: 'name',
  WORKLOAD: 'charge',
  PRICE: 'price',
  SCOPE: 'scope',
  MONTHS: 'months',
  CONTENTS: 'contents',
  TOTAL: 'total',
};

export const catalogWorkunitDatagridColumnsParams: GridColDef[] = [
  {
    field: CatalogWorkunitDatagridColumnField.REFERENCE,
    minWidth: 110,
    renderCell: (params: CatalogWorkunitDatagridCellParams<string>) => <ReferenceCell reference={params.value || ''} />,
  },
  {
    field: CatalogWorkunitDatagridColumnField.NAME,
    minWidth: 170,
    renderCell: (params: CatalogWorkunitDatagridCellParams<string>) => <NameCell name={params.value || ''} />,
  },
  {
    field: CatalogWorkunitDatagridColumnField.WORKLOAD,
    headerName: 'Workload',
    minWidth: 80,
    renderCell: (params: CatalogWorkunitDatagridCellParams<number>) => <WorkloadCell charge={params.value || 0} />,
  },
  {
    field: CatalogWorkunitDatagridColumnField.PRICE,
    minWidth: 60,
    renderCell: (params: CatalogWorkunitDatagridCellParams<number>) => <PriceCell price={params.value || 0} />,
  },
  {
    field: CatalogWorkunitDatagridColumnField.SCOPE,
    minWidth: 160,
    renderCell: (params: CatalogWorkunitDatagridCellParams<CatalogWorkunit>) => (
      <OrderAffectationScopeCell row={params.row} cellType="catalog" />
    ),
  },
  {
    field: CatalogWorkunitDatagridColumnField.MONTHS,
  },
  {
    field: CatalogWorkunitDatagridColumnField.CONTENTS,
  },
  {
    field: CatalogWorkunitDatagridColumnField.TOTAL,
    align: 'center',
    headerAlign: 'center',
    minWidth: 60,
    renderCell: (params: CatalogWorkunitDatagridCellParams<number>) => (
      <TotalCell total={params.value ? params.value : 0} />
    ),
  },
];
