import { LoadingButton } from '@mui/lab';
import { Alert, Grid, TextField, Typography } from '@mui/material';
import UserApiService from 'api/UserApiService';
import ProcessModal from 'components/ProcessModal/ProcessModal';
import AutocompleteCustom, { PropertyNameOptions, SelectTypeOptions } from 'components/Select';
import SelectUser from 'components/SelectUser';
import useApi from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import Order from 'types/entities/Order';
import User from 'types/entities/User';
import { OrderStatus } from 'types/enums/OrderStatus';
import useManagementService from '../../services/useManagementService';
import './CompleteOrderDrawer.scss';

const CompleteOrderDrawer = () => {
  const appState = useSelector((state) => state.app);
  const [clients, setClients] = useState<User[]>([]);
  const order = useSelector((state) =>
    state.management.orders?.find((o) => o.id === state.management.activeOrderId)
  ) as Order;
  const shouldProceed = order?.status === OrderStatus.PENDING;
  const { validateOrder } = useManagementService();
  const [t] = useTranslation();
  const [orderEdited, setOrderEdited] = useState<Order>(order);
  const { makeCall } = useApi();
  useEffect(() => {
    setOrderEdited(order);
  }, [order]);
  useEffect(() => {
    const getClients = async () => {
      if (appState.customer) {
        const res = await makeCall(
          UserApiService.getClientsOnCustomer(appState.customer?.id, { is_archived: 0, size: -1 }),
          'Error while loading clients',
          'completeOrderLoading'
        );
        setClients(res);
      }
    };
    getClients();
  }, [appState.customer]);

  const completeOrderLoading = useSelector((state) => state.loadings.completeOrderLoading) ?? 0;

  const handleSelectOptions = (e: SelectTypeOptions | null, property: PropertyNameOptions) => {
    if (e && property === 'affair')
      return setOrderEdited({ ...orderEdited, [`${property}`]: e, [`${property}_id`]: e.id });
    return setOrderEdited({ ...orderEdited, affair: null, affair_id: null });
  };
  const handleSelectClient = (client: User | null | undefined) => {
    if (client) return setOrderEdited({ ...orderEdited, client, client_id: client.id });
    return setOrderEdited({ ...orderEdited, client: null, client_id: null });
  };

  const [isProceedEnabled, setIsProceedEnabled] = useState(false);
  useEffect(() => {
    setIsProceedEnabled(!(orderEdited?.affair_id && orderEdited?.client_id && orderEdited?.draft_reference));
  }, [order?.affair_id, order?.client_id, orderEdited]);

  const updateOrder = async () => {
    validateOrder({ ...orderEdited, id: order.id });
  };

  return (
    <Grid container flexDirection="column" spacing={4}>
      <Grid item>
        {!orderEdited?.affair_id && appState.affairs && (
          <Alert severity="warning">
            <Typography className="description_text">{t('Text1_affair_drawer')}</Typography>
            <Typography className="description_text">{t('Text2_affair_drawer')}</Typography>
          </Alert>
        )}
      </Grid>

      <Grid item>
        {appState.affairs && (
          <Grid container flexDirection="column">
            <Grid item>
              <AutocompleteCustom
                label={t('Choose_by_name')}
                options={appState.affairs}
                optionsLabels="name"
                handleSelectOptions={handleSelectOptions}
                selectedOption={orderEdited?.affair}
                propertyName="affair"
              />
            </Grid>
            <Grid item>
              <Typography>{t('or')}</Typography>
            </Grid>
            <Grid item>
              <AutocompleteCustom
                label={t('Choose_by_OTP_code')}
                options={appState.affairs}
                optionsLabels="otp"
                handleSelectOptions={handleSelectOptions}
                selectedOption={orderEdited?.affair}
                propertyName="affair"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {clients && (
        <Grid item>
          {!orderEdited?.client_id && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {' '}
              <Typography className="description_text">{t('Text1_client_drawer')}</Typography>
              <Typography className="description_text">{t('Text2_client_drawer')}</Typography>
            </Alert>
          )}
          <SelectUser
            users={clients}
            selectedUser={orderEdited?.client}
            onSelectUser={handleSelectClient}
            label={t('Choose client')}
          />
        </Grid>
      )}
      <Grid item>
        {!orderEdited?.draft_reference && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            <Typography className="description_text">{t('Text1_chrono_drawer')}</Typography>
            <Typography className="description_text">{t('Text2_chrono_drawer')}</Typography>
          </Alert>
        )}
        <Typography>{t('Draft_reference_text')}</Typography>

        <TextField
          name="Draft_reference"
          id="Draft_reference"
          sx={{ width: '100%' }}
          placeholder={t('Draft_reference_text')}
          onChange={(e) => setOrderEdited({ ...orderEdited, draft_reference: e.target.value })}
          value={orderEdited?.draft_reference ?? ''}
        />
      </Grid>

      <Grid item>
        <Grid container justifyContent="center">
          <LoadingButton
            onClick={() => updateOrder()}
            disabled={completeOrderLoading !== 0 || isProceedEnabled}
            variant="contained"
            color="success"
            loading={completeOrderLoading !== 0}
          >
            {shouldProceed && t('Go_ahead')}
            {!shouldProceed && t('Validate')}
          </LoadingButton>
          <ProcessModal open={completeOrderLoading !== 0} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CompleteOrderDrawer;
