import ResourceAPI, { Resource } from 'api/ResourceAPI';

export default class RolesApiService {
  public static instance: RolesApiService;

  public static baseUrl = 'roles' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return RolesApiService.instance || (RolesApiService.instance = new RolesApiService());
  }

  public static async fetchAll() {
    try {
      const { data } = await ResourceAPI.get(this.baseUrl, { size: '-1' });
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }
}
