import { createAction } from '@reduxjs/toolkit';
import { PossibleSheetActions, SheetActions } from 'features/Sheet/SheetContext/SheetContext.helper';
import { initialValue } from '../../intialValue';

export const editCurrentDeliverableTitleNameAction = createAction(
  SheetActions.EditDeliverableSheetTitleName,
  (newName: string) => ({ payload: { title: newName } })
);

export const editCurrentDeliverableSheetStateAction = createAction(
  SheetActions.EditDeliverableSheetState,
  (newName: string) => ({ payload: newName })
);

export const EditDeliverableSheet = (state = initialValue, action: PossibleSheetActions) => {
  switch (action.type) {
    case SheetActions.EditDeliverableSheetTitleName:
      return {
        ...state,
        currentDeliverableSheet: {
          ...state.currentDeliverableSheet,
          title: action.payload.title,
        },
      };
    case SheetActions.UpdateDeliverableSheet:
      return {
        ...state,
        currentDeliverableSheet: action.payload,
      };
    // case SheetActions.EditDeliverableSheetState:
    //   return {
    //     ...state,
    //     currentDeliverableSheet: {
    //       ...state.currentDeliverableSheet,
    //       state: action.payload,
    //     },
    //   };
    default:
      return state;
  }
};
