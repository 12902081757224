import useUserRoles from 'hooks/useUserRoles';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { selectFilterAction } from 'store/actions/filterActions';
import { changeCurrentTabAction } from 'store/actions/managementActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { OrdersTabState } from 'types/enums/OrdersTabState';

export default function useManagementHelper() {
  const { dispatch } = useAppState();
  const filter = useSelector((state) => state.filter);
  const history = useHistory();
  const userRoles = useUserRoles();
  const isPathForProduction = useCallback(() => {
    const path = history.location.pathname;
    return path.includes('production');
  }, [history.location.pathname]);
  const isPathForQuotes = useCallback(() => {
    const path = history.location.pathname;
    return path.includes('quotes');
  }, [history.location.pathname]);

  const getColorDisplayed = (number: number) => {
    if (number <= 50) return 'light-blue';
    if (number > 50 && number <= 75) return 'blue';
    if (number > 75) return 'dark-blue';
    return 'blue';
  };

  const handleManagementTabChange = (value: OrdersTabState) => {
    setCarousel(value);
  };

  const setCarousel = (valueCarousel: OrdersTabState) => {
    const params = new URLSearchParams();
    params.append('status', valueCarousel);

    if (history.location.search.toString().includes(params.toString())) return;
    const selectedFiltersCopy = { ...filter.selected };
    Object.keys(selectedFiltersCopy).forEach((key) => {
      selectedFiltersCopy[key] = [];
    });
    history.push({ search: params.toString() });
    dispatch(selectFilterAction(selectedFiltersCopy));
    dispatch(changeCurrentTabAction(valueCarousel));
  };

  const getPageTitleName = useCallback(() => {
    const isProduction = isPathForProduction();

    if (userRoles.isMigsoManager && isProduction) return 'Management';
    if (!userRoles.isMigsoManager && isProduction) return 'Deliverables';
    return 'Quotes';
  }, [isPathForProduction, userRoles]);

  return {
    isPathForProduction,
    isPathForQuotes,
    getPageTitleName,
    getColorDisplayed,
    handleManagementTabChange,
    setCarousel,
  };
}
