import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, MenuItem, Select, TextField, InputLabel as Label, FormControl } from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import InputLabel from 'components/Input';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectFilterAction } from 'store/actions/filterActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Scope from 'types/entities/Scope';

type ISearchHeaderCellOptions = Scope[] | undefined;

type SearchHeaderCellProps = {
  field: string;
  options?: ISearchHeaderCellOptions;
};
const SearchHeaderCell = React.memo((props: SearchHeaderCellProps) => {
  const { field, options } = props;
  const { dispatch } = useAppState();
  const [t] = useTranslation();
  const filter = useSelector((state) => state.filter);
  const searchValue = filter.selected?.[field]?.[0] ?? '';
  const [inputValue, setInputValue] = useState(searchValue);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchUpdate(field, inputValue);
    }, 250);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    setInputValue(searchValue);
  }, [searchValue]);

  const searchType = useCallback(() => {
    switch (field) {
      case 'scope_id':
        return 'select';
      case 'forecast_date':
      case 'delivery_date':
        return 'date';
      default:
        return 'text';
    }
  }, [field]);

  const searchUpdate = useCallback(
    (field: string, value: any) => {
      if (field && value) dispatch(selectFilterAction({ [field]: [value] }));
      else dispatch(selectFilterAction({ [field]: [] }));
    },
    [dispatch]
  );

  const renderInput = () => {
    if (searchType() === 'select' && options && options?.length > 0) {
      return (
        <FormControl fullWidth>
          <label htmlFor={`select_search_${field}`}>{t(field)}</label>
          <Select
            labelId={`select_search_${field}`}
            id={`$current_${field}`}
            placeholder={t(field)}
            onChange={(e) => {
              e.stopPropagation();
              setInputValue(e.target.value);
            }}
            onBlur={(e) => e.stopPropagation()}
            value={inputValue || ''}
            fullWidth
            componentsProps={{}}
            sx={{
              '.MuiSelect-select': {
                p: 0.5,
              },
            }}
          >
            <MenuItem value={0}>None</MenuItem>
            {options.map((option) => (
              <MenuItem value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return (
      <>
        <label htmlFor={`input_header_${field}`}>{t(field)}</label>
        <TextField
          onChange={(newValue) => {
            setInputValue(newValue.target.value);
          }}
          id={`input_header_${field}`}
          value={inputValue}
          type={searchType()}
          required
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ visibility: inputValue ? 'visible' : 'hidden', padding: 0 }}
                onClick={() => setInputValue('')}
                color="primary"
              >
                <ClearIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            ),
            style: { fontSize: 12 },
            className: 'input_head_grid_search',
          }}
          sx={{ padding: 0, fontSize: '0.5rem', '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' } }}
        />
      </>
    );
  };

  return <Box sx={{ display: 'block', width: '100%' }}>{renderInput()}</Box>;
});

export default SearchHeaderCell;
