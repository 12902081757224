import { Chip, Popover, Stack } from '@mui/material';
import { slice, uniq } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { OrderWorkunitOrigin } from 'types/enums/OrderWorkunitOrigin';
import './TagsCell.scss';

type TagsOrder = {
  [key: string]: number;
};

const TagsCell = React.memo((row: OrderWorkunit) => {
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const chips = useMemo((): string[] => {
    const separator = '|';
    const chips = uniq(row.label?.split(separator).filter((label) => label && label !== 'quote')) || [];

    if (!chips.includes('custom') && (!row.workunit_id || row.origin === OrderWorkunitOrigin.CUSTOM)) {
      chips.push('custom');
    }

    const predicate = (a: string, b: string) => {
      const tagsImportance: TagsOrder = {
        custom: 0,
        added: 1,
        updated: 2,
        cancelled: 3,
      };

      return tagsImportance[b] - tagsImportance[a];
    };

    chips.sort(predicate);
    return chips;
  }, [row]);

  return (
    <div className="tag-cell">
      <Stack direction="row" spacing={1}>
        {chips && chips.length > 0 && <Chip label={t(chips[0])} className={`chip-${chips[0]}`} color="primary" />}
        {chips.length > 1 && (
          <>
            <Popover
              sx={{
                p: 2,
                pointerEvents: 'none',
              }}
              className="tag-cell-popover"
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              PaperProps={{
                sx: {
                  p: 1,
                },
              }}
              disableRestoreFocus
            >
              <Stack direction="row" spacing={1}>
                {slice(chips, 1).map((chip) => (
                  <Chip key={chip} label={t(chip)} className={`chip-${chip}`} color="primary" />
                ))}
              </Stack>
            </Popover>
            <Chip
              label={`+${chips.length - 1}`}
              className="chip-more"
              color="default"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            />
          </>
        )}
      </Stack>
    </div>
  );
});

export default TagsCell;
