import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content?: any;
  contentHeight?: number;
  contentMinWidth?: number;
  backgroundColor?: string;
}

const EditBlock: React.FC<Props> = ({ title, content, contentHeight, contentMinWidth, backgroundColor }) => (
  <Wrapper>
    <Column>
      <EditBlockTitle>{title}</EditBlockTitle>
      <EditBlockContent
        contentHeight={contentHeight}
        contentMinWidth={contentMinWidth}
        backgroundColor={backgroundColor}
      >
        {content}
      </EditBlockContent>
    </Column>
  </Wrapper>
);

export default EditBlock;

const Wrapper = styled.div`
  display: flex;
  font-size: 16px;
  margin-bottom: 4rem;
  height: 100%;
  /* min-height: 100%; */
  width: 100%;
  max-width: 100%;
`;

const EditBlockTitle = styled.div`
  display: flex;
  font-size: 16px;
  color: #616161;
  margin-bottom: 5px;
  font-weight: 400;
`;

const EditBlockContent = styled.div<Props>`
  display: flex;
  height: ${(props) => props.contentHeight || 46}px;
  min-width: ${(props) => props.contentMinWidth || 25}vw;
  font-size: 14px !important;
  font-weight: 500;
  /* margin-bottom: 1rem; */
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  min-width: 100%;
`;
