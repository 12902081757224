/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */

import moment from 'moment';

const monthsToString = ['none', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const userLocale = navigator.language || 'fr-FR';

export const formatDateToddMMYYY = (date: string, separator = '/'): string =>
  // `${date.slice(8, 10)}${separator}${date.slice(5, 7)}${separator}${date.slice(0, 4)}`;
  userLocale !== 'en-US'
    ? `${date.slice(8, 10)}${separator}${date.slice(5, 7)}${separator}${date.slice(0, 4)}`
    : `${date.slice(5, 7)}${separator}${date.slice(8, 10)}${separator}${date.slice(0, 4)}`;

export const formatDateToYYYY = (date: string): string => `${date.slice(0, 4)}`;

export const formatDateLitteral = (date: string): string =>
  `${date.slice(8, 10)} ${monthsToString[Number(date.slice(5, 7))]} ${date.slice(0, 4)}`;

export const formatDateToMMMMYYYY = (date: string): string => moment(date).format('MMMM YYYY');
// These generics are inferred, do not pass them in.
export const renameKey = <OldKey extends keyof T, NewKey extends string, T extends Record<string, unknown>>(
  oldKey: OldKey,
  newKey: string,
  userObject: T
): Record<NewKey, T[OldKey]> & Omit<T, OldKey> => {
  const { [oldKey]: value, ...common } = userObject;

  return {
    ...common,
    ...({ [newKey]: value } as Record<NewKey, T[OldKey]>),
  };
};

export function numberWithSpaces(x?: number) {
  if (x) {
    const parts = x.toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }
  return 0;
}

export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

// export function randomColor(firstName) {
//   const blue = ['A', 'B', 'C', 'D', 'E', 'F', 'J'];
//   const grey = ['H', 'I', 'J', 'K', 'L', 'M', 'N'];
//   const green = ['O', 'P', 'Q', 'R', 'S', 'T', 'U'];
//   if (blue.includes()) return color;
// }
export function stringToColor(str: string, attr = 'bgcolor') {
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  if (attr === 'color') {
    return `hsl(${h}, ${70}%, ${50}%)`;
  }
  return `hsl(${h}, ${70}%, ${95}%)`;
}

export function removeDuplicates(arr: any[]) {
  return arr.filter((v: { id: number }, i: any, a: any[]) => a.findIndex((v2: { id: any }) => v2.id === v.id) === i);
}

export const flattenObject = (obj: any) => {
  const flattened: any = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};

export function flatten(data: any) {
  return data.reduce((result: any[], next: any) => {
    result.push(next);
    if (next.items) {
      // eslint-disable-next-line no-param-reassign
      result = result.concat(flatten(next.items));
      // eslint-disable-next-line no-param-reassign
      next.items = [];
    }
    return result;
  }, []);
}
