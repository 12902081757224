import { CancelTokenSource } from 'axios';
import Affair from 'types/entities/Affair';
import Catalog from 'types/entities/Catalog';
import CatalogComplexity from 'types/entities/CatalogComplexity';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import Customer from 'types/entities/Customer';
import Devise from 'types/entities/Devise';
import User from 'types/entities/User';
import Alert from 'types/models/Alert';
import Token from 'types/models/Token';
import { RoleType } from 'types/RoleType';

export interface AppState {
  sidebarExpanded: boolean;
  sidebarLink?: {
    name: string;
    path?: string;
    icon?: unknown;
  };
  devises: Devise[];
  catalog?: Catalog;
  catalogs: Catalog[];
  archivedCatalogs: Catalog[];
  catalogComplexities: CatalogComplexity[];
  workunit?: CatalogWorkunit;
  workunits: CatalogWorkunit[];
  roles?: RoleType[];
  user?: User;
  consultants: User[];
  customer?: Customer;
  customers: Customer[];
  affairs: Affair[];
  token?: Token;
  alerts: Alert[];
  acceptedTermsOfuse?: boolean;
  cancelTokens: {
    [key: string]: CancelTokenSource;
  };
}

export const appState: AppState = {
  sidebarExpanded: true,
  sidebarLink: undefined,
  devises: [],
  catalog: undefined,
  catalogs: [],
  catalogComplexities: [],
  archivedCatalogs: [],
  workunit: undefined,
  workunits: [],
  roles: [],
  user: undefined,
  consultants: [],
  customer: undefined,
  customers: [],
  affairs: [],
  token: undefined,
  alerts: [],
  acceptedTermsOfuse: undefined,
  cancelTokens: {},
};
