import {
  Box,
  Checkbox,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';
import OrderApiService from 'api/OrderApiService';
import useApi from 'hooks/useApi';
import { filter, find } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, Legend, Rectangle, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from 'recharts';
import Histogram from 'types/entities/Histogram';
import Scope from 'types/entities/Scope';
import useDebounce from '../../../../hooks/useDebounce';
import { updateParamsFilterAction } from '../../../../store/actions/filterActions';
import { useSelector } from '../../../../store/hooks';
import { useAppState } from '../../../../store/Provider';
import './FinancialGraph.scss';

type FinancialGraphProps = {
  order_id: number;
  orderPrice: number;
};

type HistogramMonthValue = { value: string; ordered: number; submitted: number; accepted: number; validated: number };

const FinancialGraph = (props: FinancialGraphProps) => {
  const { order_id } = props;
  const { makeCall } = useApi();
  const { dispatch } = useAppState();
  const { filter: filterState } = useSelector((state) => state);
  const [t] = useTranslation();
  moment.tz.setDefault('Europe/London');
  const [histogramData, setHistogramData] = useState<Histogram>();
  const [months, setMonths] = useState<HistogramMonthValue[]>([]);
  const [scopesFilter, setScopesFilter] = React.useState<Scope[]>([]);
  const [loading, setLoading] = useState(false);

  const debouncedOnChangeScopesFilter = useDebounce(() => {
    getHistogram();
  });

  const generateMonthsValues = (data: Histogram) => {
    const monthsValues: HistogramMonthValue[] = [];
    Object.keys(data.histogram).forEach((month) => {
      monthsValues.push({
        value: month,
        ordered: data.histogram[month].ordered,
        submitted: data.histogram[month].submitted,
        accepted: data.histogram[month].accepeted,
        validated: data.histogram[month].validated,
      });
    });
    setMonths(monthsValues);
    setLoading(false);
  };

  const fetchFilterParams = async () => {
    if (order_id) {
      const filterParams = await makeCall(OrderApiService.fetchParamsFilterDeliverable(order_id));
      if (filterParams) {
        dispatch(updateParamsFilterAction(filterParams));
      }
    }
  };

  const getHistogram = async () => {
    setLoading(true);
    const data: Histogram = await makeCall(
      OrderApiService.getHistogramData(order_id, {
        scopes_ids: scopesFilter.map((scope) => scope.id),
      })
    );
    if (data) {
      setHistogramData(data);
      generateMonthsValues(data);
    } else {
      setLoading(false);
    }
  };

  const isCurrentOrPast = (date: string) =>
    moment().startOf('month').unix() >= moment(date, 'MM/YYYY').startOf('month').unix();
  const CustomBar = (customBarProps: any) => {
    // eslint-disable-next-line prefer-const
    const {
      payload: { value },
      fill,
    } = customBarProps;
    let definiteColor = fill;
    if (!isCurrentOrPast(value)) {
      definiteColor = '#b2bcd5';
    }

    return <Rectangle {...customBarProps} fill={definiteColor} />;
  };
  const DataFormater = (number: number) => {
    if (number > 1000000000) {
      return `${(number / 1000000000).toString()}B`;
    }
    if (number > 1000000) {
      return `${(number / 1000000).toString()}M`;
    }
    if (number > 1000) {
      return `${(number / 1000).toString()}K`;
    }
    return number.toString();
  };

  const handleChangeScopesFilter = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    const scopesIds = typeof value === 'string' ? value.split(',') : value;
    if (scopesIds && filterState?.params?.scopes) {
      setScopesFilter(
        filter(
          scopesIds.map((id) => find(filterState?.params?.scopes, ['id', id])),
          (scope): scope is Scope => !!scope
        )
      );
    }
    debouncedOnChangeScopesFilter();
  };

  useEffect(() => {
    setTimeout(() => {
      if (order_id) {
        if (!filterState?.params?.scopes) {
          fetchFilterParams();
        }
        getHistogram();
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);
  const devises = useSelector((state) => state.app.devises);
  const currentDevise = devises?.find((devise) => histogramData?.deliverable_projection.devise_name[0] === devise.name);

  return (
    <Box sx={{ position: 'relative' }}>
      <Fade in={loading} unmountOnExit>
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 1,
          }}
        />
      </Fade>
      <Box sx={{ backgroundColor: '#F2F6FD', p: '20px 25px', mb: 2 }}>
        <Typography variant="h3" color="neutral.main" sx={{ mb: 2 }}>
          {t<string>('Deliverable_projection')}
        </Typography>
        <Grid container spacing={4} flexDirection="row" alignItems="center">
          <Grid item xs flexDirection="column">
            <Typography>{t<string>('Ordered')}</Typography>
            <Typography color="#a6c7f5" sx={{ fontWeight: 600 }}>
              {histogramData?.deliverable_projection.ordered.toFixed(2)}
              {currentDevise?.symbol && currentDevise?.symbol !== 'E' ? currentDevise?.symbol?.toLowerCase() : '€'}
            </Typography>
          </Grid>
          <Grid item xs flexDirection="column">
            <Typography>{t<string>('Estimated_at_completion')}</Typography>
            <Typography color="#418BEC" sx={{ fontWeight: 600 }}>
              {histogramData?.deliverable_projection.submitted_by_consutlant.toFixed(2)}
              {currentDevise?.symbol && currentDevise?.symbol !== 'E' ? currentDevise?.symbol?.toLowerCase() : '€'}
            </Typography>
          </Grid>
          <Grid item xs flexDirection="column">
            <Typography>{t<string>('Validated_bm')}</Typography>
            <Typography color="#0C3872" sx={{ fontWeight: 600 }}>
              {histogramData?.deliverable_projection.validated_by_delivery_manager.toFixed(2)}
              {currentDevise?.symbol && currentDevise?.symbol !== 'E' ? currentDevise?.symbol?.toLowerCase() : '€'}
            </Typography>
          </Grid>
          <Grid item xs flexDirection="column">
            <Typography>{t<string>('Client_acceptance_at_completion')}</Typography>
            <Typography color="#20cc8d" sx={{ fontWeight: 600 }}>
              {histogramData?.deliverable_projection.accepted_by_client.toFixed(2)}
              {currentDevise?.symbol && currentDevise?.symbol !== 'E' ? currentDevise?.symbol?.toLowerCase() : '€'}
            </Typography>
          </Grid>
          <Grid item xs>
            {filterState?.params?.scopes && (
              <FormControl fullWidth size="small">
                <InputLabel id="filter-scopes-label">Filter by scope(s)</InputLabel>
                <Select
                  labelId="filter-scopes-label"
                  id="filter-scopes"
                  multiple
                  value={scopesFilter.map((scope) => scope.id) || []}
                  input={<OutlinedInput label="Filter by scope(s)" placeholder="Placeholder" />}
                  onChange={handleChangeScopesFilter}
                  renderValue={(selected) => {
                    if (selected.length === 0 || selected.length === filterState?.params?.scopes.length) {
                      return <>All</>;
                    }
                    return selected.map((id) => find(scopesFilter, ['id', id])?.name).join(', ');
                  }}
                >
                  {filterState.params.scopes.map((scope: Scope) => (
                    <MenuItem dense key={scope.name} value={scope.id}>
                      <Checkbox checked={!!find(scopesFilter, ['id', scope.id])} />
                      <ListItemText primary={scope.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
      </Box>
      <ResponsiveContainer height="99%" width="99%" minHeight={200} maxHeight={200}>
        <BarChart
          width={500}
          height={300}
          data={months}
          margin={{
            top: 20,
          }}
        >
          <XAxis
            dataKey="value"
            tick={(e: any) => {
              const {
                payload: { value },
              } = e;
              // eslint-disable-next-line no-param-reassign
              e.fill = isCurrentOrPast(value) ? '#0f5ee9' : '#b2bcd5';
              // eslint-disable-next-line no-param-reassign
              e.payload.value = moment(value, 'MM/YYYY').format('MMMM YY');
              return <Text {...e}>{moment(value, 'MM/YYYY').format('MMMM YY')}</Text>;
            }}
          />
          <YAxis
            tick={(e: any) => {
              const {
                payload: { value },
              } = e;
              // eslint-disable-next-line no-param-reassign
              e.fill = '#0f62e6';
              const correctedNumber = DataFormater(value);

              return <Text {...e}>{correctedNumber}</Text>;
            }}
            tickFormatter={DataFormater}
          />
          <Tooltip />
          <Legend />
          <Bar barSize={10} dataKey="ordered" name={t<string>('Ordered')} fill="#a6c7f5" radius={[10, 10, 0, 0]} />
          <Bar
            barSize={10}
            shape={CustomBar}
            dataKey="submitted"
            name={t<string>('Submitted')}
            fill="#418BEC"
            radius={[10, 10, 0, 0]}
          />
          <Bar
            barSize={10}
            shape={CustomBar}
            dataKey="validated"
            name={t<string>('Validated')}
            fill="#0C3872"
            radius={[10, 10, 0, 0]}
          />
          <Bar
            barSize={10}
            dataKey="accepted"
            shape={CustomBar}
            name={t<string>('Accepted')}
            fill="#20cc8d"
            radius={[10, 10, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default FinancialGraph;
