import { GridRowId, GridSelectionModel, GridSortModel } from '@mui/x-data-grid-pro';
import { SearchParams } from 'api/ResourceAPI';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import MissionFrequency from 'types/entities/MissionFrequency';
import MissionStatus from 'types/entities/MissionStatus';
import Order from 'types/entities/Order';
import OrderScope from 'types/entities/OrderScope';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import User from 'types/entities/User';
import Workunit from 'types/entities/Workunit';
import { DeliverableTableType } from 'types/enums/DeliverableTableType';
import { DeliverableTabPage } from 'types/enums/DeliverableTabPage';

export interface DeliverableState {
  order: Order | null;
  frequencies: MissionFrequency[];
  status: MissionStatus[];
  workunits: Workunit[];
  customers: User[];
  deliverables: OrderWorkunit[] | null;
  activeDeliverableModel: GridSelectionModel;
  selectedAllDeliverables: boolean;
  selectedDeliverablesCount: number;
  selectedDeliverablesModel: GridSelectionModel;
  excludedDeliverables: GridRowId[];
  sortDeliverablesModel: GridSortModel;
  searchParams: SearchParams | null;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  currentTab: DeliverableTabPage;
  currentTableType: DeliverableTableType;
  currentDeliverableSheet: DeliverableSheet | null;
  consultants: User[];
  deliveryManagers: User[];
  order_scopes: OrderScope[];
  isTableInReportMode: boolean;
}

export const deliverableState: DeliverableState = {
  order: null,
  frequencies: [],
  status: [],
  workunits: [],
  customers: [],
  deliverables: null,
  activeDeliverableModel: [],
  selectedAllDeliverables: false,
  selectedDeliverablesCount: 0,
  selectedDeliverablesModel: [],
  excludedDeliverables: [],
  sortDeliverablesModel: [],
  searchParams: {
    is_archived: false,
    join: [
      'scope',
      'workunit',
      'mission-frequency',
      'mission-status',
      'mission-comments',
      'mission-comments.author',
      'mission-advancement',
      'consultant',
      'client',
    ],
    size: 50,
    page: 0,
  },
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  pageSize: 50,
  currentTab: DeliverableTabPage.PRODUCTION,
  currentTableType: DeliverableTableType.TECHNICAL,
  currentDeliverableSheet: null,
  consultants: [],
  order_scopes: [],
  deliveryManagers: [],
  isTableInReportMode: false,
};
