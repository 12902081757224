import DeliverableSheet from 'types/entities/DeliverableSheet';
import FinancialReport from 'types/entities/FinancialReport';
import Order from 'types/entities/Order';
import { DeliverableReportTab } from 'types/enums/DeliverableReportTab';
import { ReportStatus } from 'types/enums/ReportStatus';

export interface DeliverableSheetState {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  items: DeliverableSheet[];
  financialReports: FinancialReport[];
  currentOrder: Order | null;
  currentDeliverableSheet: DeliverableSheet | null;
  currentFinancialReport: FinancialReport | null;
  currentReportTab: DeliverableReportTab;
  isCreateReportModalOpen: boolean;
  totalWorkunitNumber: number;
}

const financialFixtures: FinancialReport[] = [
  {
    orderscope_refs_order: [],
    orderscope_refs_work: [],
    id: 138,
    order_id: 72,
    title: 'Financial report test',
    ref_order: 'mno',
    ref_work: 'mno order test',
    reference: null,
    affair_id: 2,
    customer_id: 1,
    catalog_id: 13,
    client_id: 6,
    creator_id: 3,
    bm_id: 2,
    is_archived: false,
    order_start_date: '2023-06-15T00:00:00.000Z',
    order_end_date: '2023-10-12T00:00:00.000Z',
    start_date: '2023-10-31T00:00:00.000Z',
    end_date: '2023-10-31T00:00:00.000Z',
    average_note: '',
    nb_ordered: 1,
    nb_delivered: 1,
    nb_accepted: 0,
    nb_accepted_wreserved: 0,
    nb_refused: 0,
    nb_rated_a: 0,
    nb_rated_b: 0,
    nb_rated_c: 0,
    nb_rated_d: 0,
    nb_rated_e: 0,
    is_signed: false,
    signing_date: null,
    state: ReportStatus.WAITING_FOR_SIGNATURE,
    type: 'financial',
    pdf_updated: false,
    created_at: '2023-06-15T16:00:28.000Z',
    updated_at: '2023-06-15T16:00:28.000Z',
    total_financial_amount: 10000,
    devise_name: '€',
    assesment: null,
    comment: '',
    location: '',
    signed_by_DM: false,
    client: {
      id: 6,
      first_name: 'CLIENT',
      last_name: 'CLIENT',
      mail: 'client@test.com',
      phone: '0600000001',
      matricule: 'client@test.com',
      status: 'active',
      is_client: true,
      is_archived: false,
      cognito_status: 'enable',
      customer_id: 1,
      connection_attempt: 0,
      last_connection: '2023-06-22T08:08:58.000Z',
      accepted_terms_of_use: 1,
      updated_at: '2023-06-22T08:08:58.000Z',
      'user-roles': [],
      roles: [],
    },
  },
];

export const deliverableSheetState: DeliverableSheetState = {
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  pageSize: 10,
  items: [],
  financialReports: [],
  currentDeliverableSheet: null,
  currentOrder: null,
  currentFinancialReport: null,
  currentReportTab: DeliverableReportTab.TECHNICAL,
  isCreateReportModalOpen: false,
  totalWorkunitNumber: 0,
};
