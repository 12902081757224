/* eslint-disable @typescript-eslint/no-explicit-any */
import AuthApiService from 'api/AuthApiService';
import { GridSortModel } from '@mui/x-data-grid';
/* eslint-disable max-len */
/* eslint-disable comma-dangle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import Token from 'types/models/Token';
import moment from 'moment-timezone';

export type Resource =
  | 'alerts'
  | 'affairs'
  | 'catalogs'
  | 'catalog'
  | 'catalogs/import'
  | 'catalogs/export/template'
  | 'complexity'
  | 'complexities'
  | 'cognito/users/create'
  | 'customers'
  | 'customer'
  | 'deliverables'
  | 'deliverable-sheets'
  | 'devises'
  | 'devise'
  | 'orders'
  | 'orders/list/management'
  | 'order'
  | 'order-scopes'
  | 'order-workunits'
  | 'order-workunits/modifications'
  | 'order-workunits/delete'
  | 'order-workunits/export/xlsx'
  | 'missions'
  | 'mission-advancements'
  | 'mission-status'
  | 'mission-frequencies'
  | 'mission-comments'
  | 'me'
  | 'me/signature'
  | 'deliverable_sheets'
  | 'me/accept-terms-of-use'
  | 'scopes'
  | 'roles'
  | 'users'
  | 'users/clients'
  | 'users/roles'
  | 'test-headers'
  | 'report'
  | 'users/have-role'
  | 'tenant-workloads'
  | 'terms-of-use'
  | 'quotes'
  | 'order-workunits?join=delivery_managers'
  | 'workunits';

export interface SearchParams {
  [key: string]: string | string[] | number | number[] | boolean | GridSortModel;
}

class ResourceAPI {
  public static axiosInstance = axios;

  public static instance: ResourceAPI;

  private static secret: string = ResourceAPI.getSecret();

  private static cancelTokenSource: CancelTokenSource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return ResourceAPI.instance || (ResourceAPI.instance = new ResourceAPI());
  }

  // eslint-disable-next-line class-methods-use-this
  public static attachInterceptors(): void {
    ResourceAPI.axiosInstance.interceptors.response.use(
      (response: any) => response,
      async (error: { config: any; response: { status: number } }) => {
        const originalRequest = error.config;
        if (error && error?.response && error?.response.status === 401 && !originalRequest.retry) {
          originalRequest.retry = true;
          const localStorageToken: string | null = localStorage.getItem('cognito-token');
          if (localStorageToken) {
            const cognitoToken: Token = JSON.parse(localStorageToken);
            const { tokenUri } = await AuthApiService.getRefreshTokenUri(cognitoToken.refresh_token as string);
            const token = await AuthApiService.getAuthToken(tokenUri);
            if (token) {
              ResourceAPI.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token.id_token}`;
              return ResourceAPI.axiosInstance(originalRequest);
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }

  // eslint-disable-next-line class-methods-use-this
  public static updateAxiosInstanceWithTokenHeader(id_token?: string, slug?: string): void {
    const userLocale = navigator.language || 'fr-FR';
    const region = userLocale !== 'en-US' ? 'EU' : 'US';
    const guessedTimeZone = moment.tz.guess();

    let isRefreshing = false;
    let failedQueue: any[] = [];
    this.axiosInstance.interceptors.request.use((config: AxiosRequestConfig): any => {
      if (id_token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${id_token}`;
        // eslint-disable-next-line no-param-reassign
        config.headers['Access-Control-Allow-Origin'] = '*';
        // eslint-disable-next-line no-param-reassign
        config.timeout = 180000;
        // eslint-disable-next-line no-param-reassign
        config.headers['access-control-allow-headers'] =
          'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, X-Tenant, Tenant,X-Region, Region, X-TimeZone, TimeZone,';
      }
      const customer: string | null = localStorage.getItem('active_customer');
      const customerIsParsable = IsJsonString(customer);

      function IsJsonString(str: string | null) {
        try {
          if (!str) return false;
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
      }

      if (config.url?.includes('grant_type=refresh_token')) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Basic ${this.secret}`;
        // eslint-disable-next-line no-param-reassign
        config.headers['Access-Control-Allow-Origin'] = '*';
        // eslint-disable-next-line no-param-reassign
        config.headers['access-control-allow-headers'] =
          'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, X-Tenant, Tenant, X-Region, Region, X-TimeZone, TimeZone';
      }
      // eslint-disable-next-line no-param-reassign
      config.timeout = 10000 * 20;
      const localStorageToken: string | null = localStorage.getItem('cognito-token');
      if (localStorageToken) {
        const cognitoToken: Token = JSON.parse(localStorageToken);
        if (cognitoToken.id_token && !config.url?.includes('grant_type=refresh_token')) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${cognitoToken.id_token}`;
          // eslint-disable-next-line no-param-reassign
          config.headers['Access-Control-Allow-Origin'] = '*';
          // eslint-disable-next-line no-param-reassign
          config.headers['access-control-allow-headers'] =
            'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, X-Tenant, Tenant, X-Region, Region, X-TimeZone, TimeZone';
        }
      }
      if (customer && customerIsParsable && customer.length) {
        const customerAlreadyPresent = JSON.parse(customer);
        if (customerAlreadyPresent?.slug) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Tenant = customerAlreadyPresent?.slug;
          // eslint-disable-next-line no-param-reassign
          config.headers['X-Tenant'] = customerAlreadyPresent?.slug;
        }
      }

      if (region) {
        config.headers.Region = region;
        config.headers['X-Region'] = region;
      }

      if (guessedTimeZone) {
        config.headers.TimeZone = guessedTimeZone;
        config.headers['X-TimeZone'] = guessedTimeZone;
      }

      // eslint-disable-next-line no-param-reassign
      return config;
    });
    this.axiosInstance.interceptors.response.use(
      (res) => res,
      async (error: { config: any; response: { status: number } }) => {
        const processQueue = (e: any) => {
          failedQueue.forEach((prom) => {
            if (e) {
              prom.reject(e);
            } else {
              prom.resolve();
            }
          });

          failedQueue = [];
        };
        const originalRequest = error.config;
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axios(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }
        if (
          (error?.response?.status === 401 || error?.response?.status === 403) &&
          !originalRequest.retry &&
          !isRefreshing
        ) {
          isRefreshing = true;
          originalRequest.retry = true;
          const localStorageToken: string | null = localStorage.getItem('cognito-token');
          if (localStorageToken) {
            try {
              const token = await getNewToken(localStorageToken);
              if (token) {
                return handleNewToken(token);
              }
            } catch (err) {
              processQueue(err);
              return Promise.reject(err);
            } finally {
              isRefreshing = false;
            }
          }
        }
        isRefreshing = false;

        return Promise.reject(error);

        async function getNewToken(localStorageToken: string) {
          const cognitoToken: Token = JSON.parse(localStorageToken);
          const { tokenUri } = await AuthApiService.getRefreshTokenUri(cognitoToken.refresh_token as string);
          const token = await AuthApiService.getAuthToken(tokenUri);
          if (!token) await handleDisconnect();
          return { ...token, refresh_token: cognitoToken.refresh_token };
        }

        async function handleDisconnect() {
          const getUri = async () => {
            const uri = await AuthApiService.getAuthAuthorization();
            localStorage.removeItem('cognito-token');
            window.location.href = uri;
          };
          await getUri();
        }

        function handleNewToken(token: any) {
          ResourceAPI.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token.id_token}`;
          localStorage.setItem('cognito-token', JSON.stringify(token));
          processQueue(null);
          return ResourceAPI.axiosInstance(originalRequest);
        }
      }
    );
  }

  public static async fetchAll(resource: Resource, params?: string) {
    const constructed = !params ? resource : `${resource + params}`;
    return this.axiosInstance.get(`${process.env.REACT_APP_SERVER_URL}/${constructed}`);
  }

  public static async get(resource: Resource, searchParams?: SearchParams, cancellable = true) {
    const url = this.createURL(resource, undefined, undefined, searchParams);
    return this.axiosInstance.get(url.href, {
      cancelToken: this.cancelTokenSource && cancellable ? this.cancelTokenSource.token : undefined,
    });
  }

  public static async getWithAdditionalPath(
    resource: Resource,
    additionalPath?: string,
    searchParams?: SearchParams,
    cancellable = true
  ) {
    const url = this.createURL(resource, undefined, additionalPath, searchParams);
    return this.axiosInstance.get(url.href, {
      cancelToken: this.cancelTokenSource && cancellable ? this.cancelTokenSource.token : undefined,
    });
  }

  public static async getById(resource: Resource, id: number, additionalPath?: string, searchParams?: SearchParams) {
    const url = this.createURL(resource, id, additionalPath, searchParams);
    return this.axiosInstance.get(url.href, {
      cancelToken: this.cancelTokenSource ? this.cancelTokenSource.token : undefined,
    });
  }

  public static post(resource: Resource, body: any, id?: number, additionalPath?: string) {
    const url = this.createURL(resource, id, additionalPath);
    return this.axiosInstance.post(url.href, body, {
      cancelToken: this.cancelTokenSource ? this.cancelTokenSource.token : undefined,
    });
  }

  public static put(resource: Resource, id: number, body: any, additionalPath?: string) {
    const url = this.createURL(resource, id, additionalPath);
    return this.axiosInstance.put(url.href, body, {
      cancelToken: this.cancelTokenSource ? this.cancelTokenSource.token : undefined,
    });
  }

  public static patch(resource: Resource, body: any, id?: number, additionalPath?: string) {
    const url = this.createURL(resource, id, additionalPath);
    return this.axiosInstance.patch(url.href, body, {
      cancelToken: this.cancelTokenSource ? this.cancelTokenSource.token : undefined,
    });
  }

  public static patchById(resource: Resource, id: number, body: any) {
    const url = this.createURL(resource, id);
    return this.axiosInstance.patch(url.href, body, {
      cancelToken: this.cancelTokenSource ? this.cancelTokenSource.token : undefined,
    });
  }

  public static patchWithAdditionalPath(resource: Resource, id: number, additionalPath: string, body: any) {
    const url = this.createURL(resource, id, additionalPath);
    return this.axiosInstance.patch(url.href, body, {
      cancelToken: this.cancelTokenSource ? this.cancelTokenSource.token : undefined,
    });
  }

  public static delete(resource: Resource, id: number) {
    const url = this.createURL(resource, id);
    return this.axiosInstance.delete(url.href, {
      cancelToken: this.cancelTokenSource ? this.cancelTokenSource.token : undefined,
    });
  }

  public static deleteWithAdditionalPath(resource: Resource, id: number, additionalPath: string) {
    const url = this.createURL(resource, id, additionalPath);
    return this.axiosInstance.delete(url.href, {
      cancelToken: this.cancelTokenSource ? this.cancelTokenSource.token : undefined,
    });
  }

  public static setCancelTokenSource(value: CancelTokenSource) {
    this.cancelTokenSource = value;
  }

  public static updateCancelToken() {
    const token = axios.CancelToken.source();
    this.setCancelTokenSource(token);
    return token;
  }

  private static getSecret() {
    return Buffer.from(
      `${process.env.REACT_APP_CognitoClientAppId}:${process.env.REACT_APP_CognitoClientAppSecret}`
    ).toString('base64');
  }

  public static createURL(resource: Resource, id?: number, additionalPath?: string, searchParams?: SearchParams) {
    let pathString = resource;
    if (id) pathString += `/${id}`;
    if (additionalPath) pathString += `/${additionalPath}`;
    const url = new URL(`${process.env.REACT_APP_SERVER_URL}/${pathString}`);
    if (searchParams)
      Object.entries(searchParams).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          value.forEach((valueEntry) => {
            url.searchParams.append(key, valueEntry.toString());
          });
        } else if (value || (key !== 'attr' && (value === '' || value === 0 || value === false))) {
          url.searchParams.append(key, value.toString());
        }
      });
    return url;
  }
}

ResourceAPI.attachInterceptors();
ResourceAPI.updateAxiosInstanceWithTokenHeader(undefined, undefined);

export default ResourceAPI;
