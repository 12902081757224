import { Link, Tooltip } from '@mui/material';
import React from 'react';
import { openDrawerAction } from 'store/actions/drawerActions';
import { useAppState } from 'store/Provider';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const ReferenceCell = React.memo((row: OrderWorkunit) => {
  const { dispatch } = useAppState();

  const getReference = (): string => {
    if (row.workunit_reference) {
      return row.workunit_reference;
    }
    return '';
  };

  return (
    <Tooltip arrow title={getReference()}>
      <Link
        component="button"
        underline="none"
        variant="body2"
        onClick={() => dispatch(openDrawerAction({ name: 'deliverableDetails', data: { id: row?.id } }))}
        noWrap
      >
        {getReference()}
      </Link>
    </Tooltip>
  );
});

export default ReferenceCell;
