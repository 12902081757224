import ResourceAPI, { Resource, SearchParams } from 'api/ResourceAPI';
import Customer from 'types/entities/Customer';

export default class CustomersApiService {
  public static instance: CustomersApiService;

  public static baseUrl = 'customers' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return CustomersApiService.instance || (CustomersApiService.instance = new CustomersApiService());
  }

  public static async fetchByID(id: number, params?: SearchParams) {
    try {
      const { data } = await ResourceAPI.getById(this.baseUrl, id, undefined, params);
      return data.customer;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async fetchAll(params: SearchParams) {
    try {
      const { data } = await ResourceAPI.get(this.baseUrl, params);
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async fetchAllUsers(params: SearchParams) {
    try {
      const { data } = await ResourceAPI.getWithAdditionalPath(this.baseUrl, 'role-assignations', params);
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async updateCustomer(id: number, body: Partial<Customer>) {
    try {
      const { data } = await ResourceAPI.patch(this.baseUrl, body, id);
      return data.customer;
    } catch (error: unknown) {
      return null;
    }
  }
}
