export enum MessageType {
  mission_refusal_message = 'mission_refusal_message',
  mission_accept_partial_message = 'mission_accept_partial_message',
  mission_just_shipped_message = 'mission_just_shipped_message',
  mission_just_confirmed = 'mission_just_confirmed',
  scope_new_assignation_message = 'scope_new_assignation_message',
  mission_new_client_assignation_message = 'mission_new_client_assignation_message',
  mission_new_consultant_assignation_message = 'mission_new_consultant_assignation_message',
  mission_overdue_message = 'mission_overdue_message',
  mission_bad_ranking_message = 'mission_bad_ranking_message',
  deliverable_sheet_new = 'deliverable_sheet_new',
}
