/* eslint-disable react-hooks/exhaustive-deps */
import AuthApiService from 'api/AuthApiService';
import { handleApiError } from 'api/errors';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { loadTokenAction } from 'store/actions/appActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';

function LoginCallback() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const history = useHistory();
  const query = useQuery();
  const code = query.get('code') as string;

  useEffect(() => {
    if (appState.token?.code !== code) {
      dispatch(loadTokenAction({ ...appState.token, code }));
    }
    try {
      if (appState.token?.code) {
        const getTokenUri = async () => {
          const { tokenUri: uri } = await AuthApiService.getTokenUri(appState.token?.code as string);
          const data = await AuthApiService.getAuthToken(uri);
          dispatch(loadTokenAction(data));
          history.push('');
        };
        getTokenUri();
      }
    } catch (error) {
      handleApiError(error);
    }
    return undefined;
  }, [appState.token?.code]);

  return <></>;
}

export default LoginCallback;
