import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';

type CheckboxSelectionProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  label: string;
  className?: string;
};

const CheckboxSelection = (props: CheckboxSelectionProps) => {
  const { onChange, checked, label, className } = props;
  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox className={`checkbox_${className}`} color="primary" checked={checked} onChange={onChange} />}
        label={label}
      />
    </FormGroup>
  );
};
export default CheckboxSelection;
