import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Sidebar, Topbar } from 'components/index';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import './Layout.scss';

interface Props {
  name: string;
  path: string;
  actions?: string[];
  className?: string;
  idContent?: string;
  footer?: React.ReactNode;
  subTitleElement?: React.ReactNode;
  isWelcome?: boolean;
}

const Layout: React.FC<Props> = ({
  name,
  path,
  children,
  className = '',
  idContent = '',
  footer,
  isWelcome = false,
  subTitleElement,
}) => {
  const [toggleSidebar, setToggleSidebar] = useState(false);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      if (window.innerWidth >= 900) {
        setToggleSidebar(false);
      }
    }, 200);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  return (
    <div className="layout">
      <Sidebar handleClick={setToggleSidebar} isOpen={toggleSidebar}>
        {name}
      </Sidebar>

      <div className={`layout__column ${toggleSidebar ? 'isOpened' : ''}`}>
        <IconButton
          onClick={() => setToggleSidebar(!toggleSidebar)}
          sx={{
            position: 'fixed',
            top: 20,
            left: 160,
            border: '2px solid #FFFFFF',
            color: '#FFFFFF',
            zIndex: 100,
            display: {
              xs: toggleSidebar ? 'flex' : 'none',
              md: 'none',
            },
          }}
        >
          <Close />
        </IconButton>
        <Topbar
          name={name}
          isWelcome={isWelcome}
          subTitleElement={subTitleElement}
          handleClick={setToggleSidebar}
          isOpen={toggleSidebar}
        />
        {!footer && (
          <div id="container-content" className={`layout__content ${className}`}>
            {children}
          </div>
        )}
        {footer && (
          <article id="container-with-footer-content">
            <ContentWithFooter id="container-content" className={className}>
              {children}
            </ContentWithFooter>
            <Footer id="footer-content" className={className}>
              {footer}
            </Footer>
          </article>
        )}
      </div>
    </div>
  );
};

export default Layout;

const ContentWithFooter = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(79vh - 6rem);
  overflow: scroll;
  @media (max-height: 768px) {
    margin: 2rem 3%;
  }
  @media (max-width: 1444px) {
    margin: 0rem 4%;
  }
  @media (min-height: 768px) {
    margin: 0rem 2%;
  }
`;
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  height: 20vh;
  width: 100%;
  background-color: #ffffff;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #00000029;
  opacity: 1;
  z-index: 30;
`;
