import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

export default function useDebounce(callback: () => void) {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 500);
  }, []);
}
