import { GridColDef } from '@mui/x-data-grid-pro';
import Devise from 'types/entities/Devise';
import OrderScope from 'types/entities/OrderScope';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import Workunit from 'types/entities/Workunit';
import WorkunitComplexity from 'types/entities/WorkunitComplexity';

export type OrderWorkunitsCustomWorkunit = {
  name: string;
  complexity_id?: number;
  complexity?: WorkunitComplexity;
  workunit_id: number;
  quantity: number;
  id: number;
  price: number;
  min?: number;
  total: number;
  devise: Devise | null;
  reference: Workunit['reference'];
  isImmutable: boolean;
  consultant_id?: number | null;
  consultant?: User | null;
  client_id?: number | null;
  client?: User | null;
  scope_id: number | null;
  scope: Scope | null;
  delivery_date?: string | null;
  workunit?: Workunit;
  order_scope_id?: OrderScope['id'];
  months: WorkunitMonth[];
  randomId?: number;
  charge: number;
  content: string | null;
  content2: string | null;
  content3: string | null;
  content4: string | null;
  content5: string | null;
  complexity_name: string;
  workunit_complexity_id: WorkunitComplexity['id'];
};

export type WorkunitMonth = {
  quantity: number;
  date: string;
  price: number;
  dailyrate: number;
  fte: number;
  totalprice: number;
  workload: number;
  [x: number]: number;
};

export type ModifyDeep<A extends AnyObject, B extends DeepPartialAny<A>> = {
  [K in keyof A]: B[K] extends never ? A[K] : B[K] extends AnyObject ? ModifyDeep<A[K], B[K]> : B[K];
} &
  (A extends AnyObject ? Omit<B, keyof A> : A);

type DeepPartialAny<T> = {
  [P in keyof T]?: T[P] extends AnyObject ? DeepPartialAny<T[P]> : any;
};

type AnyObject = Record<string, any>;

export type MonthType = {
  month: string;
  value: string;
  year: string;
  date: string;
  dailyrate?: number;
  fte?: number;
  totalprice?: number;
};

export const generateRandomId = () => parseInt((Math.random() * 100000).toFixed(0), 10);

export const getColumnsParamsDef = ({
  field,
  headerName,
  filterable,
  sortable,
  hide,
  align,
  headerAlign,
  cellClassName,
  headerClassName,
  flex,
  disableReorder,
  editable,
}: Partial<GridColDef>) => ({
  field: field ?? '',
  headerName: headerName ?? '',
  filterable: filterable ?? false,
  sortable: sortable ?? false,
  hide: hide ?? false,
  align: align ?? 'center',
  headerAlign: headerAlign ?? 'center',
  cellClassName: cellClassName ?? 'cell-name',
  headerClassName: headerClassName ?? '',
  minWidth: 140,
  disableReorder: disableReorder ?? false,
  editable: editable ?? false,
});

export const isRowInEditMode = (
  activeEditRowIds: OrderWorkunitsCustomWorkunit['randomId'][],
  rowRandomId: OrderWorkunitsCustomWorkunit['randomId']
) => activeEditRowIds.includes(rowRandomId);
