import DeliverableSheet from 'types/entities/DeliverableSheet';
import { DeliverableSheetActions } from '../actions/deliverableSheetActions';
import { RootActions } from '../redux';
import { DeliverableSheetState, deliverableSheetState } from '../states/deliverableSheetState';

export const deliverableSheetReducer = (state = deliverableSheetState, action: RootActions): DeliverableSheetState => {
  switch (action.type) {
    case DeliverableSheetActions.LOAD_DELIVERABLE_SHEETS:
      return {
        ...state,
        items: action.payload.datas,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case DeliverableSheetActions.CREATE_DELIVERABLE_SHEETS:
      return {
        ...state,
        items: [...state.items, action.payload],
        totalItems: state.totalItems + 1,
      };
    case DeliverableSheetActions.UPDATE_DELIVERABLE_SHEETS:
      return {
        ...state,
        items: state.items.map((item) => (item.id === action.payload.id ? { ...item, ...action.payload } : item)),
      };
    case DeliverableSheetActions.UPDATE_DELIVERABLE_SHEET:
      return {
        ...state,
        currentDeliverableSheet: {
          ...(state.currentDeliverableSheet as DeliverableSheet),
          ...action.payload.deliverableSheet,
        },
      };
    case DeliverableSheetActions.DELETE_DELIVERABLE_SHEETS:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
        totalItems: state.totalItems - 1,
      };
    case DeliverableSheetActions.CHANGE_PAGE_DELIVERABLE_SHEET:
      return {
        ...state,
        currentPage: action.payload,
      };
    case DeliverableSheetActions.CHANGE_PAGE_SIZE_DELIVERABLE_SHEET:
      return {
        ...state,
        pageSize: action.payload,
      };
    case DeliverableSheetActions.CHANGE_REPORT_TAB:
      return {
        ...state,
        currentReportTab: action.payload,
      };
    case DeliverableSheetActions.INJECT_DELIVERABLE_SHEET:
      return {
        ...state,
        currentDeliverableSheet: action.payload.deliverableSheet,
      };
    case DeliverableSheetActions.INJECT_FINANCIAL_REPORT:
      return {
        ...state,
        currentFinancialReport: action.payload,
      };
    case DeliverableSheetActions.ADD_FINANCIAL_REPORT:
      return {
        ...state,
        financialReports: action.payload.financialReports,
      };
    case DeliverableSheetActions.SET_TOTAL_WORKUNIT_NUMBER:
      return {
        ...state,
        totalWorkunitNumber: action.payload,
      };
    case DeliverableSheetActions.INJECT_ORDER:
      return {
        ...state,
        currentOrder: action.payload.order,
      };
    default:
      return state;
  }
};
