import { handleApiError } from 'api/errors';
import RessourceApi, { Resource, SearchParams } from 'api/ResourceAPI';
import { removeDuplicates } from 'helpers/format';
import User from 'types/entities/User';

export default class UserApiService {
  public static instance: UserApiService;

  public static baseUrl = 'users' as Resource;

  public static baseUrlMe = 'me' as Resource;

  public static baseUrlClients = 'users/clients' as Resource;

  public static baseUrlRole = 'users/have-role' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return UserApiService.instance || (UserApiService.instance = new UserApiService());
  }

  public static async fetchUsersByRoleName(
    params: string,
    queryParams = '',
    searchParams?: SearchParams
  ): Promise<User[]> {
    try {
      const { data } = await RessourceApi.getWithAdditionalPath(
        `${this.baseUrlRole}`,
        `${params}${queryParams}`,
        searchParams
      );
      if (!data || !data.datas) return [];
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async fetchUserRole(): Promise<
    ('consultant' | 'bm' | 'team_leader' | 'team_leader_plus' | 'admin' | 'butl' | 'customer')[]
  > {
    try {
      const {
        data: { results },
      } = await RessourceApi.getWithAdditionalPath(this.baseUrlMe, `role-on-customer`, undefined, false);
      return results;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async get(searchParams?: SearchParams) {
    try {
      const { data } = await RessourceApi.get(this.baseUrl, searchParams);
      return data;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async fetchMe() {
    try {
      const { data } = await RessourceApi.get(this.baseUrlMe);
      return data;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async getUsersOnCustomer(customer_id: number, searchParams?: SearchParams) {
    try {
      const { data } = await RessourceApi.getWithAdditionalPath(
        this.baseUrl,
        `roles-on-customer/${customer_id}`,
        searchParams
      );
      return data;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async updateRoleOnUser(body: any) {
    try {
      const { data } = await RessourceApi.patch('users/roles', body);
      return data;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async updateUserRole(user_id: number, roleId: number) {
    try {
      const {
        data: { results },
      } = await RessourceApi.post(this.baseUrl, {}, user_id, `assign-role/${roleId}`);
      return results;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async deleteUserRole(user_id: number) {
    try {
      const { data } = await RessourceApi.deleteWithAdditionalPath(this.baseUrl, user_id, `unassign-role`);
      return data;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async updateClient(id: number, body: any) {
    const { data } = await RessourceApi.patch('users/clients', body, id);
    return data;
  }

  public static async fetchById(id: number, params: string) {
    try {
      const { data } = await RessourceApi.getById(this.baseUrl, id, `${params}&size=-1&`);
      return data.catalog;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async getClientsOnCustomer(customerId: number, searchParams?: SearchParams): Promise<User[]> {
    try {
      const { data } = await RessourceApi.get(this.baseUrlClients, { customer_id: customerId, ...searchParams });
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async createClient(body: any) {
    try {
      const {
        data: { user_client },
      } = await RessourceApi.post(this.baseUrlClients, body);
      return user_client;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async deleteClient(id: number) {
    try {
      const { data } = await RessourceApi.delete(this.baseUrlClients, id);
      return data;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async openAccess(user_id: number, email: string) {
    try {
      const {
        data: { results },
      } = await RessourceApi.post(this.baseUrlClients, { email }, user_id, 'open-access');
      return results;
    } catch (error: unknown) {
      handleApiError(error);
      throw error;
    }
  }

  public static async resetPassword(user_id: number) {
    try {
      const {
        data: { user_client },
      } = await RessourceApi.post(this.baseUrlClients, {}, user_id, 'reset-password');
      return user_client;
    } catch (error: unknown) {
      handleApiError(error);
      throw error;
    }
  }

  public static async deactivateUser(userId: number, requestData: any): Promise<any> {
    try {
      const { data } = await RessourceApi.post(this.baseUrl, requestData, userId, 'anonymize-user');
      return data;
    } catch (error: unknown) {
      handleApiError(error);
      throw error;
    }
  }

  public static fetchPossibleContacts = async (searchParams?: SearchParams): Promise<User[]> => {
    try {
      const getAdmins = this.fetchUsersByRoleName('admin', '', searchParams);
      const getBm = this.fetchUsersByRoleName('bm', '', searchParams);
      const getTlPlus = this.fetchUsersByRoleName('team_leader_plus', '', searchParams);
      const getBUTL = this.fetchUsersByRoleName('butl', '', searchParams);
      const getDM = this.fetchUsersByRoleName('team_leader', '', searchParams);
      const [admins, bm, tlPlus, butl, dm] = await Promise.all([getAdmins, getBm, getTlPlus, getBUTL, getDM]);

      return removeDuplicates([...admins, ...bm, ...tlPlus, ...butl, ...dm]);
    } catch (error: unknown) {
      return [];
    }
  };

  public static async fetchPossiblesDelegues(): Promise<User[]> {
    try {
      const getAdmins = this.fetchUsersByRoleName('admin', '?size=-1');
      const getBm = this.fetchUsersByRoleName('bm', '?size=-1');
      const getTlPlus = this.fetchUsersByRoleName('team_leader_plus', '?size=-1');
      const [admins, bm, tlPlus] = await Promise.all([getAdmins, getBm, getTlPlus]);

      return removeDuplicates([...admins, ...bm, ...tlPlus]);
    } catch (error: unknown) {
      return [];
    }
  }

  public static async fetchPossiblesConsultants(): Promise<User[]> {
    try {
      const getAdmins = this.fetchUsersByRoleName('admin', '?size=-1');
      const getBm = this.fetchUsersByRoleName('bm', '?size=-1');
      const getTlPlus = this.fetchUsersByRoleName('team_leader_plus', '?size=-1');
      const getTl = this.fetchUsersByRoleName('team_leader', '?size=-1');
      const getConsultants = this.fetchUsersByRoleName('consultant', '?size=-1');
      const [admins, bm, tlPlus, consultants, tl] = await Promise.all([
        getAdmins,
        getBm,
        getTlPlus,
        getConsultants,
        getTl,
      ]);

      return removeDuplicates([...admins, ...bm, ...tlPlus, ...tl, ...consultants]);
    } catch (error: unknown) {
      return [];
    }
  }

  public static async fetchPossiblesTeamLeaders(): Promise<User[]> {
    try {
      const getAdmins = this.fetchUsersByRoleName('admin', '?size=-1');
      const getTlPlus = this.fetchUsersByRoleName('team_leader_plus', '?size=-1');
      const getTl = this.fetchUsersByRoleName('team_leader', '?size=-1');
      const getBm = this.fetchUsersByRoleName('bm', '?size=-1');
      const [admins, tl, tlPlus, bm] = await Promise.all([getAdmins, getTlPlus, getTl, getBm]);

      return removeDuplicates([...admins, ...tl, ...tlPlus, ...bm]);
    } catch (error: unknown) {
      return [];
    }
  }

  public static async fetchPossiblesDeliverableTeamLeaders(): Promise<User[]> {
    try {
      const getAdmins = this.fetchUsersByRoleName('admin', '?size=-1');
      const getTlPlus = this.fetchUsersByRoleName('team_leader_plus', '?size=-1');
      const getTl = this.fetchUsersByRoleName('team_leader', '?size=-1');
      const [admins, tl, tlPlus] = await Promise.all([getAdmins, getTlPlus, getTl]);

      return removeDuplicates([...admins, ...tl, ...tlPlus]);
    } catch (error: unknown) {
      return [];
    }
  }

  public static async getSignature() {
    return RessourceApi.get('me/signature');
  }

  public static async saveSignature(resource: string, data: any, contentType: string) {
    try {
      await fetch(resource, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: data,
      });
    } catch (error) {
      console.error(error);
    }
  }
}
