import { AppActions } from '../actions/appActions';
import { RootActions } from '../redux';
import { appState, AppState } from '../states/appState';

const appReducer = (state = appState, action: RootActions): AppState => {
  switch (action.type) {
    case AppActions.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarExpanded: !state.sidebarExpanded,
      };
    case AppActions.LOAD_SIDEBAR_LINK:
      return {
        ...state,
        sidebarLink: action.payload,
      };
    case AppActions.LOAD_DEVISES:
      return {
        ...state,
        devises: action.payload,
      };
    case AppActions.LOAD_CATALOG:
      return {
        ...state,
        catalog: action.payload,
      };
    case AppActions.LOAD_CATALOGS:
      return {
        ...state,
        catalogs: action.payload,
      };
    case AppActions.LOAD_CATALOGS_ARCHIVED:
      return {
        ...state,
        archivedCatalogs: action.payload,
      };
    case AppActions.LOAD_CATALOG_COMPLEXITIES:
      return {
        ...state,
        catalogComplexities: action.payload,
      };
    case AppActions.LOAD_WORKUNIT:
      return {
        ...state,
        workunit: action.payload,
      };
    case AppActions.LOAD_WORKUNITS:
      return {
        ...state,
        workunits: action.payload,
      };
    case AppActions.LOAD_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case AppActions.LOAD_USER:
      return {
        ...state,
        user: action.payload,
      };
    case AppActions.LOAD_CONSULTANTS:
      return {
        ...state,
        consultants: action.payload,
      };
    case AppActions.LOAD_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case AppActions.UPDATE_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case AppActions.LOAD_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case AppActions.LOAD_AFFAIRS:
      return {
        ...state,
        affairs: action.payload,
      };
    case AppActions.LOAD_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case AppActions.INJECT_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case AppActions.ADD_CANCEL_TOKEN:
      return {
        ...state,
        cancelTokens: {
          ...state.cancelTokens,
          [action.payload.name]: action.payload.source,
        },
      };
    case AppActions.REMOVE_CANCEL_TOKEN:
      // eslint-disable-next-line no-case-declarations
      const { [action.payload]: cancelToken, ...cancelTokens } = state.cancelTokens;
      return {
        ...state,
        cancelTokens,
      };
    case AppActions.UPDATE_TERMS_OF_USE:
      return {
        ...state,
        acceptedTermsOfuse: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
