/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Card, CardContent, CardHeader, Grid, Link, Tooltip, Typography } from '@mui/material';
import { MenuActions } from 'components/index';
import { formatDateToddMMYYY } from 'helpers/format';
import useUserRoles from 'hooks/useUserRoles';
/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'store/hooks';
import Catalog from 'types/entities/Catalog';
import moment from 'moment';

const CatalogCard: React.FC<{ catalog: Catalog }> = ({ catalog }: { catalog: Catalog }) => {
  const history = useHistory();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const handleClick = () => {
    history.push(`/${appState.customer?.slug}/catalog/${catalog.id}/new-order`);
  };

  const [isAbleToCreateOrder, setIsAbleToCreateOrder] = React.useState(false);
  React.useEffect(() => {
    setIsAbleToCreateOrder(!userRoles.isButl);
  }, [userRoles]);

  const [t] = useTranslation();
  const isCatalogDisabled = catalog?.is_archived || !catalog?.nb_workunits;

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader
        action={!userRoles.isManager && <MenuActions activeItem={catalog} />}
        title={
          <>
            <Typography>Catalog</Typography>
            <Tooltip title={catalog.reference} placement="bottom-start">
              <Link
                component={RouterLink}
                to={{
                  pathname: `/${appState.customer?.slug}/catalogs/${catalog.id}`,
                }}
                underline="none"
                noWrap
              >
                {catalog.reference}
              </Link>
            </Tooltip>
          </>
        }
        sx={{ paddingLeft: '25px' }}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Typography variant="body2" align="left" color="textSecondary" noWrap style={{ fontWeight: 500 }}>
              Validity period
            </Typography>
            <Tooltip
              title={`${formatDateToddMMYYY(catalog?.validity_from || moment().format('YYYY-MM-DD'))} - 
               ${formatDateToddMMYYY(catalog?.validity_to || moment().format('YYYY-MM-DD'))}`}
            >
              <Box>
                <Typography color="textPrimary" sx={{ fontWeight: 600 }} noWrap>
                  {formatDateToddMMYYY(catalog?.validity_from || moment().format('YYYY-MM-DD'))}
                </Typography>
                <Typography color="textPrimary" sx={{ fontWeight: 600 }} noWrap>
                  {formatDateToddMMYYY(catalog?.validity_to || moment().format('YYYY-MM-DD'))}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="body2" color="textSecondary" noWrap sx={{ fontWeight: 500 }}>
              Last updated
            </Typography>
            <Tooltip title={formatDateToddMMYYY(catalog.updated_at)}>
              <Typography color="textPrimary" sx={{ fontWeight: 600 }} noWrap>
                {formatDateToddMMYYY(catalog.updated_at)}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
      {isAbleToCreateOrder && (
        <Box
          sx={{
            padding: '0 25px 25px',
            mt: 'auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Tooltip arrow title={isCatalogDisabled ? t<string>('No_workunit_in_catalog') : ''} enterNextDelay={0}>
            <Button variant="contained" disabled={isCatalogDisabled} onClick={handleClick} fullWidth>
              {t('Create_an_order')}
            </Button>
          </Tooltip>
        </Box>
      )}
    </Card>
  );
};
export default CatalogCard;
