import { DocumentsTabState } from '../../types/enums/DocumentsTabState';

export interface DocumentsState {
  documents: string[];
  currentTab: DocumentsTabState;
}

export const documentsState: DocumentsState = {
  documents: [],
  currentTab: DocumentsTabState.TCS,
};
