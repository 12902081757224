const fr = {
  To_Duplicate: '',
  Optional: 'Optionnel',
  Order_number: 'Order N°',
  Add_a_number: 'Ajoutez un nombre',
  Date_delivery: 'Date de livraison prévue',
  Add_this_new_deliverable: 'Ajouter ce nouveau livrable',
  Select_the_start_date: 'Selectionner la date de début',
  Edit_deliverable: 'Editer le livrable',
  Generate_sheet: 'Exporter',
  Duplicate_deliverable: 'Dupliquer un livrable',
  Done: 'Terminé',
  Open: 'Ouvert',
  One_time: 'Une seule fois',
  Weekly: 'Hebdomadaire',
  Monthly: 'Mensuel',
  Other: 'Autre',
  Status: 'Statut',
  Name: 'Nom',
  Reference: 'Référence',
  Edit: 'Éditer',
  Deliverables: 'Livrables',
  Deliverable_date: 'Date de livraison',
  Frequency: 'Fréquence',
  Submitted: 'Soumission',
  Validated: 'Validation',
  Accepted: 'Accepté',
  Rating: 'Note',
  Comment: 'Commentaire',
  Action: 'Actions',
  Add_new_deliverable: 'Ajouter un livrable',
  REFERENCE_OF_NEW_DELIVERABLE: 'Reference du livrable',
  NAME_OF_NEW_DELIVERABLE: 'Nom du livrable',
};

export default fr;
