import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TermsOfUseDialog from '../../components/TermsOfUseDialog/TermsOfUseDialog';
import { openDialogAction } from '../../store/actions/dialogsActions';
import { useAppState } from '../../store/Provider';

const TermsOfUse = () => {
  const history = useHistory();
  const { state, dispatch } = useAppState();
  const { app } = state;
  useEffect(() => {
    if (app.acceptedTermsOfuse === false) {
      dispatch(openDialogAction({ name: 'termsOfUseDialog' }));
    } else if (app.acceptedTermsOfuse === true) {
      history.replace(`/${app.customer?.slug}/dashboard`);
    }
  }, [app.acceptedTermsOfuse]);
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <TermsOfUseDialog />
      </Box>
    </>
  );
};
export default TermsOfUse;
