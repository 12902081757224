/* eslint-disable class-methods-use-this */
import { handleApiError } from 'api/errors';
import ResourceAPI, { Resource, SearchParams } from 'api/ResourceAPI';
import axios, { AxiosResponse } from 'axios';
import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import OrderWorkunitModification from 'types/entities/OrderWorkunitModification';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import MissionForCreation from 'types/models/MissionForCreation';
import { SelectedFiltersList } from '../types/models/SelectedFiltersList';
import InMemoryOrderWorkunitApiService from './InMemory/InMemoryOrderWorkunitApiService';

type OrderWorkunitKeys = keyof OrderWorkunit;

type ExcelExportBody = {
  customer_id?: number;
  order_workunit_ids: number[];
  order_id?: number;
  filters?: {
    select_all: boolean;
  };
};

export type PostBulkOrderWorkunitsWorkunits = {
  workunit_id: number;
  delivery_date: string;
  scope_id: Scope['id'] | null;
  workunit_name: string;
  workunit_reference: string;
  content: string;
  content2: string;
  content3: string;
  content4: string;
  content5: string;
  qte: number;
  workunit_complexity_id: number | null;
}[];
export interface IOrderWorkunitApiService {
  getAllOrderWorkUnitByOrderId(id: number, additionalParams?: SearchParams): Promise<OrderWorkunit[]>;
  getOrderWorkunitByOrderId(id: number, props: OrderWorkunitKeys[], params?: SearchParams): Promise<OrderWorkunit>;
  createOrderWorkunits(
    orderWorkunit: Partial<OrderWorkunit> | Partial<OrderWorkunit>[] | MissionForCreation[]
  ): Promise<OrderWorkunit[]>;
  updateWorkunit(workunitId: number, workunit: Partial<OrderWorkunit>): Promise<OrderWorkunit>;
  sendModificationWithReason(
    orderId: number,
    modificationBody: Omit<OrderWorkunitModification, 'id' | 'created_at' | 'updated_at'>
  ): Promise<void | undefined>;
  massCancelOrderWorkunits(
    orderId: number,
    missionIds: number[],
    cancellation_reason: string,
    selectAll: boolean,
    filters?: SelectedFiltersList
  ): Promise<OrderWorkunitModification>;
  duplicateManyOrderWorkunit(orderIds: number[]): Promise<OrderWorkunit[]>;
  updateManyOrderWorkunit(body: OrderWorkunit[]): Promise<OrderWorkunit[]>;
  bulkUpdateOrderWorkunits(ids: OrderWorkunit['id'][], dataToUpdate: Partial<OrderWorkunit>): Promise<OrderWorkunit[]>;
  archiveWorkunits(missionsIds: number[], selectAll: boolean, filters?: SelectedFiltersList): Promise<void>;
  getClientOrderWorkunitsByDateAndScope(
    order_id: number,
    params: {
      scope_ids: number[];
      dates: {
        start: string;
        end: string;
      }[];
    }
  ): Promise<AxiosResponse<{ user: User; workunits: number }[]> | undefined>;
}

class OrderWorkunitApiService implements IOrderWorkunitApiService {
  static baseUrl = 'order-workunits' as Resource;

  static baseUrlBulk = `${process.env.REACT_APP_SERVER_URL}/order-workunits/bulk`;

  static resource = 'order-workunits' as Resource;

  static baseUrlXlsx = 'order-workunits/export/xlsx' as Resource;

  static resourcebulk = 'order-workunits/bulk' as Resource;

  static axios = ResourceAPI.axiosInstance;

  public static instance: OrderWorkunitApiService;

  get Instance() {
    if (!OrderWorkunitApiService.instance) {
      OrderWorkunitApiService.instance = new OrderWorkunitApiService();
    }
    return OrderWorkunitApiService.instance;
  }

  async getAllOrderWorkUnitByOrderId(id: number, additionalParams?: SearchParams) {
    try {
      const params = {
        order_id: id,
        join: ['consultant', 'workunit', 'client'],
        size: '1000',
        ...additionalParams,
      };
      const { data } = await ResourceAPI.get(OrderWorkunitApiService.baseUrl, params);
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  async getOrderWorkunitByOrderId(id: number, props: OrderWorkunitKeys[] = [], params?: SearchParams) {
    try {
      const { data } = await ResourceAPI.get(OrderWorkunitApiService.baseUrl, {
        order_id: id,
        attr: props.join('.'),
        ...params,
      });

      return data;
    } catch (error: unknown) {
      if (axios.isCancel(error)) {
        return { datas: null, totalPages: 0, currentPage: 0, totalItems: 0 };
      }
      return { datas: [], totalPages: 0, currentPage: 0, totalItems: 0 };
    }
  }

  async createOrderWorkunits(orderWorkunit: Partial<OrderWorkunit> | Partial<OrderWorkunit>[] | MissionForCreation[]) {
    try {
      const {
        data: { order_workunits },
      } = await ResourceAPI.post(OrderWorkunitApiService.baseUrl, orderWorkunit);
      return order_workunits;
    } catch (error: unknown) {
      return [];
    }
  }

  async updateWorkunit(workunitId: number, workunit: Partial<OrderWorkunit>) {
    try {
      const { data } = await ResourceAPI.patchById(OrderWorkunitApiService.baseUrl, workunitId, workunit);
      return data.order_workunit;
    } catch (error: unknown) {
      throw Error('Unable to save workunit');
    }
  }

  async sendModificationWithReason(
    orderId: number,
    modificationBody: Omit<OrderWorkunitModification, 'id' | 'created_at' | 'updated_at'>
  ) {
    try {
      await ResourceAPI.post('order-workunits/modifications', modificationBody);
    } catch (error) {
      throw Error('Unable to save modification');
    }
  }

  async massCancelOrderWorkunits(
    orderId: number,
    missionIds: number[],
    cancellation_reason: string,
    selectAll: boolean,
    filters?: SelectedFiltersList
  ): Promise<OrderWorkunitModification> {
    const body = {
      order_id: orderId,
      cancellation_reason,
      ressources: missionIds.map((id) => ({
        id,
        cancellation_reason,
      })),
      filters: {
        ...filters,
        select_all: selectAll,
      },
    };

    try {
      const modification = await ResourceAPI.post('order-workunits/cancel' as Resource, body);
      return modification.data;
    } catch (error) {
      throw new Error(`Failed to cancel order workunits: ${error}`);
    }
  }

  async duplicateManyOrderWorkunit(orderIds: number[]): Promise<OrderWorkunit[]> {
    try {
      const body = {
        ressources: orderIds,
      };
      const res = await ResourceAPI.post('order-workunits/duplicate' as Resource, body);
      return res.data.order_workunits;
    } catch (error) {
      return [];
    }
  }

  async updateManyOrderWorkunit(body: OrderWorkunit[]): Promise<OrderWorkunit[]> {
    try {
      const { data } = await ResourceAPI.patch(OrderWorkunitApiService.baseUrl, body);
      return data;
    } catch (error) {
      return [];
    }
  }

  async bulkUpdateOrderWorkunits(
    ids: OrderWorkunit['id'][],
    dataToUpdate: Partial<OrderWorkunit>
  ): Promise<OrderWorkunit[]> {
    try {
      const { data } = await ResourceAPI.patch('order-workunits/bulk' as Resource, {
        ressources: ids,
        data: dataToUpdate,
      });
      return data;
    } catch (error) {
      return [];
    }
  }

  async archiveWorkunits(
    missionsIds: OrderWorkunit['id'][],
    selectAll: boolean,
    filters?: SelectedFiltersList
  ): Promise<void> {
    try {
      const data: {
        ressources?: number[];
        filters?: {
          select_all: boolean;
        };
      } = {
        filters: {
          ...filters,
          select_all: selectAll,
        },
      };
      if (missionsIds.length > 0) {
        data.ressources = missionsIds;
      }
      await ResourceAPI.post(OrderWorkunitApiService.baseUrl, data, undefined, 'delete');
    } catch (error) {
      handleApiError(error);
    }
  }

  async postBulkOrderWorkunits(body: {
    order_id: Order['id'];
    order_workunits: PostBulkOrderWorkunitsWorkunits;
  }): Promise<AxiosResponse<any> | null> {
    try {
      const req = ResourceAPI.post(OrderWorkunitApiService.resourcebulk, body);
      return await req;
    } catch (error) {
      handleApiError(error);
      return null;
    }
  }

  async exportXlsx(body: ExcelExportBody) {
    try {
      return await ResourceAPI.post(OrderWorkunitApiService.baseUrlXlsx, body);
    } catch (error) {
      return undefined;
    }
  }

  async getClientOrderWorkunitsByDateAndScope(
    order_id: number,
    params: {
      scope_ids: number[];
      dates: {
        start: string;
        end: string;
      }[];
    }
  ): Promise<AxiosResponse<{ user: User; workunits: number }[]> | undefined> {
    try {
      return await ResourceAPI.post(`orders/${order_id}/client-workunits` as Resource, params);
    } catch (error) {
      return undefined;
    }
  }
}

const isTestMode = process.env.REACT_APP_NODE_ENV === 'test';
const orderWorkunitApiService = isTestMode ? new InMemoryOrderWorkunitApiService() : new OrderWorkunitApiService();

export default orderWorkunitApiService;
