import { RemoveCircle } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useOrderWorkunitsService from 'features/Orders/OrderWorkunits/services/useOrderWorkunitsService';
import React, { memo } from 'react';
import { useSelector } from 'store/hooks';
import { useGridApiContext } from '@mui/x-data-grid-pro';

const ContentHeader = ({ number, type }: { number: number; type: 'catalog' | 'order' }) => {
  const contentColumnsNumber = useSelector((state) =>
    type === 'catalog'
      ? state.catalogToOrder.catalogHandler.contentsNumber
      : state.catalogToOrder.orderHandler.contentsNumber
  );

  const apiRef = useGridApiContext();

  const activeEditRows = useSelector((state) => state.catalogToOrder.selectedWorkunitsEditIds);

  const {
    increaseCatalogContentColumnsNumber,
    decreaseCatalogContentColumnsNumber,
    increaseOrderContentColumnsNumber,
    decreaseOrderContentColumnsNumber,
  } = useOrderWorkunitsService();

  const handleDecrease = async () => {
    const contentField = `content${number > 0 ? number : ''}`;
    for (let i = 0; i < activeEditRows.length; i += 1) {
      if (apiRef.current.getCellMode(activeEditRows[i], contentField) === 'edit') {
        apiRef.current.stopCellEditMode({ id: activeEditRows[i], field: contentField });
      }
    }

    /* Trick to wait for cells edit mode to be stopped before decreasing 
      the number of contents columns in order to avoid Datagrid's undefined valueSetter error */
    await new Promise((r) => setTimeout(r, 0));
    /* ---- */

    if (type === 'catalog') {
      decreaseCatalogContentColumnsNumber();
    } else {
      decreaseOrderContentColumnsNumber();
    }
  };

  const handleIncrease = () => {
    if (type === 'catalog') {
      increaseCatalogContentColumnsNumber();
    } else {
      increaseOrderContentColumnsNumber();
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {number > 1 && number === contentColumnsNumber && (
        <IconButton size="small" onClick={handleDecrease}>
          <RemoveCircle fontSize="inherit" color="error" />
        </IconButton>
      )}
      <Typography variant="body2">Content {number === 1 ? '' : number}</Typography>
      {number < 5 && number === contentColumnsNumber && (
        <IconButton size="small" onClick={handleIncrease}>
          <AddCircleIcon fontSize="inherit" color="primary" />
        </IconButton>
      )}
    </Box>
  );
};

export default memo(ContentHeader);
