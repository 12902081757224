import OrderApiService from 'api/OrderApiService';
import DrawerFilters from 'components/DrawerFilters/DrawerFilters';
import useApi from 'hooks/useApi';
import useCancelApi from 'hooks/useCancelApi';
import React, { useCallback, useEffect } from 'react';
import { updateItemsFilterAction, updateParamsFilterAction } from 'store/actions/filterActions';
import { changePageAction } from 'store/actions/managementActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { useManagementFilterItems } from '../hooks/useManagementFilterItems';
import useManagementHelper from '../services/useManagementHelper';

const ManagementFilter: React.FC = () => {
  const { makeCall } = useApi();
  const { dispatch } = useAppState();
  const { addCancelToken, cancelRequests } = useCancelApi();
  const { filter } = useSelector((state) => state);
  const { isPathForProduction, isPathForQuotes } = useManagementHelper();
  const currentTab = useSelector((state) => state.management.pageHandler.currentTab);
  const filterItems = useManagementFilterItems();

  const fetchFilterParams = useCallback(async () => {
    if (currentTab !== OrdersTabState.NULL && currentTab !== OrdersTabState.ARCHIVED) {
      const response = await makeCall(OrderApiService.fetchParamsFilterOrder(currentTab));
      if (response && response['super-filters']) {
        dispatch(updateParamsFilterAction(response['super-filters']));
      }
    } else if (currentTab === OrdersTabState.ARCHIVED) {
      let status;
      if (isPathForProduction()) {
        status = 'production';
      } else if (isPathForQuotes()) {
        status = 'drafted';
      }
      if (status) {
        const response = await makeCall(OrderApiService.fetchParamsFilterOrder(status, { is_archived: true }));
        if (response && response['super-filters']) {
          dispatch(updateParamsFilterAction(response['super-filters']));
        }
      }
    }
  }, [dispatch, currentTab, makeCall, isPathForProduction, isPathForQuotes]);

  useEffect(() => {
    addCancelToken('cancelTokenFetchDeliverables');
    fetchFilterParams();
    return () => {
      cancelRequests('cancelTokenFetchDeliverables');
    };
  }, [fetchFilterParams]);

  useEffect(() => {
    dispatch(updateItemsFilterAction(filterItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  useEffect(() => {
    dispatch(changePageAction(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.selected]);

  return (
    <DrawerFilters
      baseUrl={{
        pathname: 'status',
        value: currentTab,
      }}
    />
  );
};

export default ManagementFilter;
