import RessourceApi, { Resource, SearchParams } from 'api/ResourceAPI';
import Workunit from 'types/entities/Workunit';

export default class WorkunitApiService {
  public static instance: WorkunitApiService;

  public static baseUrl = 'workunits' as Resource;

  public static me = 'me' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return WorkunitApiService.instance || (WorkunitApiService.instance = new WorkunitApiService());
  }

  public static async get(catalog_id: number, params: SearchParams) {
    try {
      const { data } = await RessourceApi.get('workunits', {
        catalog_id,
        ...params,
      });
      return data;
    } catch (error) {
      return [];
    }
  }

  public static async create(workunit: Partial<Workunit>) {
    try {
      const {
        data: { workunit: workunitCreated },
      } = await RessourceApi.post(this.baseUrl, workunit);
      return workunitCreated;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async update(workunit: Partial<Workunit>) {
    try {
      const { data } = await RessourceApi.patchById(this.baseUrl, workunit?.id as number, workunit);
      return data.workunit;
    } catch (error: unknown) {
      return [];
    }
  }
}
