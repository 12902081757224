import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import UserApiService from 'api/UserApiService';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import User from 'types/entities/User';

type ClientDialogUnassignUserProps = {
  activeUser: User;
  setClients: React.Dispatch<React.SetStateAction<User[]>>;
};

const ClientDialogUnassignUser: React.FC<ClientDialogUnassignUserProps> = ({ activeUser, setClients }) => {
  const [t] = useTranslation();
  const dialogs = useSelector((state) => state.dialogs);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAppState();
  const { makeCall } = useApi();

  const close = () => {
    dispatch(closeDialogAction('ClientDialogUnassignUser'));
  };

  const unAssignUser = async () => {
    setLoading(true);
    const res = await makeCall(UserApiService.deleteUserRole(activeUser.id), 'Error unassign user');
    if (res) {
      dispatch(setSnackbarAction({ message: 'User unassigned successfully', open: true, severity: 'success' }));
      dispatch(closeDialogAction('ClientDialogUnassignUser'));
      setClients((prevClients) => (prevClients || []).filter((client) => client.id !== activeUser.id));
    } else {
      dispatch(
        setSnackbarAction({ message: 'Error! User is not unassigned. Try Again later', open: true, severity: 'error' })
      );
      dispatch(closeDialogAction('ClientDialogUnassignUser'));
    }

    setLoading(false);
  };

  return (
    <>
      <Dialog style={{ maxHeight: 435 }} maxWidth="sm" open={dialogs.ClientDialogUnassignUser || false}>
        <DialogTitle>{t('unassign_user')}</DialogTitle>
        <DialogContent>
          <div>{t('confirm_unassign_user')}</div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={close}>
            {t('cancel')}
          </Button>
          <LoadingButton variant="contained" loading={loading} onClick={unAssignUser} color="primary">
            {t('unassign_user_button')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientDialogUnassignUser;
