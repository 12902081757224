import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { DownloadIcon } from 'components/CustomIcons';
import StatusReport from 'components/StatusReport/StatusReport';
import useDeliverableReportService from 'features/Deliverables/DeliverableReports/hooks/useDeliverableReportService';
import DialogMarkAsSigned from 'features/Sheet/Components/Dialogs/DialogMarkAsSigned/DialogMarkAsSigned';
import { setShouldRefreshAction } from 'features/Sheet/SheetContext/_reducers/ShouldRefresh/ShouldRefresh';
import { useSheetContext } from 'features/Sheet/SheetContext/SheetContext';
import useUserRoles from 'hooks/useUserRoles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import FinancialReport from 'types/entities/FinancialReport';
import { ReportStatus } from 'types/enums/ReportStatus';
import ChangeDocumentLocation from '../ChangeDocumentLocation/ChangeDocumentLocation';

type SheetPreviewActionButtonsProps = {
  pdfUrl: string | null;
  report: DeliverableSheet | FinancialReport;
};

const SheetPreviewActionButtons = ({ pdfUrl, report }: SheetPreviewActionButtonsProps) => {
  const { dispatch } = useAppState();
  const { dispatch: dispatchLocalContext } = useSheetContext();
  const loading = useSelector((state) => state.loadings.sendToClient);
  const [t] = useTranslation();
  const userRoles = useUserRoles();
  const { downloadReport, openMarkAsSignedModal, openAssessSignModal, openApproveSignModal, sendToClient } =
    useDeliverableReportService();

  const handleSendToClient = async () => {
    dispatch(addLoadingAction('sendToClient'));
    await sendToClient(report);
    dispatch(removeLoadingAction('sendToClient'));
  };

  return (
    <Stack direction="column" spacing={2}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, mb: 3, gap: 1 }}>
        {userRoles.isDeliverableManager && report.state === ReportStatus.TO_BE_SENT && (
          <LoadingButton
            loading={!!loading}
            type="button"
            color="success"
            variant="contained"
            onClick={/* () => sendToClient(report) */ handleSendToClient}
          >
            {t('Send to client')}
          </LoadingButton>
        )}
        {userRoles.isDeliverableManager &&
          (report.state === ReportStatus.TO_BE_SENT || report.state === ReportStatus.WAITING_FOR_SIGNATURE) && (
            <Button type="button" color="success" variant="contained" onClick={() => openMarkAsSignedModal(report)}>
              {t('Mark as signed')}
            </Button>
          )}
        {userRoles.isClient && report.state === ReportStatus.WAITING_FOR_SIGNATURE && (
          <>
            {report.type === 'technical' && (
              <Button type="button" color="success" variant="contained" onClick={() => openAssessSignModal(report)}>
                {t('Assess_and_sign')}
              </Button>
            )}
            {report.type === 'financial' && (
              <Button type="button" color="success" variant="contained" onClick={() => openApproveSignModal(report)}>
                {t('Approve_and_sign')}
              </Button>
            )}
          </>
        )}
        <Button onClick={() => downloadReport(report)} variant="outlined" startIcon={<DownloadIcon />}>
          {t('extract_in_pdf')}
        </Button>
        <Box sx={{ ml: 'auto' }}>
          <StatusReport state={report.state as string} />
        </Box>
      </Box>
      {/* report.state === ReportStatus.FINISHED && report.signed_by_DM && !userRoles.isClient && (
        <ChangeDocumentLocation savedLocation={report.location} reportId={report.id} />
      ) */}
      <DialogMarkAsSigned callback={() => dispatchLocalContext(setShouldRefreshAction(true))} />
    </Stack>
  );
};

export default SheetPreviewActionButtons;
