import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import { AffectationSearch } from './OrderWorkunitAffectationContext';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

// eslint-disable-next-line no-shadow
export enum OrderWorkunitAffectationType {
  ChangePage = 'CHANGE_PAGE_OWU_AFFECTATIONS',
  ChangePageSize = 'CHANGE_PAGE_SIZE',
  LoadDeliverable = 'LOAD_DELIVERABLE',
  Inject = 'INJECT_OWU_AFFECTATIONS',
  InjectOrder = 'INJECT_ORDER',
  InjectScopes = 'INJECT_SCOPES',
  OpenEditDrawer = 'OPEN_EDIT_DRAWER',
  CloseEditDrawer = 'CLOSE_EDIT_DRAWER',
  ChangeTotalItems = 'CHANGE_TOTAL_ITEMS',
  ChangeTotalPages = 'CHANGE_TOTAL_PAGES',
  UpdateOrderWorkunit = 'UPDATE_ORDER_WORKUNIT',
  UpdateSearch = 'UPDATE_SEARCH',
  SelectAffectationItems = 'SELECT_AFFECTATION_ITEMS',
  GridIsLoading = 'GRID_IS_LOADING',
  GridNotLoading = 'GRID_NOT_LOADING',
  BulkUpdateOrderWorkunit = 'BULK_UPDATE_ORDER_WORKUNIT',
  SetAffectationConsultants = 'SET_AFFECTATION_CONSULTANTS',
  SetAffectationClients = 'SET_AFFECTATION_CLIENTS',
  SetAffectationDM = 'SET_AFFECTATION_DM',
}

// eslint-disable-next-line @typescript-eslint/ban-types
type OrderWorkunitAffectationPayload = {
  [OrderWorkunitAffectationType.Inject]: {
    orderWorkunits: OrderWorkunit[];
    totalItems: number;
    totalPages: number;
    currentPage: number;
  };
  [OrderWorkunitAffectationType.ChangePage]: { newPage: number };
  [OrderWorkunitAffectationType.ChangePageSize]: { newPageSize: number };
  [OrderWorkunitAffectationType.LoadDeliverable]: null;
  [OrderWorkunitAffectationType.InjectOrder]: { order: Order | null };
  [OrderWorkunitAffectationType.InjectScopes]: { scopes: Scope[] };
  [OrderWorkunitAffectationType.OpenEditDrawer]: undefined;
  [OrderWorkunitAffectationType.CloseEditDrawer]: undefined;
  [OrderWorkunitAffectationType.ChangeTotalItems]: { totalItems: number };
  [OrderWorkunitAffectationType.ChangeTotalPages]: { totalPages: number };
  [OrderWorkunitAffectationType.UpdateOrderWorkunit]: { orderWorkunit: OrderWorkunit | Partial<OrderWorkunit> };
  [OrderWorkunitAffectationType.UpdateSearch]: { field: keyof AffectationSearch; value: any };
  [OrderWorkunitAffectationType.SelectAffectationItems]: { orderWorkunitIds: OrderWorkunit['id'][] };
  [OrderWorkunitAffectationType.GridIsLoading]: undefined;
  [OrderWorkunitAffectationType.GridNotLoading]: undefined;
  [OrderWorkunitAffectationType.BulkUpdateOrderWorkunit]: Partial<OrderWorkunit>;
  [OrderWorkunitAffectationType.SetAffectationConsultants]: { consultants: User[] };
  [OrderWorkunitAffectationType.SetAffectationClients]: { clients: User[] };
  [OrderWorkunitAffectationType.SetAffectationDM]: { deliveryManagers: User[] };
};

export type PossiblesOrderWorkunitAffectationActions =
  ActionMap<OrderWorkunitAffectationPayload>[keyof ActionMap<OrderWorkunitAffectationPayload>];
