import OrderApiService from 'api/OrderApiService';
import DrawerFilters from 'components/DrawerFilters/DrawerFilters';
import useApi from 'hooks/useApi';
import React, { useEffect } from 'react';
import { updateItemsFilterAction, updateParamsFilterAction } from 'store/actions/filterActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { changePageAction } from '../../../store/actions/deliverableActions';
import { useDeliverableFilterItems } from '../hooks/useDeliverableFilterItems';

const DeliverableFilter: React.FC = () => {
  const { makeCall } = useApi();
  const { dispatch } = useAppState();
  const { deliverable, filter } = useSelector((state) => state);
  const filterItems = useDeliverableFilterItems();

  const fetchFilterParams = async () => {
    if (deliverable?.order?.id) {
      const filterParams = await makeCall(OrderApiService.fetchParamsFilterDeliverable(deliverable?.order?.id));
      if (filterParams) {
        dispatch(updateParamsFilterAction(filterParams));
      }
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchFilterParams();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [deliverable?.order?.id]);

  useEffect(() => {
    dispatch(updateItemsFilterAction(filterItems));
  }, [filterItems]);

  useEffect(() => {
    dispatch(changePageAction(0));
  }, [filter.selected]);

  return <DrawerFilters />;
};

export default DeliverableFilter;
