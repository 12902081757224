import React from 'react';
import { SxProps, Theme, styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface Props {
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  isOpen: boolean | undefined;
  sx?: SxProps<Theme>;
}

const CustomToggleButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#FFFFFF',
  backgroundColor: theme.palette.primary.dark,
  width: '30px',
  height: '30px',
  minWidth: 'auto',
  padding: 0,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ToggleButton: React.FC<Props> = ({ handleClick, isOpen, sx }) => (
  <CustomToggleButton onClick={handleClick} sx={sx}>
    {isOpen ? <ExpandLess /> : <ExpandMore />}
  </CustomToggleButton>
);

export default ToggleButton;
