import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import OrderApiService from 'api/OrderApiService';
import { Loader } from 'components';
import { CheckIcon, RefuseIcon } from 'components/CustomIcons';
import Kpi from 'components/Kpi/Kpi';
import useApi from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import styled from 'styled-components';

const DashboardQualitySlider = () => {
  const appState = useSelector((state) => state.app);
  const [t] = useTranslation();
  const [quality, setQuality] = useState<{
    accepted_with_reserve: number;
    client_refused: number;
    rated_d: number;
    rated_e: number;
    validated_with_reserve: number;
    tl_refused: number;
  }>({
    accepted_with_reserve: 0,
    client_refused: 0,
    rated_d: 0,
    rated_e: 0,
    validated_with_reserve: 0,
    tl_refused: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { makeCall } = useApi();

  useEffect(() => {
    async function fetchOrders() {
      setIsLoading(true);

      try {
        const tenantQuality = await makeCall(OrderApiService.fetchTenantQuality(), 'Unable to fetch tenant quality');
        if (tenantQuality) {
          setQuality(tenantQuality);
        }
      } finally {
        setIsLoading(false);
      }
    }

    if (appState.customer?.id) {
      fetchOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer?.id]);

  return !isLoading ? (
    <Card>
      <CardContent>
        <Typography variant="h2" component="div" color="primary" sx={{ mb: 3 }}>
          {t('Quality')}
        </Typography>
        <Typography variant="h3" component="div" sx={{ mb: 1 }}>
          {t('Client')}
        </Typography>
        <Divider sx={{ mb: 3, borderColor: 'text.primary' }} />
        <Grid container spacing={4} sx={{ mb: 6 }}>
          <Grid item xs={12} lg={6} xl={3}>
            <Kpi number={quality.accepted_with_reserve} content="Acceptation with reserve">
              <CheckIcon sx={{ color: 'warning.main', fontSize: '2.813rem' }} />
            </Kpi>
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <Kpi number={quality.client_refused} content="Refused">
              <RefuseIcon sx={{ color: 'error.main', fontSize: '2.813rem' }} />
            </Kpi>
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <Kpi rating="D" number={quality.rated_d} content="Rated D" />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <Kpi rating="E" number={quality.rated_e} content="Rated E" />
          </Grid>
        </Grid>

        {!appState.user?.is_client && (
          <Box>
            <Typography variant="h3" component="div" sx={{ mb: 1 }}>
              {t('Team Leader')}
            </Typography>
            <Divider sx={{ mb: 3, borderColor: 'text.primary' }} />
            <Grid container spacing={4}>
              <Grid item xs={12} xl={3}>
                <Kpi number={quality.validated_with_reserve} content="Validated with reserve">
                  <CheckIcon sx={{ color: 'warning.main', fontSize: '2.813rem' }} />
                </Kpi>
              </Grid>
              <Grid item xs={12} xl={3}>
                <Kpi icon="refused" number={quality.tl_refused} content="Refused">
                  <RefuseIcon sx={{ color: 'error.main', fontSize: '2.813rem' }} />
                </Kpi>
              </Grid>
            </Grid>
          </Box>
        )}
      </CardContent>
    </Card>
  ) : (
    <Loader />
  );
};

export default DashboardQualitySlider;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: left;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
