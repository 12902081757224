import { generateRandomId } from 'features/Orders/OrderWorkunits/helpersOrderWorkunits';
import moment from 'moment';
import { CatalogToOrderActions } from 'store/actions/catalogToOrderActions';
import { CatalogToOrderState } from 'store/states/catalogToOrderState';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import { SelectedWorkunit } from 'types/entities/SelectedWorkunit';
import { Month } from 'types/models/Months';
import { RootActions } from '../redux';

export const clearWorkunitScope = (state: CatalogToOrderState, action: RootActions): CatalogToOrderState => {
  if (action.type !== CatalogToOrderActions.CLEAR_WORKUNIT_SCOPE) {
    return state;
  }

  const { workunitId, target } = action.payload;

  const clear = (workunit: CatalogWorkunit) => {
    if (workunit.id !== workunitId) {
      return workunit;
    }

    return {
      ...workunit,
      scope: null,
      scope_id: null,
    };
  };

  const clearSelected = (workunit: SelectedWorkunit) => {
    if (workunit.randomId !== workunitId) {
      return workunit;
    }

    return {
      ...workunit,
      scope: null,
      scope_id: null,
    };
  };

  return {
    ...state,
    ...(target === 'catalog' && { workunits: state.workunits.map(clear) }),
    ...(target === 'selected' && {
      selectedWorkunits: state.selectedWorkunits.map(clearSelected),
    }),
  };
};

export const changeWorkunitScope = (state: CatalogToOrderState, action: RootActions): CatalogToOrderState => {
  if (action.type !== CatalogToOrderActions.CHANGE_WORKUNIT_SCOPE) {
    return state;
  }

  const { workunitId, scope, target } = action.payload;

  const changeScope = (workunit: CatalogWorkunit) => {
    if (workunit.id !== workunitId) {
      return workunit;
    }

    return {
      ...workunit,
      scope,
      scope_id: scope.id || null,
    };
  };

  const changeScopeSelected = (workunit: SelectedWorkunit) => {
    if (workunit.randomId !== workunitId) {
      return workunit;
    }
    return {
      ...workunit,
      scope,
      scope_id: scope.id || null,
    };
  };

  return {
    ...state,
    ...(target === 'catalog' && { workunits: state.workunits.map(changeScope) }),
    ...(target === 'selected' && {
      selectedWorkunits: state.selectedWorkunits.map(changeScopeSelected),
    }),
  };
};
export const workunitsHaveSameData = (workunit1: CatalogWorkunit, workunit2: CatalogWorkunit) =>
  workunit1.workunit_id === workunit2.workunit_id &&
  workunit1?.scope_id === workunit2.scope_id &&
  ((workunit1.content === workunit2.content &&
    workunit1.content2 === workunit2.content2 &&
    workunit1.content3 === workunit2.content3 &&
    workunit1.content4 === workunit2.content4 &&
    workunit1.content5 === workunit2.content5) ||
    (!workunit1.content && (!workunit2.content || workunit2.content === '')));

export const addWorkunitToOrder = (state: CatalogToOrderState, action: RootActions): CatalogToOrderState => {
  if (action.type !== CatalogToOrderActions.ADD_WORKUNIT_TO_ORDER) {
    return state;
  }

  const { workunit: orderWorkunitToTransfer } = action.payload;

  const matchingWorkunit = state.selectedWorkunits.find((wu) => workunitsHaveSameData(wu, orderWorkunitToTransfer));

  if (matchingWorkunit) {
    return {
      ...state,
      selectedWorkunits: state.selectedWorkunits.map((wu) => {
        if (workunitsHaveSameData(wu, orderWorkunitToTransfer)) {
          return {
            ...wu,
            workunit_id: orderWorkunitToTransfer.id,
            complexity_id: wu.complexity?.id,
            total: (wu?.total ?? 0) + orderWorkunitToTransfer.months.map((m) => m.quantity).reduce((a, b) => a + b, 0),
            months: wu.months.map((m) => {
              const quantityToAdd = orderWorkunitToTransfer.months.find(
                (month) =>
                  moment(month.date).endOf('month').format('MM-YYYY').toString() ===
                  moment(m.date).endOf('month').format('MM-YYYY').toString()
              )?.quantity;
              if (quantityToAdd) {
                return {
                  ...m,
                  quantity: m.quantity + (quantityToAdd ?? 0),
                };
              }
              return m;
            }),
          };
        }
        return wu;
      }),
    };
  }
  return {
    ...state,
    selectedWorkunits: [
      ...state.selectedWorkunits,
      {
        ...orderWorkunitToTransfer,
        workunit_id: orderWorkunitToTransfer.id,
        complexity_id: orderWorkunitToTransfer?.complexity?.id,
        total: orderWorkunitToTransfer.months.map((m) => m.quantity).reduce((a, b) => a + b, 0),
        randomId: generateRandomId(),
      },
    ],
  };
};

export const clearWorkunitData = (state: CatalogToOrderState, action: RootActions): CatalogToOrderState => {
  if (action.type !== CatalogToOrderActions.RESET_ADDED_WORKUNIT) {
    return state;
  }

  const clear = (workunit: CatalogWorkunit) => {
    if (workunit.id !== action.payload.workunitId) {
      return workunit;
    }

    return {
      ...workunit,
      scope: null,
      scope_id: null,
      total: 0,
      content: '',
      content2: '',
      content3: '',
      content4: '',
      content5: '',
      months: state.months.map((m: Month) => ({
        date: m.date,
        quantity: 0,
        price: 0,
        totalprice: 0,
        fte: 0,
        dailyrate: 0,
        workload: 0,
      })),
    };
  };

  return {
    ...state,
    workunits: state.workunits.map(clear),
  };
};

type ChangeQuantityMode = 'catalog' | 'selected';

export const changeWorkunitMonthQuantity = (
  state: CatalogToOrderState,
  action: RootActions,
  mode: ChangeQuantityMode
): CatalogToOrderState => {
  if (
    action.type !== CatalogToOrderActions.CHANGE_MONTH_QUANTITY_WORKUNIT &&
    action.type !== CatalogToOrderActions.CHANGE_MONTH_QUANTITY_ORDER_WORKUNIT
  ) {
    return state;
  }

  const updateMonthQuantity = (workunit: CatalogWorkunit | SelectedWorkunit): CatalogWorkunit | SelectedWorkunit => {
    if (
      workunit.id !== action.payload.workunitId &&
      (workunit as SelectedWorkunit).randomId !== action.payload.workunitId
    ) {
      return workunit;
    }
    const months = workunit.months.map((m) => {
      if (moment(m.date).format('YYYY-MM') !== moment(action.payload.monthDate).format('YYYY-MM')) {
        return m;
      }

      return {
        ...m,
        quantity: action.payload.quantity,
        qte: action.payload.quantity,
      };
    });

    const total = months.map((m) => m.quantity).reduce((a, b) => a + b, 0);

    return {
      ...workunit,
      months,
      total: total < 0 ? 0 : total,
    };
  };
  return {
    ...state,
    ...(mode === 'catalog' && { workunits: state.workunits.map(updateMonthQuantity) }),
    ...(mode === 'selected' && {
      selectedWorkunits: state.selectedWorkunits.map(updateMonthQuantity) as SelectedWorkunit[],
    }),
  };
};

export const updateWorkunitData = (state: CatalogToOrderState, action: RootActions): CatalogToOrderState => {
  if (action.type !== CatalogToOrderActions.UPDATE_WORKUNIT) {
    return state;
  }

  const { workunit } = action.payload;

  const update = (wu: CatalogWorkunit) => {
    if (workunit.id !== wu.id) {
      return wu;
    }

    return { ...wu, ...workunit };
  };

  return {
    ...state,
    workunits: state.workunits.map(update),
  };
};

export const updateSelectedWorkunitData = (state: CatalogToOrderState, action: RootActions): CatalogToOrderState => {
  if (action.type !== CatalogToOrderActions.UPDATE_SELECTED_WORKUNIT) {
    return state;
  }

  const { workunit } = action.payload;

  const update = (wu: SelectedWorkunit) => {
    if (workunit.randomId !== wu.randomId) {
      return wu;
    }

    return { ...wu, ...workunit };
  };

  return {
    ...state,
    selectedWorkunits: state.selectedWorkunits.map(update),
  };
};

export const updateContentNumber = (
  state: CatalogToOrderState,
  action: RootActions,
  increase: boolean
): CatalogToOrderState => {
  if (
    action.type !== CatalogToOrderActions.INCREASE_SELECTED_CONTENTS_NUMBER &&
    action.type !== CatalogToOrderActions.DECREASE_SELECTED_CONTENTS_NUMBER &&
    action.type !== CatalogToOrderActions.INCREASE_CATALOG_CONTENTS_NUMBER &&
    action.type !== CatalogToOrderActions.DECREASE_CATALOG_CONTENTS_NUMBER
  ) {
    return state;
  }

  const { target } = action.payload;

  switch (target) {
    case 'catalog':
      return {
        ...state,
        catalogHandler: {
          ...state.catalogHandler,
          contentsNumber:
            state.catalogHandler.contentsNumber && state.catalogHandler.contentsNumber + (increase ? 1 : -1),
        },
      };
    case 'selected':
      return {
        ...state,
        orderHandler: {
          ...state.orderHandler,
          contentsNumber: state.orderHandler.contentsNumber && state.orderHandler.contentsNumber + (increase ? 1 : -1),
        },
      };
    default:
      return state;
  }
};

export const updateSliceMonths = (
  state: CatalogToOrderState,
  action: RootActions,
  increase: boolean
): CatalogToOrderState => {
  if (
    action.type !== CatalogToOrderActions.INCREASE_MONTH_SLICE_SUMMARY &&
    action.type !== CatalogToOrderActions.DECREASE_MONTH_SLICE_SUMMARY &&
    action.type !== CatalogToOrderActions.INCREASE_MONTH_SLICE_CATALOG &&
    action.type !== CatalogToOrderActions.DECREASE_MONTH_SLICE_CATALOG &&
    action.type !== CatalogToOrderActions.INCREASE_MONTH_SLICE_SELECTED &&
    action.type !== CatalogToOrderActions.DECREASE_MONTH_SLICE_SELECTED
  )
    return state;
  const { target } = action.payload;

  switch (target) {
    case 'catalog':
      return {
        ...state,
        catalogHandler: {
          ...state.catalogHandler,
          monthSlice: {
            startSlice: state.catalogHandler.monthSlice.startSlice + (increase ? 1 : -1),
            endSlice: state.catalogHandler.monthSlice.endSlice + (increase ? 1 : -1),
          },
        },
      };
    case 'selected':
      return {
        ...state,
        orderHandler: {
          ...state.orderHandler,
          monthSlice: {
            startSlice: state.orderHandler.monthSlice.startSlice + (increase ? 1 : -1),
            endSlice: state.orderHandler.monthSlice.endSlice + (increase ? 1 : -1),
          },
        },
      };
    case 'summary':
      return {
        ...state,
        summaryHandler: {
          ...state.summaryHandler,
          monthSlice: {
            startSlice: state.summaryHandler.monthSlice.startSlice + (increase ? 1 : -1),
            endSlice: state.summaryHandler.monthSlice.endSlice + (increase ? 1 : -1),
          },
        },
      };
    case 'base':
    default:
      return state;
  }
};

export const updateSearchParams = (state: CatalogToOrderState, action: RootActions): CatalogToOrderState => {
  if (action.type !== CatalogToOrderActions.UPDATE_SEARCH_PARAMS) {
    return state;
  }

  const { target, newSearchValue } = action.payload;

  switch (target) {
    case 'catalog':
      return {
        ...state,
        catalogHandler: {
          ...state.catalogHandler,
          searchParams: { ...state.catalogHandler.searchParams, ...newSearchValue },
        },
      };
    case 'selected':
      return {
        ...state,
        orderHandler: {
          ...state.orderHandler,
          searchParams: { ...state.orderHandler.searchParams, ...newSearchValue },
        },
      };
    default:
      return state;
  }
};
