import { AlertColor } from '@mui/material';

export interface SnackbarState {
  message: string;
  open: boolean;
  severity: AlertColor;
  duration?: number;
  undo?: boolean;
  undoCallback?: () => void | Promise<void>;
}

export const snackbarState: SnackbarState = {
  message: '',
  open: false,
  severity: 'success',
  duration: undefined,
  undo: undefined,
  undoCallback: undefined,
};
