import { Box } from '@mui/system';
import InputDate from 'components/InputDate';
import AutocompleteCustom, { PropertyNameOptions, SelectTypeOptions } from 'components/Select';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import Order from 'types/entities/Order';
import Scope from 'types/entities/Scope';

interface OrderKpiFiltersProps {
  order: Order;
  scopes: Scope[];
  setFilterFromDate: (fromDate: string) => void;
  setFilterToDate: (toDate: string) => void;
  updateSelectedScope: (scopeId: number) => void;
}

const OrderKpiFilters = ({
  order,
  scopes,
  setFilterFromDate,
  setFilterToDate,
  updateSelectedScope,
}: OrderKpiFiltersProps) => {
  const [selectedScope, setSelectedScope] = useState<Scope>({ id: 0, name: 'All' } as unknown as Scope);
  const [fromDate, setFromDate] = useState<string>(order?.start_date);
  const [toDate, setToDate] = useState<string>(order?.end_date);

  useEffect(() => {
    updateSelectedScope(selectedScope.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScope, fromDate, toDate]);

  const handleSelectOptions = (e: SelectTypeOptions | null, property: PropertyNameOptions) => {
    if (e) setSelectedScope(e as Scope);
    else setSelectedScope({ id: 0, name: 'All' } as unknown as Scope);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#EBF1FC',
        p: '20px 25px',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box sx={{ mr: { md: 2 }, mb: { xs: 2 }, minWidth: { lg: '200px' } }}>
        <AutocompleteCustom
          options={scopes}
          selectedOption={selectedScope}
          defaultOption={{ id: 0, name: 'All' } as unknown as Scope}
          optionsLabels="name"
          label="Scope"
          handleSelectOptions={handleSelectOptions}
          propertyName="scope"
          variant="filled"
        />
      </Box>
      <Box sx={{ mr: { md: 2 }, mb: { xs: 2 } }}>
        <InputDate
          label="Date from"
          name="date_from_order_workunit"
          id="date_from_order_workunit"
          placeholder=""
          reset={moment(order?.start_date)}
          value={fromDate}
          onchange={(name: string, date: Moment | null): void | null => {
            if (date) {
              setFromDate(date.format('YYYY-MM-DD'));
              setFilterFromDate(date.format('YYYY-MM-DD'));
            } else setFromDate(moment().format('YYYY-MM-DD'));
          }}
          variant="filled"
        />
      </Box>
      <Box sx={{ mr: { md: 2 }, mb: { xs: 2 } }}>
        <InputDate
          label="Date to"
          id="date_to_order_workunit"
          name="date_to_order_workunit"
          placeholder=""
          reset={moment(order?.end_date)}
          onchange={(name: string, date: Moment | null): void | null => {
            if (date) {
              setToDate(date.format('YYYY-MM-DD'));
              setFilterToDate(date.format('YYYY-MM-DD'));
            } else setToDate(moment().format('YYYY-MM-DD'));
          }}
          value={toDate}
          variant="filled"
        />
      </Box>
    </Box>
  );
};

export default OrderKpiFilters;
