import { createAction } from '@reduxjs/toolkit';
import { formatOrderWorkunitForPdf } from 'features/Sheet/helper';
import { initialValue } from '../../intialValue';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import DeliverableSheetWorkunit from 'types/entities/DeliverableSheetWorkunit';
import Order from 'types/entities/Order';
import DeliverableSheetWorkunitDetailsList from 'types/models/DeliverableSheetWorkunitDetailsList';
import DeliverableSheetWorkunitFormatted from 'types/models/DeliverableSheetWorkunitFormatted';
import { PossibleSheetActions, SheetActions } from '../../SheetContext.helper';

export const changePageSize = createAction(SheetActions.ChangePageSize, (newPageSize: number) => ({
  payload: newPageSize,
}));
export const changePage = createAction(SheetActions.ChangePage, (newPage: number) => ({
  payload: newPage,
}));

export const injectSheetAction = createAction(
  SheetActions.InjectDeliverableSheet,
  (deliverableSheet: DeliverableSheet) => ({
    payload: deliverableSheet,
  })
);
export const injectDeliverablesAction = createAction(
  SheetActions.InjectDeliverable,
  (deliverablesOptions: {
    deliverablesSheetWorkunit: DeliverableSheetWorkunit[];
    totalItems: number;
    totalPages: number;
    currentPage: number;
  }) => ({
    payload: {
      deliverables: deliverablesOptions.deliverablesSheetWorkunit,
      totalItems: deliverablesOptions.totalItems,
      totalPages: deliverablesOptions.totalPages,
      currentPage: deliverablesOptions.currentPage,
    },
  })
);

export const injectOrderAction = createAction(SheetActions.InjectOrder, (order: Order) => ({
  payload: order,
}));
export const setLoadingDeliverableAction = createAction(SheetActions.SetDeliverableLoading, (loading: boolean) => ({
  payload: { loading },
}));
export const ResetDeliverableSheetContext = createAction(SheetActions.Reset, () => ({
  payload: null,
}));

export const injectDeliveredListAction = createAction(
  SheetActions.InjectDeliveredList,
  (deliveredList: DeliverableSheetWorkunitFormatted[]) => ({
    payload: deliveredList,
  })
);

export const injectDeviationsListAction = createAction(
  SheetActions.InjectDeviationsList,
  (deviationsList: DeliverableSheetWorkunit[]) => ({
    payload: deviationsList,
  })
);

export const injectDetailsListAction = createAction(
  SheetActions.InjectDetailsList,
  (detailsList: DeliverableSheetWorkunitDetailsList) => ({
    payload: detailsList,
  })
);

export const setTotalWorkunitNumberAction = createAction(
  SheetActions.SetTotalWorkunitNumber,
  (totalWorkunitnumber: number) => ({
    payload: totalWorkunitnumber,
  })
);

export default (state = initialValue, action: PossibleSheetActions) => {
  switch (action.type) {
    case SheetActions.InjectDeliverableSheet:
      return {
        ...state,
        currentDeliverableSheet: {
          ...action.payload,
          ...formatOrderWorkunitForPdf(action.payload),
        },
        deliverables: action.payload['deliverable-sheet-workunits'],
      };

    case SheetActions.SetDeliverableLoading:
      return {
        ...state,
        isDeliverablesLoading: action.payload,
      };
    case SheetActions.ChangePageSize:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SheetActions.ChangePage:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SheetActions.LoadDeliverable:
      return {
        ...state,
        deliverables: [],
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
      };
    case SheetActions.InjectOrder:
      return {
        ...state,
        order: action.payload,
      };

    case SheetActions.InjectDeliveredList:
      return {
        ...state,
        deliveredList: action.payload,
      };
    case SheetActions.InjectDeviationsList:
      return {
        ...state,
        deviationList: action.payload,
      };
    case SheetActions.InjectDetailsList:
      return {
        ...state,
        detailsDeliverableList: action.payload.detailsList,
      };
    case SheetActions.SetTotalWorkunitNumber:
      return {
        ...state,
        totalWorkunitsNumber: action.payload,
      };
    case SheetActions.Reset:
      return initialValue;
    default:
      return state;
  }
};
