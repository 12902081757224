import { Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CommentStepProps {
  comment: string | null;
  setComment: (comment: string) => void;
}

const CommentStep = ({ comment, setComment }: CommentStepProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <Stack sx={{ mt: 2 }}>
        <Typography fontWeight={500} sx={{ mb: 2 }}>
          {t('Comment the service performance')}:
        </Typography>
        <TextField
          name="rating_comment"
          id="rating_comment"
          rows={11}
          className="active-blue no-border deliverables-input "
          value={comment}
          onChange={(e) => setComment(e.currentTarget.value)}
          placeholder={t('Your text here')}
          multiline
          fullWidth
        />
        <Typography sx={{ color: '#b2bcd5' }}>{`${t('Number of characters')}: ${
          comment ? comment.length : 0
        }/700`}</Typography>
      </Stack>
    </div>
  );
};

export default CommentStep;
