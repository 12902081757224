import { Grid } from '@mui/material';
import DeliverableSheetApiService from 'api/DeliverableSheetApiService';
import { Loader } from 'components';
import React, { useEffect, useState } from 'react';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import Order from 'types/entities/Order';
import Dialogs from '../Components/Dialogs/Dialogs';
import { setShouldRefreshAction } from '../SheetContext/_reducers/ShouldRefresh/ShouldRefresh';
import { useSheetContext } from '../SheetContext/SheetContext';
import './SheetPreview.scss';
import SheetPreviewActionButtons from './SheetPreviewActionButtons/SheetPreviewActionButtons';

type SheetPreviewProps = {
  orderId: Order['id'];
  deliverableSheetId: DeliverableSheet['id'];
  report: DeliverableSheet | null;
};

const SheetPreview = ({ orderId, deliverableSheetId, report }: SheetPreviewProps) => {
  const { state, dispatch } = useSheetContext();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDeliverableSheetPdf = async () => {
      try {
        const pdf: string = await DeliverableSheetApiService.fetchDeliverableSheetPdf(deliverableSheetId);
        if (pdf) {
          setPdfUrl(pdf.replace('"', ''));
          dispatch(setShouldRefreshAction(false));
        }
      } catch {
        setPdfUrl(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeliverableSheetPdf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldRefresh]);

  return (
    <Grid container flexDirection="column">
      <Grid item flexDirection="row">
        {report && <SheetPreviewActionButtons report={report} pdfUrl={pdfUrl} />}
      </Grid>
      {pdfUrl ? (
        <embed
          type="application/pdf"
          className="iframe-viewer"
          src={`${pdfUrl}`}
          title={state.pdfFileName}
          aria-label="test"
        />
      ) : (
        <Loader />
      )}
      <Dialogs />
    </Grid>
  );
};

export default SheetPreview;
