import { useAppState } from './Provider';
import { TypedUseSelectorHook } from './redux';

export const useSelector: TypedUseSelectorHook = (cb) => {
  const { state } = useAppState();

  return cb(state);
};

export const useDispatch = () => {
  const { dispatch } = useAppState();

  return dispatch;
};
