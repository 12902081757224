import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material/Button';
import { Typography, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Props {
  content: string;
  onclick: () => void;
}

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  backgroundColor: 'transparent',
  borderWidth: 3,
  borderStyle: 'dashed',
  borderRadius: 15,
  width: '100%',
  minHeight: 250,
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: '0px 3px 16px #00000019',
  },
}));

const DashedButton: React.FC<Props> = ({ content, onclick }) => (
  <CustomButton
    onClick={onclick}
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    color="primary"
    data-testid="add_item_button"
  >
    <AddCircleOutlineIcon fontSize="large" sx={{ mb: 2, fontSize: '3rem' }} />
    <Typography sx={{ fontWeight: 600 }} variant="h5" component="div">
      {content}
    </Typography>
  </CustomButton>
);

export default DashedButton;
