import { Remove, SmsOutlined } from '@mui/icons-material';
import SmsIcon from '@mui/icons-material/Sms';
import { IconButton } from '@mui/material';
import { CommentCheckIcon, CommentIcon, CommentMoreIcon } from 'components/CustomIcons';
import useUserRoles from 'hooks/useUserRoles';
import React from 'react';
import { openDrawerAction } from 'store/actions/drawerActions';
import { useAppState } from 'store/Provider';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { DeliverableTabOption } from 'types/enums/DeliverableTabOption';
import EmptyCell from '../EmptyCell/EmptyCell';

const CommentsCell = React.memo((row: OrderWorkunit) => {
  const userRoles = useUserRoles();

  const hasComments = () => {
    if (!userRoles.isCustomer) {
      return row['mission-comments'] && row['mission-comments'].length > 0;
    }
    return (
      row['mission-comments'] &&
      row['mission-comments'].length > 0 &&
      row['mission-comments'].some((comment) => !comment.is_private)
    );
  };

  const { dispatch } = useAppState();
  const onClick = () => {
    dispatch(
      openDrawerAction({ name: 'deliverableDetails', data: { id: row.id, tab: DeliverableTabOption.FEEDBACK } })
    );
  };

  return (
    <>
      <IconButton onClick={onClick} size="large" sx={{ color: 'text.primary' }}>
        {hasComments() ? <CommentCheckIcon color="success" /> : <CommentMoreIcon sx={{ color: 'text.primary' }} />}
      </IconButton>
    </>
  );
});

export default CommentsCell;
