import { Add, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, InputLabel, Typography } from '@mui/material';
import { Loader } from 'components';
import SelectUser from 'components/SelectUser';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import User from 'types/entities/User';
// import './OrderWorkunitAffectationEdit.scss';

type Props = {
  consultants?: User[];
  customers?: User[];
  deliveryManagers?: User[];
  validateSelectedOptions: (multiSelect: Partial<OrderWorkunit>) => void;
  isLoading: boolean;
  dispatchOpen: React.DispatchWithoutAction;
};

const OrderWorkunitAffectationEdit: React.FC<Props> = ({
  consultants,
  customers,
  deliveryManagers,
  validateSelectedOptions,
  isLoading,
  dispatchOpen,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Partial<OrderWorkunit>>({});

  const [t] = useTranslation();
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
        <Typography variant="h2">{t('Edit_Order_Workunit')}</Typography>
        <IconButton sx={{ ml: 'auto' }} color="primary" onClick={dispatchOpen}>
          <Close />
        </IconButton>
      </Box>

      <>
        <Box sx={{ mb: 3 }}>
          {!consultants ? (
            <Loader size={20} />
          ) : (
            <SelectUser
              disabled={isLoading}
              users={consultants}
              selectedUser={selectedOptions.consultant}
              onSelectUser={(user) =>
                setSelectedOptions({
                  ...selectedOptions,
                  consultant_id: user?.id,
                  consultant: user as User | undefined,
                })
              }
              label={t('Consultant')}
            />
          )}
        </Box>

        <Box sx={{ mb: 3 }}>
          {!deliveryManagers ? (
            <Loader size={20} />
          ) : (
            <SelectUser
              disabled={isLoading}
              users={deliveryManagers}
              selectedUser={selectedOptions.consultant}
              onSelectUser={(user) =>
                setSelectedOptions({
                  ...selectedOptions,
                  delivery_manager_ids: user ? [user.id] : [],
                  delivery_manager: user ? [user] : [],
                })
              }
              label={t('delivery_manager')}
            />
          )}
        </Box>

        <Box sx={{ mb: 3 }}>
          {!customers ? (
            <Loader size={20} />
          ) : (
            <SelectUser
              users={customers}
              disabled={isLoading}
              selectedUser={selectedOptions.client}
              onSelectUser={(user) =>
                setSelectedOptions({
                  ...selectedOptions,
                  client_id: user?.id,
                  client: user as User | undefined,
                })
              }
              label={t('Customer Validation')}
            />
          )}
        </Box>
      </>
      <LoadingButton
        variant="contained"
        color="success"
        startIcon={<Add />}
        loading={isLoading}
        onClick={() => validateSelectedOptions(selectedOptions)}
      >
        Submit
      </LoadingButton>
    </>
  );
};

export default OrderWorkunitAffectationEdit;
