import { Button, Stack } from '@mui/material';
import useUserRoles from 'hooks/useUserRoles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { changeReportTabAction } from 'store/actions/deliverableSheetActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { DeliverableReportTab } from 'types/enums/DeliverableReportTab';
import { OrdersTabParentState } from 'types/enums/OrdersTabParentState';

const DeliverableReportTabs = () => {
  const { t } = useTranslation();
  const { dispatch, state } = useAppState();
  const userRoles = useUserRoles();
  const currentReportTab = useSelector((state) => state.deliverableSheet.currentReportTab);
  const handleTabChange = (newValue: DeliverableReportTab) => {
    dispatch(changeReportTabAction(newValue));
  };

  const ordertype = useSelector((state) => state.deliverable.order?.order_type_id);

  return !userRoles.isConsultant ? (
    <Stack direction="row" gap={1} sx={{ marginBottom: 4 }}>
      <Button
        sx={{
          minWidth: 100,
          paddingX: 3,
          paddingY: 1,
          backgroundColor: currentReportTab === DeliverableReportTab.TECHNICAL ? '#0f5ee924' : 'transparent',
          color: currentReportTab === DeliverableReportTab.TECHNICAL ? '#0F5EE9' : '#B2BCD5',
        }}
        onClick={() => handleTabChange(DeliverableReportTab.TECHNICAL)}
      >
        {t('technical')}
      </Button>
      {ordertype !== 2 && (
        <Button
          sx={{
            minWidth: 100,
            paddingX: 3,
            paddingY: 1,
            backgroundColor: currentReportTab === DeliverableReportTab.FINANCIAL ? '#0f5ee924' : 'transparent',
            color: currentReportTab === DeliverableReportTab.FINANCIAL ? '#0F5EE9' : '#B2BCD5',
          }}
          onClick={() => handleTabChange(DeliverableReportTab.FINANCIAL)}
        >
          {t('financial')}
        </Button>
      )}
    </Stack>
  ) : (
    <></>
  );
};

export default DeliverableReportTabs;
