import useUserRoles from 'hooks/useUserRoles';
import { useMemo } from 'react';
import { useSelector } from 'store/hooks';
import { FilterItem, FilterItemOption, FilterParams, FilterParamsManagement } from 'types/models/FilterItem';
import useManagementHelper from '../services/useManagementHelper';

const loadOptions = (filterParams: FilterParams | undefined) => {
  type OptionName =
    | 'scopes'
    | 'consultants'
    | 'clients'
    | 'businessManagers'
    | 'delegues'
    | 'orderReference'
    | 'engagementDates'
    | 'deliveryManager'
    | 'affairName'
    | 'affairOtp';
  const options: Record<OptionName, FilterItemOption[]> = {
    scopes: [],
    consultants: [],
    clients: [],
    businessManagers: [],
    delegues: [],
    orderReference: [],
    engagementDates: [],
    deliveryManager: [],
    affairName: [],
    affairOtp: [],
  };

  function typeGuard(filterParams: FilterParams): filterParams is FilterParamsManagement {
    return !!filterParams && 'business_managers' in filterParams;
  }
  if (typeGuard(filterParams)) {
    if (filterParams.scopes) {
      filterParams.scopes.forEach((scope) => {
        options.scopes.push({ label: scope.scope, value: scope.id, sublabel: `${scope?.order_id?.length || 0} items` });
      });
    }

    if (filterParams.consultants) {
      filterParams.consultants.forEach((consultant) => {
        options.consultants.push({
          label: consultant.consultant,
          value: consultant.id,
          sublabel: `${consultant?.order_id?.length || 0} items`,
        });
      });
    }
    if (filterParams.clients) {
      filterParams.clients.forEach((client) => {
        options.clients.push({
          label: client.client,
          value: client.id,
          sublabel: `${client?.order_id?.length || 0} items`,
        });
      });
    }
    if (filterParams.business_managers) {
      filterParams.business_managers.forEach((bm) => {
        options.businessManagers.push({
          label: bm.bm,
          value: bm.id,
          sublabel: `${bm?.order_id?.length || 0} items`,
        });
      });
    }
    if (filterParams.delegues) {
      filterParams.delegues.forEach((delegue) => {
        options.delegues.push({
          label: delegue.delegue,
          value: delegue.id,
          sublabel: `${delegue?.order_id?.length || 0} items`,
        });
      });
    }
    if (filterParams.delivery_managers) {
      filterParams.delivery_managers.forEach((dm) => {
        options.deliveryManager.push({
          label: dm.accountable,
          value: dm.id,
          sublabel: `${dm?.order_id?.length || 0} items`,
        });
      });
    }
    if (filterParams.affairs) {
      filterParams.affairs.forEach((affair) => {
        options.affairName.push({
          label: affair.name,
          value: affair.id,
          sublabel: `${affair?.order_id?.length || 0} items`,
        });
        options.affairOtp.push({
          label: affair.otp,
          value: affair.id,
          sublabel: `${affair?.order_id?.length || 0} items`,
        });
      });
    }
  }

  return options;
};

export const useManagementFilterItems = () => {
  const filterState = useSelector((state) => state.filter);
  const userRoles = useUserRoles();
  const { isPathForQuotes } = useManagementHelper();

  return useMemo<FilterItem[]>(() => {
    const options = loadOptions(filterState.params);

    return [
      {
        field: 'affairs',
        filterName: 'Engagement',
        inputLabel: 'Select engagement',
        inputType: 'multi_autocomplete',
        options: options.affairName,
        isVisible: options.affairName.length > 0,
        uniq: true,
        multiple: [
          {
            rank: 1,
            inputLabel: 'Name OTP ',
            options: options.affairName,
            field: 'affairs',
            uniq: true,
          },
          {
            rank: 2,
            inputLabel: 'Code OTP',
            options: options.affairOtp,
            field: 'affairs',
            uniq: true,
          },
        ],
      },
      {
        field: 'date',
        filterName: 'Dates',
        inputType: 'date',
        rangeDate: true,
        optionsDisplayDirection: 'column',
        isVisible: true,
        classNameLabel: 'bold-blue',
        updateSolo: true,
      },
      {
        field: 'scopes',
        filterName: 'Scope',
        inputLabel: 'Select scope',
        inputType: 'select',
        options: options.scopes,
        isVisible: options.scopes.length > 0,
      },
      {
        field: 'clients',
        filterName: 'Client',
        inputLabel: 'Select client',
        inputType: 'select',
        options: options.clients,
        isVisible: options.clients.length > 0,
        uniq: true,
      },

      {
        field: 'business_managers',
        filterName: 'Business manager',
        inputLabel: 'Select business manager',
        inputType: 'select',
        options: options.businessManagers,
        isVisible: options.businessManagers.length > 0,
        uniq: true,
      },

      {
        field: 'delivery_managers',
        filterName: 'Delivery manager',
        inputLabel: 'Select delivery manager',
        inputType: 'select',
        options: options.deliveryManager,
        isVisible: options.deliveryManager.length > 0,
        uniq: true,
      },
      {
        field: 'delegues',
        filterName: 'Representative',
        inputLabel: 'Select representative',
        inputType: 'select',
        options: options.delegues,
        isVisible: options.delegues.length > 0,
        uniq: true,
      },
      {
        field: 'consultants',
        filterName: 'Consultant',
        inputLabel: 'Select consultant',
        inputType: 'select',
        options: options.consultants,
        isVisible:
          options.consultants.length > 0 && !isPathForQuotes() && !userRoles.isClient && !userRoles.isConsultant,
        uniq: true,
      },
    ];
  }, [filterState.params, userRoles]);
};
