import { Box, TextField } from '@mui/material';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'store/hooks';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import { SelectedWorkunit } from 'types/entities/SelectedWorkunit';
import useOrderWorkunitApi from '../../../services/useOrderWorkunitApi';
import './OrderAffectationScopeCell.scss';

type OrderAffectationScopeCellProps = {
  row: OrderWorkunit | CatalogWorkunit;
  disabled?: boolean;
  cellType: 'catalog' | 'selected';
};

const OrderAffectationScopeCell = (props: OrderAffectationScopeCellProps) => {
  const { row, disabled = false, cellType } = props;
  const [inputValue, setInputValue] = useState<string>(row.scope?.name ?? '');

  const state = useSelector((state) => state.catalogToOrder);

  const { changeWorkunitsScope, addScope } = useOrderWorkunitApi();

  const handleInputChange = (value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'clear' && value === '') {
      setInputValue('');
      changeWorkunitsScope(null, row.id, cellType, true);
    }
    setInputValue(value);
  };

  const onSelectScope = (scope: Scope, rowId: number, reason?: string) => {
    if (scope) {
      changeWorkunitsScope(scope, rowId, cellType);
      setInputValue(scope.name);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        options={state.scopes}
        disabled={disabled}
        autoHighlight
        onKeyDown={(event) => event.stopPropagation()}
        getOptionLabel={(option) => {
          if (option.name.includes('Add scope')) return option.name.replace('Add scope', '');
          return option.name;
        }}
        isOptionEqualToValue={(option: any) => option.id === row.scope_id}
        renderOption={(boxprops, option, { selected }) => (
          <Box component="li" {...{ ...boxprops }}>
            {option.name}
          </Box>
        )}
        filterOptions={(options, params) => {
          const filtered: Scope[] = options.filter((option) =>
            option.name?.toLocaleLowerCase().includes(params.inputValue?.toLocaleLowerCase())
          );
          if (filtered.every((scop) => scop.name.toLocaleUpperCase() !== inputValue.toLocaleUpperCase())) {
            filtered.push({ name: `Add scope ${inputValue}` } as unknown as Scope);
          }
          const filters = filtered.filter((scop) => scop.name !== 'not_attribued');
          return filters.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue, reason) => {
          handleInputChange(newInputValue, reason);
        }}
        value={row.scope}
        defaultValue={row.scope}
        noOptionsText={
          inputValue.length > 0 ||
          state.scopes.every(
            (scop) =>
              scop.name.toLocaleUpperCase() !== inputValue.toLocaleUpperCase() &&
              !(`Add scope ${scop.name}` === `Add scope ${inputValue}`)
          ) ? (
            <button className="no-style-button" type="button">
              {`Add scope ${inputValue}`}
            </button>
          ) : (
            'No options'
          )
        }
        onChange={(e, value, reason) => handleSelectScope(onSelectScope, value, row, reason, addScope)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            fullWidth
            inputProps={{
              placeholder: 'Scope',
              autoComplete: '',
              ...params.inputProps,
            }}
            sx={{ '& .MuiInputBase-input': { fontSize: '.9rem' } }}
          />
        )}
      />
    </Box>
  );
};

export default (props: OrderAffectationScopeCellProps) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => <OrderAffectationScopeCell {...props} />, [props.row?.scope]);

async function handleSelectScope(
  onSelectScope: (scope: Scope, rowId: number, reason?: string) => void,
  value: Scope | null,
  row: OrderWorkunit | CatalogWorkunit | SelectedWorkunit,
  reason: string,
  addScope: (name: string) => Promise<void | Scope>
): Promise<void> {
  const isCustomWorkunit = (type: OrderWorkunit | CatalogWorkunit | SelectedWorkunit): type is SelectedWorkunit =>
    'randomId' in type;

  const rowId = isCustomWorkunit(row) ? row.randomId ?? row.id : row.id;
  const newValue = value?.name?.startsWith('Add scope ') ? await addScope(value.name.replace('Add scope ', '')) : value;

  return onSelectScope(newValue as Scope, rowId, reason);
}
