import { AddCircle, Close, Delete, Save } from '@mui/icons-material';
import { Box, Button, Drawer, IconButton, InputLabel, TextField, Tooltip, Typography } from '@mui/material';
import CatalogApiService from 'api/CatalogApiService';
import { Loader } from 'components';
import useUserRoles from 'hooks/useUserRoles';
import { differenceWith } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadCatalogComplexitiesAction } from 'store/actions/appActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import CatalogComplexity from 'types/entities/CatalogComplexity';
import './EditComplexity.scss';

type EditComplexityProps = {
  open: boolean;
  dispatchOpen: React.DispatchWithoutAction;
  isComplexityLoading: boolean;
};
const EditComplexity: React.FC<EditComplexityProps> = ({
  open,
  dispatchOpen,
  isComplexityLoading,
}: EditComplexityProps) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const [catalogComplexities, setCatalogComplexities] = useState<CatalogComplexity[]>([]);

  function setComplexityValue(complexityIndex: number, e: React.ChangeEvent<HTMLInputElement>) {
    if (catalogComplexities) {
      const complexitiesValues = catalogComplexities?.map((complexity, index) => {
        if (complexityIndex !== index) return complexity;
        return { ...complexity, name: e.target.value };
      });
      setCatalogComplexities(complexitiesValues);
    }
  }

  useEffect(() => {
    setComplexityLoading(isComplexityLoading);
  }, [isComplexityLoading]);

  useEffect(() => {
    setCatalogComplexities(appState.catalogComplexities);
  }, [appState.catalogComplexities]);

  const handleAddComplexity = () => {
    const newCatalogComplexities = catalogComplexities
      ? [
          ...catalogComplexities,
          { name: 'empty', catalog_id: appState.catalog?.id, is_archived: false, is_removable: true },
        ]
      : [{ name: 'empty', catalog_id: appState.catalog?.id, is_archived: false, is_removable: true }];
    setCatalogComplexities(newCatalogComplexities);
  };
  const [complexityLoading, setComplexityLoading] = useState<boolean>(false);
  const [t] = useTranslation();

  const deleteComplexity = async (complexityIndex: number) => {
    const complexities = [...catalogComplexities];
    complexities.splice(complexityIndex, 1);
    setCatalogComplexities(complexities);
  };

  const updateComplexity = async () => {
    setComplexityLoading(true);
    if (catalogComplexities) {
      const deletedCatalogComplexities = differenceWith(
        appState.catalogComplexities,
        catalogComplexities,
        (a, b) => a.id === b.id
      );
      const deleteComplexityPromises = deletedCatalogComplexities.map((complexity) => {
        if (appState.catalog && complexity.id) {
          return CatalogApiService.deleteComplexity(appState.catalog.id, complexity.id);
        }
        return null;
      });
      const complexityPromises = catalogComplexities.map((complexity) => {
        if (appState.catalog) {
          if (complexity.id) {
            return CatalogApiService.updateComplexity(appState.catalog.id, complexity.id, complexity);
          }
          return CatalogApiService.createComplexity(appState.catalog.id, complexity);
        }
        return null;
      });
      await Promise.all(deleteComplexityPromises);
      const complexities = await Promise.all(complexityPromises);
      dispatch(loadCatalogComplexitiesAction(complexities as CatalogComplexity[]));
      setComplexityLoading(false);
      dispatchOpen();
    }
  };

  const onClose = () => {
    setCatalogComplexities(appState.catalogComplexities);
    dispatchOpen();
  };

  const usedComplexityText = t('used_complexity');

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: {
            xs: '100%',
            lg: 450,
          },
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
        <Typography variant="h2">Edit complexity</Typography>
        <IconButton sx={{ ml: 'auto' }} color="primary" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      {!complexityLoading ? (
        <>
          {catalogComplexities &&
            catalogComplexities.length &&
            catalogComplexities
              .filter((complexity) => complexity && complexity.is_archived === false)
              .map(
                (complexity, index) =>
                  !complexity.is_archived && (
                    <Box sx={{ mb: 3 }}>
                      <InputLabel htmlFor={`editfield_catalog_name_complexity_${(complexity?.id as number) + 1}`}>
                        Complexity {index + 1}
                      </InputLabel>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          id={`editfield_catalog_name_complexity_${(complexity?.id as number) + 1}`}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComplexityValue(index, e)}
                          value={complexity.name}
                          fullWidth
                          disabled={!userRoles.isAdmin}
                          sx={{ mr: 1 }}
                        />
                        {!userRoles.isManager &&
                          (complexity.is_removable ? (
                            <IconButton onClick={() => deleteComplexity(index)} size="small" color="error">
                              <Delete />
                            </IconButton>
                          ) : (
                            <Tooltip title={usedComplexityText} placement="top">
                              <span>
                                <IconButton size="small" color="error" disabled>
                                  <Delete />
                                </IconButton>
                              </span>
                            </Tooltip>
                          ))}
                      </Box>
                    </Box>
                  )
              )}
          {!userRoles.isManager && (
            <Button
              onClick={() => {
                handleAddComplexity();
              }}
              variant="outlined"
              sx={{ mb: 5 }}
              startIcon={<AddCircle />}
            >
              Add a complexity
            </Button>
          )}
          {!userRoles.isManager && (
            <Button
              className="submit-button add-button"
              onClick={() => {
                updateComplexity();
              }}
              startIcon={<Save />}
              variant="contained"
            >
              {t('Save')}
            </Button>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Drawer>
  );
};
export default EditComplexity;
