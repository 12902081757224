import { createTheme } from '@mui/material';
// noinspection ES6UnusedImports
import {} from '@mui/x-data-grid-pro/themeAugmentation';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    closed: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    closed: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    closed: true;
  }
}

export const theme = createTheme({
  palette: {
    background: {
      default: '#F2F6FD',
    },
    text: {
      primary: '#043474',
      secondary: '#646464',
    },
    primary: {
      light: '#F2F6FD',
      main: '#0F5EE9',
    },
    secondary: {
      main: '#646464',
    },
    success: {
      main: '#20CC8D',
    },
    error: {
      main: '#CE3030',
    },
    warning: {
      main: '#FFAB38',
    },
    neutral: {
      light: '#e1e5ef',
      main: '#b2bcd5',
      dark: '#838ca3',
    },
    closed: {
      main: '#A4C7F6',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'warning' && {
              color: '#FFFFFF',
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'success' && {
              color: '#FFFFFF',
            }),
          borderRadius: '10px',
          textTransform: 'initial',
          fontSize: '1rem',
          padding: '6px 20px',
          '&.MuiLoadingButton-root': {
            padding: '6px 20px',
            fontSize: '1rem !important',
          },
        }),
        sizeSmall: {
          fontSize: '.9rem',
          padding: '4px 10px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
        notchedOutline: {
          borderColor: '#B2BCD5',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            padding: '0',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02);',
          },
          '&::before, &::after': {
            content: 'none',
          },
        },
        input: {
          padding: '16.5px 14px',
          fontWeight: '500',
          '&::placeholder': {
            fontWeight: '500',
          },
          '&.MuiAutocomplete-input': {
            padding: '16.5px 14px !important',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          borderRadius: '10px',
          padding: '4px 16px',
          '&::before, &::after': {
            content: 'none',
          },
        },
        input: {
          fontWeight: '600',
          paddingTop: 4,
          '&::placeholder': {
            fontWeight: '600',
          },
          '&:placeholder-shown': {
            textOverflow: 'ellipsis',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          justifyContent: 'start',
          color: '#b2bcd5',
          textTransform: 'initial',
          fontSize: '1.125rem',
          minHeight: '48px',
          padding: '12px 20px',
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            color: '#0F5EE9',
            opacity: '.9',
            pointerEvents: 'initial',
          },
          '&.Mui-selected .MuiTab-iconWrapper path': {
            fill: '#0F5EE9',
          },
          '& .MuiTab-iconWrapper path': {
            fill: '#b2bcd5',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        expandIconWrapper: {
          backgroundColor: '#043474',
          borderRadius: '5px',
          color: '#FFFFFF',
          width: '30px',
          height: '30px',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '8px 0',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 16px #00000019',
          borderRadius: '15px',
          overflow: 'hidden',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          minWidth: 0,
        },
        title: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '25px',
          '&:last-child': {
            paddingBottom: '25px',
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '25px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '20px',
          boxShadow: '0px 3px 12px #B2BCD571',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '24px 0',
          margin: '0 30px',
          fontSize: '1.75rem',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: '#b2bcd5', // TODO : improve
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '24px 30px !important',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px 30px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          padding: '28px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#043474',
          fontWeight: 500,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&.wrapped': {
            border: '1px solid #B2BCD5',
            borderRadius: '5px',
            padding: '8px 12px',
            marginRight: '5px',
            marginBottom: '5px',
            marginLeft: 0,
            '&.checked': {
              backgroundColor: '#C6DDFE',
              borderColor: '#0F5EE9',
              color: '#0F5EE9',
            },
            '& .MuiRadio-root': {
              marginRight: '5px',
              padding: 0,
            },
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
          lineHeight: 'normal',
          fontWeight: 600,
          width: '45px',
          height: '45px',
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          borderColor: '#FFFFFF',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 3px 12px #00000029 !important',
          borderRadius: '10px !important',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: '0 none',
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            lineHeight: 'initial !important',
            width: '100%',
            '& label': {
              display: 'block',
              fontWeight: 500,
            },
            '& input.custom-input': {
              border: '1px solid #B2BCD5',
              width: '100%',
              borderRadius: '5px',
              padding: '4px',
              fontFamily: 'Poppins',
            },
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#FFFFFF',
            },
          },
        },
        virtualScroller: {
          backgroundColor: '#FFFFFF',
        },
        toolbarContainer: {
          marginBottom: '5px',
        },
        cell: {
          border: '0 none',
          '&:focus': {
            outline: 'none',
          },
        },
        row: {
          '&.Mui-odd': {
            backgroundColor: '#F8F9FD',
          },
          '&:hover': {
            backgroundColor: '#f0f3fb',
          },
        },
        columnHeaders: {
          border: '0 none',
          backgroundColor: '#F8F9FD',
        },
        columnHeader: {
          '&:focus-within': {
            outline: 'none',
          },
          '&.MuiDataGrid-columnHeader--moving': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#B2BCD5',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {},
        ul: {
          justifyContent: 'flex-end',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          borderRadius: '6px',
          color: '#b2bcd5',
          fontWeight: 500,
          width: '40px',
          height: '40px',
          '&.Mui-selected': {
            backgroundColor: '#0F5EE9',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#043474',
              color: '#FFFFFF',
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontSize: '2.5rem',
      fontWeight: '600',
    },
    h2: {
      fontSize: '1.625rem',
      fontWeight: '600',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '600',
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: '600',
    },
    h5: {
      fontSize: '1.1rem',
      fontWeight: '600',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '1.2',
    },
  },
});
