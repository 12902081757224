import { DialogContent, DialogContentText, Typography } from '@mui/material';
import ModalCustom from 'components/Modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useAppState } from 'store/Provider';
import useManagementService from '../services/useManagementService';

type ManagementDuplicationDialogProps = {
  isFromTM: boolean;
};

const ManagementDuplicationDialog: React.FC<ManagementDuplicationDialogProps> = ({ isFromTM }) => {
  const { state, dispatch } = useAppState();
  const { dialogs } = state;
  const isOpen = dialogs.managementDuplicationDialog;
  const { activeOrderId } = state.management;
  const { duplicateOrder } = useManagementService();
  const [t] = useTranslation();
  const message = isFromTM
    ? t('Do you want to duplicate this T&M Order ? When duplicated, the copy goes back to drafts')
    : t('Do you want to duplicate this quote ? When duplicated, the copy goes back to quotes');

  const dialogBody = (
    <DialogContent>
      <DialogContentText>
        <Typography>{message}</Typography>
      </DialogContentText>
    </DialogContent>
  );
  return (
    <ModalCustom
      open={isOpen}
      modalName="managementDuplicationDialog"
      onClose={() => dispatch(closeDialogAction('managementDuplicationDialog'))}
      onConfirm={() => duplicateOrder(activeOrderId)}
      titleText="Duplicate quote"
      confirmText="Duplicate"
      cancelText="Don't copy"
      body={dialogBody}
      size="md"
      confirmLoad
    />
  );
};

export default ManagementDuplicationDialog;
