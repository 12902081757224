import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Card, CardContent, IconButton, Typography, Grid, Box, Avatar } from '@mui/material';
import { purple, red } from '@mui/material/colors';
import OrderApiService from 'api/OrderApiService';
import { Loader } from 'components';
import { CheckIcon, FlagIcon, DeliverIcon } from 'components/CustomIcons';
import Kpi from 'components/Kpi/Kpi';
import useApi from 'hooks/useApi';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DashboardKpiSliders = () => {
  const [t] = useTranslation();
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [monthQuality, setMonthQuality] = useState<{
    to_deliver: number;
    submitted: number;
    validated: number;
    accepted: number;
  }>({
    to_deliver: 0,
    submitted: 0,
    validated: 0,
    accepted: 0,
  });
  const [isDashboardDataLoading, setIsDashboardDataLoading] = useState(false);
  const { makeCall } = useApi();
  useEffect(() => {
    async function fetchDeliverablesDashboardData() {
      setIsDashboardDataLoading(true);
      try {
        const dashboardDeliverables = await makeCall(
          OrderApiService.fetchDashboardDeliverables(date),
          'Error while fetching dashboard data'
        );
        if (dashboardDeliverables) {
          setMonthQuality(dashboardDeliverables);
        }
      } finally {
        setIsDashboardDataLoading(false);
      }
    }

    fetchDeliverablesDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);
  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, mb: 5 }}>
          <Typography variant="h2" component="div" color="primary" sx={{ mb: { xs: 2, lg: 0 } }}>
            {t('Deliverables')}
          </Typography>
          <Box sx={{ ml: { xs: 0, lg: 'auto' }, display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={() => setDate(() => moment(date).subtract(1, 'month').format('YYYY-MM-DD'))}
              size="small"
              disabled={isDashboardDataLoading}
              color="primary"
              sx={{ mr: 1 }}
            >
              <ArrowLeft />
            </IconButton>
            <Box>{isDashboardDataLoading ? <Loader size={20} /> : moment(date).format('MMMM-YYYY')}</Box>
            <IconButton
              onClick={() => setDate(() => moment(date).add(1, 'month').format('YYYY-MM-DD'))}
              size="small"
              disabled={isDashboardDataLoading}
              color="primary"
              sx={{ ml: 1 }}
            >
              <ArrowRight />
            </IconButton>
          </Box>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6} xl={3}>
            <Kpi number={monthQuality.to_deliver} content="To deliver">
              <Avatar sx={{ bgcolor: 'primary.light' }}>
                <DeliverIcon sx={{ color: 'primary.main' }} />
              </Avatar>
            </Kpi>
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <Kpi number={monthQuality.submitted} content="Submitted">
              <Avatar sx={{ bgcolor: red[50] }}>
                <FlagIcon sx={{ color: red[300] }} />
              </Avatar>
            </Kpi>
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <Kpi number={monthQuality.validated} content={t('dm_validation')}>
              <Avatar sx={{ bgcolor: red[50] }}>
                <FlagIcon sx={{ color: red[300] }} />
              </Avatar>
            </Kpi>
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <Kpi number={monthQuality.accepted} content={t('client_acceptance')}>
              <CheckIcon sx={{ color: 'success.main', fontSize: '2.813rem' }} />
            </Kpi>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DashboardKpiSliders;
// const Row = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;
//   @media (max-width: 768px) {
//     flex-direction: column;
//     align-items: center;
//     justify-content: left;
//   }
// `;
