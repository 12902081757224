import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { remove } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cancelSelectByHand } from 'store/actions/deliverableActions';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';

export const DeliverableCancelReportMode = () => {
  const [t] = useTranslation();
  const { state, dispatch } = useAppState();
  const open = useSelector((state) => state.dialogs.deliverableCancelReportMode);

  const handleClose = () => {
    dispatch(closeDialogAction('deliverableCancelReportMode'));
  };

  const handleConfirm = () => {
    dispatch(cancelSelectByHand());
    if (state.dialogs.data.confirm) state.dialogs.data.confirm();
    handleClose();
  };
  return (
    <>
      <Dialog style={{ maxHeight: 435 }} maxWidth="xs" open={open}>
        <DialogTitle>{t('Exit report mode?')}</DialogTitle>
        <DialogContent>
          <div>
            {t('You are in report mode. By doing this action, you will exit the mode and loose all your progression')}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
