import React from 'react';
import { useTranslation } from 'react-i18next';

const WorkloadCell = React.memo((row: any) => {
  const [t] = useTranslation();

  return <div>{row?.charge ? row.charge : t('N/A')}</div>;
});

export default WorkloadCell;
