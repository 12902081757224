import { Tooltip, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const ClientCell = React.memo((row: OrderWorkunit) => (
  <>
    {row?.client_fullname && (
      <Tooltip title={`${capitalize(row?.client_fullname)}`}>
        <Typography variant="body2" noWrap>{`${capitalize(row?.client_fullname)}`}</Typography>
      </Tooltip>
    )}

    {!row?.client_fullname && row.client && (
      <Tooltip title={`${capitalize(row?.client.last_name)} ${capitalize(row?.client.first_name)}`}>
        <Typography variant="body2" noWrap>{`${capitalize(row?.client.last_name)} ${capitalize(
          row?.client.first_name
        )}`}</Typography>
      </Tooltip>
    )}
  </>
));

export default ClientCell;
