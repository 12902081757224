import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { RootActions, RootState } from './redux';
import { rootReducer, rootState } from './store';

export const AppContext = createContext<{ state: RootState; dispatch: Dispatch<RootActions> }>({
  state: rootState,
  dispatch: () => '',
});

export const AppStateProvider: React.FC<{ initialState?: RootState }> = ({ initialState = rootState, children }) => {
  const [state, dispatch] = useReducer(rootReducer(), initialState);
  const store = { state, dispatch };
  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export function useAppState() {
  return useContext(AppContext);
}
