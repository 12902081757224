import { Tooltip, Typography } from '@mui/material';
import React from 'react';

const TotalCell = React.memo(({ total }: { total: number }) => (
  <Tooltip arrow title={total.toFixed(0)}>
    <Typography variant="body2" noWrap>
      {total}
    </Typography>
  </Tooltip>
));

export default TotalCell;
