import { GridRowId } from '@mui/x-data-grid-pro';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { DeliverableActions } from '../actions/deliverableActions';
import { RootActions } from '../redux';
import {
  addCommentDeliverable,
  cancelDeliverable,
  changeMissionAdvancement,
  changeRating,
  deleteCommentDeliverable,
  editDeliverables,
  resetRating,
  restoreDeliverable,
  updateCommentDeliverable,
} from '../services/deliverableServices';
import { deliverableState, DeliverableState } from '../states/deliverableState';

const deliverableReducer = (state = deliverableState, action: RootActions): DeliverableState => {
  switch (action.type) {
    case DeliverableActions.CREATE_DELIVERABLE:
      return {
        ...state,
        totalItems: state.totalItems + action.payload.length,
        deliverables: [...(state?.deliverables ?? []), ...action.payload],
      };
    case DeliverableActions.DELETE_DELIVERABLE:
      return {
        ...state,
        totalItems: state.totalItems - 1,
        deliverables:
          state?.deliverables?.filter((deliverable: OrderWorkunit) => deliverable.id !== action.payload) ?? [],
        selectedDeliverablesModel: state.selectedDeliverablesModel.filter(
          (id: GridRowId) => Number(id) !== action.payload
        ),
      };
    case DeliverableActions.CANCEL_DELIVERABLE:
      return cancelDeliverable(state, action);
    case DeliverableActions.RESTORE_DELIVERABLE:
      return restoreDeliverable(state, action);
    case DeliverableActions.CHANGE_MISSION_ADVANCEMENT:
      return changeMissionAdvancement(state, action);
    case DeliverableActions.CHANGE_RATING:
      return changeRating(state, action);
    case DeliverableActions.ADD_COMMENT_DELIVERABLE:
      return addCommentDeliverable(state, action);
    case DeliverableActions.RESET_RATING:
      return resetRating(state, action);
    case DeliverableActions.UPDATE_COMMENT_DELIVERABLE:
      return updateCommentDeliverable(state, action);
    case DeliverableActions.DELETE_COMMENT_DELIVERABLE:
      return deleteCommentDeliverable(state, action);
    case DeliverableActions.CHANGE_TABLE_TYPE:
      return {
        ...state,
        currentTableType: action.payload,
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
        selectedDeliverablesModel: [],
        selectedAllDeliverables: false,
      };
    case DeliverableActions.CHANGE_TAB:
      return {
        ...state,
        currentTab: action.payload,
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
        selectedDeliverablesModel: [],
        selectedAllDeliverables: false,
      };
    case DeliverableActions.CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case DeliverableActions.CHANGE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case DeliverableActions.EDIT_DELIVERABLES:
      return editDeliverables(state, action);
    case DeliverableActions.LOAD_DELIVERABLES:
      return {
        ...state,
        deliverables: action.payload.deliverables,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      };
    case DeliverableActions.RESET_DELIVERABLES:
      return {
        ...state,
        deliverables: [],
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
      };
    case DeliverableActions.LOAD_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case DeliverableActions.LOAD_FREQUENCIES:
      return {
        ...state,
        frequencies: action.payload,
      };
    case DeliverableActions.LOAD_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case DeliverableActions.DELIVERABLE_LOAD_WORKUNITS:
      return {
        ...state,
        workunits: action.payload,
      };
    case DeliverableActions.LOAD_DELIVERABLE_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case DeliverableActions.LOAD_DELIVERABLE_CONSULTANTS:
      return {
        ...state,
        consultants: action.payload,
      };
    case DeliverableActions.LOAD_DELIVERABLE_DM:
      return {
        ...state,
        deliveryManagers: action.payload,
      };
    case DeliverableActions.SELECT_DELIVERABLE:
      return {
        ...state,
        activeDeliverableModel: action.payload ? [action.payload] : [],
      };
    case DeliverableActions.SELECT_DELIVERABLES:
      return {
        ...state,
        selectedDeliverablesModel: action.payload,
      };

    case DeliverableActions.SELECT_ALL_DELIVERABLES:
      return {
        ...state,
        selectedAllDeliverables: action.payload,
      };
    case DeliverableActions.CHANGE_SELECTED_DELIVERABLES_COUNT:
      return {
        ...state,
        selectedDeliverablesCount: action.payload,
      };
    case DeliverableActions.EXCLUDE_DELIVERABLES_COUNT:
      return {
        ...state,
        excludedDeliverables: action.payload,
      };
    case DeliverableActions.SORT_DELIVERABLES:
      return {
        ...state,
        sortDeliverablesModel: action.payload,
      };
    case DeliverableActions.CHANGE_SEARCH_PARAMS_DELIVERABLE:
      return {
        ...state,
        searchParams: action.payload,
      };
    case DeliverableActions.LOAD_ORDER_SCOPES:
      return {
        ...state,
        order_scopes: action.payload,
      };
    case DeliverableActions.START_SELECT_BY_HAND_CREATE_REPORT_MODAL:
      return {
        ...state,
        isTableInReportMode: true,
        selectedDeliverablesModel: [],
      };
    case DeliverableActions.CONFIRM_SELECT_BY_HAND_CREATE_REPORT_MODAL:
      return {
        ...state,
        isTableInReportMode: false,
      };

    case DeliverableActions.CANCEL_SELECT_BY_HAND_CREATE_REPORT_MODAL:
      return {
        ...state,
        isTableInReportMode: false,
        selectedDeliverablesModel: [],
      };
    default:
      return state;
  }
};

export default deliverableReducer;
