/* eslint-disable no-nested-ternary */
import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Tooltip } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { CheckIcon, RefuseIcon, UserIcon } from 'components/CustomIcons';
import useUserRoles from 'hooks/useUserRoles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { openDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';

type DeliverableTableValidateButtonsProps = {
  row: GridRowModel;
  accepted: number[];
  refused: number[];
  param: string;
  displayButton: number[];
  accept: number;
  refuse: number;
  withReserve: number[];
  disabled: boolean;
  isClient: boolean;
};

const DeliverableTableValidateButtons: React.FC<DeliverableTableValidateButtonsProps> = ({
  row,
  accepted,
  refused,
  param,
  displayButton,
  accept,
  refuse,
  withReserve,
  disabled,
  isClient: isForCustomer,
}) => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const clientConnection = useSelector((state) => state.deliverable.order?.client_connection);
  const creatorId = useSelector((state) => state.deliverable.order?.creator_id);
  const delegueId = useSelector((state) => state.deliverable.order?.delegue_id);
  const userId = useSelector((state) => state.app.user?.id);
  const validatedByDM = row.validator && !row.validator.is_client;
  const userRoles = useUserRoles();

  const displayedIfValidated = (
    <div className="icon-container" data-testid={`svg_icon_ok_${row.id}`}>
      <CheckIcon color="success" />
    </div>
  );
  const stateReportMode = useSelector((state) => state.deliverable.isTableInReportMode);

  const handleOpenDialog = (acceptance: boolean) => {
    if (stateReportMode) {
      dispatch(openDialogAction({ name: 'deliverableCancelReportMode', data: { confirm: () => confirmAction(row) } }));
    } else confirmAction(row);

    function confirmAction(orderWorkunit: { [x: string]: any }) {
      dispatch(
        openDialogAction({
          name: 'deliverableDialogChangeAdvancement',
          data: { acceptState: acceptance, accept, refuse, withReserve, activeDeliverable: orderWorkunit },
        })
      );
    }
  };
  const isAccept = isForCustomer || (!clientConnection && userRoles.isDeliverableManager);
  const acceptOrValidate = isAccept ? t('Accept') : t('Validate');
  const displayedIfNull = (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        // className={`validate-button ${colorOver}`}
        disabled={disabled}
        onClick={() => handleOpenDialog(true)}
        size="small"
        color="success"
        startIcon={<CheckCircle />}
        data-testid={`cell_${
          !clientConnection && userRoles.isDeliverableManager && accept === 4 ? 'validate' : 'accept'
        }_positive_${row.id}`}
      >
        {acceptOrValidate}
      </Button>
      <Button
        disabled={disabled}
        onFocus={() => null}
        onClick={() => {
          handleOpenDialog(false);
        }}
        size="small"
        color="error"
        startIcon={<RemoveCircle />}
        data-testid={`cell_${isAccept}_negative_${row.id}`}
      >
        {t('Refuse')}
      </Button>
    </Box>
  );
  const displayedIfFalse = (
    <div className="icon-container" data-testid={`svg_icon_refused_${row.id}`}>
      <RefuseIcon color="error" />
    </div>
  );
  const displayedWithReserve = (
    <div className="icon-container" data-testid={`svg_icon_reserve_${row.id}`}>
      <CheckIcon color="warning" />
    </div>
  );

  const displayedIfAcceptedCustomer = !validatedByDM ? (
    <div className="icon-container" data-testid={`svg_icon_ok_${row.id}`}>
      <CheckIcon color="success" />
    </div>
  ) : (
    <Tooltip
      title={t('accept_dm') as string}
      arrow
      placement="top"
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            textAlign: 'center',
          },
        },
      }}
    >
      <div className="icon-container" style={{ maxWidth: '24px' }}>
        <CheckIcon color="success" />
        <UserIcon fontSize="small" sx={{ position: 'relative', top: 10, left: -10, color: 'text.primary' }} />
      </div>
    </Tooltip>
  );
  const displayedIfFalseCustomer = !validatedByDM ? (
    <div className="icon-container" data-testid={`svg_icon_refused_${row.id}`}>
      <RefuseIcon color="error" />
    </div>
  ) : (
    <Tooltip
      title={t('refuse_dm') as string}
      arrow
      placement="top"
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            textAlign: 'center',
          },
        },
      }}
    >
      <div className="icon-container" style={{ maxWidth: '24px' }} data-testid={`svg_icon_refused_${row.id}`}>
        <RefuseIcon color="error" />
        <UserIcon fontSize="small" sx={{ position: 'relative', top: 10, left: -10, color: 'text.primary' }} />
      </div>
    </Tooltip>
  );
  const displayedWithReserveCustomer = !validatedByDM ? (
    <div className="icon-container" data-testid={`svg_icon_reserve_${row.id}`}>
      <CheckIcon color="warning" />
    </div>
  ) : (
    <Tooltip
      title={t('reserve_dm') as string}
      arrow
      placement="top"
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            textAlign: 'center',
          },
        },
      }}
    >
      <div className="icon-container" style={{ maxWidth: '24px' }} data-testid={`svg_icon_reserve_${row.id}`}>
        <CheckIcon color="warning" />
        <UserIcon fontSize="small" sx={{ position: 'relative', top: 10, left: -10, color: 'text.primary' }} />
      </div>
    </Tooltip>
  );

  const emptyCell = <RemoveIcon color="disabled" data-testid={`empty_cell_${row.id}`} />;

  function switchValidateElementForCustomerInTeamLeaderCell(number: MissionAdvancementPossibilities) {
    if (number <= MissionAdvancementPossibilities.REFUSED_TL) return emptyCell;
    // if (number === MissionAdvancementPossibilities.RESERVE_TL) return displayedWithReserve;
    if (number >= MissionAdvancementPossibilities.RESERVE_TL) return displayedIfValidated;
    return emptyCell;
  }

  function switchValidateElementForCustomerInCustomerCell(number: MissionAdvancementPossibilities) {
    if (number <= MissionAdvancementPossibilities.REFUSED_TL) return emptyCell;
    if (number === MissionAdvancementPossibilities.REFUSED_CLIENT) return displayedIfFalseCustomer;
    if (number === MissionAdvancementPossibilities.RESERVE_CLIENT) return displayedWithReserveCustomer;
    if (number === MissionAdvancementPossibilities.ACCEPTATION_CLIENT) return displayedIfAcceptedCustomer;
    return displayedIfNull;
  }

  function switchValidateElementForTeamLeaderInTeamLeaderCell(number: MissionAdvancementPossibilities) {
    // if (number <= MissionAdvancementPossibilities.REFUSED_TL) return emptyCell;
    if (number === MissionAdvancementPossibilities.REFUSED_TL) return displayedIfFalse;
    if (number === MissionAdvancementPossibilities.RESERVE_TL) return displayedWithReserve;
    if (number >= MissionAdvancementPossibilities.ACCEPTATION_TL) return displayedIfValidated;
    return displayedIfNull;
  }

  function switchValidateElementForConsultantInTeamLeaderCell(number: MissionAdvancementPossibilities) {
    if (number < MissionAdvancementPossibilities.REFUSED_TL) return emptyCell;
    if (number === MissionAdvancementPossibilities.REFUSED_TL) return displayedIfFalse;
    if (number === MissionAdvancementPossibilities.RESERVE_TL) return displayedWithReserve;
    if (number >= MissionAdvancementPossibilities.ACCEPTATION_TL) return displayedIfValidated;
    return emptyCell;
  }

  function switchValidatedElementForOthersInCustomerCell(number: MissionAdvancementPossibilities) {
    if (number <= MissionAdvancementPossibilities.REFUSED_TL) return emptyCell;
    if (number === MissionAdvancementPossibilities.REFUSED_CLIENT) return displayedIfFalseCustomer;
    if (number === MissionAdvancementPossibilities.RESERVE_CLIENT) return displayedWithReserveCustomer;
    if (number >= MissionAdvancementPossibilities.ACCEPTATION_CLIENT) return displayedIfAcceptedCustomer;
    return emptyCell;
  }

  function switchValidateElement(validated: number) {
    if (isForCustomer) {
      if (
        (isForCustomer && userRoles.isCustomer) ||
        (!clientConnection &&
          !row.deliverable_sheet_id &&
          (userRoles.isAdmin ||
            userRoles.isButl ||
            userRoles.isBm ||
            delegueId === userId ||
            creatorId === userId ||
            ((userRoles.isTeamLeader || userRoles.isTeamLeaderPlus) &&
              (row['order-scope']?.accountable_id === userId ||
                (row.delivery_manager[0] && row.delivery_manager[0].id === userId)))))
      ) {
        return switchValidateElementForCustomerInCustomerCell(validated);
      }

      if ((isForCustomer && !userRoles.isCustomer) || !clientConnection) {
        return switchValidatedElementForOthersInCustomerCell(validated);
      }
    }
    if (!isForCustomer) {
      if (!isForCustomer && userRoles.isCustomer) {
        return switchValidateElementForCustomerInTeamLeaderCell(validated);
      }
      if (!isForCustomer && userRoles.isDeliverableManager) {
        return switchValidateElementForTeamLeaderInTeamLeaderCell(validated);
      }

      if (!isForCustomer && userRoles.isConsultant) {
        return switchValidateElementForConsultantInTeamLeaderCell(validated);
      }
    }

    if (disabled && displayButton.includes(validated)) {
      return emptyCell;
    }
    if (
      appState.user?.is_client && // TODO use customer roles ?
      validated === MissionAdvancementPossibilities.REFUSED_TL
    ) {
      return emptyCell;
    }
    if (displayButton.includes(validated)) {
      return displayedIfNull;
    }
    if (accepted.includes(validated)) return displayedIfValidated;
    if (refused.includes(validated)) return displayedIfFalse;
    if (validated === 6 && (isForCustomer || !clientConnection)) {
      return displayedWithReserve;
    }
    if (param === 'validated' && !isForCustomer && validated === 6) {
      return displayedWithReserve;
    }

    if (withReserve.includes(validated) && !appState.user?.is_client) {
      // TODO use customer roles ?
      return displayedWithReserve;
    }
    if (validated === 3 && appState.user?.is_client) {
      // TODO use customer roles ?
      return displayedIfValidated;
    }

    return displayedIfNull;
  }

  return (
    <div className="cell-validated icons">
      <div className="dashed-line" />
      {switchValidateElement(row.mission_advancement_id)}
    </div>
  );
};

export default React.memo(DeliverableTableValidateButtons);
