import RessourceApi, { Resource } from 'api/ResourceAPI';
import { TenantMonthsWorkload } from 'features/Admin/AdminTenantSettings/AdminTenantSettings';

// type TenantMonthsWorkload = {
//   is_daily_unit: boolean;
//   is_monthly: boolean;
//   workload: number;
//   tenant_id: number | undefined;
//   date: string;
//   id: number;
// };

export interface UpdateTenantWorkload extends TenantMonthsWorkload {
  month_workloads: TenantMonthsWorkload[];
}

export default class TenantWorkloadApiService {
  public static instance: TenantWorkloadApiService;

  public static baseUrl = 'tenant-workloads' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return TenantWorkloadApiService.instance || (TenantWorkloadApiService.instance = new TenantWorkloadApiService());
  }

  public static async fetchAllWorkloads(params: string) {
    try {
      const { data } = await RessourceApi.fetchAll(this.baseUrl, `?size=-1${params}`);
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async postTenantWorkload(i: unknown) {
    try {
      const { data } = await RessourceApi.post(this.baseUrl, i);
      return data;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async updateMany(i: UpdateTenantWorkload) {
    try {
      const { data } = await RessourceApi.patch(this.baseUrl, i, i?.id);

      return data.tenant_workload;
    } catch (error: unknown) {
      return [];
    }
  }
}
