import { Link, Tooltip } from '@mui/material';
import React from 'react';
import { openDrawerAction } from 'store/actions/drawerActions';
import { useAppState } from 'store/Provider';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const NameCell = React.memo((row: OrderWorkunit) => {
  const { dispatch } = useAppState();

  return (
    <Tooltip arrow title={row?.workunit_name ?? ''}>
      <Link
        variant="body2"
        onClick={() => dispatch(openDrawerAction({ name: 'deliverableDetails', data: { id: row?.id } }))}
        noWrap
        underline="none"
        component="button"
      >
        {row.workunit_name ?? ''}
      </Link>
    </Tooltip>
  );
});

export default NameCell;
