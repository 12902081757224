/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef } from 'react';
import Scope from 'types/entities/Scope';

export type InputLabelOnChangeHandlerType = React.ChangeEvent<HTMLInputElement | HTMLSelectElement>;

type InputLabelProps = {
  label: string;
  name: string;
  id: string;
  className?: string;
  placeholder: string;
  onchange: (e: InputLabelOnChangeHandlerType) => void;
  required?: boolean;
  value?: string | number;
  type?: string | undefined;
  min?: number;
  options?: Scope[];
  clearable?: boolean;
  classNameLabel?: string;
};
const InputLabel = (props: InputLabelProps) => {
  const {
    label,
    name,
    id,
    className,
    placeholder,
    onchange,
    required = false,
    value = '',
    type,
    min = 0,
    options,
    clearable = false,
    classNameLabel = '',
  } = props;
  const handleReset = () => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
    if (nativeInputValueSetter && inputRef?.current) {
      nativeInputValueSetter.call(inputRef?.current, '');

      const ev2 = new Event('input', { bubbles: true });
      inputRef?.current.dispatchEvent(ev2);
    }
    if (inputRef?.current) inputRef.current.value = '';
  };
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <label htmlFor={id} className="label-input">
      <span className={classNameLabel}>
        {label}
        {required && <span className="required_label">*</span>}
      </span>
      {type !== 'select' && (
        <div className="input_clearable_container">
          <input
            name={name}
            ref={inputRef}
            id={id}
            className={`custom-input active-blue-custom ${className}`}
            placeholder={placeholder}
            onChange={(e) => onchange(e)}
            required={required}
            value={value}
            type={type}
            min={min}
          />
          {clearable && <button className="close-icon" value="" type="button" onClick={(e) => handleReset()} />}
        </div>
      )}
      {type === 'select' && options && (
        <select
          className={`custom-input active-blue-custom ${className}`}
          name="select_options"
          id={id}
          value={value ? Number(value) : undefined}
          onChange={(e) => onchange(e)}
        >
          <option value="">--Choose an option--</option>
          {options.map((option) => option && <option value={option.id}>{option.name}</option>)}
        </select>
      )}
    </label>
  );
};
export default InputLabel;
