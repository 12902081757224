import React from 'react';
import { SxProps, Theme, styled } from '@mui/material/styles';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';

interface Props {
  sx?: SxProps<Theme>;
  size?: 'small' | 'medium' | 'large' | undefined;
  children?: React.ReactNode;
}

const CustomButtonGroup = styled(ButtonGroup)<ButtonGroupProps>(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.neutral.main,
  borderRadius: '6px',
  padding: '2px',
  '& .MuiButton-root': {
    borderRadius: '6px',
  },
}));

const ButtonSwitcher: React.FC<Props> = ({ sx, size, children }) => (
  <CustomButtonGroup sx={sx} size={size}>
    {children}
  </CustomButtonGroup>
);

export default ButtonSwitcher;
