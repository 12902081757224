/* eslint-disable react-hooks/exhaustive-deps */
import { Close, Save } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import WorkunitApiService from 'api/WorkunitApiService';
import { Loader } from 'components';
import { capitalize } from 'helpers/format';
import useUserRoles from 'hooks/useUserRoles';
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Catalog from 'types/entities/Catalog';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';

const WorkunitEdition = (props: {
  workunit: CatalogWorkunit;
  catalog_id: Catalog['id'];
  upsertWorkunit: (workunit: CatalogWorkunit) => void;
  dispatchOpen: () => void;
}) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { workunit, catalog_id, upsertWorkunit, dispatchOpen } = props;
  const { dispatch } = useAppState();
  const catalogComplexities = useSelector((state) => state.app.catalogComplexities);
  const devises = useSelector((state) => state.app.devises);
  const userRoles = useUserRoles();
  const [t] = useTranslation();
  const [valuesSelected, setValuesSelected] = React.useState<Partial<CatalogWorkunit>>({
    ...workunit,
    skill: workunit.skill && typeof workunit.skill === 'number' ? workunit.skill : null,
  });
  const slidersValues: {
    label: string;
    value: number;
  }[] = catalogComplexities.map((complexity, index) => ({
    label: complexity.name,
    value: index,
  }));

  const [isWorkunitValidationDisabled, setIsWorkunitValidationDisabled] = useState<boolean>(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValuesSelected({ ...valuesSelected, [e.currentTarget.name]: e.currentTarget.value });
  };
  const handleDeviseChange = (e: any) => {
    setValuesSelected({
      ...valuesSelected,
      devise: devises.find((devise) => devise.id === Number(e.currentTarget.value)),
      devise_id: Number(e.currentTarget.value),
    });
  };

  const submit = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault();
    try {
      setIsLoading(true);
      const workunitCreated = await WorkunitApiService.update({
        ...workunit,
        ...valuesSelected,
        name: valuesSelected.name,
        desc: valuesSelected.desc,
        complexity_id: valuesSelected.complexity_id,
        accept_standard: valuesSelected.accept_standard,
        level_standard: valuesSelected.level_standard,
        is_archived: false,
        devise_id: valuesSelected.devise_id,
        reference: valuesSelected.reference,
        scope: undefined,
        scope_id: undefined,
        skill: workunit.skill && typeof workunit.skill === 'number' ? workunit.skill : null,
        devise: valuesSelected.devise || undefined,
        catalog_id,
      });

      if (workunitCreated) {
        upsertWorkunit({
          ...workunitCreated,
          devise: valuesSelected.devise,
          complexity: catalogComplexities.find((complexity) => complexity.id === workunitCreated.complexity_id),
        });
      }
    } catch (error) {
      const result = (error as Error).message;

      if (result) {
        dispatch(setSnackbarAction({ message: `Unable to create workunit: ${result}`, open: true, severity: 'error' }));
      } else {
        dispatch(setSnackbarAction({ message: `Unable to create workunit : ${error}`, open: true, severity: 'error' }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSliderChange = (event: React.ChangeEvent<unknown>, value: number | number[]) => {
    const selectedComplexity = catalogComplexities?.[value as number];
    if (selectedComplexity) {
      setValuesSelected({
        ...valuesSelected,
        complexity_id: selectedComplexity.id,
      });
    }
  };

  useEffect(() => {
    const isFormOk =
      !!valuesSelected.devise_id && !!valuesSelected.name && !!valuesSelected.reference && !!valuesSelected.desc;
    if (isFormOk) setIsWorkunitValidationDisabled(false);
  }, [
    valuesSelected,
    valuesSelected.desc,
    valuesSelected.devise_id,
    valuesSelected.input,
    valuesSelected.level_standard,
    valuesSelected.name,
    valuesSelected.output,
    valuesSelected.reference,
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleEstimationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValuesSelected({
      ...valuesSelected,
      charge: Math.abs(Number(e.currentTarget.value)),
    });
    setSelectedEstimation(Math.abs(Number(e.currentTarget.value)));
  };
  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value.replace(',', '.');
    setValuesSelected({
      ...valuesSelected,
      price: Math.abs(Number(e.currentTarget.value)),
    });
    setSelectedPrice(Math.abs(Number(e.currentTarget.value)));
  };

  const [selectedEstimation, setSelectedEstimation] = useState<number>(workunit.charge || 0);
  const [selectedPrice, setSelectedPrice] = useState<number>(workunit.price || 0);

  const [currentSymbol, setCurrentSymbol] = useState<string>('');

  useEffect(() => {
    const currentDevise = devises?.find((devise) => Number(valuesSelected?.devise_id) === devise.id);
    if (currentDevise) {
      if (currentDevise.symbol === 'E') {
        setCurrentSymbol('€');
        return;
      }
      setCurrentSymbol(currentDevise.symbol);
    }
  }, [devises, valuesSelected?.devise_id]);

  return (
    <form onSubmit={submit}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
        <Box>
          <Typography variant="h2">{t('Edit_workunit')}</Typography>
          <Typography variant="body1">{capitalize(valuesSelected?.name as string)}</Typography>
        </Box>

        <IconButton sx={{ ml: 'auto' }} color="primary" onClick={dispatchOpen}>
          <Close />
        </IconButton>
      </Box>
      {!isLoading ? (
        <>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_ref">{t('Reference')}</InputLabel>
            <TextField
              id="workunit_ref"
              onChange={(e) => handleChange(e)}
              placeholder={t('REFERENCE_OF_WORKUNIT')}
              name="reference"
              disabled={!userRoles.isAdmin}
              fullWidth
              value={valuesSelected.reference}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_name">{t('Name')}</InputLabel>
            <TextField
              id="workunit_name"
              onChange={(e) => handleChange(e)}
              placeholder={t('NAME_OF_NEW_WORKUNIT')}
              name="name"
              disabled={!userRoles.isAdmin}
              fullWidth
              value={valuesSelected.name}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_desc">{t('Description')}</InputLabel>
            <TextField
              id="workunit_desc"
              onChange={(e) => handleChange(e)}
              placeholder={t('DESCRIPTION_NEW_WORKUNIT')}
              name="desc"
              disabled={!userRoles.isAdmin}
              fullWidth
              multiline
              rows={3}
              value={valuesSelected.desc}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_devise">{t('Devise')}</InputLabel>
            <RadioGroup
              aria-labelledby="workunit_devise"
              id="workunit_devise"
              name="devise_id"
              row
              onChange={(e) => handleDeviseChange(e)}
              defaultValue={valuesSelected.devise_id}
            >
              {devises?.map((devise) => (
                <FormControlLabel
                  key={devise.id}
                  value={devise.id.toString()}
                  className={`wrapped ${Number(valuesSelected?.devise_id) === devise.id ? 'checked' : ''}`}
                  control={<Radio color="primary" />}
                  label={t(devise.name) as string}
                  disabled={!userRoles.isAdmin}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel>{t('Complexity')}</InputLabel>
            <Box sx={{ px: 2 }}>
              <ComplexitySlider
                defaultValue={
                  slidersValues?.findIndex(
                    (value) => workunit.complexity && value.label === workunit.complexity.name
                  ) ?? 0
                }
                onChange={(e: React.ChangeEvent<unknown>, value: number | number[]) => handleSliderChange(e, value)}
                min={0}
                max={catalogComplexities ? catalogComplexities?.length - 1 : 0}
                marks={slidersValues}
                step={1}
                disabled={!userRoles.isAdmin}
              />
            </Box>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="workunit_load_estimation">{t('Load_Estimation')}</InputLabel>
                <TextField
                  id="workunit_load_estimation"
                  value={selectedEstimation}
                  fullWidth
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 0.00001,
                    },
                    endAdornment: <InputAdornment position="end">{t('Days')}</InputAdornment>,
                  }}
                  type="number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEstimationChange(e)}
                  disabled={!userRoles.isAdmin}
                  sx={{ '& .MuiInputBase-input': { textAlign: 'right' } }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="workunit_price">{t('Price')}</InputLabel>
                <TextField
                  id="workunit_price"
                  value={selectedPrice}
                  fullWidth
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 0.01,
                    },
                    endAdornment: <InputAdornment position="end">{currentSymbol}</InputAdornment>,
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePriceChange(e)}
                  type="number"
                  disabled={!userRoles.isAdmin}
                  sx={{ '& .MuiInputBase-input': { textAlign: 'right' } }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_input">{t('INPUTS')}</InputLabel>
            <TextField
              id="workunit_input"
              onChange={(e) => handleChange(e)}
              placeholder={t('INPUT_NEW_WORKUNIT')}
              name="input"
              fullWidth
              multiline
              rows={3}
              value={valuesSelected.input}
              disabled={!userRoles.isAdmin}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_output">{t('OUTPUTS')}</InputLabel>
            <TextField
              id="workunit_output"
              onChange={(e) => handleChange(e)}
              placeholder={t('OUTPUT_NEW_WORKUNIT')}
              name="output"
              fullWidth
              multiline
              rows={3}
              value={valuesSelected.output}
              disabled={!userRoles.isAdmin}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_accept_standard">{t('Accept_standard')}</InputLabel>
            <TextField
              id="workunit_accept_standard"
              onChange={(e) => handleChange(e)}
              placeholder={t('ACCEPT_STANDARD_WORKUNIT')}
              name="accept_standard"
              fullWidth
              multiline
              rows={3}
              value={valuesSelected.accept_standard}
              disabled={!userRoles.isAdmin}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="workunit_level_standard">{t('Level_standard')}</InputLabel>
            <TextField
              id="workunit_level_standard"
              onChange={(e) => handleChange(e)}
              placeholder={t('LEVEL_STANDARD_WORKUNIT')}
              name="level_standard"
              fullWidth
              multiline
              rows={3}
              value={valuesSelected.level_standard}
              disabled={!userRoles.isAdmin}
            />
          </Box>
          {userRoles.isAdmin && (
            <Button
              startIcon={<Save />}
              fullWidth
              variant="contained"
              onClick={submit}
              disabled={isWorkunitValidationDisabled}
            >
              Validate
            </Button>
          )}
        </>
      ) : (
        <Loader />
      )}
    </form>
  );
};

export default WorkunitEdition;

const ComplexitySlider = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
    },
  })
)(Slider);
