import React from 'react';
import styled from 'styled-components/macro';

interface ParentCompProps {
  childComp?: React.ReactNode;
  height?: number;
  width?: number;
}

const CardWithTop: React.FC<ParentCompProps> = ({ childComp, height, width }) => (
  <Root height={height} width={width}>
    <CardContainer height={height} width={width}>
      <TopBlock theme={{ border: '#D8D8D8', background: '#D8D8D8' }} />
      <Content>{childComp}</Content>
    </CardContainer>
  </Root>
);

export default CardWithTop;

const Root = styled.div<ParentCompProps>`
  display: flex;
  min-width: ${(props) => props.width}px !important;
  min-height: ${(props) => props.height}px !important;
`;
const CardContainer = styled.div<ParentCompProps>`
  display: flex;
  flex-direction: column;
  margin-right: 4rem;
  margin-top: 1rem;
  max-width: ${(props) => props.width}px !important;
  max-height: ${(props) => props.height}px !important;
  border-radius: 30px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
`;

const TopBlock = styled.div`
  display: flex;
  height: 24px;
  border: ${(props) => props.theme.border};
  background-color: ${(props) => props.theme.background};
  opacity: 0.6;
  border-radius: 40px 40px 0px 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
`;
