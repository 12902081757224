import ResourceAPI, { Resource } from 'api/ResourceAPI';
import MissionComment from 'types/entities/MissionComment';
import InMemoryMissionCommentsApiService from './InMemory/InMemoryMissionCommentsApiService';

export interface IMissionCommentsApiService {
  fetchByMissionId(missionId: number): Promise<MissionComment[]>;
  updateComment(id: number, data: Partial<MissionComment>): Promise<MissionComment | undefined>;
}
class MissionCommentsApiService implements IMissionCommentsApiService {
  public static instance: MissionCommentsApiService;

  baseUrl = 'mission-comments' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return MissionCommentsApiService.instance || (MissionCommentsApiService.instance = new MissionCommentsApiService());
  }

  async fetchByMissionId(missionId: number) {
    try {
      const { data } = await ResourceAPI.get(this.baseUrl, { mission_id: missionId, size: '-1' });
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  async updateComment(id: number, data: Partial<MissionComment>): Promise<MissionComment | undefined> {
    try {
      const { data: mission_comment } = await ResourceAPI.put(this.baseUrl, id, data);
      return mission_comment;
    } catch (error: unknown) {
      return undefined;
    }
  }
}

const isTestMode = process.env.NODE_ENV === 'test';
const missionCommentsApiService = !isTestMode
  ? new MissionCommentsApiService()
  : new InMemoryMissionCommentsApiService();
export default missionCommentsApiService;
