import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Backdrop,
  BackdropProps,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useAppState } from 'store/Provider';
import ApiAuthService from '../../api/AuthApiService';
import TermsOfUseApiService from '../../api/TermsOfUseApiService';
import { loadTokenAction, updateTermsOfUse } from '../../store/actions/appActions';
import { addLoadingAction, removeLoadingAction } from '../../store/actions/loadingsActions';
import { setSnackbarAction } from '../../store/actions/snackbarActions';
import { WarningIcon } from '../CustomIcons';
import { Loader } from '../index';
import './TermsOfUseDialog.scss';

const BackdropCustom = styled(Backdrop)<BackdropProps>(() => ({
  backdropFilter: 'blur(1rem)',
}));

const TermsOfUseDialog: React.FC = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const { state, dispatch } = useAppState();
  const { dialogs, loadings } = state;
  const isOpen = dialogs.termsOfUseDialog;
  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const [pdfContainerWidth, setPdfContainerWidth] = React.useState<number | undefined>();
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [isRefused, setIsRefused] = useState<boolean | null>(false);
  const [isRead, setIsRead] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>();
  const [numPages, setNumPages] = useState<number | undefined>(undefined);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPdfContainerWidth((pdfContainerRef?.current?.clientWidth || 15) - 15);
  };

  const fetchTermsOfUse = async () => {
    const { data } = await TermsOfUseApiService.get();
    if (data && data.presignedUri) {
      setPdfUrl(data.presignedUri);
    }
  };

  useEffect(() => {
    fetchTermsOfUse();
  }, []);

  useEffect(() => {
    function handleResize() {
      setPdfContainerWidth(pdfContainerRef?.current?.clientWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleScroll = () => {
    if (pdfContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = pdfContainerRef.current;
      if (scrollTop + clientHeight + 10 >= scrollHeight) {
        setIsRead(true);
      }
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccepted(e.currentTarget.checked);
  };

  const onClose = () => {
    dispatch(closeDialogAction('termsOfUseDialog'));
    setTimeout(() => {
      setIsRefused(false);
    }, 1000);
  };

  const onValidate = async () => {
    dispatch(addLoadingAction('accept_terms_of_use'));
    await TermsOfUseApiService.accept();
    dispatch(removeLoadingAction('accept_terms_of_use'));
    onClose();
    dispatch(updateTermsOfUse(true));
    dispatch(
      setSnackbarAction({
        message: 'General terms and conditions of use are accepted',
        open: true,
        severity: 'success',
      })
    );
  };

  const onBack = () => {
    fetchTermsOfUse();
    setIsRefused(false);
    setIsAccepted(false);
    setIsRead(false);
  };

  const onRefuse = () => {
    setPdfUrl(null);
    setIsRefused(null);
    setIsAccepted(false);
    setIsRead(false);
  };

  const onContinue = () => {
    setPdfUrl(null);
    setIsRefused(true);
    setIsAccepted(false);
    setIsRead(false);
  };

  const previewUrl = async () => {
    dispatch(addLoadingAction('preview_terms_of_use'));
    const { data } = await TermsOfUseApiService.get();
    dispatch(removeLoadingAction('preview_terms_of_use'));

    if (data && data.presignedUri) {
      window.open(data.presignedUri, '_blank', 'noreferrer');
    }
  };

  const onLogout = async () => {
    localStorage.removeItem('cognito-token');
    dispatch(loadTokenAction());
    window.location.href = await ApiAuthService.getAuthAuthorization();
  };

  const title = () => {
    switch (isRefused) {
      case true:
        return 'Access to E-Delivery blocked';
      case false:
        return 'General terms and conditions of use';
      default:
        return 'Refusal of general terms and conditions of use';
    }
  };

  const bodyIsRefused = (
    <Box>
      <Typography color="textPrimary">
        You have to accept General terms and conditions of use to have access to E-Delivery.
      </Typography>
    </Box>
  );

  const bodyIsNotRefused = (
    <Stack spacing={2}>
      <Alert severity="info" variant="outlined">
        Thank you for scrolling through the general conditions of use (below) to read them. Then check the box at the
        bottom of the page to validate them and click on the button to finalize the activation of your account.
      </Alert>

      <Box className="pdf-container">
        {pdfUrl ? (
          <>
            <Box className="pdf" onScroll={handleScroll} ref={pdfContainerRef}>
              <Document
                loading={<Loader size={20} className="white-loader" />}
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {[...Array(numPages)]
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <Page loading="" width={pdfContainerWidth} pageNumber={page} />
                  ))}
              </Document>
            </Box>
            <Fab
              size="small"
              disabled={!!loadings.preview_terms_of_use}
              className="button-pdf-download"
              onClick={previewUrl}
              color="primary"
            >
              {loadings.preview_terms_of_use ? (
                <Loader size={20} className="white-loader" />
              ) : (
                <PreviewIcon fontSize="small" />
              )}
            </Fab>
          </>
        ) : (
          <Loader size={20} className="white-loader" />
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox disabled={!isRead} color="primary" checked={isAccepted} onChange={onChange} />}
            label="I accept the general conditions of use and certify that I have read the entire document"
          />
        </FormGroup>
        <InfoOutlinedIcon color="info" fontSize="medium" sx={{ ml: 1 }} />
      </Box>
    </Stack>
  );

  const bodyDefault = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <Typography color="textPrimary" sx={{ mb: 1 }}>
        Are you sure you want to refuse general terms and conditions of use?
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <WarningIcon color="warning" sx={{ mr: 1 }} />
        <Typography color="warning.main">Warning : if you continue you will not be able to use E-Delivery</Typography>
      </Box>
    </Box>
  );

  const bodyContent = () => {
    switch (isRefused) {
      case true:
        return bodyIsRefused;
      case false:
        return bodyIsNotRefused;
      default:
        return bodyDefault;
    }
  };

  const body = (
    <DialogContent>
      <DialogContentText>{bodyContent()}</DialogContentText>
    </DialogContent>
  );

  const actionsIsRefused = (
    <Stack direction="row" spacing={2}>
      <Button variant="text" onClick={onLogout}>
        Logout
      </Button>
      <LoadingButton variant="contained" onClick={onBack}>
        Go to T&Cs
      </LoadingButton>
    </Stack>
  );

  const actionsIsNotRefused = (
    <Stack direction="row" spacing={2}>
      <Button variant="text" onClick={onRefuse}>
        I refuse
      </Button>
      <LoadingButton
        disabled={!isAccepted}
        variant="contained"
        onClick={onValidate}
        loading={!!loadings.accept_terms_of_use}
      >
        Validate
      </LoadingButton>
    </Stack>
  );

  const actionsDefault = (
    <Stack direction="row" spacing={2}>
      <Button variant="text" onClick={onBack}>
        Back
      </Button>
      <LoadingButton variant="contained" onClick={onContinue}>
        Yes, continue
      </LoadingButton>
    </Stack>
  );

  const actionsContent = () => {
    switch (isRefused) {
      case true:
        return actionsIsRefused;
      case false:
        return actionsIsNotRefused;
      default:
        return actionsDefault;
    }
  };

  const actions = <DialogActions>{actionsContent()}</DialogActions>;

  return (
    <Dialog className="terms-of-use-dialog" open={isOpen} maxWidth="md" fullWidth BackdropComponent={BackdropCustom}>
      <DialogTitle sx={{ display: 'flex' }}>{title()}</DialogTitle>
      {body}
      {actions}
    </Dialog>
  );
};

export default TermsOfUseDialog;
