import DeliverableSheet from 'types/entities/DeliverableSheet';
import FinancialReport from 'types/entities/FinancialReport';
import Order from 'types/entities/Order';
import { DeliverableReportTab } from 'types/enums/DeliverableReportTab';
import { Load } from 'types/Load';

export const DeliverableSheetActions = {
  OPEN_MODAL_CREATE_REPORT: 'OPEN_MODAL_CREATE_REPORT',
  CLOSE_MODAL_CREATE_REPORT: 'CLOSE_DIALOG_CREATE_REPORT',
  LOAD_DELIVERABLE_SHEETS: 'LOAD_DELIVERABLE_SHEETS',
  CREATE_DELIVERABLE_SHEETS: 'CREATE_DELIVERABLE_SHEETS',
  UPDATE_DELIVERABLE_SHEETS: 'UPDATE_DELIVERABLE_SHEETS',
  DELETE_DELIVERABLE_SHEETS: 'DELETE_DELIVERABLE_SHEETS',
  RESET_DELIVERABLE_SHEETS: 'RESET_DELIVERABLE_SHEETS',
  CREATE_DELIVERABLE_SHEET: 'CREATE_DELIVERABLE_SHEET',
  CHANGE_PAGE_DELIVERABLE_SHEET: 'CHANGE_PAGE_DELIVERABLE_SHEET',
  INJECT_DELIVERABLE_SHEET: 'INJECT_DELIVERABLE_SHEET',
  UPDATE_DELIVERABLE_SHEET: 'UPDATE_DELIVERABLE_SHEET',
  INJECT_FINANCIAL_REPORT: 'INJECT_FINANCIAL_REPORT',
  ADD_FINANCIAL_REPORT: 'ADD_FINANCIAL_REPORT',
  SET_TOTAL_WORKUNIT_NUMBER: 'SET_TOTAL_WORKUNIT_NUMBER',
  INJECT_ORDER: 'INJECT_ORDER',
  CHANGE_PAGE_SIZE_DELIVERABLE_SHEET: 'CHANGE_PAGE_SIZE_DELIVERABLE_SHEET',
  CHANGE_REPORT_TAB: 'CHANGE_REPORT_TAB',
} as const;

export const createDeliverableSheetAction = (payload: DeliverableSheet) => ({
  type: DeliverableSheetActions.CREATE_DELIVERABLE_SHEETS,
  payload,
});

export const updateDeliverableSheetAction = (
  payload: DeliverableSheet | (Pick<DeliverableSheet, 'id'> & Partial<DeliverableSheet>)
) => ({
  type: DeliverableSheetActions.UPDATE_DELIVERABLE_SHEETS,
  payload,
});

export const deleteDeliverableSheetAction = (payload: DeliverableSheet['id']) => ({
  type: DeliverableSheetActions.DELETE_DELIVERABLE_SHEETS,
  payload,
});

export const resetDeliverableSheetAction = () => ({
  type: DeliverableSheetActions.RESET_DELIVERABLE_SHEETS,
});

export const openModalCreateReportAction = () => ({
  type: DeliverableSheetActions.OPEN_MODAL_CREATE_REPORT,
});

export const closeModalCreateReportAction = () => ({
  type: DeliverableSheetActions.CLOSE_MODAL_CREATE_REPORT,
});

export const loadDeliverableSheetsAction = (payload: Load<DeliverableSheet>) => ({
  type: DeliverableSheetActions.LOAD_DELIVERABLE_SHEETS,
  payload,
});

export const changePageDeliverableSheetAction = (payload: number) => ({
  type: DeliverableSheetActions.CHANGE_PAGE_DELIVERABLE_SHEET,
  payload,
});

export const changePageSizeDeliverableSheetAction = (payload: number) => ({
  type: DeliverableSheetActions.CHANGE_PAGE_SIZE_DELIVERABLE_SHEET,
  payload,
});

export const changeReportTabAction = (payload: DeliverableReportTab) => ({
  type: DeliverableSheetActions.CHANGE_REPORT_TAB,
  payload,
});

export const injectFinancialReportAction = (payload: FinancialReport) => ({
  type: DeliverableSheetActions.INJECT_FINANCIAL_REPORT,
  payload,
});

export const setTotalWorkunitNumberAction = (payload: number) => ({
  type: DeliverableSheetActions.SET_TOTAL_WORKUNIT_NUMBER,
  payload,
});

export const injectOrderAction = (order: Order) => ({
  type: DeliverableSheetActions.INJECT_ORDER,
  payload: { order },
});

export const injectDeliverableSheetAction = (deliverableSheet: DeliverableSheet) => ({
  type: DeliverableSheetActions.INJECT_DELIVERABLE_SHEET,
  payload: { deliverableSheet },
});
export const addFinancialReport = (financialReports: FinancialReport[]) => ({
  type: DeliverableSheetActions.ADD_FINANCIAL_REPORT,
  payload: { financialReports },
});

export const updateCurrentDeliverableSheetAction = (
  deliverableSheet: DeliverableSheet | (Pick<DeliverableSheet, 'id'> & Partial<DeliverableSheet>)
) => ({
  type: DeliverableSheetActions.UPDATE_DELIVERABLE_SHEET,
  payload: { deliverableSheet },
});

export const deliverableSheetActions = {
  createDeliverableSheetAction,
  updateDeliverableSheetAction,
  deleteDeliverableSheetAction,
  resetDeliverableSheetAction,
  openModalCreateReportAction,
  closeModalCreateReportAction,
  loadDeliverableSheetsAction,
  changePageDeliverableSheetAction,
  changePageSizeDeliverableSheetAction,
  changeReportTabAction,
  injectDeliverableSheetAction,
  injectFinancialReportAction,
  setTotalWorkunitNumberAction,
  injectOrderAction,
  updateCurrentDeliverableSheetAction,
  addFinancialReport,
};
