import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Link } from '@mui/material';
import useOrderWorkunitsService from 'features/Orders/OrderWorkunits/services/useOrderWorkunitsService';
import React, { memo } from 'react';
import { Month } from 'types/models/Months';
import { displayYear } from '../monthsColumnsHelper';

type HeaderWithArrowProps = {
  month: Month;
  dir: 'left' | 'right';
  isYearDisplayed?: boolean;
  type: 'catalog' | 'order';
};

const HeaderWithArrow = ({ month, dir, isYearDisplayed, type }: HeaderWithArrowProps) => {
  const {
    increaseSelectedMonthSlice,
    decreaseSelectedMonthSlice,
    increaseCatalogMonthSlice,
    decreaseCatalogMonthSlice,
  } = useOrderWorkunitsService();

  const handleLeftArrowClick = () => {
    if (type === 'catalog') {
      decreaseCatalogMonthSlice();
    } else {
      decreaseSelectedMonthSlice();
    }
  };

  const handleRightArrowClick = () => {
    if (type === 'catalog') {
      increaseCatalogMonthSlice();
    } else {
      increaseSelectedMonthSlice();
    }
  };

  const withLeftArrow = (
    <Link onClick={handleLeftArrowClick} component="button">
      <ArrowLeft />
    </Link>
  );
  const withRightArrow = (
    <Link onClick={handleRightArrowClick} component="button">
      <ArrowRight />
    </Link>
  );
  return (
    <>
      {dir === 'left' && withLeftArrow}
      {isYearDisplayed && displayYear(month)}
      {month.month}
      {dir === 'right' && withRightArrow}
    </>
  );
};

export default memo(HeaderWithArrow);
