import { TableCell, TableRow, TextField } from '@mui/material';
import { capitalize } from 'helpers/format';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AdminTenantSettingRow(props: {
  m: {
    is_daily_unit: boolean;
    is_monthly: boolean;
    workload: number;
    tenant_id: number | undefined;
    date: string;
    id: number;
  };
  setMonthValue: (workload: number, id: number, month: string) => void;
  is_days: boolean;
}): JSX.Element {
  const {
    m: { workload, date, id },
    setMonthValue,
    is_days,
  } = props;
  const [t] = useTranslation();

  return (
    <TableRow>
      <TableCell>{capitalize(moment(date).format('MMMM'))}</TableCell>
      <TableCell align="right">
        <TextField
          type="number"
          dir="rtl"
          value={workload}
          size="small"
          label={is_days ? t('Days') : t('Hours')}
          onChange={(e) => setMonthValue(parseFloat(e.target.value), id, date)}
        />
      </TableCell>
    </TableRow>
  );
}
