/* eslint-disable jsx-a11y/label-has-associated-control */
import { Autocomplete, Avatar, Box, InputLabel, TextField } from '@mui/material';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';
import { stringToColor } from 'helpers/format';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/entities/User';

type SelectUsersProps = {
  users: User[];
  selectedUser: User | null | undefined;
  onSelectUser: (user: User | null | undefined, reason?: string) => void | Promise<void>;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  id?: string;
  variant?: string;
};
const SelectUser = (props: SelectUsersProps) => {
  const { users, selectedUser, onSelectUser, required = false, label, disabled = false, id, variant } = props;
  const [t] = useTranslation();
  const [userSelected, setSelectedCustomer] = useState<User | undefined>();
  useEffect(() => {
    const user = users.find((u) => u.id === selectedUser?.id);
    setSelectedCustomer(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);
  const [avatar, setAvatarToDisplay] = useState<JSX.Element>();

  useEffect(() => {
    const getAvatarToDisplay = () => {
      if (!userSelected) return <></>;

      if (userSelected?.id)
        return userSelected.avatar_uri ? (
          <Avatar src={userSelected.avatar_uri} alt={t('Active_user_avatar')} className="avatar_select" />
        ) : (
          <Avatar
            style={{
              backgroundColor: `${stringToColor(userSelected.first_name)}`,
              color: `${stringToColor(userSelected.first_name, 'color')}`,
            }}
            className="avatar_select"
          >{`${userSelected.last_name.charAt(0)}${userSelected.first_name.charAt(0)}`}</Avatar>
        );
      if (!userSelected) return <></>;
      return <></>;
    };

    setAvatarToDisplay(getAvatarToDisplay());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelected?.id]);
  const [inputValue, setInputValue] = useState<string>('');
  const handleInputChange = (value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'clear' || value === '') {
      setInputValue('');
      return;
    }
    setInputValue(value);
  };

  return (
    <>
      <InputLabel htmlFor={id}>
        {label} {required && '*'}
      </InputLabel>
      <Autocomplete
        options={users}
        autoHighlight
        disabled={disabled}
        getOptionLabel={(option) => `${option.last_name.toLocaleUpperCase()} ${option.first_name}`}
        isOptionEqualToValue={(option: any) => option.id === selectedUser?.id}
        renderOption={(boxprops, option, { selected }) => (
          <Box component="li" {...boxprops} key={option.id}>
            {option && option?.avatar_uri ? (
              <Avatar src={option.avatar_uri} sx={{ mr: 1 }} />
            ) : (
              <Avatar
                sx={{
                  backgroundColor: `${stringToColor(option?.first_name)}`,
                  color: `${stringToColor(option?.first_name, 'color')}`,
                  mr: 1,
                }}
              >{`${option?.last_name.charAt(0)}${option?.first_name.charAt(0)}`}</Avatar>
            )}
            {option.last_name.toLocaleUpperCase()} {option.first_name}
          </Box>
        )}
        inputValue={inputValue}
        onInputChange={(event, newInputValue, reason) => {
          handleInputChange(newInputValue, reason);
        }}
        onChange={(e, value, reason) => onSelectUser(value as User, reason)}
        value={selectedUser}
        defaultValue={selectedUser}
        placeholder="select_client"
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            disabled={disabled}
            variant={variant as any}
            inputProps={{
              autoComplete: '',
              ...params.inputProps,
            }}
          />
        )}
      />
    </>
  );
};
export default SelectUser;
