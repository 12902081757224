import {
  canCancelAcceptanceDeliverables,
  canCancelCommentDeliverables,
  canEveryDeliverableBeAccepted,
  canEveryDeliverableBeSubmitted,
  canManagersEditDeliverables,
  canRateDeliverables,
  canReviewDeliverables,
  isEveryDeliverableCancellableByConsultant,
  isEveryDeliverableCancellableByManagers,
  isEveryDeliverableCancelled,
  isEveryDeliverableDeletableByConsultants,
  isEveryDeliverableDeletableByManagers,
  isEveryDeliverablesRated,
  isEveryDeliverablesRefusedByClient,
  isEveryDeliverablesSubmissionCancellable,
  isEveryDeliverablesValidationCancellable,
  isEveryDeliverableValidadable,
  isNotEveryCustomDeliverablesAboveSubmittedStatus,
} from 'features/Deliverables/services/deliverableService';
import { PERMISSIONS } from 'helpers/permissions';
import useUserPermissions, { hasPermissions } from 'hooks/useUserPermissions';
import useUserRoles from 'hooks/useUserRoles';
import { useSelector } from 'store/hooks';
import OrderWorkunit from 'types/entities/OrderWorkunit';

/**
 * React hook that defines rights specific to the Deliverable feature
 */
export default function useDeliverableRights(deliverables?: OrderWorkunit[]) {
  const userRoles = useUserRoles();
  const userPermissions = useUserPermissions();
  const selectAll = useSelector((state) => state.deliverable.selectedAllDeliverables);
  const userId = useSelector((state) => state.app.user?.id);
  const creatorId = useSelector((state) => state.deliverable.order?.creator_id);
  const delegueId = useSelector((state) => state.deliverable.order?.delegue_id);
  const isDelegue = delegueId === userId;
  const isCreator = creatorId === userId;
  const rights = {
    canAdd: hasPermissions([PERMISSIONS.SUBMIT_DELIVERABLE], userPermissions),
    canDuplicate: hasPermissions([PERMISSIONS.DUPLICATE_DELIVERABLE], userPermissions),
    canUseQuickActions: hasPermissions([PERMISSIONS.USE_QUICK_ACTION_DELIVERABLE], userPermissions),
    canExport: hasPermissions([PERMISSIONS.EXPORT_DELIVERABLE], userPermissions),
    canExportGR: hasPermissions([PERMISSIONS.EXPORT_GR_DELIVERABLE], userPermissions),
    canCreateDeliverableSheet: hasPermissions([PERMISSIONS.CREATE_DELIVERABLE_SHEET], userPermissions),
    canCreateReport: hasPermissions([PERMISSIONS.CREATE_REPORT], userPermissions),
    canSubmit: hasPermissions([PERMISSIONS.SUBMIT_DELIVERABLE], userPermissions),
    canAccept: false,
    canValidate: false,
    canManagerAccept: false,
    canCancel: false,
    canCancelAcceptance: false,
    canCancelRefused: false,
    canCancelSubmission: false,
    canCancelValidation: false,
    canCancelRating: false,
    canCancelComment: false,
    canEdit: false,
    canDelete: false,
    canRestore: false,
    canRate: false,
    canManagerRate: false,
    canReview: false,
  };

  if (deliverables) {
    rights.canSubmit =
      hasPermissions([PERMISSIONS.SUBMIT_DELIVERABLE], userPermissions) && canEveryDeliverableBeSubmitted(deliverables);
    rights.canAccept =
      hasPermissions([PERMISSIONS.ACCEPT_DELIVERABLE], userPermissions) && canEveryDeliverableBeAccepted(deliverables);
    rights.canManagerAccept =
      hasPermissions([PERMISSIONS.MANAGER_ACCEPT_DELIVERABLE], userPermissions) &&
      canEveryDeliverableBeAccepted(deliverables) &&
      (userRoles.isAdmin ||
        userRoles.isButl ||
        userRoles.isBm ||
        isDelegue ||
        isCreator ||
        ((userRoles.isTeamLeader || userRoles.isTeamLeaderPlus) &&
          deliverables.every(
            (deliverable) =>
              deliverable['order-scope']?.accountable_id === userId ||
              (deliverable.delivery_manager[0] && deliverable.delivery_manager[0].id === userId)
          )));
    rights.canValidate =
      hasPermissions([PERMISSIONS.VALIDATE_DELIVERABLE], userPermissions) &&
      isEveryDeliverableValidadable(deliverables);
    const canCancelByConsultant = userRoles.isConsultant && isEveryDeliverableCancellableByConsultant(deliverables);
    const canCancelByManager = userRoles.isDeliverableManager && isEveryDeliverableCancellableByManagers(deliverables);
    rights.canCancel = canCancelByConsultant || canCancelByManager;
    rights.canCancelAcceptance =
      hasPermissions([PERMISSIONS.CANCEL_ACCEPTANCE_DELIVERABLE], userPermissions) &&
      canCancelAcceptanceDeliverables(deliverables) &&
      !isEveryDeliverablesRefusedByClient(deliverables);
    rights.canCancelRefused =
      hasPermissions([PERMISSIONS.CANCEL_ACCEPTANCE_DELIVERABLE], userPermissions) &&
      canCancelAcceptanceDeliverables(deliverables) &&
      isEveryDeliverablesRefusedByClient(deliverables);
    rights.canCancelSubmission =
      hasPermissions([PERMISSIONS.CANCEL_SUBMISSION_DELIVERABLE], userPermissions) &&
      isEveryDeliverablesSubmissionCancellable(deliverables);
    rights.canCancelValidation =
      hasPermissions([PERMISSIONS.CANCEL_VALIDATION_DELIVERABLE], userPermissions) &&
      isEveryDeliverablesValidationCancellable(deliverables);
    rights.canCancelRating =
      hasPermissions([PERMISSIONS.CANCEL_RATING_DELIVERABLE], userPermissions) &&
      isEveryDeliverablesRated(deliverables);
    rights.canCancelComment =
      hasPermissions([PERMISSIONS.CANCEL_COMMENT_DELIVERABLE], userPermissions) &&
      canCancelCommentDeliverables(deliverables);
    const canEditByConsultant = userRoles.isConsultant && canEveryDeliverableBeSubmitted(deliverables);
    const canEditByManager = userRoles.isDeliverableManager && canManagersEditDeliverables(deliverables);
    rights.canEdit = (canEditByConsultant || canEditByManager) && !selectAll;
    const canDeleteByConsultant = userRoles.isConsultant && isEveryDeliverableDeletableByConsultants(deliverables);
    const canDeleteByManager = userRoles.isDeliverableManager && isEveryDeliverableDeletableByManagers(deliverables);
    rights.canDelete =
      !deliverables.some((deliverable) => deliverable.origin === 'quote') &&
      (canDeleteByConsultant || canDeleteByManager) &&
      deliverables.some(
        (deliverable) =>
          deliverable.origin === 'custom' || (deliverable.origin === 'catalog' && deliverable.label?.includes('added'))
      ) &&
      isNotEveryCustomDeliverablesAboveSubmittedStatus(deliverables);
    rights.canRestore =
      hasPermissions([PERMISSIONS.RESTORE_DELIVERABLE], userPermissions) && isEveryDeliverableCancelled(deliverables);
    rights.canRate =
      hasPermissions([PERMISSIONS.RATE_DELIVERABLE], userPermissions) && canRateDeliverables(deliverables);
    rights.canManagerRate =
      hasPermissions([PERMISSIONS.MANAGER_RATE_DELIVERABLE], userPermissions) &&
      canRateDeliverables(deliverables) &&
      (userRoles.isAdmin ||
        userRoles.isButl ||
        userRoles.isBm ||
        isDelegue ||
        isCreator ||
        ((userRoles.isTeamLeader || userRoles.isTeamLeaderPlus) &&
          deliverables.every(
            (deliverable) =>
              deliverable['order-scope']?.accountable_id === userId ||
              (deliverable.delivery_manager[0] && deliverable.delivery_manager[0].id === userId)
          )));
    rights.canReview =
      hasPermissions([PERMISSIONS.REVIEW_DELIVERABLE], userPermissions) && canReviewDeliverables(deliverables);
  }

  return rights;
}
