import { ArrowBackIos } from '@mui/icons-material';
import { Button } from '@mui/material';
import DeliverableSheetApiService from 'api/DeliverableSheetApiService';
import OrderApiService from 'api/OrderApiService';
import { Layout } from 'components';
import useApi from 'hooks/useApi';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { injectDeliverableSheetAction } from 'store/actions/deliverableSheetActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import OrderCard from '../Orders/OrderCard/OrderCard';
import SheetDeliverable from './Components/SheetDeliverables';
import SheetTab from './Components/Tab';
import './DeliverableSheet.scss';
import { changeCurrentTab } from './SheetContext/_reducers/CurrentTab/CurrentTab';
import {
  injectOrderAction,
  injectSheetAction,
  setTotalWorkunitNumberAction,
} from './SheetContext/_reducers/Inject/Inject';
import { SheetProvider, useSheetContext } from './SheetContext/SheetContext';
import { TabSheet } from './SheetContext/SheetContext.helper';
import SheetPreview from './SheetPreview/SheetPreview';

type Params = {
  order_id: string;
  deliverable_sheet_id: string;
};
const DeliverableSheet: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const { state: appState, dispatch } = useAppState();
  const { state, dispatch: dispatchSheetContext } = useSheetContext();
  const { makeCall } = useApi();
  const activeCustomer = useSelector((state) => state.app.customer);
  const history = useHistory();
  const [t] = useTranslation();

  useEffect(() => {
    const historyState: any = history.location.state;
    if (historyState && historyState.tab) {
      dispatchSheetContext(changeCurrentTab(historyState.tab));
    }
  }, [history, dispatchSheetContext]);

  useEffect(() => {
    const fetchDeliverableSheet = async () => {
      try {
        const deliverableSheet = await makeCall(
          DeliverableSheetApiService.fetchDeliverableSheet(
            parseInt(match?.params.order_id, 10),
            parseInt(match?.params.deliverable_sheet_id, 10)
          )
        );

        if (deliverableSheet) {
          dispatch(injectDeliverableSheetAction(deliverableSheet));
          dispatchSheetContext(injectSheetAction(deliverableSheet));
          dispatchSheetContext(setTotalWorkunitNumberAction(deliverableSheet.totalItems));
          // await DeliverableSheetApiService.generatePdf(deliverableSheet.id);
        }
      } catch (error) {
        const result = (error as Error).message;

        if (result) {
          dispatch(
            setSnackbarAction({ message: `Error while loading report: ${result}`, open: true, severity: 'error' })
          );
        } else {
          dispatch(
            setSnackbarAction({ message: `Error while loading report: ${error}`, open: true, severity: 'error' })
          );
        }
      }
    };
    fetchDeliverableSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params.order_id, match?.params.deliverable_sheet_id]);
  // const getOrderWorkunitInDeliverableSheet = async( ) => {
  //     try{
  //       DeliverableSheetApiService.get()
  //     }
  // }
  useEffect(() => {
    const fetchDeliverableSheetOrder = async () => {
      try {
        const { orderForCustomer } = await makeCall(
          OrderApiService.fetchCurrentOrdersListManagement(appState.app?.customer?.id as number, {
            id: Number(match?.params.order_id),
            size: 1,
          })
        );
        if (orderForCustomer[0]) {
          dispatchSheetContext(injectOrderAction(orderForCustomer[0]));
        }
      } catch (error) {
        const result = (error as Error).message;

        if (result) {
          dispatch(
            setSnackbarAction({ message: `Error while loading report: ${result}`, open: true, severity: 'error' })
          );
        } else {
          dispatch(
            setSnackbarAction({ message: `Error while loading report: ${error}`, open: true, severity: 'error' })
          );
        }
      }
    };
    fetchDeliverableSheetOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params.order_id]);

  const handleLayoutActionButton = () => (
    <Button onClick={() => history.goBack()} startIcon={<ArrowBackIos />}>
      {t('Deliverables')}
    </Button>
  );

  return (
    <Layout path="/order/preview" name="Report preview" subTitleElement={handleLayoutActionButton()}>
      <div id="deliverable-sheet-container">
        {state.order && state.currentDeliverableSheet && (
          <>
            <section id="order_preview">
              <OrderCard order={state.order} menu={false} stats={false} kpi={false} />
            </section>
          </>
        )}
        <SheetTab />

        <div className={`tab-content ${state.currentTab === TabSheet.PREVIEW ? '' : 'hide'}`}>
          <SheetPreview
            orderId={parseInt(match?.params?.order_id, 10)}
            deliverableSheetId={parseInt(match?.params?.deliverable_sheet_id, 10)}
            report={appState.deliverableSheet.currentDeliverableSheet}
          />
        </div>
        <div className={`tab-content ${state.currentTab === TabSheet.DELIVERABLE ? '' : 'hide'}`}>
          <SheetDeliverable />
        </div>
      </div>
    </Layout>
  );
};
export default (props: any) =>
  React.useMemo(
    () => (
      <SheetProvider>
        <DeliverableSheet {...props} />
      </SheetProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.location]
  );
