import { Avatar, AvatarGroup, Box, Button, CardContent, Grid, Stack, Tooltip, Typography } from '@mui/material';
import ProtectedLink from 'components/ProtectedLink';
import { trim } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { capitalize, stringToColor } from '../../../helpers/format';
import useManagementRights from '../../../hooks/rights/useManagementRights';
import useUserRoles from '../../../hooks/useUserRoles';
import { useSelector } from '../../../store/hooks';
import OrderMenu from '../OrderMenu/OrderMenu';
import { OrderCardProps } from './OrderCard';
import useOrderCardService from './useOrderCardService';

const OrderValidatedCardContent: React.FC<OrderCardProps> = ({ order, action = true, menu = true }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { app } = useSelector((state) => state);
  const managementRights = useManagementRights(order);
  const userRoles = useUserRoles();
  const { engagementDate, orderConsultantTooltipTitle, orderClient } = useOrderCardService(order);
  const pathname = `/${app.customer?.slug}/orders/workunits/affectation/order/${order?.id}`;

  return (
    <CardContent>
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item xs={12} md>
          <Grid
            container
            spacing={4}
            justifyContent="space-between"
            alignItems="center"
            sx={{ '& .MuiGrid-item': { width: 0 } }}
          >
            <Grid item xs={12} md={6} lg={!userRoles.isConsultant && !userRoles.isCustomer ? 2 : 3}>
              <ProtectedLink pathname={pathname} right={managementRights.canAffectationOrder}>
                {order.affair ? (
                  <Tooltip title={trim([order.affair?.otp, order.affair?.name].join(' - '))}>
                    <div>
                      <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                        {order.affair?.otp}
                      </Typography>
                      <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                        {order.affair?.name}
                      </Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <Typography noWrap>{t('Affair_not_indicated')} </Typography>
                )}
              </ProtectedLink>
            </Grid>
            {!userRoles.isConsultant && !userRoles.isCustomer && (
              <Grid item xs={12} md={6} lg={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography>{t('Consultants')}</Typography>
                  <Tooltip placement="bottom-start" title={orderConsultantTooltipTitle}>
                    {order.consultants && order.consultants[0] ? (
                      <AvatarGroup max={4}>
                        {order.consultants.map((c) =>
                          c.avatar_uri ? (
                            <Avatar src={c.avatar_uri} key={c.id} />
                          ) : (
                            <Avatar
                              sx={{
                                backgroundColor: stringToColor(c.first_name),
                                color: stringToColor(c.first_name, 'color'),
                              }}
                              key={c.id}
                            >{`${capitalize(c.first_name.charAt(0))}${capitalize(c.last_name.charAt(0))}`}</Avatar>
                          )
                        )}
                      </AvatarGroup>
                    ) : (
                      <Typography sx={{ color: 'primary.main', fontWeight: 500 }}>-</Typography>
                    )}
                  </Tooltip>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={!userRoles.isConsultant && !userRoles.isCustomer ? 2 : 3}>
              <Typography noWrap>{t('Client')}</Typography>
              <Tooltip title={orderClient()}>
                <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                  {orderClient()}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography>{t('Project_name')}</Typography>
              <Tooltip title={order.name_spec ?? t<string>('Not_defined')}>
                <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                  {order?.name_spec}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography>{t('Engagement_date')}</Typography>
              <Tooltip title={engagementDate()}>
                <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                  {engagementDate()}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ml: 'auto' }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            {action && managementRights.canAffectationOrder && (
              <Button variant="contained" onClick={() => history.push(pathname)}>
                {t('Assign')}
              </Button>
            )}
            {menu && <OrderMenu order={order} />}
          </Stack>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default OrderValidatedCardContent;
