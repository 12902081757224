import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, DialogContentText, Stack, Typography } from '@mui/material';
import ModalCustom from 'components/Modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useAppState } from 'store/Provider';
import { setActiveOrderIdAction } from '../../../store/actions/managementActions';
import useManagementService from '../services/useManagementService';

const ManagementCloseDialog = () => {
  const { state, dispatch } = useAppState();
  const { dialogs, management, loadings } = state;
  const isOpen = dialogs.managementCloseDialog;
  const [isConfirm, setIsConfirm] = useState<boolean | null>(null);
  const { closeOrder } = useManagementService();
  const [t] = useTranslation();
  const confirmText = 'Close order';
  const cancelText = 'Cancel';
  const loading = () => !!loadings[`close_${management.activeOrderId}`] || !!loadings.orders;

  const onConfirm = async () => {
    const reponse = await closeOrder(management.activeOrderId);
    setIsConfirm(!!reponse);
  };

  const onClose = () => {
    dispatch(closeDialogAction('managementCloseDialog'));
    dispatch(setActiveOrderIdAction(null));
    setTimeout(() => {
      setIsConfirm(null);
    }, 1000);
  };

  const bodyIsConfimed = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <CheckCircleIcon color="success" fontSize="large" sx={{ mb: 1 }} />
      <Typography color="textPrimary">{t('This order is closed')}</Typography>
      <Typography variant="caption" color="textPrimary" fontStyle="italic">
        {t('You can always reopen it from the order close page')}
      </Typography>
    </Box>
  );

  const bodyIsNotConfimed = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <ErrorIcon color="error" fontSize="large" sx={{ mb: 1 }} />
      <Typography color="error">{t('An error occured !')}</Typography>
    </Box>
  );

  const bodyDefault = (
    <Box>
      <Typography color="textPrimary">{t('Do you want to close this order ?')}</Typography>
    </Box>
  );

  const bodyContent = () => {
    switch (isConfirm) {
      case true:
        return bodyIsConfimed;
      case false:
        return bodyIsNotConfimed;
      default:
        return bodyDefault;
    }
  };

  const body = (
    <DialogContent>
      <DialogContentText>{bodyContent()}</DialogContentText>
    </DialogContent>
  );

  const actionsIsConfimed = (
    <Stack direction="row" sx={{ flex: 'auto', justifyContent: 'center' }}>
      <Button variant="contained" onClick={onClose}>
        {t('Understood')}
      </Button>
    </Stack>
  );

  const actionsDefault = (
    <Stack direction="row" spacing={2}>
      <Button variant="text" onClick={onClose} disabled={loading()}>
        {cancelText}
      </Button>
      <LoadingButton variant="contained" onClick={onConfirm} loading={loading()}>
        {confirmText}
      </LoadingButton>
    </Stack>
  );

  const actionsContent = () => {
    switch (isConfirm) {
      case true:
        return actionsIsConfimed;
      case false:
      default:
        return actionsDefault;
    }
  };

  const actions = <DialogActions>{actionsContent()}</DialogActions>;

  return (
    <ModalCustom
      open={isOpen}
      modalName="managementCloseDialog"
      onClose={onClose}
      onConfirm={onConfirm}
      disabledConfirm={isConfirm !== null}
      titleText="Close order"
      body={body}
      actions={actions}
      size="md"
    />
  );
};

export default ManagementCloseDialog;
