import { createAction } from '@reduxjs/toolkit';
import { initialValue } from '../../intialValue';
import { PossibleSheetActions, SheetActions, TabSheet } from '../../SheetContext.helper';

export const changeCurrentTab = createAction(SheetActions.ChangeTab, (tab: TabSheet) => ({
  payload: tab,
}));

export default (state = initialValue, action: PossibleSheetActions) => {
  switch (action.type) {
    case SheetActions.ChangeTab:
      return {
        ...state,
        currentTab: action.payload,
      };
    default:
      return state;
  }
};
