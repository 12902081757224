import { Box, Drawer, IconButton, Typography } from '@mui/material';
import OrderEditDrawer from 'features/Orders/OrderEdit/OrderEditDrawer';
import React from 'react';
import CompleteOrderDrawer from './CompleteOrderDrawer/CompleteOrderDrawer';
import { useAppState } from 'store/Provider';
import { useSelector } from 'store/hooks';
import { closeDrawerAction } from 'store/actions/drawerActions';
import { Close } from '@mui/icons-material';

const ManagementDrawers = () => {
  const { state, dispatch } = useAppState();
  const drawerState = useSelector((state) => state.drawer);
  const onClose = () => {
    dispatch(closeDrawerAction());
  };

  // TODO ICON TITLE

  const drawers: {
    [name: string]: {
      title: string;
      titleColor?: string;
      titleIcon?: JSX.Element;
      content?: React.FC<any>;
      props?: any;
    };
  } = {
    CompleteOrderDrawer: {
      title: 'Complete order',
      content: CompleteOrderDrawer,
    },
    EditOrderDrawer: {
      title: 'Edit order',
      content: OrderEditDrawer,
    },
  };

  const content = () =>
    drawerState.name && drawers[drawerState.name] !== undefined && drawers[drawerState.name].content
      ? React.createElement(drawers[drawerState.name].content || '', drawers[drawerState.name].props)
      : null;

  return (
    <>
      {drawerState.name && content() && (
        <Drawer
          anchor="right"
          PaperProps={{
            sx: {
              width: {
                xs: '100%',
                lg: 450,
              },
            },
          }}
          open={!!drawerState.name}
          onClose={onClose}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
            <Typography variant="h2">{drawers[drawerState.name].title}</Typography>
            <IconButton sx={{ ml: 'auto' }} color="primary" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          {content()}
        </Drawer>
      )}
    </>
  );
};
export default ManagementDrawers;
