import { LinearProgress, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({
  value = 0,
  color = 'green',
}: {
  value?: number;
  color?: 'green' | 'yellow' | 'red' | 'light-blue' | 'blue' | 'dark-blue';
}) => (
  <>
    <BorderLinearProgress variant="determinate" value={value} defaultValue={0} className={`progress_bar ${color}`} />
  </>
);

export default ProgressBar;

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 7,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#0ACB8F',
    },
  })
)(LinearProgress);
