import React from 'react';
import { Typography, Box } from '@mui/material';
import { FolderEmptyIcon } from 'assets/img/icons';

interface Props {
  title: string;
  message?: string;
}

const KeyNumbers: React.FC<Props> = ({ title, message }) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      p: {
        xs: 1,
        lg: 5,
      },
    }}
  >
    <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '80px', p: 10, textAlign: 'center' }}>
      <Box sx={{ mb: 3 }}>
        <FolderEmptyIcon
          style={{ width: '200px', display: 'block', height: 'auto', maxWidth: '100%', margin: 'auto' }}
        />
      </Box>
      <Typography variant="h3" color="primary.main" sx={{ mb: message ? 3 : '' }}>
        {title}
      </Typography>
      {message && <Typography>{message}</Typography>}
    </Box>
  </Box>
);

export default KeyNumbers;
