import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { RestartAlt } from '@mui/icons-material';
import MomentUtils from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Popover,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Box,
  Chip,
} from '@mui/material';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from './CustomIcons';

type InputDateRangeHandle = {
  clear: () => void;
};

type InputDateRangeProps = {
  field: string;
  label: string;
  name: string;
  id: string;
  placeholder: string;
  onchange: (name: string, date: Moment | [Moment, Moment] | null) => void | null;
  required?: boolean;
  value: Moment | [Moment, Moment] | null;
  min?: string | null;
  max?: string | null;
  classNameLabel?: string;
  disabled?: boolean;
  canEmpty?: boolean;
  showError?: boolean;
  variant?: any;
  reset?: Moment | null;
};

const InputDateRange = forwardRef<InputDateRangeHandle, InputDateRangeProps>((props, ref) => {
  const {
    field,
    label,
    name,
    id,
    placeholder,
    onchange,
    required = false,
    value,
    min,
    max,
    disabled = false,
    canEmpty = false,
    showError = false,
    variant,
    reset,
  } = props;

  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const modeStorageKey = `${field}_mode`;
  const viewStorageKey = `${field}_view`;
  const [selectedDate, setSelectedDate] = useState<Moment | null>(Array.isArray(value) ? value[0] : value);
  const [selectedRange, setSelectedRange] = useState<[Moment | null, Moment | null]>(
    Array.isArray(value) ? (value as [Moment | null, Moment | null]) : [null, null]
  );
  const [openSinglePicker, setOpenSinglePicker] = useState(false);
  const [startMonth, setStartMonth] = useState<Moment | null>(null);
  const [endMonth, setEndMonth] = useState<Moment | null>(null);

  const userLocale = navigator.language || 'fr-FR';

  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  const [mode, setMode] = useState<'single' | 'range'>(() => {
    if (
      startMonth === null &&
      endMonth === null &&
      selectedRange[0] === null &&
      selectedRange[1] === null &&
      selectedDate === null
    ) {
      return 'range';
    }
    const storedMode = localStorage.getItem(modeStorageKey);
    return storedMode === 'single' || storedMode === 'range' ? storedMode : 'range';
  });

  const [view, setView] = useState<'month' | 'date'>(() => {
    if (
      startMonth === null &&
      endMonth === null &&
      selectedRange[0] === null &&
      selectedRange[1] === null &&
      selectedDate === null
    ) {
      return 'month';
    }
    const storedView = localStorage.getItem(viewStorageKey);
    return storedView === 'month' || storedView === 'date' ? storedView : 'month';
  });

  const minSelectableDate = moment().subtract(5, 'years'); // 5 years in the past
  const maxSelectableDate = moment().add(5, 'years'); // 5 years in the future

  const [isError, setIsError] = useState(false);
  const [textErrorHelper, setTextErrorHelper] = useState<string | null>(null);
  const START_DATE = 'startDate';
  const END_DATE = 'endDate';

  const [focusedInput, setFocusedInput] = useState<string | null>(START_DATE);

  useEffect(() => {
    if (viewStorageKey === '') setView('month');
  }, [mode]);

  useEffect(() => {
    if (mode === 'range' && view === 'month' && selectedRange[0] && selectedRange[1]) {
      // Set startMonth and endMonth based on the selected range when switching to month view
      setStartMonth(selectedRange[0].clone().startOf('month'));
      setEndMonth(selectedRange[1].clone().endOf('month'));
    }
  }, [mode, view, selectedRange]);

  useEffect(() => {
    if (mode === 'range' && view === 'month' && startMonth && endMonth) {
      if (endMonth.isBefore(startMonth)) {
        setIsError(true);
        setTextErrorHelper(
          `${t('month_should_be_inferior_to')} ${startMonth ? startMonth.format('MMM YYYY') : 'Select end date'}`
        );
      } else {
        setIsError(false);
        setTextErrorHelper(null);
      }
    } else {
      setIsError(false);
      setTextErrorHelper(null);
    }
  }, [startMonth, endMonth, mode, view, max, t]);

  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        setSelectedRange(value as [Moment | null, Moment | null]);
        setStartMonth(value[0]?.clone().startOf('month') || null);
        setEndMonth(value[1]?.clone().endOf('month') || null);
      } else {
        setSelectedDate(value);
        if (mode === 'single' && view === 'month' && value) {
          setSelectedRange([value.clone().startOf('month'), value.clone().endOf('month')]);
        }
      }
    } else {
      // If the value is null, clear the internal state
      setSelectedDate(null);
      setSelectedRange([null, null]);
      setStartMonth(null);
      setEndMonth(null);
    }
  }, [value]);

  useImperativeHandle(ref, () => ({
    clear: handleReset,
  }));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleApply = () => {
    if (mode === 'single') {
      if (view === 'month' && selectedDate) {
        const startOfMonth = selectedDate.clone().startOf('month');
        const endOfMonth = selectedDate.clone().endOf('month');
        onchange(name, [startOfMonth, endOfMonth]);
      } else {
        onchange(name, selectedDate);
      }
    } else if (view === 'month' && startMonth && endMonth) {
      const adjustedStartMonth = startMonth.clone().startOf('month');
      const adjustedEndMonth = endMonth.clone().endOf('month');
      onchange(name, [adjustedStartMonth, adjustedEndMonth]);
    } else if (selectedRange[0] && selectedRange[1]) {
      onchange(name, selectedRange as [Moment, Moment]);
    } else {
      onchange(name, null);
    }
    localStorage.setItem(modeStorageKey, mode);
    localStorage.setItem(viewStorageKey, view);
    handleClose();
  };

  const handleClear = () => {
    onchange(name, null);
    setSelectedDate(null);
    setSelectedRange([null, null]);
    setStartMonth(null);
    setEndMonth(null);
    setOpen(false);
    handleClose();
  };

  const handleRangeChange = (newRange: [Moment | null, Moment | null]) => {
    // console.log('handleRangeChange callled');
    // console.log('newRange[0] : ', newRange[0]);
    // console.log('newRange[1] : ', newRange[1]);
    // console.log('selectedRange[0] : ', selectedRange[0]);
    if (newRange[0]?.format(dateFormat) !== selectedRange[0]?.format(dateFormat) && newRange[1] != null) {
      setSelectedRange([newRange[0], null]); // Clear the end date if the start date changes
    } else {
      setSelectedRange(newRange);
    }
  };

  const getValue = () => {
    if (mode === 'range' && view === 'month' && startMonth && endMonth) {
      return `${startMonth.format(dateFormat)} - ${endMonth.format(dateFormat)}`;
    }
    if (mode === 'range') {
      return formattedDateRange();
    }
    return selectedDate ? selectedDate.format(dateFormat) : '';
  };

  const formattedDateRange = () => {
    let dateStr = '';
    if (selectedRange[0] && selectedRange[1]) {
      dateStr = `${selectedRange[0] ? selectedRange[0].format(dateFormat) : ''} - ${
        selectedRange[1] ? selectedRange[1].format(dateFormat) : ''
      }`;
    }
    return dateStr;
  };

  const getFormattedSingleDateRange = () => {
    if (!selectedDate) return '';
    const startOfMonth = selectedDate.clone().startOf('month').format(dateFormat);
    const endOfMonth = selectedDate.clone().endOf('month').format(dateFormat);
    return `${startOfMonth} - ${endOfMonth}`;
  };

  const handleStartMonthChange = (newMonth: Moment | null) => {
    if (newMonth) {
      const startOfMonth = newMonth.clone().startOf('month');
      setStartMonth(startOfMonth);
    } else {
      setStartMonth(null);
    }
  };

  const handleEndMonthChange = (newMonth: Moment | null) => {
    if (newMonth) {
      const endOfMonth = newMonth.clone().endOf('month');
      setEndMonth(endOfMonth);
    } else {
      setEndMonth(null);
    }
  };

  const handleDateChange = (newDate: Moment | null) => {
    setSelectedDate(newDate);
    if (mode === 'single' && view === 'month' && newDate) {
      const startOfMonth = newDate.clone().startOf('month');
      const endOfMonth = newDate.clone().endOf('month');
      setSelectedRange([startOfMonth, endOfMonth]);
    } else if (mode === 'range') {
      if (newDate) {
        if (!selectedRange[0]) {
          setSelectedRange([newDate, null]);
        } else {
          setSelectedRange([selectedRange[0], newDate]);
        }
      }
    }
  };

  const handleReset = () => {
    //  console.log('handleReset called');
    if (reset && moment(reset).isValid()) {
      const formattedReset: Moment = moment(reset);
      if (formattedReset) {
        onchange(name, formattedReset);
      }
    } else {
      handleClear(); // Clear without opening the dialog
      localStorage.removeItem(modeStorageKey);
      localStorage.removeItem(viewStorageKey);
    }
  };

  const isApplyDisabled = () => {
    if (mode === 'single') {
      return !selectedDate;
    }
    if (mode === 'range') {
      if (view === 'month') {
        return !startMonth || !endMonth || endMonth.isBefore(startMonth);
      }
      return !selectedRange[0] || !selectedRange[1] || selectedRange[1].isBefore(selectedRange[0]);
    }
    return false;
  };

  const determineValue = () => {
    // console.log('determineValue called');
    // If there's an error, you might want to show an empty string or a default message
    if (isError) {
      return ''; // or some other placeholder like "Invalid date"
    }
    // Determine the value based on the mode and view
    if (mode === 'single' && view === 'month') {
      return getFormattedSingleDateRange();
    }
    return getValue();
  };

  const handleFocusChange = (focusedInput: string | null) => {
    setFocusedInput(focusedInput || START_DATE);
  };

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <FormControl fullWidth>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <TextField
          id={id}
          name={name}
          // onClick={handleOpen}
          placeholder={placeholder}
          value={determineValue()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={handleReset} size="small">
                  <RestartAlt />
                </IconButton>
                <IconButton edge="end" onClick={handleOpen} size="small" color="primary">
                  <CalendarIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
            readOnly: true,
          }}
          disabled={disabled}
          required={required}
          error={isError}
          color={required ? 'primary' : undefined}
          // helperText={textErrorHelper}
          variant={variant}
        />

        <Popover
          open={open}
          anchorEl={document.getElementById(id)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ p: 2, width: '780px' }}>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }}>Mode:</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  backgroundColor: '#e0e0e0',
                  borderRadius: '16px',
                  padding: '4px 8px',
                }}
              >
                <Chip
                  label="Single"
                  onClick={() => setMode('single')}
                  color={mode === 'single' ? 'primary' : 'default'}
                  clickable
                />
                <Chip
                  label="Range"
                  onClick={() => setMode('range')}
                  color={mode === 'range' ? 'primary' : 'default'}
                  clickable
                />
              </Box>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography>View:</Typography>
              <Select value={view} onChange={(e) => setView(e.target.value as 'month' | 'date')} fullWidth>
                <MenuItem value="month">By Month</MenuItem>
                <MenuItem value="date">By Date</MenuItem>
              </Select>
            </Box>
            {mode === 'range' && view === 'date' && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <DateRangePicker
                  calendars={2}
                  startText="Start Date"
                  endText="End Date"
                  value={selectedRange}
                  onChange={handleRangeChange}
                  minDate={minSelectableDate}
                  maxDate={maxSelectableDate}
                  inputFormat={dateFormat}
                  renderInput={(startProps, endProps) => (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <TextField
                        {...startProps}
                        InputProps={{
                          ...startProps.InputProps,
                          readOnly: true,
                          onKeyDown: (event) => event.preventDefault(),
                          onFocus: () => handleFocusChange(START_DATE),
                        }}
                        onClick={startProps.onClick}
                      />
                      <TextField
                        {...endProps}
                        InputProps={{
                          ...endProps.InputProps,
                          readOnly: true,
                          onKeyDown: (event) => event.preventDefault(),
                          onFocus: () => handleFocusChange(END_DATE),
                        }}
                        onClick={endProps.onClick}
                      />
                    </Box>
                  )}
                />
              </Box>
            )}
            {mode === 'range' && view === 'month' && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <DatePicker
                  views={['month', 'year']}
                  value={startMonth}
                  onChange={(date) => {
                    handleStartMonthChange(date);
                  }}
                  minDate={minSelectableDate}
                  maxDate={maxSelectableDate}
                  inputFormat="MMM YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Start Month"
                      InputProps={{
                        ...params.InputProps,
                        readOnly: true, // Ensure the input is read-only
                        onKeyDown: (event) => event.preventDefault(), // Prevent typing
                      }}
                      // You can explicitly handle onClick if necessary
                      onClick={params.InputProps?.onClick}
                    />
                  )}
                />
                <DatePicker
                  views={['month', 'year']}
                  value={endMonth}
                  onChange={(date) => {
                    handleEndMonthChange(date);
                  }}
                  minDate={minSelectableDate}
                  maxDate={maxSelectableDate}
                  inputFormat="MMM YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="End Month"
                      InputProps={{
                        ...params.InputProps,
                        readOnly: true, // Ensure the input is read-only
                        onKeyDown: (event) => event.preventDefault(), // Prevent typing
                      }}
                      // You can explicitly handle onClick if necessary
                      onClick={params.InputProps?.onClick}
                    />
                  )}
                />
              </Box>
            )}
            {mode !== 'range' && (
              <DatePicker
                openTo="month"
                value={selectedDate}
                onChange={handleDateChange}
                views={view === 'month' ? ['month', 'year'] : ['day']}
                inputFormat={view === 'month' ? 'MMM YYYY' : dateFormat}
                minDate={minSelectableDate}
                maxDate={maxSelectableDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true, // Ensure the input is read-only
                      onKeyDown: (event) => event.preventDefault(), // Prevent typing
                    }}
                    onClick={params.InputProps?.onClick} // Ensure clicking on the field opens the picker
                  />
                )}
              />
            )}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              {isError && (
                <Typography color="error" sx={{ mr: 2 }}>
                  {textErrorHelper}
                </Typography>
              )}
              <Button onClick={handleApply} color="primary" variant="contained" disabled={isApplyDisabled()}>
                Apply
              </Button>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </Box>
          </Box>
        </Popover>
      </FormControl>
    </LocalizationProvider>
  );
});

export default InputDateRange;
