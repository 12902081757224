import { LoadingButton } from '@mui/lab';
import { Grid, TextField, Typography } from '@mui/material';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDrawerAction } from 'store/actions/drawerActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectActiveDeliverable } from 'store/selectors/deliverableSelectors';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import './DeliverableCancel.scss';

const DeliverableCancel: React.FC = () => {
  const [t] = useTranslation();
  const { cancelDeliverables } = useDeliverableService();
  const { dispatch } = useAppState();

  const loadings = useSelector((state) => state.loadings);
  const activeDeliverable = useSelector(selectActiveDeliverable);

  const [cancelReason, setCancelReason] = useState('');

  const handleCancelDeliverableConfirmation = async (missionChanged: OrderWorkunit | null, isUndo = false) => {
    if (!missionChanged) return;
    await cancelDeliverables([missionChanged.id], cancelReason, false);
    dispatch(closeDrawerAction());
  };

  const handleCancelReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCancelReason(e.target.value);
  };

  return (
    <Grid container justifyContent="space-between" flexDirection="column" spacing={4}>
      {activeDeliverable && (
        <>
          <Grid item>
            <Typography>{t('Reason of cancellation')}</Typography>
            <TextField
              id="cancel-reason-input"
              name="cancel-reason"
              onChange={handleCancelReasonChange}
              placeholder={t('Enter your text here')}
              value={cancelReason}
              required
              rows={4}
              multiline
              fullWidth
              disabled={!!loadings.api}
            />
          </Grid>
          <Grid item justifyContent="center" display="flex">
            <LoadingButton
              loading={!!loadings.api}
              variant="contained"
              color="error"
              disabled={cancelReason.length === 0}
              className="submit-button error"
              onClick={() => handleCancelDeliverableConfirmation(activeDeliverable, false)}
            >
              {t('Cancel deliverable')}
            </LoadingButton>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DeliverableCancel;
