/* eslint-disable react/require-default-props */
import { Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Rating.scss';

type RatingProps = {
  rating: string;
  size?: string;
  open?: boolean;
  handleTooltipParent?: (string: string) => void;
};

const Rating = ({ rating, size = '', open = false, handleTooltipParent }: RatingProps) => {
  const [t] = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState('');
  const handleTooltip = (rate: string) => {
    setTooltipOpen(rate);
  };
  const getTooltip = () => {
    if (rating === 'A') return t('Excellent');
    if (rating === 'B') return t('Above_expectations');
    if (rating === 'C') return t('Average');
    if (rating === 'D') return t('Below_expectations');
    if (rating === 'E') return t('Not_Acceptable');
    if (rating === 'S') return t('Skip Assessment');
    return '';
  };

  // useEffect(() => {
  //   if (tooltipOpen === (rating as string) && open && handleTooltipParent) handleTooltipParent('');
  // }, [handleTooltipParent, tooltipOpen]);
  return (
    <Tooltip
      title={getTooltip() as string}
      key={`${rating as string}`}
      open={tooltipOpen === (rating as string) && open}
      placement="top"
      PopperProps={{ style: { pointerEvents: 'none', marginTop: '30px', zIndex: 1564 } }}
    >
      <span
        onMouseEnter={() => {
          handleTooltip(rating as string);
        }}
        onMouseLeave={() => {
          handleTooltip('');
        }}
        onMouseOut={() => {
          handleTooltip('');
        }}
        onBlur={() => handleTooltip('')}
        className="tooltip-container"
      >
        <span className={`rate-container ${size}`}>
          <div translate="no" className={`rate ${rating} ${size}`}>
            {rating !== 'S' ? rating : 'Skip'}
          </div>
        </span>
      </span>
    </Tooltip>
  );
};

export default Rating;
