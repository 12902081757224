import { Box } from '@mui/material';
import {
  DataGridPro as DataGrid,
  GridColumns,
  GridLinkOperator,
  GridRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import React, { memo } from 'react';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import useOrderWorkunitsService from '../../services/useOrderWorkunitsService';
import CatalogWorkunitDataGridColumns from './CatalogWorkunitDatagridColumns';
import { catalogWorkunitDatagridColumnsParams } from './services/catalogWorkunitDatagridService';

const CatalogWorkunitDataGrid = () => {
  const { dispatch } = useAppState();

  const searchFilter = useSelector((state) => state.catalogToOrder.catalogHandler.searchParams);
  const workunits = useSelector((state) => state.catalogToOrder.workunits);

  const { updateWorkunit } = useOrderWorkunitsService();

  const apiRef = useGridApiRef();

  const processRowUpdate = async (row: GridRowModel) => {
    updateWorkunit({ ...row });
    return row;
  };

  const handleProcessRowUpdateError = React.useCallback(
    (error: Error) => {
      dispatch(setSnackbarAction({ message: error.message, open: true, severity: 'error' }));
    },
    [dispatch]
  );

  const catalogDatagridColumns = () =>
    CatalogWorkunitDataGridColumns({ columnsDef: catalogWorkunitDatagridColumnsParams, apiRef });

  return (
    <Box sx={{ height: '402px' }}>
      <DataGrid
        columns={catalogDatagridColumns() as GridColumns}
        rows={workunits}
        apiRef={apiRef}
        experimentalFeatures={{ newEditingApi: true, lazyLoading: true }}
        checkboxSelection={false}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableColumnResize
        headerHeight={70}
        rowHeight={70}
        editMode="cell"
        disableColumnReorder
        filterModel={{
          items: [
            { id: 1, columnField: 'reference', operatorValue: 'contains', value: searchFilter?.reference },
            { id: 2, columnField: 'name', operatorValue: 'contains', value: searchFilter?.name },
          ],
          linkOperator: GridLinkOperator.Or,
        }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        sx={{
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          overflow: 'auto',
        }}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd')}
      />
    </Box>
  );
};

export default memo(CatalogWorkunitDataGrid);
