import { intersection } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'store/hooks';
import { RoleType } from 'types/RoleType';

/**
 * React hook and test method to know if the user has specific role or group of roles
 */
export default function useUserRoles() {
  const { roles, user } = useSelector((state) => state.app);
  return useMemo(
    () => ({
      isAdmin: hasRoles(['admin'], roles),
      isBm: hasRoles(['bm'], roles),
      isButl: hasRoles(['butl'], roles),
      isConsultant: hasRoles(['consultant'], roles),
      isCustomer: hasRoles(['customer'], roles),
      isSupportBu: hasRoles(['support_bu'], roles),
      isTeamLeader: hasRoles(['team_leader'], roles),
      isTeamLeaderPlus: hasRoles(['team_leader_plus'], roles),
      isClient: user?.is_client, // TODO use customer role ??
      isDeliverableManager: hasRoles(['admin', 'bm', 'team_leader', 'team_leader_plus'], roles),
      isOrderManager: hasRoles(['admin', 'bm', 'team_leader_plus', 'team_leader'], roles),
      isMigsoManager: hasRoles(['admin', 'bm', 'butl', 'support_bu', 'team_leader', 'team_leader_plus'], roles),
      isManager: hasRoles(['bm', 'team_leader_plus', 'butl', 'support_bu'], roles),
    }),
    [roles, user]
  );
}

const hasRoles = (roles: RoleType[], userRoles: RoleType[] | undefined): boolean =>
  !!userRoles && !!intersection(roles, userRoles).length;
