import { createAction } from '@reduxjs/toolkit';
import { initialValue } from '../../intialValue';
import { PossibleSheetActions, SheetActions } from '../../SheetContext.helper';

export const setIsAssessSignModalOpenAction = createAction(
  SheetActions.SetIsAssessSignModalOpen,
  (isAssesSignModalOpen: boolean) => ({
    payload: isAssesSignModalOpen,
  })
);

export default (state = initialValue, action: PossibleSheetActions) => {
  switch (action.type) {
    case SheetActions.SetIsAssessSignModalOpen:
      return {
        ...state,
        isAssesSignModalOpen: action.payload,
      };
    default:
      return state;
  }
};
