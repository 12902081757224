import axios, { AxiosRequestConfig } from 'axios';
import Token from 'types/models/Token';
import BaseApiService from './BaseApiService';
import InMemoryAuthApiService from './InMemory/InMemoryAuthApiService';

export interface IAuthApiService {
  getAuthAuthorization(): Promise<string>;
  getAuthToken(tokenUri: string): Promise<Token>;
  getTokenUri(code: string): Promise<{ tokenUri: string }>;
  getAuthCode(authUri: string): Promise<{ tokenUri: string }>;
  getRefreshTokenUri(refresh_token: string): Promise<{ tokenUri: string }>;
}
class AuthApiService extends BaseApiService {
  private baseUrl: string;

  private secret: string;

  private redirectUri: string;

  private axiosAuthConfig: AxiosRequestConfig;

  constructor() {
    super();
    this.secret = Buffer.from(
      `${process.env.REACT_APP_CognitoClientAppId}:${process.env.REACT_APP_CognitoClientAppSecret}`
    ).toString('base64');

    this.baseUrl = `${process.env.REACT_APP_SERVER_URL}/auth`;
    this.verifyParams();
    this.redirectUri = process.env.REACT_APP_REDIRECT_URI_CODE as string;
    this.axiosAuthConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
      },
    };
  }

  async getAuthAuthorization(): Promise<string> {
    const res = await axios.get(`${this.baseUrl}/authorize?redirect_uri=${this.redirectUri}`, this.axiosAuthConfig);
    return res.data.authUri;
  }

  async getAuthToken(tokenUri: string) {
    try {
      const res = await axios.post(
        tokenUri,
        {},
        {
          ...this.axiosAuthConfig,
          headers: {
            ...this.axiosAuthConfig.headers,
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${this.secret}`,
          },
        }
      );

      this.setLocalStorageToken(res.data);
      return res.data;
    } catch (error) {
      return error;
    }
  }

  async getTokenUri(code: string): Promise<{ tokenUri: string }> {
    const res = await axios.post(
      `${this.baseUrl}/token?code=${code}&redirect_uri=${this.redirectUri}`,
      this.axiosAuthConfig
    );
    return res.data;
  }

  async getAuthCode(authUri: string): Promise<{ tokenUri: string }> {
    return axios.get(authUri, this.axiosAuthConfig);
  }

  async getRefreshTokenUri(refresh_token: string): Promise<{ tokenUri: string }> {
    const res = await axios.post(`${this.baseUrl}/refresh`, { refresh_token });
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  public setLocalStorageToken(data: Token): void {
    const tokenAlreadyPresent = localStorage.getItem('cognito-token');
    let dataToStock = data;
    if (tokenAlreadyPresent) {
      dataToStock = { ...JSON.parse(tokenAlreadyPresent), ...data };
    }
    // eslint-disable-next-line no-param-reassign
    data.expires_at = (Date.now() + (data.expires_in as number)) as number;
    localStorage.setItem('cognito-token', JSON.stringify(dataToStock));
  }

  private verifyParams() {
    if (!this.secret) {
      throw new Error('Missing secret');
    }
  }
}
const isTestMode = process.env.REACT_APP_NODE_ENV === 'test';
const authApiService = isTestMode ? new InMemoryAuthApiService() : new AuthApiService();

export default authApiService;
