import { Box, InputLabel, TextField, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rating } from 'types/enums/Rating';
import { UploadIcon } from 'components/CustomIcons';

type DocumentSaveLocationProps = {
  paramsForSignature: {
    location: string;
    pdfBlob: Blob;
    assesment: Rating | null;
    comment: string;
    documentUploaded: boolean;
    invalidFileType: boolean;
  };
  setParamsForSignature: (paramsForSignature: {
    location: string;
    pdfBlob: Blob;
    assesment: Rating | null;
    comment: string;
    documentUploaded: boolean;
    invalidFileType: boolean;
  }) => void;
};

const DocumentSaveLocationStep = ({ paramsForSignature, setParamsForSignature }: DocumentSaveLocationProps) => {
  const { t } = useTranslation();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      setParamsForSignature({
        ...paramsForSignature,
        pdfBlob: file,
        location: 'signed',
        documentUploaded: true,
        invalidFileType: false,
      });
    } else {
      setParamsForSignature({ ...paramsForSignature, invalidFileType: true, documentUploaded: false });
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    if (file && file.type === 'application/pdf') {
      setParamsForSignature({
        ...paramsForSignature,
        pdfBlob: file,
        location: 'signed',
        documentUploaded: true,
        invalidFileType: false,
      });
    } else {
      setParamsForSignature({ ...paramsForSignature, invalidFileType: true, documentUploaded: false });
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <Box
      sx={{ mb: 3, border: '2px dashed #aaa', borderRadius: '8px', padding: '16px' }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {/* <InputLabel htmlFor="document_location">{t('Enter document save location')}</InputLabel>
      <TextField
        name="document_location"
        id="document_location"
        onChange={(e) => setParamsForSignature({ ...paramsForSignature, location: e.target.value })}
        value={paramsForSignature.location}
        fullWidth
  /> */}
      <InputLabel htmlFor="document_import">{t('Import your signed document to continue')}</InputLabel>
      <input
        type="file"
        id="document_file"
        accept=".pdf" // Accept only PDF files
        style={{ display: 'none' }} // Hide the input element
        onChange={handleFileChange}
      />
      <label htmlFor="document_file">
        <Button variant="contained" component="span" startIcon={<UploadIcon />}>
          {t('Import Document')}
        </Button>
      </label>
      <Typography variant="body2" color={paramsForSignature.documentUploaded ? 'success' : 'inherit'}>
        {paramsForSignature.documentUploaded && !paramsForSignature.invalidFileType
          ? t('Document uploaded successfully!')
          : t('Or drag and drop your document here!')}
        {paramsForSignature.invalidFileType && (
          <Typography variant="body2" color="error">
            {t('Invalid File. Only pdf documents are allowed.')}
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

export default DocumentSaveLocationStep;
