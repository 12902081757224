import { FormControl, MenuItem, Select, TableCell } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import Role from 'types/entities/Role';
import User from 'types/entities/User';
import UserRole from 'types/entities/UserRole';

const UserRoleCellAdmin = (props: {
  user: User;
  users: User[];
  roles: Role[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  handleClickRole: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, user: User, value: Omit<UserRole, 'id'>) => void;
}) => {
  const [t] = useTranslation();
  const { user, roles, handleClickRole } = props;
  const appState = useSelector((state) => state.app);
  return (
    <TableCell>
      <FormControl fullWidth>
        <Select
          labelId="deliverable_filter_status"
          id="deliverable_filter_status"
          disableUnderline
          disabled={appState.user?.id === user.id && appState.roles?.[0] === 'admin'}
          value={user['user-roles'][0].role_name}
          native={false}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }}
        >
          {roles.map((r) => (
            <MenuItem
              value={r.name}
              key={r.id}
              onClick={(e) =>
                handleClickRole(e, user, {
                  tenant_slug: appState.customer?.slug as string,
                  user_id: user.id,
                  role_name: r.name,
                  created_at: null,
                  updated_at: null,
                })
              }
            >
              {t(r?.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  );
};
export default UserRoleCellAdmin;
