import Customer from 'types/entities/Customer';

/**
 * load or save the customer in the browser's local storage
 */
export default function findActiveCustomer(customerList: Customer[]) {
  const storedCustomer = localStorage.getItem('active_customer');

  if (storedCustomer && storedCustomer.length) {
    const customer = JSON.parse(storedCustomer);
    const storedCustomerIsInCustomerList = customerList.find(
      (c) => c?.id === customer?.id && c.slug === customer?.slug && c.name === customer.name
    );
    if (storedCustomerIsInCustomerList) {
      localStorage.setItem('active_customer', JSON.stringify(storedCustomerIsInCustomerList));

      return storedCustomerIsInCustomerList;
    }

    if (!storedCustomerIsInCustomerList && customerList[0]) {
      localStorage.setItem('active_customer', JSON.stringify(customerList[0]));

      return customerList[0];
    }
  }
  if (!storedCustomer && customerList[0]) {
    localStorage.setItem('active_customer', JSON.stringify(customerList[0]));

    return customerList[0];
  }
  return undefined;
}
