import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, Link, Tooltip } from '@mui/material';
import { GridAlignment, GridCellParams, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import React from 'react';
import Slice from 'types/entities/MonthSlice';
import { SelectedWorkunit } from 'types/entities/SelectedWorkunit';
import { Month } from 'types/models/Months';
import { isRowInEditMode } from '../../../helpersOrderWorkunits';
import SelectWorkunitButtonCell from './Cells/SelectWorkunitButtonCell';
import HeaderWithArrow from './HeaderWithArrow/HeaderWithArrow';

const max_displayed_months = 6;

type CellType = 'catalog' | 'order';

export const defaultColumnParams = (
  monthName: string,
  alignCell: GridAlignment,
  isYearDisplayed = false
): GridColDef => ({
  field: monthName,
  headerName: monthName,
  filterable: false,
  sortable: false,
  align: alignCell || 'center',
  headerAlign: alignCell || 'center',
  headerClassName: `columnHeader-calendar ${isYearDisplayed ? 'with_year' : ''}`,
  cellClassName: 'columnCell-calendar',
  minWidth: 80,
  flex: 1,
  disableReorder: true,
});
export const monthsColumns = (
  months: Month[],
  sliceMonth: Slice,
  isEditMode: boolean,
  cellType: CellType,
  activeEditRowIds?: SelectedWorkunit['randomId'][]
): GridColDef[] => {
  if (!isEditMode) {
    return constructNotEditableMonthsColumns(months, sliceMonth, activeEditRowIds, cellType) as GridColDef[];
  }
  if (isEditMode) {
    return constructEditableMonthsColumns(months, sliceMonth, cellType) as GridColDef[];
  }
  return [];
};

const constructEditableMonthsColumns = (months: Month[], sliceMonth: Slice, cellType: CellType): GridColDef[] => {
  if (months.length > max_displayed_months - 1) {
    return months.slice(sliceMonth.startSlice, sliceMonth.endSlice).map((month, index) => {
      const isYearDisplayed = shouldDisplayYear(index, month, months, sliceMonth.startSlice);
      if (index === 0 && sliceMonth.startSlice !== 0) {
        return {
          ...defaultColumnParams(month.month, 'center', isYearDisplayed),
          renderHeader: () => (
            <HeaderWithArrow dir="left" month={month} isYearDisplayed={isYearDisplayed} type={cellType} />
          ),
          renderCell: (params: GridCellParams) => (
            <SelectWorkunitButtonCell workunit={params.row} month={month} mode={cellType} />
          ),
        };
      }
      if (index === max_displayed_months - 1 && sliceMonth.endSlice < months.length) {
        return {
          ...defaultColumnParams(month.month, 'center', isYearDisplayed),
          renderHeader: () => (
            <HeaderWithArrow dir="right" month={month} isYearDisplayed={isYearDisplayed} type={cellType} />
          ),
          renderCell: (params: GridCellParams) => (
            <SelectWorkunitButtonCell workunit={params.row} month={month} mode={cellType} />
          ),
        };
      }
      return defaultEditableCell(month, cellType, isYearDisplayed);
    });
  }

  return months.map((month, index) => {
    const isYearDisplayed = shouldDisplayYear(index, month, months, sliceMonth.startSlice);
    return defaultEditableCell(month, cellType, isYearDisplayed);
  });
};
const constructNotEditableMonthsColumns = (
  months: Month[],
  sliceMonth: Slice,
  activeEditRowIds: SelectedWorkunit['randomId'][] = [],
  cellType: CellType
): GridColDef[] => {
  if (months.length > max_displayed_months - 1) {
    return months.slice(sliceMonth.startSlice, sliceMonth.endSlice).map((month, index) => {
      const isYearDisplayed = shouldDisplayYear(index, month, months, sliceMonth.startSlice);

      if (index === 0 && sliceMonth.startSlice !== 0) {
        return {
          ...defaultColumnParams(month.month, 'center', isYearDisplayed),
          renderHeader: () => (
            <HeaderWithArrow dir="left" month={month} isYearDisplayed={isYearDisplayed} type={cellType} />
          ),
          renderCell: (params: GridCellParams) => DefaultNotEditableCell(params, month, activeEditRowIds, cellType),
        };
      }
      if (index === max_displayed_months - 1 && sliceMonth.endSlice < months.length) {
        return {
          ...defaultColumnParams(month.month, 'center', isYearDisplayed),
          renderHeader: () => (
            <HeaderWithArrow dir="right" month={month} isYearDisplayed={isYearDisplayed} type={cellType} />
          ),
          renderCell: (params: GridCellParams) => DefaultNotEditableCell(params, month, activeEditRowIds, cellType),
        };
      }

      return {
        ...defaultColumnParams(month.month, 'center', isYearDisplayed),
        renderHeader: () => defaultCellHeader(month, isYearDisplayed),
        renderCell: (params: GridCellParams) => DefaultNotEditableCell(params, month, activeEditRowIds, cellType),
      };
    });
  }
  return months.map((month, index) => {
    const isYearDisplayed = shouldDisplayYear(index, month, months, sliceMonth.startSlice);
    return {
      ...defaultColumnParams(month.month, 'center', isYearDisplayed),
      renderHeader: () => defaultCellHeader(month, isYearDisplayed),
      renderCell: (params: GridCellParams) => DefaultNotEditableCell(params, month, activeEditRowIds, cellType),
    };
  });
};

export function shouldDisplayYear(index: number, month: Month, months: Month[], start_slice: number) {
  if (index === 0) return true;

  const previousIndex = start_slice + index;
  if (months[previousIndex - 1] && months[previousIndex - 1].year) {
    return month.year !== months[previousIndex - 1].year;
  }
  return true;
}

export function DefaultNotEditableCell(
  params: GridCellParams,
  month: Month,
  activeEditRowIds: SelectedWorkunit['randomId'][] = [],
  cellType: CellType
) {
  const { row } = params;
  const isEditMode = isRowInEditMode(activeEditRowIds, row.randomId);
  return <SelectWorkunitButtonCell workunit={row} month={month} mode={cellType} isEdit={isEditMode} />;
}

function defaultEditableCell(month: Month, cellType: CellType, isYearDisplayed?: boolean): GridColDef {
  return {
    ...defaultColumnParams(month.month, 'center', isYearDisplayed),
    renderHeader: (params: GridColumnHeaderParams) => defaultCellHeader(month, isYearDisplayed),
    renderCell: (params: GridCellParams) => (
      <SelectWorkunitButtonCell workunit={params.row} month={month} mode={cellType} />
    ),
  };
}

export function defaultCellHeader(month: Month, isYearDisplayed?: boolean): JSX.Element {
  return (
    <Box sx={{ fontWeight: 500 }}>
      {isYearDisplayed && displayYear(month)}
      {month.month}
    </Box>
  );
}

export function headerWithArrow(
  update: {
    increase: any;
    decrease: any;
  },
  month: Month,
  dir: 'left' | 'right',
  isYearDisplayed?: boolean
): JSX.Element {
  const withLeftArrow = (
    <Link onClick={() => update.decrease()} component="button">
      <ArrowLeft />
    </Link>
  );
  const withRightArrow = (
    <Link onClick={() => update.increase()} component="button">
      <ArrowRight />
    </Link>
  );
  return (
    <>
      {dir === 'left' && withLeftArrow}
      {isYearDisplayed && displayYear(month)}
      {month.month}
      {dir === 'right' && withRightArrow}
    </>
  );
}

export function displayYear(month: Month): React.ReactNode {
  return <div className="year_displayed"> {month.year}</div>;
}

type KeyOfSelectedWorkunit = keyof SelectedWorkunit;

export function defaultCell(params: GridCellParams, key: KeyOfSelectedWorkunit) {
  <div className="bold-darker-blue">{params?.row?.reference}</div>;

  return (
    <Tooltip arrow title={params?.row?.reference}>
      <div className="bold-darker-blue">{params.row[key]}</div>
    </Tooltip>
  );
}
