import { Tooltip, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const CancellationReasonCell = React.memo((row: OrderWorkunit) => (
  <Tooltip title={row?.cancellation_reason ? `${capitalize(row?.cancellation_reason)}` : ''}>
    <Typography variant="body2" noWrap>
      {row?.cancellation_reason ? `${capitalize(row?.cancellation_reason)}` : ''}
    </Typography>
  </Tooltip>
));

export default CancellationReasonCell;
