import { createAction } from '@reduxjs/toolkit';
import User from 'types/entities/User';
import { initialValue } from '../../helperContext';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const setAffectationClientsAction = createAction(
  OrderWorkunitAffectationType.SetAffectationClients,
  (clients: User[]) => ({
    payload: { clients },
  })
);

export default (state = initialValue, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.SetAffectationClients:
      return {
        ...state,
        clients: action.payload.clients,
      };
    default:
      return state;
  }
};
