/* eslint-disable max-len */
import { handleApiError } from 'api/errors';
import RessourceApi, { Resource, SearchParams } from 'api/ResourceAPI';
import axios, { CancelTokenSource } from 'axios';
import FinancialReport from 'types/entities/FinancialReport';
import Histogram from 'types/entities/Histogram';
import Order from 'types/entities/Order';
import OrderDiscount from 'types/entities/OrderDiscount';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import { OrderKpiBoxData } from '../features/Orders/OrderKpi/OrderKpiBox/OrderKpiBox';
import { SelectedFiltersList } from '../types/models/SelectedFiltersList';
/* eslint-disable class-methods-use-this */
import BaseApiService from './BaseApiService';
import InMemoryOrderApiService from './InMemory/InMemoryOrderApiService';

export interface IOrderApiService {
  baseUrl: string;
  fetchTenantQuality(): Promise<number>;
  fetchDashboardDeliverables(date: any): Promise<Histogram[]>;
  fetchParamsFilterOrder(status?: string, params?: SearchParams): Promise<any>;
  fetchOrderWorkunit(orderId: number): Promise<Order | undefined>;
  fetchOrderManagement(orderId: number): Promise<any[]>;
  getOrderById(orderId: number, params?: SearchParams): Promise<Order | undefined>;
  getOrderExcel(orderId: number): Promise<{ data: Blob; filename: string }>;
  getExcelReport(
    orderId: number,
    selectAll: boolean,
    filters: SelectedFiltersList,
    ids: OrderWorkunit['id'][],
    type: 'financial-reports' | 'deliverables'
  ): Promise<{ data: Blob; filename: string }>;
  fetchCountOfToAssignOrder(activeCustomerId: number): Promise<{ totalItems: number }>;
  create(order: Partial<Order>): Promise<Order | null>;
  update(orderId: number, orderToUpdate: Partial<Order>): Promise<Partial<Order>>;
  fetchSummary(orderId: number): Promise<Order | undefined>;
  duplicate(orderId: number): Promise<Order | undefined>;
  fetchCurrentOrders(
    activeCustomerId: number,
    additionalParams: SearchParams,
    cancelTokenSource?: CancelTokenSource
  ): Promise<any>;
  fetchCurrentOrdersListManagement(
    activeCustomerId: number,
    additionalParams: SearchParams,
    cancelTokenSource?: CancelTokenSource
  ): Promise<any>;
  fetchCurrentCustomerOrdersList(activeCustomerId: number, searchParams: SearchParams): Promise<any>;
  archive(orderId: number): Promise<any>;
  getMiniDashboardData(
    orderId: Order['id'],
    scopes: Scope['id'][],
    dateFrom: string,
    dateTo: string
  ): Promise<OrderKpiBoxData | unknown>;
  getHistogramData(orderId: Order['id'], params: SearchParams): Promise<Histogram | null>;
  bulkUpdateOrderWorkunits(orderId: number, data: any): Promise<any>;
  fetchParamsFilterDeliverable(
    orderId: number
  ): Promise<{ scopes: Scope[]; consultants: User[]; clients: User[] } | undefined>;
  updateDiscount(orderId: Order['id'], discountId: number, discount: any): Promise<OrderDiscount | undefined>;
  addDiscount(orderId: Order['id'], discount: any): Promise<OrderDiscount | undefined>;
  getOrderWorkunitInOrder(id: number): Promise<any>;
}

class OrderApiService extends BaseApiService implements IOrderApiService {
  static instance: OrderApiService;

  baseUrlListManagement = 'orders/list/management' as Resource;

  baseUrl: string;

  constructor(private url = '/orders') {
    super();
    this.baseUrl = `${process.env.REACT_APP_SERVER_URL}${url}`;
  }

  get Instance() {
    // eslint-disable-next-line no-return-assign
    return OrderApiService.instance || (OrderApiService.instance = new OrderApiService());
  }

  async fetchTenantQuality() {
    const res = await RessourceApi.get('orders/dashboard/quality' as Resource);
    return res.data.quality;
  }

  async fetchDashboardDeliverables(date: any) {
    const res = await RessourceApi.post('orders/dashboard/deliverables' as Resource, { date });
    return res.data.datas;
  }

  async fetchParamsFilterOrder(status?: string, params?: any) {
    try {
      const { data } = await RessourceApi.getWithAdditionalPath('orders', `super-filters/${status}`, params);
      return data;
    } catch (error) {
      return undefined;
    }
  }

  async fetchOrderWorkunit(orderId: number): Promise<Order | undefined> {
    try {
      const params = {
        join: [
          'affair',
          'order-scopes',
          'order-scopes.accountable',
          'affair.bm',
          'customer',
          'client',
          'deliverable-sheets',
          'order-workunits',
          'order-workunits.consultant',
        ],
      };
      const {
        data: { order: orderRetrieved },
      }: { data: { order: Order } } = await RessourceApi.getById('orders', Number(orderId), undefined, params);
      return orderRetrieved;
    } catch (error) {
      return undefined;
    }
  }

  async fetchOrderManagement(orderId: number) {
    try {
      const { data } = await RessourceApi.get(this.baseUrlListManagement, {
        id: orderId,
        size: 1,
      });
      return data.datas;
    } catch (error) {
      return [];
    }
  }

  async getOrderById(orderId: number, params?: SearchParams): Promise<Order | undefined> {
    try {
      const { data } = await RessourceApi.getById('orders', Number(orderId), undefined, params);
      return data.order;
    } catch (error) {
      return undefined;
    }
  }

  async getOrderExcel(orderId: number): Promise<any> {
    const {
      data: {
        xlsx_order: { data, filename },
      },
    } = await RessourceApi.getWithAdditionalPath('orders', `${orderId}/xlsx`);
    return { data, filename };
  }

  async getExcelReport(
    orderId: number,
    selectAll: boolean,
    filters: SelectedFiltersList,
    ids: OrderWorkunit['id'][],
    type: 'financial-reports' | 'deliverables'
  ): Promise<any> {
    const {
      data: { data, filename },
    } = await RessourceApi.post(
      'orders',
      {
        order_workunit_ids: ids,
        filters: {
          ...filters,
          select_all: selectAll,
        },
      },
      orderId,
      `${type}/xlsx`
    );
    return { data, filename };
  }

  async fetchCountOfToAssignOrder(activeCustomerId: number): Promise<{ totalItems: number }> {
    try {
      const params = {
        customer_id: activeCustomerId,
        status: 'validated',
        is_archived: 0,
      };
      let res;
      if (activeCustomerId) {
        res = await RessourceApi.get('orders', params);
      }
      return { totalItems: res?.data.totalItems };
    } catch (e) {
      handleApiError(e);
      return { totalItems: 0 };
    }
  }

  async create(order: Partial<Order>) {
    try {
      const {
        data: { order: orderCreated },
      } = await RessourceApi.post('orders', order);
      return orderCreated;
    } catch (error) {
      return null;
    }
  }

  async update(orderId: number, orderToUpdate: Partial<Order>): Promise<Partial<Order>> {
    try {
      const {
        data: { order },
      } = await RessourceApi.patchById('orders', orderId, orderToUpdate);
      return order;
    } catch (error) {
      return orderToUpdate;
    }
  }

  async fetchSummary(orderId: number): Promise<Order | undefined> {
    try {
      const params = {
        join: [
          'order-scopes',
          'client',
          'order-scopes.scope',
          'order-scopes.accountable',
          'order-scopes.order-workunits',
          'affair',
        ],
      };
      const {
        data: { order },
      } = await RessourceApi.getById('orders', orderId, undefined, params);
      return order;
    } catch (error) {
      return undefined;
    }
  }

  async fetchParamsFilterDeliverable(
    orderId: number
  ): Promise<
    | { scopes: Scope[]; consultants: User[]; clients: User[]; delivery_managers: User[]; purchase_orders: string[] }
    | undefined
  > {
    try {
      const {
        data: {
          'filter-params': { scopes, consultants, clients, delivery_managers, purchase_orders },
        },
      } = await RessourceApi.getWithAdditionalPath('orders', `${orderId}/filter-params`);
      return { scopes, consultants, clients, delivery_managers, purchase_orders };
    } catch (error) {
      return undefined;
    }
  }

  async bulkUpdateOrderWorkunits(
    orderId: number,
    /* data: { ressources: OrderWorkunit['id'][]; data: Partial<OrderWorkunit> } */
    data: any
  ): Promise<any> {
    try {
      const {
        data: { order_workunit },
      } = await RessourceApi.patch('orders', data, orderId, 'order-workunits/bulk');
      return order_workunit;
    } catch (error) {
      return error;
    }
  }

  async duplicate(orderId: Order['id']) {
    try {
      const {
        data: { order },
      } = await RessourceApi.post('orders', {}, orderId, 'duplicate');
      return order;
    } catch (error) {
      return error;
    }
  }

  async archive(orderId: number) {
    try {
      const {
        data: { order },
      } = await RessourceApi.delete('orders', orderId);
      return order;
    } catch (error) {
      return error;
    }
  }

  async getOrderByIdForPdf(orderId: Order['id']): Promise<Order | undefined> {
    try {
      const params = {
        join: ['affair', 'affair.bm', 'customer', 'client'],
      };
      const {
        data: { order: orderRetrieved },
      }: { data: { order: Order } } = await RessourceApi.getById('orders', Number(orderId), undefined, params);

      return orderRetrieved;
    } catch (error) {
      return undefined;
    }
  }

  async addDiscount(orderId: Order['id'], discount: any): Promise<OrderDiscount | undefined> {
    try {
      const {
        data: { order_discount },
      } = await RessourceApi.post('orders', discount, orderId, 'discount');
      return order_discount;
    } catch (error) {
      return undefined;
    }
  }

  async updateDiscount(orderId: Order['id'], discountId: number, discount: any): Promise<OrderDiscount | undefined> {
    try {
      const {
        data: { order_discount },
      } = await RessourceApi.put('orders', discount, orderId, `discount/${discountId}`);
      return order_discount;
    } catch (error) {
      return undefined;
    }
  }

  // Fetch customer's orders.
  async fetchCurrentCustomerOrders(
    activeCustomerId: number,
    searchParams: SearchParams
  ): Promise<{ orderForCustomer: Order[] | []; totalPage: number }> {
    try {
      if (activeCustomerId) {
        const params = {
          customer_id: activeCustomerId,
          join: [
            ...(searchParams.join as any[]),
            'order-scopes',
            'order-workunits.scope',
            'order-workunits.workunit.devise',
            'order-scopes',
            'order-scopes.accountable',
            'order-scopes.scope',
            'affair',
            'delegue',
          ],
          size: 10,
          ...searchParams,
        };
        const res = await RessourceApi.get('orders', params);
        return { orderForCustomer: res?.data?.datas, totalPage: res?.data.totalPages };
      }
      return { orderForCustomer: [], totalPage: 0 };
    } catch (e) {
      handleApiError(e);
      return { orderForCustomer: [], totalPage: 0 };
    }
  }

  async fetchCurrentCustomerOrdersList(activeCustomerId: number, searchParams: SearchParams): Promise<any> {
    try {
      const params = {
        ...searchParams,
        customer_id: activeCustomerId,
        join: [...(searchParams.join as any[]), 'client', 'delegue'],
      };
      let res;
      if (activeCustomerId) {
        res = await RessourceApi.get('orders', params);
      }
      return { orderForCustomer: res?.data?.datas, totalPage: res?.data.totalPages };
    } catch (e) {
      handleApiError(e);
      return { orderForCustomer: null, totalPage: 0 };
    }
  }

  async fetchCurrentOrders(
    activeCustomerId: number,
    additionalParams: SearchParams,
    cancelTokenSource?: CancelTokenSource
  ): Promise<any> {
    try {
      const params = {
        customer_id: activeCustomerId,
        join: ['client', 'affair', 'delegue'],
        ...additionalParams,
      };
      let res;
      if (activeCustomerId) {
        res = await RessourceApi.get('orders', params);
      }
      return { orderForCustomer: res?.data?.datas, totalPage: res?.data.totalPages, totalItems: res?.data.totalItems };
    } catch (e) {
      if (axios.isCancel(e)) {
        return { datas: null, totalPage: 0, totalItems: 0, currentPage: 0 };
      }
      handleApiError(e);
      return { orderForCustomer: null, totalPage: 0, totalItems: 0 };
    }
  }

  async getOrderWorkunitInOrder(id: number) {
    try {
      const { data } = await RessourceApi.getWithAdditionalPath('orders', `${id}/order_workunits/myorder`);
      return data;
    } catch (error: unknown) {
      if (axios.isCancel(error)) {
        return { datas: null, totalPage: 0, totalItems: 0, currentPage: 0 };
      }
      return { datas: [], totalPages: 0, currentPage: 0, totalItems: 0 };
    }
  }

  async fetchCurrentOrdersListManagement(
    activeCustomerId: number,
    additionalParams: SearchParams,
    cancelTokenSource?: CancelTokenSource
  ): Promise<any> {
    try {
      const params = {
        customer_id: activeCustomerId,
        join: ['client', 'affair'],
        ...additionalParams,
      };
      let res;
      if (activeCustomerId) {
        res = await RessourceApi.get('orders/list/management', params);
        // const url = RessourceApi.createURL('orders/list/management', undefined, undefined, params);
        // res = await axios.get(url.href, {
        //   cancelToken: cancelTokenSource?.token,
        // });
      }
      return {
        orderForCustomer: res?.data?.datas,
        totalPage: res?.data.totalPages,
        totalItems: res?.data.totalItems,
        currentPage: res?.data.currentPage,
      };
    } catch (e) {
      if (axios.isCancel(e)) {
        return { orderForCustomer: null, totalPage: 0, totalItems: 0, currentPage: 0 };
      }
      handleApiError(e);
      return { orderForCustomer: [], totalPage: 0, totalItems: 0, currentPage: 0 };
    }
  }

  async getMiniDashboardData(
    orderId: Order['id'],
    scopes: Scope['id'][],
    dateFrom: string,
    dateTo: string
  ): Promise<OrderKpiBoxData | unknown> {
    try {
      const {
        data: { order: miniDashboardData },
      }: { data: { order: OrderKpiBoxData } } = await RessourceApi.post(
        'orders',
        {
          date_from: dateFrom,
          date_to: dateTo,
          scope_id: scopes,
          order_id: orderId,
        },
        orderId,
        'management/mini-dashboard'
      );

      return miniDashboardData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getHistogramData(orderId: Order['id'], params: SearchParams): Promise<Histogram | null> {
    try {
      const {
        data: { histogram: histogramData },
      } = await RessourceApi.getById('orders', orderId, 'histogram', params);
      return histogramData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getFinancialReports(orderId: Order['id']): Promise<FinancialReport[]> {
    try {
      const {
        data: { financial_reports },
      } = await RessourceApi.getById('orders', orderId, 'financial-reports');
      return financial_reports;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const isTest = process.env.NODE_ENV === 'test';
const orderApiService = isTest ? new InMemoryOrderApiService() : new OrderApiService();

export default orderApiService;
