import React from 'react';
import styled from 'styled-components/macro';
import { EditIcon } from './CustomIcons';

interface ParentCompProps {
  childComp?: React.ReactNode;
  topBlock?: boolean;
  isEditable?: boolean;
  height?: number;
  width?: number;
  dispatchOpen?: () => void;
}

const Card: React.FC<ParentCompProps> = ({ childComp, height, isEditable = false, width, dispatchOpen }) => (
  <Root height={height} width={width}>
    <Content>
      {isEditable && (
        <IconContainer onClick={dispatchOpen}>
          <EditIcon />
        </IconContainer>
      )}
      {childComp}
    </Content>
  </Root>
);

export default Card;

const Root = styled.div<ParentCompProps>`
  display: flex;
  position: relative;
  height: 120px;
  width: ${(props) => props.width}px !important;
  /* margin-right: 30px; */
`;

const Content = styled.div<ParentCompProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 30px;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07), 0 0 32px rgba(0, 0, 0, 0.07), 0 0 64px rgba(0, 0, 0, 0.07);
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  @media (min-width: 1366px) {
    width: 250px;
  }
  @media (max-width: 1366px) {
    width: 280px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  position: absolute;
  right: 16px;
  top: 23px;
  cursor: pointer;
`;
