/* eslint-disable no-nested-ternary */
import { ArrowBack, Save, Send } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { handleApiError } from 'api/errors';
import NotificationApiService from 'api/NotificationApiService';
import OrderApiService from 'api/OrderApiService';
import OrderScopeApiService from 'api/OrderScopeApiService';
import UserApiService from 'api/UserApiService';
import { AxiosError, AxiosResponse } from 'axios';
import { Layout } from 'components/index';
import SelectUser from 'components/SelectUser';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Order from 'types/entities/Order';
import OrderScope from 'types/entities/OrderScope';
import User from 'types/entities/User';
import { MessageType } from 'types/enums/MesageType';
import { OrderStatus } from 'types/enums/OrderStatus';
import OrderCard from './OrderCard/OrderCard';

interface RouteParams {
  order_id: string;
}

const OrderWorkunitsSummary: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const history = useHistory();
  const { makeCall } = useApi();
  const [displayed, setDisplayed] = useState<OrderScope[]>([]);
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const loadings = useSelector((state) => state.loadings);
  const userRoles = useUserRoles();
  const [order, setOrder] = useState<Order | Partial<Order> | null>(null);
  const [orderDetails, setOrderDetails] = useState<{ totalPrice: number; totalCharge: number }>({
    totalPrice: 0,
    totalCharge: 0,
  });

  const [possiblesTeamLeaders, setPossiblesTeamsLeaders] = useState<User[]>([]);
  useEffect(() => {
    dispatch(addLoadingAction('api'));
    if (match.params.order_id && appState.roles) {
      const fetchOrder = async () => {
        try {
          const { orderForCustomer } = await makeCall(
            OrderApiService.fetchCurrentOrdersListManagement(appState?.customer?.id as number, {
              id: Number(match.params.order_id),
              size: 1,
            })
          );

          if (orderForCustomer[0]) {
            setOrder(orderForCustomer[0]);
          }
        } catch (error) {
          const result = (error as Error).message;

          if (result) {
            dispatch(
              setSnackbarAction({ message: `Failed to retrieve order  : ${result}`, open: true, severity: 'error' })
            );
          } else dispatch(setSnackbarAction({ message: 'Failed to retrieve order ', open: true, severity: 'error' }));
        } finally {
          dispatch(removeLoadingAction('api'));
        }
      };
      const fetchOrderScopes = async () => {
        try {
          const res: OrderScope[] | undefined = await OrderScopeApiService.fetchOrderScopeById(
            Number(match.params.order_id),
            {
              join: ['scope', 'accountable'],
            }
          );
          if (res) {
            setDisplayed(res as OrderScope[]);
            setOrderDetails({
              totalPrice: res.reduce((acc, curr) => acc + curr.price, 0),
              totalCharge: res.reduce((acc, curr) => acc + curr.charge, 0),
            });
          }
        } catch (error) {
          const result = (error as Error).message;

          if (result) {
            dispatch(
              setSnackbarAction({ message: `Failed to retrieve order  : ${result}`, open: true, severity: 'error' })
            );
          } else dispatch(setSnackbarAction({ message: 'Failed to retrieve order ', open: true, severity: 'error' }));
        } finally {
          dispatch(removeLoadingAction('api'));
        }
      };
      fetchOrderScopes();
      fetchOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.order_id]);
  useEffect(() => {
    const getConsultants = async () => {
      try {
        const res = await UserApiService.fetchPossiblesTeamLeaders();

        setPossiblesTeamsLeaders(res);
      } catch (error) {
        handleApiError(error);
      }
    };

    if (appState.customer?.id) getConsultants();
  }, [appState.customer?.id]);

  const [isUserTL, setIsUserTL] = useState(true);

  /*
  This useffect is used to see if the user is a team_leader
  */
  useEffect(() => {
    setIsUserTL(() => appState.roles?.includes('team_leader') ?? false);
  }, [appState.roles]);
  const updateOrder = async () => {
    try {
      if (order) {
        const {
          customer,
          affair,
          client,
          discounts,
          price_discounted,
          'order-scopes': orderScops,
          'order-workunits': OrderWorkunits,
          price,
          catalog,
          charge,
          status,
          ...orderToSave
        } = order;
        if (status !== OrderStatus.PRODUCTION) {
          await makeCall(OrderApiService.update(Number(match.params.order_id), orderToSave));
        }
        if (displayed && displayed?.length) {
          await Promise.all(
            displayed?.map(async (orderscope) => {
              if (orderscope.accountable_id) {
                return NotificationApiService.Instance.postNotification({
                  type: MessageType.scope_new_assignation_message,
                  appendix: orderscope.id,
                  uri: `/${appState.customer?.slug}/deliverables/${order.id}`,
                  resource_name: 'mission',
                  wording: order?.ref_spec as string,
                });
              }
              return false;
            })
          );
        }
      }
    } catch (error: unknown) {
      if ((error as AxiosError)?.response?.data?.errors) {
        dispatch(
          setSnackbarAction({
            message: `Failed to update  : ${(error as AxiosError)?.response?.data?.errors[0]}`,
            open: true,
            severity: 'error',
          })
        );
      } else {
        const result = (error as Error).message;
        if (result) {
          dispatch(setSnackbarAction({ message: `Failed to update  : ${result}`, open: true, severity: 'error' }));
        } else dispatch(setSnackbarAction({ message: 'Failed to update ', open: true, severity: 'error' }));
      }
    }
  };
  const submitOrder = async () => {
    if (order) {
      const {
        affair,
        'order-scopes': orderScops,
        discounts,
        price_discounted,
        client,
        catalog,
        'order-workunits': orderWorkunits,
        status,
        ...rest
      } = order;
      try {
        dispatch(addLoadingAction('save'));
        await saveAsDraft(false);
        if (status !== OrderStatus.PRODUCTION) {
          const updated = await makeCall(
            OrderApiService.update(Number(match.params.order_id), { ...rest, status: OrderStatus.PRODUCTION })
          );
          if (updated && orderWorkunits && orderWorkunits?.length) {
            setOrder({ ...order, is_submitted: true });
            const consultantSet = new Set<number>();
            const clientSet = new Set();
            orderWorkunits.forEach((orderWorkunitCreated) => {
              if (orderWorkunitCreated.consultant_id) consultantSet.add(orderWorkunitCreated.id);
              if (orderWorkunitCreated.client_id) clientSet.add(orderWorkunitCreated.id);
            });
            await Promise.all(
              [...consultantSet]?.map(async () =>
                NotificationApiService.Instance.postNotification({
                  type: MessageType.mission_new_consultant_assignation_message,
                  appendix: order.id as number,
                  uri: `/${appState.customer?.slug}/deliverables/${order.id}`,
                  resource_name: 'mission',
                  wording: order?.reference as string,
                })
              )
            );
            await Promise.all(
              [...clientSet]?.map(async () =>
                NotificationApiService.Instance.postNotification({
                  type: MessageType.mission_new_client_assignation_message,
                  appendix: order.id as number,
                  uri: `/${appState.customer?.slug}/deliverables/${order.id}`,
                  resource_name: 'mission',
                  wording: order?.reference as string,
                })
              )
            );
          }
        }
      } catch (error) {
        const result = (error as Error).message;

        if (result) {
          dispatch(setSnackbarAction({ message: `Failed to update  : ${result}`, open: true, severity: 'error' }));
        } else dispatch(setSnackbarAction({ message: 'Failed to update ', open: true, severity: 'error' }));
      } finally {
        dispatch(removeLoadingAction('save'));
        history.push(`/${appState.customer?.slug}/orders/production?status=production`);
      }
    }
  };

  const [t] = useTranslation();
  const saveAsDraft = async (load?: boolean) => {
    if (!isUserTL) await updateOrder();

    dispatch(addLoadingAction('save'));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toSave: Promise<AxiosResponse<any>>[] = [];
    displayed.forEach((row) => {
      if (row.id) {
        toSave.push(
          OrderScopeApiService.updateOrderScope(row.id, {
            accountable_id: row.accountable_id || null,
            scope_id: row.scope_id,
            order_id: Number(match.params.order_id),
            ref_work: row.ref_work,
            ref_order: row.ref_order,
            id: row.id,
          })
        );
      } else if (!row.id && row.accountable_id) {
        toSave.push(
          OrderScopeApiService.createOrderScope({
            accountable_id: row.accountable_id || null,
            scope_id: row.scope_id,
            order_id: Number(match.params.order_id),
            ref_work: row.ref_work,
            ref_order: row.ref_order,
            id: row.id,
          })
        );
      }
    });
    try {
      await Promise.all(toSave);
    } catch (error: any) {
      const result = (error as Error).message;
      if (result) {
        dispatch(setSnackbarAction({ message: `Failed to update  : ${result}`, open: true, severity: 'error' }));
      } else dispatch(setSnackbarAction({ message: 'Failed to update ', open: true, severity: 'error' }));
    } finally {
      if (load) {
        dispatch(removeLoadingAction('save'));
      }
    }
  };

  const devises = useSelector((state) => state.app.devises);
  const [devise_name] = displayed.map((row) => row?.devise_name);

  const affectedDevise =
    devise_name && devise_name.length > 0 ? devises?.find((devise) => devise_name[0] === devise.name)?.symbol : null;

  return (
    <Layout name="Order Summary" path="/order/summary">
      {order && <OrderCard order={order as Order} menu={false} graph={false} kpi={false} />}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <TableContainer component={Box} sx={{ mb: 4 }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t('Scope')}</TableCell>
                  {/* <TableCell align="center">{t('Quantity')}</TableCell> */}
                  <TableCell align="center">{t('Validated_by')}</TableCell>
                  <TableCell align="center">{t('Ref_order_items')}</TableCell>
                  <TableCell align="center">{t('Charge')}</TableCell>
                  <TableCell align="center">{t('Price')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayed.map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    order={order}
                    devise={affectedDevise || ''}
                    team_leaders={possiblesTeamLeaders}
                    userIsClient={userRoles.isCustomer}
                    userIsTl={userRoles.isTeamLeader}
                    userIsTlPlus={userRoles.isTeamLeaderPlus}
                    setRefOrder={(e, id) =>
                      setDisplayed(
                        displayed.map((displayedRow) =>
                          displayedRow.id !== id ? displayedRow : { ...displayedRow, ref_order: e?.target?.value || '' }
                        )
                      )
                    }
                    setAccountable={(accountable: User | null, id: number) =>
                      setDisplayed(
                        displayed.map((displayedRow) =>
                          // eslint-disable-next-line no-nested-ternary
                          displayedRow.id !== id
                            ? displayedRow
                            : accountable
                            ? { ...displayedRow, accountable, accountable_id: accountable.id }
                            : { ...displayedRow, accountable: null, accountable_id: null }
                        )
                      )
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ ml: 'auto' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ mr: 2 }}>{t('Total_Price')}</Typography>
                <Typography sx={{ fontWeight: 500 }}>
                  {orderDetails?.totalPrice?.toFixed(2) || 0}
                  {affectedDevise && affectedDevise !== 'E' ? affectedDevise?.toLowerCase() : '€' ?? ''}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ mr: 2 }}>{t('Total_charge')}</Typography>
                <Typography sx={{ fontWeight: 500 }}>{orderDetails.totalCharge?.toFixed(2)}</Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {!userRoles.isCustomer && (
        <Box sx={{ display: 'flex' }}>
          <Button
            variant="contained"
            startIcon={<ArrowBack />}
            onClick={() =>
              history.push(`/${appState.customer?.slug}/orders/workunits/affectation/order/${(order as Order).id}`)
            }
            disabled={!order || !order?.id}
          >
            {t('Previous')}
          </Button>
          {!userRoles.isButl && (
            <Box sx={{ ml: 'auto' }}>
              <LoadingButton
                startIcon={<Save />}
                variant="contained"
                onClick={() => saveAsDraft(true)}
                loading={!!loadings.save}
                sx={{ mr: 1 }}
              >
                {t('Save')}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                startIcon={<Send />}
                onClick={() => submitOrder()}
                loading={!!loadings.save}
                color="success"
              >
                {t('Submit')}
              </LoadingButton>
            </Box>
          )}
        </Box>
      )}
    </Layout>
  );
};

export default OrderWorkunitsSummary;

function Row(props: {
  row: OrderScope;
  team_leaders: User[];
  userIsClient: boolean;
  userIsTl: boolean;
  userIsTlPlus: boolean;
  devise: string;
  setRefOrder: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => void;
  setAccountable: (accountable: User, id: number) => void;
  order: Order | Partial<Order> | null;
}) {
  const { row, team_leaders, setRefOrder, setAccountable, userIsClient, userIsTl } = props;
  const devises = useSelector((state) => state.app.devises);
  // const [open, setOpen] = React.useState(false);
  const [t] = useTranslation();
  const currentDevise = devises?.find((devise) => row?.devise_name[0] === devise.name);
  return (
    <>
      <TableRow>
        <TableCell>
          {/* <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton> */}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.scope?.name ? t(row?.scope?.name) : 'Not defined'}
        </TableCell>
        <TableCell align="center" width="20%">
          <SelectUser
            users={team_leaders}
            selectedUser={row?.accountable}
            disabled={userIsClient || userIsTl}
            onSelectUser={(value) => setAccountable(value as User, row.id)}
          />
        </TableCell>

        <TableCell align="center">
          <Input
            value={row.ref_order || ''}
            onChange={(e) => setRefOrder(e, row.id)}
            required
            disabled={userIsClient}
            sx={{ border: '1px solid', borderColor: 'neutral.main' }}
          />
        </TableCell>
        <TableCell align="center">{(row?.charge ?? 0).toFixed(2)} </TableCell>
        <TableCell align="center">
          {(row?.price ?? 0).toFixed(2)}
          {currentDevise?.symbol && currentDevise?.symbol !== 'E' ? currentDevise?.symbol?.toLowerCase() : '€'}
        </TableCell>
      </TableRow>
    </>
  );
}
