import React from 'react';
import ManagementCloseDialog from './ManagementClose';
import ManagementDuplicationDialog from './ManagementDuplication';
import ManagementOpenDialog from './ManagementOpen';
import TimeMaterialEditionOrder from 'features/Orders/TnMEditionOrder/TnMEditionOrder';
import { useSelector } from 'store/hooks';
import { OrdersTabState } from 'types/enums/OrdersTabState';

const ManagementDialogs: React.FC = () => {
  const dialogData = useSelector((state) => state.dialogs);
  const { management } = useSelector((state) => state);
  let isFromTM = false;

  if (
    management.pageHandler.currentTab === OrdersTabState.MATERIAL_PRODUCTION ||
    management.pageHandler.currentTab === OrdersTabState.MATERIAL_DRAFT ||
    management.pageHandler.currentTab === OrdersTabState.MATERIAL_CLOSED ||
    management.pageHandler.currentTab === OrdersTabState.MATERIAL_DELETED
  ) {
    isFromTM = true;
  }

  return (
    <>
      <ManagementDuplicationDialog isFromTM={isFromTM} />
      <ManagementCloseDialog />
      <ManagementOpenDialog />
      {dialogData?.timeMaterialEditionOrder && <TimeMaterialEditionOrder />}
    </>
  );
};

export default ManagementDialogs;
