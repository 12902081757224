import EditDrawer from './EditDrawer/EditDrawer';
import GridHandler from './GridHandler/GridHandler';
import InjectOrder from './InjectOrder/InjectOrder';
import InjectOrderWorkunitAffectation from './InjectOrderWorkunitAffectation/InjectOrderWorkunitAffectation';
import InjectScopes from './InjectScopes/InjectScopes';
import SelectItems from './SelectItems/SelectItems';
import SetAffectationClients from './SetAffectationClients/SetAffectationClients';
import SetAffectationConsultants from './SetAffectationConsultants/SetAffectationConsultants';
import SetAffectationDM from './SetAffectationDM/SetAffectationDM';
import UpdateOrderWorkunit from './UpdateOrderWorkunit/UpdateOrderWorkunit';
import UpdateSearch from './UpdateSearch/UpdateSearch';

const ReduceReducers =
  (...reducers: any[]) =>
  (prevState: any, value: any, ...args: any) =>
    reducers.reduce((newState, reducer) => reducer(newState, value, ...args), prevState);

export default ReduceReducers(
  EditDrawer,
  GridHandler,
  InjectOrder,
  InjectOrderWorkunitAffectation,
  InjectScopes,
  SelectItems,
  SetAffectationClients,
  SetAffectationConsultants,
  UpdateOrderWorkunit,
  UpdateSearch,
  SetAffectationDM
);
