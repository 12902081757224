import Customer from 'types/entities/Customer';
import MissionStatus from 'types/entities/MissionStatus';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { OrderWorkunitOrigin } from 'types/enums/OrderWorkunitOrigin';
import { userAdminB, userClientA } from './users';
import { workunitA } from './workunit';

export const orderWorkunitA: OrderWorkunit = {
  final_price: 1896.12,
  quantity: 1,
  workunit_complexity_id: 2,
  customer_id: 127,
  customer: undefined as unknown as Customer,
  orderWorkunitModifications: [],
  'order-scope': {
    scope_name: 'General Management',
    id: 58,
    ref_order: 'string',
    ref_work: 'string',
    accountable_id: 28,
    scope_id: 2,
    order_id: 27,
    devise_name: '',
    scope: {
      id: 2,
      name: 'General Management',
      customer_id: 127,
      created_at: '2023-05-11T14:12:50.000Z',
      updated_at: '2023-05-11T14:12:50.000Z',
      is_archived: false,
    },
    order: undefined,
    accountable: {
      id: 28,
      first_name: 'TL',
      last_name: 'E2E',
      mail: 'tl@e2e-test.com',
      phone: 'null',
      position: 'null',
      birthday: 'null',
      start_date: 'null',
      end_date: 'null',
      department: 'null',
      matricule: 'null',
      direction: 'null',
      is_client: false,
      is_archived: false,
      cognito_status: 'enable',
      customer_id: 127,
      last_connection: '2023-05-11T14:12:50.000Z',
      created_at: '2023-05-11T14:12:50.000Z',
      updated_at: '2023-05-11T14:12:50.000Z',
      avatar_uri: '',
      have_cognito_account: false,
      roles: [],
      'user-roles': [],
      accepted_terms_of_use: 0,
      connection_attempt: 0,
      status: 'active',
    },
    start_date: 'string',
    end_date: 'string',
    rating: 'string',
    price: 12,
    charge: 1,
    'order-workunits': [],
  },
  id: 176878,
  delivery_date: '2023-01-31',
  forecast_date: '2023-01-31',
  order_id: 18,
  workunit_id: 1,
  workunit: workunitA,
  client_id: null,
  consultant_id: null,
  client_fullname: '',
  consultant_fullname: '',
  price: 1896.12,
  charge: 4.98979,
  rating: 'A',
  mission_advancement_id: 7,
  mission_status_id: 0,
  mission_frequency_id: 0,
  scope_id: 2,
  order_scope_id: 58,
  devise_name: 'Euro',
  complexity_name: '-',
  is_archived: false,
  workunit_name: 'Dossier Synthèse Ticket Entrée / Budget GM',
  workunit_desc: '-',
  workunit_accept_standard: '-',
  workunit_level_standard: '-',
  workunit_reference: 'BC.1.1 HCC',
  purchase_order: 'BC.1.1 HCC',
  workunit_input: '-',
  workunit_output: '-',
  workunit_comment: '-',
  content: ' ',
  content2: ' ',
  content3: ' ',
  content4: ' ',
  content5: ' ',
  origin: OrderWorkunitOrigin.QUOTE,
  label: 'quote',
  cancellation_reason: null,
  review: 'pending',
  modification_reason: null,
  discount: 0,
  created_at: '2023-01-16T09:40:41.000Z',
  updated_at: '2023-02-24T16:33:56.000Z',
  scope: {
    id: 2,
    name: 'WP1',
    is_archived: false,
    customer_id: 1,
    created_at: '2023-01-09T07:57:07.000Z',
    updated_at: '2023-01-09T07:57:07.000Z',
  },

  'mission-frequency': undefined,
  'mission-status': undefined as unknown as MissionStatus,
  'mission-comments': [
    {
      id: 1,
      comment: 'GOOD',
      is_private: false,
      rating: null,
      author_id: 6,
      mission_advancement_id: 7,
      mission_id: 0,
      order_workunit_id: 176878,
      created_at: '2023-01-17T11:15:07.000Z',
      author: {
        id: 6,
        first_name: 'CLIENT',
        last_name: 'CLIENT',
        mail: 'client@test.com',
        phone: '0600000001',
        position: '',
        birthday: 'null',
        start_date: 'null',
        end_date: 'null',
        department: 'null',
        matricule: 'client@test.com',
        direction: 'null',
        is_client: true,
        is_archived: false,
        cognito_status: 'enable',
        customer_id: 1,
        created_at: 'null',
        updated_at: '2023-02-16T16:08:08.000Z',
        avatar_uri: '',
        have_cognito_account: false,
        roles: [],
        last_connection: '',
        'user-roles': [],
        accepted_terms_of_use: 0,
        connection_attempt: 0,
        status: 'active',
      },
    },
  ],
  'mission-advancement': { id: 7, name: 'Accepted' },
  consultant: null,
  client: null,
  delivery_manager: [],
  delivery_manager_ids: [],
};
export const orderWorkunitB = {
  final_price: 1896.12,
  id: 176879,
  delivery_date: '2023-01-31',
  forecast_date: '2023-01-31',
  order_id: 18,
  workunit_id: 1,
  client_id: null,
  consultant_id: null,
  client_fullname: null,
  consultant_fullname: null,
  price: 1896.12,
  charge: 4.98979,
  order_number: null,
  cdc_ws_number: null,
  rating: null,
  mission_advancement_id: null,
  mission_status_id: null,
  mission_frequency_id: null,
  scope_id: 2,
  order_scope_id: 58,
  devise_name: 'Euro',
  complexity_name: '-',
  is_archived: false,
  workunit_name: 'Dossier Synthèse Ticket Entrée / Budget GM',
  workunit_desc: '-',
  workunit_accept_standard: '-',
  workunit_level_standard: '-',
  workunit_reference: 'BC.1.1 HCC',
  purchase_order: 'BC.1.1 HCC',
  workunit_input: '-',
  workunit_output: '-',
  workunit_comment: '-',
  content: ' ',
  content2: ' ',
  content3: ' ',
  content4: ' ',
  content5: ' ',
  origin: 'quote',
  label: 'quote',
  cancellation_reason: null,
  review: 'pending',
  modification_reason: null,
  discount: 0,
  created_at: '2023-01-16T09:40:41.000Z',
  updated_at: '2023-02-24T16:33:56.000Z',
  scope: {
    id: 2,
    name: 'WP1',
    is_archived: false,
    customer_id: 1,
    created_at: '2023-01-09T07:57:07.000Z',
    updated_at: '2023-01-09T07:57:07.000Z',
  },
  workunit: {
    id: 2,
    reference: 'BC.1.1 HCC',
    name: 'Dossier Synthèse Ticket Entrée / Budget GM',
    desc: '-',
    accept_standard: '-',
    level_standard: '-',
    input: '-',
    output: '-',
    comment: '-',
    business_unit: 'd',
    skill: 'null',
    price: 1896.12,
    charge: 4.98979,
    is_archived: false,
    catalog_id: 2,
    devise_id: 2,
    customer_id: 1,
    complexity_id: 4,
    created_at: '2023-01-10T16:45:58.000Z',
    updated_at: '2023-01-10T16:45:58.000Z',
  },
  'mission-frequency': null,
  'mission-status': null,
  'mission-comments': [],
  'mission-advancement': { id: 5, name: 'Acceptation refused', created_at: null, updated_at: null },
  consultant: null,
  client: null,
};
export const orderWorkunitC = {
  final_price: 1896.12,
  id: 176880,
  delivery_date: '2023-01-31',
  forecast_date: '2023-01-31',
  order_id: 18,
  workunit_id: 2,
  client_id: null,
  consultant_id: null,
  client_fullname: null,
  consultant_fullname: null,
  price: 1896.12,
  charge: 4.98979,
  order_number: null,
  cdc_ws_number: null,
  rating: null,
  mission_advancement_id: 1,
  mission_status_id: null,
  mission_frequency_id: null,
  scope_id: 2,
  order_scope_id: 58,
  devise_name: 'Euro',
  complexity_name: '-',
  is_archived: false,
  workunit_name: 'Dossier Synthèse Ticket Entrée / Budget GM',
  workunit_desc: '-',
  workunit_accept_standard: '-',
  workunit_level_standard: '-',
  workunit_reference: 'BC.1.1 HCC',
  purchase_order: 'BC.1.1 HCC',
  workunit_input: '-',
  workunit_output: '-',
  workunit_comment: '-',
  content: ' ',
  content2: ' ',
  content3: ' ',
  content4: ' ',
  content5: ' ',
  origin: 'quote',
  label: 'quote',
  cancellation_reason: null,
  review: 'pending',
  modification_reason: null,
  discount: 0,
  created_at: '2023-01-16T09:40:41.000Z',
  updated_at: '2023-02-24T16:33:56.000Z',
  scope: {
    id: 2,
    name: 'WP1',
    is_archived: false,
    customer_id: 1,
    created_at: '2023-01-09T07:57:07.000Z',
    updated_at: '2023-01-09T07:57:07.000Z',
  },
  workunit: {
    id: 2,
    reference: 'BC.1.1 HCC',
    name: 'Dossier Synthèse Ticket Entrée / Budget GM',
    desc: '-',
    accept_standard: '-',
    level_standard: '-',
    input: '-',
    output: '-',
    comment: '-',
    business_unit: 'd',
    skill: 'null',
    price: 1896.12,
    charge: 4.98979,
    is_archived: false,
    catalog_id: 2,
    devise_id: 2,
    customer_id: 1,
    complexity_id: 4,
    created_at: '2023-01-10T16:45:58.000Z',
    updated_at: '2023-01-10T16:45:58.000Z',
  },
  'mission-frequency': null,
  'mission-status': null,
  'mission-comments': [],
  'mission-advancement': { id: 1, name: 'Submitted', created_at: null, updated_at: null },
  consultant: null,
  client: null,
};
export const orderWorkunits = [orderWorkunitA, orderWorkunitB, orderWorkunitC];

export const orderWorkunitValidatedDM: OrderWorkunit[] = [
  { ...orderWorkunitA, mission_advancement_id: 4 },
  { ...orderWorkunitB, mission_advancement_id: 4 },
  { ...orderWorkunitC, mission_advancement_id: 4 },
] as unknown as OrderWorkunit[];

export const orderWorkunitCancelled: OrderWorkunit[] = [
  {
    ...orderWorkunitA,
    mission_advancement_id: 4,
    cancellation_reason: 'test',
    label: 'cancelled',
    origin: OrderWorkunitOrigin.QUOTE,
  },
  {
    ...orderWorkunitB,
    mission_advancement_id: 4,
    cancellation_reason: 'test',
    label: 'cancelled',
    origin: OrderWorkunitOrigin.QUOTE,
  },
  {
    ...orderWorkunitC,
    mission_advancement_id: 4,
    cancellation_reason: 'test',
    label: 'cancelled',
    origin: OrderWorkunitOrigin.QUOTE,
  },
] as unknown as OrderWorkunit[];

export const orderWorkunitCancelledErrors: OrderWorkunit[] = [
  {
    ...orderWorkunitA,
    mission_advancement_id: 4,
    cancellation_reason: 'test',
    label: 'custom',
    origin: OrderWorkunitOrigin.CUSTOM,
  },
  {
    ...orderWorkunitB,
    mission_advancement_id: 4,
    cancellation_reason: 'test',
    label: 'custom',
    origin: OrderWorkunitOrigin.CUSTOM,
  },
  {
    ...orderWorkunitC,
    mission_advancement_id: 4,
    cancellation_reason: 'test',
    label: 'cancelled',
    origin: OrderWorkunitOrigin.QUOTE,
  },
] as unknown as OrderWorkunit[];

export const orderWorkunitNotSubmitted: OrderWorkunit[] = [
  { ...orderWorkunitA, mission_advancement_id: null },
  { ...orderWorkunitB, mission_advancement_id: null },
] as unknown as OrderWorkunit[];
export const orderWorkunitSubmitted: OrderWorkunit[] = [
  { ...orderWorkunitA, mission_advancement_id: 1 },
  { ...orderWorkunitB, mission_advancement_id: 1 },
] as unknown as OrderWorkunit[];
export const orderWorkunitRefused: OrderWorkunit[] = [
  { ...orderWorkunitA, mission_advancement_id: 2 },
  { ...orderWorkunitB, mission_advancement_id: 2 },
] as unknown as OrderWorkunit[];
export const orderWorkunitMIGSOReserve: OrderWorkunit[] = [
  { ...orderWorkunitA, mission_advancement_id: 3 },
  { ...orderWorkunitB, mission_advancement_id: 3 },
] as unknown as OrderWorkunit[];
export const orderWorkunitClientReserve: OrderWorkunit[] = [
  { ...orderWorkunitA, mission_advancement_id: 6 },
  { ...orderWorkunitB, mission_advancement_id: 6 },
] as unknown as OrderWorkunit[];
export const orderWorkunitAcceptedByClient: OrderWorkunit[] = [
  { ...orderWorkunitA, mission_advancement_id: 7, 'mission-comments': [], rating: 'A' },
  {
    ...orderWorkunitB,
    mission_advancement_id: 7,
    rating: 'E',
    last_validator: userClientA.id,
    'mission-comments': [
      {
        id: 1,
        comment: 'test',
        order_workunit_id: orderWorkunitB.id,
        author_id: userClientA.id,
        author: userClientA,
        created_at: '2023-01-16T09:40:41.000Z',
        rating: null,
        mission_id: null,
        is_private: false,
        mission_advancement_id: MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
      },
    ],
  },
] as unknown as OrderWorkunit[];

export const orderWorkunitNotAcceptedByClient: OrderWorkunit[] = [
  { ...orderWorkunitA, mission_advancement_id: 7, 'mission-comments': [], rating: null },
  {
    ...orderWorkunitB,
    rating: 'B',
    mission_advancement_id: 4,
    'mission-comments': [
      {
        id: 1,
        comment: 'test',
        order_workunit_id: orderWorkunitB.id,
        author_id: userClientA.id,
        author: userClientA,
        created_at: '2023-01-16T09:40:41.000Z',
        rating: null,
        mission_id: null,
        is_private: false,
        mission_advancement_id: MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
      },
    ],
  },
] as unknown as OrderWorkunit[];
export const orderWorkunitRefusedByClient: OrderWorkunit[] = [
  { ...orderWorkunitA, mission_advancement_id: 5 },
  { ...orderWorkunitB, mission_advancement_id: 5 },
] as unknown as OrderWorkunit[];
export const orderWorkunitsNotSubmittedDelete: OrderWorkunit[] = [
  { ...orderWorkunitA, mission_advancement_id: null, origin: OrderWorkunitOrigin.CATALOG },
  { ...orderWorkunitB, mission_advancement_id: null, origin: OrderWorkunitOrigin.CUSTOM },
  { ...orderWorkunitC, mission_advancement_id: null, origin: OrderWorkunitOrigin.CUSTOM },
  {
    ...orderWorkunitC,
    id: orderWorkunitC.id + 14983,
    mission_advancement_id: null,
    origin: OrderWorkunitOrigin.CUSTOM,
  },
] as unknown as OrderWorkunit[];

export const orderWorkunitsDuplication: OrderWorkunit[] = [
  { ...orderWorkunitA, workunit: undefined, origin: OrderWorkunitOrigin.CUSTOM },
  { ...orderWorkunitB, workunit: undefined, mission_advancement_id: null, origin: OrderWorkunitOrigin.CUSTOM },
  { ...orderWorkunitC, workunit: workunitA, mission_advancement_id: null, origin: OrderWorkunitOrigin.CATALOG },
] as unknown as OrderWorkunit[];

export const orderWorkunitsModifyWithReason: OrderWorkunit[] = [
  { ...orderWorkunitA, workunit: undefined, origin: OrderWorkunitOrigin.CUSTOM },
  { ...orderWorkunitB, workunit: undefined, mission_advancement_id: null, origin: OrderWorkunitOrigin.CUSTOM },
  { ...orderWorkunitC, workunit: workunitA, mission_advancement_id: null, origin: OrderWorkunitOrigin.CATALOG },
] as unknown as OrderWorkunit[];

export const orderWorkunitsWithComments: OrderWorkunit[] = [
  {
    ...orderWorkunitA,
    'mission-comments': [
      {
        id: 1,
        comment: 'orderWorkunitA test comment',
        order_workunit_id: orderWorkunitA.id,
        author_id: userClientA.id,
        mission_id: null,
        rating: null,
        is_private: false,
        mission_advancement_id: 1,
        created_at: '2023-01-16T09:40:41.000Z',
      },
    ],
  },
  {
    ...orderWorkunitB,
    'mission-comments': [
      {
        id: 2,
        comment: 'orderWorkunitB test comment',
        order_workunit_id: orderWorkunitB.id,
        author_id: userAdminB.id,
        mission_id: null,
        rating: null,
        is_private: true,
        mission_advancement_id: 1,
        created_at: '2023-01-16T09:40:41.000Z',
      },
    ],
  },
] as unknown as OrderWorkunit[];
