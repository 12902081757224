import React from 'react';

import DeliverableReportsCreateReportModal from '../DeliverableReports/DeliverableReportsCreateReportModal/DeliverableReportsCreateReportModal';
import { DeliverableCancelReportMode } from './DeliverableCancelReportMode/DeliverableCancelReportMode';
import DeliverableCreateReportConfirm from './DeliverableCreateReportConfirm/DeliverableCreateReportConfirm';
import DeliverableDialogCancelAcceptance from './DeliverableDialogCancelAcceptance/DeliverableDialogCancelAcceptance';
import DeliverableDialogCancelAssessment from './DeliverableDialogCancelAssessment/DeliverableDialogCancelAssessment';
import DeliverableDialogChangeAdvancement from './DeliverableDialogChangeAdvancement/DeliverableDialogChangeAdvancement';
import DeliverableDialogDelete from './DeliverableDialogDelete/DeliverableDialogDelete';
import DeliverableDialogRate from './DeliverableDialogRate/DeliverableDialogRate';
import DeliverableSubmissionCancellation from './DeliverableSubmissionCancellation/DeliverableSubmissionCancellation';
import DeliverableConfirmationDialog from './DeliverableConfirmationDialog/DeliverableConfirmationDialog';

const DeliverableDialogs: React.FC = () => (
  <>
    <DeliverableDialogRate />
    <DeliverableDialogDelete />
    <DeliverableDialogCancelAcceptance />
    <DeliverableDialogCancelAssessment />
    <DeliverableDialogChangeAdvancement />
    <DeliverableCancelReportMode />
    <DeliverableReportsCreateReportModal />
    <DeliverableCreateReportConfirm />
    <DeliverableSubmissionCancellation />
    <DeliverableConfirmationDialog />
  </>
);

export default DeliverableDialogs;
