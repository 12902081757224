export const DrawerActions = {
  OPEN_DRAWER: 'OPEN_DRAWER',
  CLOSE_DRAWER: 'CLOSE_DRAWER',
} as const;

export const openDrawerAction = (payload: { name: string; data?: any }) => ({
  type: DrawerActions.OPEN_DRAWER,
  payload,
});

export const closeDrawerAction = () => ({
  type: DrawerActions.CLOSE_DRAWER,
});

export const drawerActions = {
  openDrawerAction,
  closeDrawerAction,
};
