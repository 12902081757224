import { CircularProgress } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import './Loader.scss';

type Props = {
  size?: number;
  className?: string;
};

const Loader: React.FC<Props> = ({ size, className = '' }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${className}`}>
      <CircularProgress size={size || 50} className={className} />
    </div>
  );
};

export default Loader;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);
