import { Layout } from 'components/index';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './Dashboard.scss';
import DashboardKpiSliders from './DashboardKpiSliders/DashboardKpiSliders';
import DashboardQualitySlider from './DashboardQualitySlider/DashboardQualitySlider';
import OrderCardSlider from './OrderCardSlider/OrderCardSlider';
import { Grid } from '@mui/material';

const DashboardPage: React.FC<RouteComponentProps> = ({ match }) => (
  <Layout name="Dashboard" path="" isWelcome>
    <Grid container spacing={4} sx={{ mb: 4 }}>
      <Grid item sm={12} md={6}>
        <DashboardKpiSliders />
      </Grid>
      <Grid item sm={12} md={6}>
        <DashboardQualitySlider />
      </Grid>
    </Grid>
    <OrderCardSlider />
  </Layout>
);

export default DashboardPage;
