import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import OrderApiService from 'api/OrderApiService';
import { Loader } from 'components';
import useApi from 'hooks/useApi';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import Order from 'types/entities/Order';
import OrderCard from './OrderCard';

const OrderCardSlider = () => {
  const appState = useSelector((state) => state.app);
  const loadings = useSelector((state) => state.loadings);
  const [t] = useTranslation();
  const [orders, setOrders] = React.useState<Order[]>([]);
  const [scrollData, setScrollData] = React.useState({ page: 0, totalPage: 1, totalItems: 1 });
  const [isLoading, setIsLoading] = React.useState(false);
  const { makeCall } = useApi();
  useEffect(() => {
    async function fetchOrders() {
      setIsLoading(true);

      try {
        const attrCall = [
          'id',
          'draft_reference',
          'reference',
          'name_spec',
          'ref_spec',
          'start_date',
          'affair_otp',
          'affair_name',
          'end_date',
          'status',
          'accepted_deliverables_price',
          'realised_deliverables_price',
          'nb_order_workunits',
          'nb_submitted_deliverables',
          'nb_realised_deliverables',
          'nb_accepted_deliverables',
          'amount_charge',
          'amount_price',
          'final_amount_price',
          'percentage_of_realised_deliverables',
          'percentage_of_submitted_deliverables',
          'percentage_of_accepted_deliverables',
          'created_at',
          'ref_work',
          'devise_name',
        ];
        const {
          orderForCustomer: ordersFetched,
          totalPage,
          totalItems,
        } = await makeCall(
          OrderApiService.fetchCurrentOrders(appState.customer?.id as number, {
            is_archived: 0,
            status: 'production',
            size: 3,
            page: scrollData.page,
            attr: attrCall.join('.'),
          }),
          'Error while fetching orders',
          'loadingDashboardOrders'
        );
        setOrders(ordersFetched);
        setScrollData({ ...scrollData, totalPage, totalItems });
      } finally {
        setIsLoading(false);
      }
    }

    if (appState.customer?.id) {
      fetchOrders();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollData.page]);
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Typography variant="h2" component="div" color="primary">
          {t('Orders')}
        </Typography>
        <Box sx={{ ml: 'auto' }}>
          <IconButton
            onClick={() => setScrollData({ ...scrollData, page: scrollData.page - 1 })}
            size="large"
            disabled={scrollData.page === 0 || !!loadings.loadingDashboardOrders}
            color="primary"
            sx={{ flexShrink: 0, m: 1 }}
          >
            <ArrowLeft />
          </IconButton>
          <IconButton
            onClick={() => setScrollData({ ...scrollData, page: scrollData.page + 1 })}
            size="large"
            disabled={scrollData.page === scrollData.totalPage - 1 || !!loadings.loadingDashboardOrders}
            color="primary"
            sx={{ flexShrink: 0, m: 1 }}
          >
            <ArrowRight />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={4}>
        {!isLoading ? (
          orders
            ?.sort((a, b) => +new Date(a.end_date) - +new Date(b.end_date))
            ?.map((order) => <OrderCard order={order} key={order.id} />)
        ) : (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default OrderCardSlider;
