import User from 'types/entities/User';

export const userClientA: User = {
  id: 2,
  first_name: 'Jane',
  last_name: 'Doe',
  mail: 'janedoe@example.com',
  phone: '9876543210',
  position: 'Product Manager',
  birthday: '1995-05-05',
  start_date: '2021-01-01',
  end_date: '2023-01-01',
  department: 'Product',
  customer_id: 2,
  matricule: 'DEF456',
  direction: 'West',
  is_archived: false,
  is_client: true,
  cognito_status: 'enable',
  avatar_uri: '/path/to/avatar',
  created_at: '2022-04-11T09:00:00.000Z',
  updated_at: '2022-04-12T11:00:00.000Z',
  accepted_terms_of_use: 0,
  connection_attempt: 0,
  status: 'active',
  have_cognito_account: true,
  roles: [
    {
      id: 1,
      name: 'client',
      is_archived: false,
    },
  ],
  'user-roles': [
    {
      id: 1,
      user_id: 2,
      tenant_slug: 'customer-2',
      role_name: 'client',
    },
  ],
  last_connection: '2022-04-11T09:00:00.000Z',
};
export const userAdminB: User = {
  id: 1,
  first_name: 'John',
  cognito_status: 'enable',
  last_name: 'Doe',
  mail: 'johndoe@example.com',
  phone: '1234567890',
  position: 'Software Engineer',
  birthday: '1990-01-01',
  start_date: '2020-01-01',
  end_date: '2022-01-01',
  department: 'Engineering',
  customer_id: 1,
  matricule: 'ABC123',
  direction: 'North',
  is_archived: false,
  is_client: false,
  avatar_uri: '/path/to/avatar',
  created_at: '2022-04-12T10:00:00.000Z',
  updated_at: '2022-04-12T12:00:00.000Z',
  have_cognito_account: true,
  accepted_terms_of_use: 0,
  connection_attempt: 0,
  status: 'active',
  roles: [{ id: 2, name: 'admin', is_archived: false }],
  'user-roles': [{ id: 2, role_name: 'admin' }],
  last_connection: '2022-04-12T10:00:00.000Z',
};

export const userC: User = {
  id: 3,
  first_name: 'John',
  last_name: 'Smith',
  cognito_status: 'enable',

  mail: 'johnsmith@example.com',
  phone: '5551234567',
  position: 'Software Engineer',
  birthday: '1990-02-14',
  start_date: '2018-07-01',
  end_date: '2022-01-01',
  department: 'Engineering',
  customer_id: 3,
  matricule: 'GHI789',
  direction: 'East',
  is_archived: false,
  is_client: false,
  accepted_terms_of_use: 0,
  connection_attempt: 0,
  status: 'active',
  avatar_uri: '/path/to/avatar',
  created_at: '2022-04-12T13:00:00.000Z',
  updated_at: '2022-04-12T13:00:00.000Z',
  have_cognito_account: false,
  roles: [
    {
      id: 2,
      name: 'consultant',
      is_archived: false,
    },
  ],
  'user-roles': [
    {
      id: 2,
      user_id: 3,
      tenant_slug: 'customer-3',
      role_name: 'consultant',
    },
  ],
  last_connection: '2022-04-12T13:00:00.000Z',
};

const users: User[] = [userClientA, userAdminB, userC];

export default users;
