import { GridCellParams } from '@mui/x-data-grid-pro';
import { isRowInEditMode } from 'features/Orders/OrderWorkunits/helpersOrderWorkunits';
import OrderAffectationScopeCell from 'features/Orders/OrderWorkunits/OrderWorkunitsDataGrids/Cells/DataGridScopeCell/OrderAffectationScopeCell';
import React from 'react';
import { useSelector } from 'store/hooks';
import { SelectedWorkunit } from 'types/entities/SelectedWorkunit';

export default function ScopeCell(props: { params: GridCellParams<any, SelectedWorkunit, any> }) {
  const { params } = props;
  const activeEditRowIds = useSelector((state) => state.catalogToOrder.selectedWorkunitsEditIds);
  if (isRowInEditMode(activeEditRowIds, params.row.randomId)) {
    return <OrderAffectationScopeCell row={params.row as SelectedWorkunit} cellType="selected" />;
  }

  return <>{(params.row.scope && params.row.scope.name) || 'General'}</>;
}
