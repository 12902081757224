import React, { Dispatch, useContext, useReducer } from 'react';
import SheetContextReducers from './_reducers';
import { PossibleSheetActions } from './SheetContext.helper';
import { InitialDeliverableSheetType, initialValue } from './intialValue';

const SheetContext = React.createContext<{
  state: InitialDeliverableSheetType;
  dispatch: Dispatch<PossibleSheetActions>;
}>({
  state: initialValue,
  dispatch: () => null,
});
SheetContext.displayName = 'SheetContext';

const SheetProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(SheetContextReducers, initialValue);

  return <SheetContext.Provider value={{ state, dispatch }}>{children}</SheetContext.Provider>;
};
export { SheetProvider, SheetContext };
export const useSheetContext = () => useContext(SheetContext);
