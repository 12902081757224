import { Tooltip, Typography } from '@mui/material';
import { trim } from 'lodash';
import React, { useMemo } from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const DeliveryManagerCell = React.memo((row: OrderWorkunit) => {
  const getName = useMemo(
    () =>
      row.delivery_manager && row.delivery_manager[0]
        ? trim(` ${row.delivery_manager[0].last_name.toUpperCase() || ''} ${row.delivery_manager[0].first_name || ''}`)
        : '',
    [row]
  );

  return (
    <Tooltip title={getName}>
      <Typography variant="body2" noWrap>
        {getName}
      </Typography>
    </Tooltip>
  );
});

export default DeliveryManagerCell;
