import { FilterItem, FilterParams } from 'types/models/FilterItem';
import { SelectedFiltersList } from 'types/models/SelectedFiltersList';

export interface FilterState {
  params?: FilterParams;
  items: FilterItem[];
  selected: SelectedFiltersList;
}

export const filterState: FilterState = {
  params: undefined,
  items: [],
  selected: {},
};
