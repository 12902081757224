import { reduce } from 'lodash';
import { FilterState } from '../states/filterState';

export const clearSelectedFilters = (selected: FilterState['selected']) =>
  reduce(
    selected,
    (result: FilterState['selected'], value, key) => {
      result[key] = [];
      return result;
    },
    {}
  );

export const clearResetAllFilters = (selected: FilterState['selected']) =>
  reduce(
    selected,
    (result: FilterState['selected'], value, key) => {
      // console.log('Processing key:', key); // Print the key
      if (key !== 'date_start' && key !== 'date_end') {
        result[key] = [];
      } else {
        result[key] = value;
      }
      return result;
    },
    {}
  );
