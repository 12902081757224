import CatalogApiService from 'api/CatalogApiService';
import OrderApiService from 'api/OrderApiService';
import OrderWorkunitApiService from 'api/OrderWorkunitApiService';
import ScopeApiService from 'api/ScopeApiService';
import TenantWorkloadApiService from 'api/TenantWorkloadApiService';
import WorkunitApiService from 'api/WorkunitApiService';
import { sortedTenantWorkload } from 'helpers/utils';
import useApi from 'hooks/useApi';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  addScopeAction,
  changeWorkunitsScopeAction,
  clearWorkunitScopeAction,
  injectCatalogComplexities,
  injectOrderAction,
  injectScopesAction,
  injectSelectedWorkunitsAction,
  injectTenantWorkloadAction,
  injectWorkunitsAction,
} from 'store/actions/catalogToOrderActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Catalog from 'types/entities/Catalog';
import CatalogComplexity from 'types/entities/CatalogComplexity';
import Order from 'types/entities/Order';
import Scope from 'types/entities/Scope';
import { OrderStatus } from 'types/enums/OrderStatus';
import { generateRandomId } from '../helpersOrderWorkunits';
import useOrderWorkunitsService from './useOrderWorkunitsService';

const useOrderWorkunitApi = () => {
  const { makeCall } = useApi();
  const { dispatch } = useAppState();
  const history = useHistory();
  const order = useSelector((state) => state.catalogToOrder.order);

  const customer = useSelector((state) => state.app.customer);
  const { formateRows, generateMonthsQuarters, createBodyPostBulkOrderWorkunits, downloadExcel } =
    useOrderWorkunitsService();

  const addScope = useCallback(
    async (name: string) => {
      const {
        data: { scope },
      } = await makeCall(
        ScopeApiService.postScope({ name, customer_id: customer?.id as number }),
        'Unable to save new scope'
      );
      dispatch(addScopeAction(scope));
      return scope;
    },
    [customer?.id, dispatch, makeCall]
  );

  const changeWorkunitsScope = useCallback(
    async (scope: Scope | null, rowId: number, target: 'catalog' | 'selected', clear = false) => {
      if (scope && !scope.id && scope.name) {
        await addScope(scope.name);
      }
      if (clear || scope?.name === '') {
        dispatch(clearWorkunitScopeAction(rowId, target));
      } else if (scope && scope.id && rowId) {
        dispatch(changeWorkunitsScopeAction(scope, rowId, target));
      }
    },
    [addScope, dispatch]
  );

  const updateOrderStatus = useCallback(
    async (order: Order) => {
      const { affair, client, price_discounted, discounts, 'order-workunits': owu, status, ...orderToSend } = order;
      await makeCall(OrderApiService.update(orderToSend.id, { ...orderToSend, status: OrderStatus.PENDING }));
    },
    [makeCall]
  );

  const fetchWorkunitsInCatalog = React.useCallback(
    async (catalogId: number) => {
      const timeValues = generateMonthsQuarters();
      if (timeValues) {
        dispatch(addLoadingAction('fetchWorkunitsInCatalog'));
        const res = await makeCall(
          WorkunitApiService.get(catalogId, {
            join: ['complexity'],
            size: -1,
          })
        );
        const rowsToDisplay = formateRows(res.datas, timeValues);
        dispatch(injectWorkunitsAction(rowsToDisplay));
        dispatch(removeLoadingAction('fetchWorkunitsInCatalog'));
      }
    },
    [dispatch, formateRows, generateMonthsQuarters, makeCall]
  );

  const handleUpdateOrderAction = async (order: Order) => {
    dispatch(addLoadingAction('updateOrderDrawerEdit'));
    const { status, ...orderUpdate } = order;
    await makeCall(OrderApiService.update(order.id, orderUpdate));
    if (order) {
      dispatch(injectOrderAction(order));
    }
    dispatch(removeLoadingAction('updateOrderDrawerEdit'));
  };

  const getOrderWorkunits = useCallback(
    async (orderId: number) => {
      dispatch(addLoadingAction('getOrderWorkunits'));
      const data = await makeCall(OrderApiService.getOrderWorkunitInOrder(Number(orderId)));
      if (data.order_workunits && data.order_workunits.length > 0) {
        const datas = [];
        for (let i = 0; i < data.order_workunits.length; i += 1) {
          datas.push({
            ...data.order_workunits[i],
            randomId: Number(generateRandomId() + i),
          });
        }
        dispatch(
          injectSelectedWorkunitsAction(
            datas.map((owu: any, i: number) => ({
              ...owu,
              name: owu.workunit.name,
              reference: owu.workunit.reference,
              workload: owu.charge,
              scope_id: owu.scope.id,
              total: owu.months.reduce((acc: number, month: any) => acc + month.qte, 0),
              months: owu.months.map((month: any) => ({
                ...month,
                quantity: month.qte,
              })),
            }))
          )
        );
      }

      dispatch(removeLoadingAction('getOrderWorkunits'));
    },
    [dispatch, makeCall]
  );

  const getScopes = async () => {
    dispatch(addLoadingAction('getScopes'));

    if (customer && customer.id) {
      const scopesRetrieved = await makeCall(
        ScopeApiService.fetchByCustomerId(customer.id),
        'Unable to retrieve scopes'
      );
      dispatch(injectScopesAction(scopesRetrieved));
    }

    dispatch(removeLoadingAction('getScopes'));
  };

  const fetchOrder = async (orderId: number) => {
    dispatch(addLoadingAction('fetchOrder'));
    const orderFetched = await makeCall(
      OrderApiService.getOrderById(Number(orderId), {
        join: ['affair', 'client', 'creator', 'delegue'],
      })
    );

    if (orderFetched) {
      if (!['drafted', 'pending', 'production'].includes(orderFetched.status)) {
        history.push(`/${customer?.slug}/orders/quotes?status=pending`);
      }
      dispatch(injectOrderAction(orderFetched));
    }
  };

  const getTenantWorkloads = async (order: Order) => {
    const yearsParams = `&date=%3E=${moment(order?.start_date).startOf('month').format('YYYY-MM-DD')}%3B%3C=${moment(
      order?.end_date
    )
      .add(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD')}&tenant_id=${customer?.id}&is_monthly=1`;
    const res = await TenantWorkloadApiService.fetchAllWorkloads(yearsParams);
    if (res.length) {
      dispatch(injectTenantWorkloadAction(sortedTenantWorkload(res).filter((r) => r.is_monthly)));
    }
  };
  const getCatalogComplexities = async (catalogId: Catalog['id']) => {
    const catalogComplexities = await CatalogApiService.fetchById(catalogId, {
      join: 'complexities',
    });
    if (catalogComplexities && catalogComplexities.complexities) {
      dispatch(
        injectCatalogComplexities(catalogComplexities.complexities.filter((c: CatalogComplexity) => !c.is_archived))
      );
    }
  };

  const bulkCreateOrderWorkunits = async () => {
    if (order) {
      const orderWorkunits = createBodyPostBulkOrderWorkunits();
      await makeCall(
        OrderWorkunitApiService.postBulkOrderWorkunits({
          order_id: order?.id,
          order_workunits: orderWorkunits,
        })
      );
    }
  };

  const exportExcel = async () => {
    if (order) {
      const excel = await makeCall(
        OrderApiService.getOrderExcel(order.id),
        'Error while trying to create file to export',
        'extract_excel_summary'
      );
      downloadExcel(excel);
    }
  };

  return {
    addScope,
    changeWorkunitsScope,
    fetchWorkunitsInCatalog,
    handleUpdateOrderAction,
    getOrderWorkunits,
    getScopes,
    fetchOrder,
    getCatalogComplexities,
    getTenantWorkloads,
    updateOrderStatus,
    bulkCreateOrderWorkunits,
    exportExcel,
  };
};

export default useOrderWorkunitApi;
