import { Button, Menu, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { useSelector } from 'store/hooks';
import styled from 'styled-components/macro';
import Affair from 'types/entities/Affair';
import Catalog from 'types/entities/Catalog';
import Workunit from 'types/entities/Workunit';

interface Props {
  activeCatalog?: Catalog;
  activeWorkunit?: Workunit;
  activeAffairs?: Affair[];
  list?: string[] | number[];
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  selected: number;
  fontSize?: number;
}

const MenuDropDown: React.FC<Props> = ({
  activeCatalog,
  activeAffairs,
  activeWorkunit,
  list,
  setSelected,
  selected,
  fontSize,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const complexities = useSelector((state) => state.app.catalogComplexities);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index: number) => {
    handleClose();
    if (setSelected) {
      setSelected(index);
    }
  };

  return (
    <>
      {activeCatalog && activeCatalog.complexities && (
        <Container>
          <ButtonMenu aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            {activeCatalog.complexities[0].name}
          </ButtonMenu>
          <MenuCustom
            id="simple-menu-catalog"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {activeCatalog.complexities &&
              activeCatalog.complexities?.map((complexity, index) => (
                <MenuItemCustom onClick={handleClose} key={`complexity_${String(index)}`}>
                  {complexity.name}
                </MenuItemCustom>
              ))}
          </MenuCustom>
        </Container>
      )}

      {activeWorkunit && activeWorkunit.complexity && (
        <Container>
          <ButtonMenu aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            {typeof activeWorkunit.complexity !== 'string' ? activeWorkunit.complexity.name : activeWorkunit.complexity}
          </ButtonMenu>
          <MenuCustom
            id="simple-menu-wu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {complexities &&
              complexities.map((wuComplexity, index) => (
                <MenuItemCustom onClick={() => handleMenuItemClick(index)} key={`wucomplexity_${String(index)}`}>
                  {wuComplexity.name}
                </MenuItemCustom>
              ))}
          </MenuCustom>
        </Container>
      )}

      {activeAffairs && (
        <Container>
          <ButtonMenu aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            {activeAffairs[selected].name}-{activeAffairs[selected].otp}
          </ButtonMenu>
          <MenuCustom
            id="simple-menu-wu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {activeAffairs &&
              activeAffairs.map((affair, index) => (
                <MenuItemCustom onClick={() => handleMenuItemClick(index)} key={`affair_${String(index)}`}>
                  {affair.name}-{affair.otp}
                </MenuItemCustom>
              ))}
          </MenuCustom>
        </Container>
      )}

      {list && (
        <Container>
          <ButtonMenu
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size="small"
            style={{
              fontSize,
            }}
          >
            {list[selected]}
          </ButtonMenu>
          <MenuCustom
            id="simple-menu-wu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {list &&
              list.map((item, index) => (
                <MenuItemCustom onClick={() => handleMenuItemClick(index)} key={`item_${String(index)}`}>
                  {item}
                </MenuItemCustom>
              ))}
          </MenuCustom>
        </Container>
      )}
    </>
  );
};

export default MenuDropDown;

const Container = styled.div`
  display: flex;
  border: 1px solid #1e90ff;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  font-size: 13px;
  text-transform: none;
  width: fit-content;
`;

const ButtonMenu = withStyles({
  root: {
    backgroundColor: 'white',
    color: '#0063E5',
    '&:focus, &:hover, &:active': {
      color: 'white',
      backgroundColor: '#0063E5',
    },
  },
})(Button);

const MenuCustom = withStyles({
  paper: {
    backgroundColor: 'white',
    borderRadius: 15,
  },
  list: {
    backgroundColor: 'white',
  },
})(Menu);

const MenuItemCustom = withStyles({
  root: {
    fontSize: 12,
    color: '#0063E5',
  },
})(MenuItem);
