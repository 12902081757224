import { Box, Divider, Link } from '@mui/material';
import useUserRoles from 'hooks/useUserRoles';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store/hooks';
import { Loader } from '../index';
import { LogoMigso } from './icons/index';
import './sidebar.scss';
import SidebarCustomerSelect from './SidebarCustomerSelect';
import { getRoutesByRole, RouteType } from './sidebarData';
import SidebarItem from './SidebarItem';
import FreshdeskWidget from './Freshdeskwidget';

interface Props {
  handleClick: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

const Sidebar: React.FC<Props> = ({ isOpen, handleClick }) => {
  const history = useHistory();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const [entriesToDisplay, setEntriesToDisplay] = useState<RouteType[]>([]);
  const [showFreshdeskWidget, setShowFreshdeskWidget] = useState(false);

  useEffect(() => {
    if (appState.roles && appState.customer?.slug)
      setEntriesToDisplay(getRoutesByRole(userRoles, appState.customer?.slug, appState.user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.roles]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFreshdeskWidget(true);
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <div>
      <Box
        sx={{
          width: 140,
          backgroundColor: 'primary.main',
          left: { xs: isOpen ? 0 : -140, md: 0 },
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          height: '100vh',
          position: 'fixed',
          overflow: 'auto',
          zIndex: 100,
          transition: 'all .4s',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 4 }}>
            <Link
              onClick={() => history.push('/dashboard')}
              sx={{ cursor: 'pointer', width: { xs: '50px', xl: '60px' }, height: { xs: '50px', xl: '60px' } }}
            >
              <LogoMigso />
            </Link>
          </Box>
          {entriesToDisplay && entriesToDisplay.length ? (
            entriesToDisplay
              ?.filter((x) => !x.positionBottom)
              ?.map((clientEntry, index) => (
                <React.Fragment key={`cliententry_${String(index)}`}>
                  <SidebarItem item={clientEntry}>{clientEntry.name}</SidebarItem>
                </React.Fragment>
              ))
          ) : (
            <></>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 'auto' }}>
          {entriesToDisplay && entriesToDisplay.length ? (
            <>
              <Divider sx={{ borderColor: 'primary.light', mb: 4 }} />
              {entriesToDisplay
                ?.filter((x) => x.positionBottom)
                ?.map((clientEntry, index) => (
                  <React.Fragment key={`cliententry_${String(index)}`}>
                    <SidebarItem item={clientEntry}>{clientEntry.name}</SidebarItem>
                  </React.Fragment>
                ))}
            </>
          ) : (
            <></>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 4 }}>
            {appState.customer && appState.customers ? (
              <SidebarCustomerSelect customers={appState.customers} />
            ) : (
              <Loader />
            )}
          </Box>
        </Box>
      </Box>
      {showFreshdeskWidget && <FreshdeskWidget />}
    </div>
  );
};

export default Sidebar;
