import { Block, CheckCircle, History, Restore } from '@mui/icons-material';
import MoreVert from '@mui/icons-material/MoreVert';
import { CircularProgress, IconButton, ListItemIcon, ListItemText, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { CancelIcon, DeleteIcon, EditIcon } from 'components/CustomIcons';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectAllDeliverablesAction, selectDeliverableAction } from 'store/actions/deliverableActions';
import { openDialogAction } from 'store/actions/dialogsActions';
import { openDrawerAction } from 'store/actions/drawerActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { setSnackbarAction } from 'store/actions/snackbarActions';

const ActionsCell = React.memo((row: OrderWorkunit) => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const loadings = useSelector((state) => state.loadings);
  const deliverableRights = useDeliverableRights([row]);
  const orderType = useSelector((state) => state.deliverable.order?.order_type_id);
  const { changeMissionAdvancement, restoreDeliverables, handleDeliverableCancelComment } = useDeliverableService();
  const userIsBm = appState.roles?.toString().includes('bm');
  const userIsTl = appState.roles?.toString().includes('team_leader');
  const userIsTlPlus = appState.roles?.toString().includes('team_leader_plus');
  const userIsCustomer = appState.user?.is_client;
  const userIsConsultant = appState.roles?.toString().includes('consultant');

  const [anchorMenuActionEl, setAnchorMenuActionEl] = React.useState<null | HTMLElement>(null);

  const handleMenuActionOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuActionEl(event.currentTarget);
  };

  const handleMenuActionClose = () => {
    setAnchorMenuActionEl(null);
  };

  const stateReportMode = useSelector((state) => state.deliverable.isTableInReportMode);

  const handleActions = async (item: any) => {
    if (stateReportMode) {
      dispatch(openDialogAction({ name: 'deliverableCancelReportMode', data: { confirm: () => confirmAction(item) } }));
    } else {
      await confirmAction(item);
    }
    async function confirmAction(item: any) {
      dispatch(addLoadingAction(`action${item.label}`));
      dispatch(selectDeliverableAction(row.id));
      await item.action();
      dispatch(removeLoadingAction(`action${item.label}`));
      handleMenuActionClose();
    }
  };

  const editAction = useCallback(() => {
    dispatch(selectAllDeliverablesAction(false));
    dispatch(openDrawerAction({ name: 'deliverableEdit', data: [row.id] }));
  }, [dispatch, row.id]);

  const deleteAction = useCallback(() => {
    dispatch(openDialogAction({ name: 'deliverableDelete' }));
  }, [dispatch]);

  const submitAction = useCallback(async () => {
    const anyIsNull = row.consultant_id === null || row.client_id === null;
    if (anyIsNull) {
      // Display a snackbar message
      dispatch(
        setSnackbarAction({
          message:
            'The selected deliverable must have a consultant and client assigned ' +
            'before submitting. Please add the missing assignments.',
          open: true,
          severity: 'info',
        })
      );

      // Prevent submission if any value is null
      return;
    }
    await changeMissionAdvancement([row.id], MissionAdvancementPossibilities.SUBMITTED);
  }, [changeMissionAdvancement, row.id]);

  /*   const discountAction = () => {
    // TODO open drawer or modal for discount
  }; */

  const cancelAction = useCallback(() => {
    dispatch(openDrawerAction({ name: 'deliverableCancel' }));
  }, [dispatch]);

  const cancelRating = useCallback(async () => {
    dispatch(openDialogAction({ name: 'deliverableCancelAssessment' }));
  }, [dispatch]);

  const cancelComment = useCallback(async () => {
    await handleDeliverableCancelComment(row);
  }, [handleDeliverableCancelComment, row]);

  const cancelAcceptance = useCallback(async () => {
    dispatch(openDialogAction({ name: 'deliverableCancelAcceptance' }));
  }, [dispatch]);

  const restoreAction = useCallback(async () => {
    await restoreDeliverables([row]);
  }, [restoreDeliverables, row]);

  const cancelValidationAction = useCallback(async () => {
    await changeMissionAdvancement([row.id], MissionAdvancementPossibilities.SUBMITTED);
  }, [changeMissionAdvancement, row.id]);

  const cancelSubmissionAction = useCallback(async () => {
    await changeMissionAdvancement([row.id], null);
  }, [row, changeMissionAdvancement]);

  const menuItems = useMemo(
    () => [
      {
        action: submitAction,
        permission: deliverableRights.canSubmit,
        label: 'submit',
        icon: <CheckCircle sx={{ color: 'text.primary' }} />,
      },
      {
        action: editAction,
        permission: deliverableRights.canEdit,
        label: 'edit',
        icon: <EditIcon sx={{ color: 'text.primary' }} fontSize="small" />,
      },
      {
        action: restoreAction,
        label: 'restore',
        permission: deliverableRights.canRestore,
        icon: <Restore sx={{ color: 'text.primary' }} />,
      },
      /*       {
        action: discountAction,
        label: row.discount > 0 ? 'discount_modify' : 'discount_apply',
        icon: <Restore />,
      }, */
      {
        action: cancelValidationAction,
        label: 'cancel_validation',
        permission: deliverableRights.canCancelValidation,
        icon: <History color="error" />,
        isError: true,
      },
      {
        action: cancelSubmissionAction,
        permission: deliverableRights.canCancelSubmission,
        label: 'cancel_submission',
        icon: <History color="error" />,
        isError: true,
      },
      {
        action: cancelAction,
        label: 'cancel',
        icon: <Block color="error" />,
        isError: true,
        permission:
          !userIsCustomer &&
          (deliverableRights.canCancel ||
            (orderType === 2 &&
              !deliverableRights.canRestore &&
              !deliverableRights.canCancelAcceptance &&
              !userIsBm &&
              !userIsTl &&
              !userIsTlPlus &&
              !userIsConsultant)),
      },
      {
        action: deleteAction,
        label: 'delete',
        icon: <DeleteIcon color="error" fontSize="small" />,
        isError: true,
        permission: deliverableRights.canDelete,
      },
      {
        action: cancelRating,
        label: 'cancel_assessment',
        icon: <CancelIcon color="error" fontSize="small" />,
        isError: true,
        permission: deliverableRights.canCancelRating && !deliverableRights.canCancelRefused,
      },
      {
        action: cancelComment,
        label: 'cancel_comment',
        icon: <CancelIcon color="error" fontSize="small" />,
        isError: true,
        permission: deliverableRights.canCancelComment && !deliverableRights.canCancelRefused,
      },
      {
        action: cancelAcceptance,
        label: 'cancel_acceptance',
        icon: <CancelIcon color="error" fontSize="small" />,
        isError: true,
        permission: deliverableRights.canCancelAcceptance && !deliverableRights.canCancelRefused,
      },

      {
        action: cancelAcceptance,
        label: 'cancel_refusal',
        icon: <CancelIcon color="error" fontSize="small" />,
        isError: true,
        permission: deliverableRights.canCancelRefused,
      },
    ],
    [
      submitAction,
      deliverableRights.canSubmit,
      deliverableRights.canEdit,
      deliverableRights.canRestore,
      deliverableRights.canCancelValidation,
      deliverableRights.canCancelSubmission,
      deliverableRights.canCancel,
      deliverableRights.canDelete,
      deliverableRights.canCancelRating,
      deliverableRights.canCancelComment,
      deliverableRights.canCancelAcceptance,
      deliverableRights.canCancelRefused,
      editAction,
      restoreAction,
      cancelValidationAction,
      cancelSubmissionAction,
      cancelAction,
      deleteAction,
      cancelRating,
      cancelComment,
      cancelAcceptance,
    ]
  );

  const menuItemsAvailables = useMemo(() => menuItems.filter((item) => item.permission !== false), [menuItems]);
  return (
    <>
      <IconButton onClick={handleMenuActionOpen} size="large" disabled={!menuItemsAvailables.length}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorMenuActionEl}
        open={Boolean(anchorMenuActionEl)}
        onClose={handleMenuActionClose}
      >
        {menuItemsAvailables.map((item) => (
          <MenuItem key={item.label} onClick={() => handleActions(item)} disabled={!!loadings[`action${item.label}`]}>
            <ListItemIcon>
              {loadings[`action${item.label}`] ? <CircularProgress size="1.5rem" color="inherit" /> : item.icon}
            </ListItemIcon>
            <ListItemText sx={{ color: !item.isError ? '' : 'error.main' }}>{t(item.label)}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

export default ActionsCell;
