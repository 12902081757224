import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { SelectedWorkunit } from 'types/entities/SelectedWorkunit';
import HeaderCell from '../Cells/HeaderCell/HeaderCell';
import { contentsColumns } from '../Columns/ContentsColumns/contentsColumnsHelper';
import { monthsColumns } from '../Columns/MonthsColumns/monthsColumnsHelper';
import { SelectedWorkunitDatagridColumnField } from './service/selectedWorkunitDatagridColumnsService';

type SelectedWorkunitDatagridColumnsProps = {
  columnsDef: GridColDef[];
  apiRef: React.MutableRefObject<GridApiPro>;
};

const SelectedWorkunitDatagridColumns = ({
  columnsDef,
  apiRef,
}: SelectedWorkunitDatagridColumnsProps): GridColDef[] => {
  const [t] = useTranslation();

  const state = useSelector((state) => state.catalogToOrder.orderHandler);
  const months = useSelector((state) => state.catalogToOrder.months);
  const activeSelectedWorkunit = useSelector((state) => state.catalogToOrder.selectedWorkunitsEditIds);

  const defaultColumnParams: GridColDef = useMemo(
    () => ({
      field: '',
      headerName: '',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
    }),
    []
  );

  type SearchHeaderFields = keyof SelectedWorkunit;

  const searchHeader: SearchHeaderFields[] = useMemo(() => ['reference', 'name'], []);

  const isASearchHeader = useCallback(
    (value: string): value is SearchHeaderFields => searchHeader.includes(value as SearchHeaderFields),
    [searchHeader]
  );

  const generateMonthsColumns = useMemo(
    (): GridColDef[] => monthsColumns(months, state.monthSlice, false, 'order', activeSelectedWorkunit),
    [state.monthSlice, months, activeSelectedWorkunit]
  );

  const generateContentColumns = useMemo(
    (): GridColDef[] => contentsColumns(state.contentsNumber as number, apiRef, 'order'),
    [apiRef, state.contentsNumber]
  );

  const generateBaseColumns = useMemo(
    () =>
      columnsDef.map((col) => {
        if (isASearchHeader(col.field)) {
          return {
            ...defaultColumnParams,
            ...col,
            ...{
              headerName: col.headerName ? t(col.headerName) : t(col.field),
              renderHeader: (params: GridColumnHeaderParams) => <HeaderCell params={params} target="selected" />,
            },
          };
        }
        return { ...defaultColumnParams, ...col, ...{ headerName: col.headerName ? t(col.headerName) : t(col.field) } };
      }),
    [columnsDef, defaultColumnParams, isASearchHeader, t]
  );

  const generateColumns = useCallback(
    (colDef: GridColDef[]): GridColDef[] => {
      const columns: GridColDef[] = [];
      columns.push(...generateBaseColumns);
      const monthsColumns = generateMonthsColumns;
      columns.splice(
        columns.findIndex((col) => col.field === SelectedWorkunitDatagridColumnField.MONTHS),
        1,
        ...monthsColumns
      );
      columns.splice(
        columns.findIndex((col) => col.field === SelectedWorkunitDatagridColumnField.CONTENTS),
        1,
        ...generateContentColumns
      );
      return columns;
    },
    [generateBaseColumns, generateContentColumns, generateMonthsColumns]
  );

  return useMemo(() => generateColumns(columnsDef), [columnsDef, generateColumns]);
};

export default SelectedWorkunitDatagridColumns;
