import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import React from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import DeliverableTableValidateButtons from '../../DeliverableTableValidateButtons/DeliverableTableValidateButtons';
import EmptyCell from '../EmptyCell/EmptyCell';

const ValidatedCell = (props: { row: OrderWorkunit }) => {
  const { row } = props;
  const { canValidate } = useDeliverableRights([row]);
  const isVisible = () => {
    const { mission_advancement_id, label } = row;
    return mission_advancement_id !== null && !(label && label.includes('cancelled'));
  };
  return (
    <>
      {isVisible() ? (
        <DeliverableTableValidateButtons
          row={row}
          accepted={[
            MissionAdvancementPossibilities.ACCEPTATION_TL,
            MissionAdvancementPossibilities.REFUSED_CLIENT,
            MissionAdvancementPossibilities.RESERVE_CLIENT,
            MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
          ]}
          refused={[MissionAdvancementPossibilities.REFUSED_TL]}
          displayButton={[MissionAdvancementPossibilities.SUBMITTED]}
          accept={MissionAdvancementPossibilities.ACCEPTATION_TL}
          refuse={MissionAdvancementPossibilities.REFUSED_TL}
          withReserve={[MissionAdvancementPossibilities.RESERVE_TL]}
          param="mission_advancement_id"
          disabled={!canValidate}
          isClient={false}
        />
      ) : (
        <EmptyCell />
      )}
    </>
  );
};

export default ValidatedCell;
