import { createAction } from '@reduxjs/toolkit';
import { deliverableState } from 'store/states/deliverableState';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const changePageSize = createAction(OrderWorkunitAffectationType.ChangePageSize, (newPageSize: number) => ({
  payload: { newPageSize },
}));
export const changePage = createAction(OrderWorkunitAffectationType.ChangePage, (newPage: number) => ({
  payload: { newPage },
}));

export const changeTotalItems = createAction(OrderWorkunitAffectationType.ChangeTotalItems, (totalItems: number) => ({
  payload: { totalItems },
}));

export const changeTotalPages = createAction(OrderWorkunitAffectationType.ChangeTotalPages, (totalPages: number) => ({
  payload: { totalPages },
}));
export const injectOrderWorkunitAffectation = createAction(
  OrderWorkunitAffectationType.Inject,
  (orderWorkunits: OrderWorkunit[], totalItems: number, totalPages: number, currentPage: number) => ({
    payload: { orderWorkunits, totalItems, totalPages, currentPage },
  })
);

export default (state = deliverableState, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.Inject:
      return {
        ...state,
        orderWorkunits: action.payload.orderWorkunits,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case OrderWorkunitAffectationType.LoadDeliverable:
      return {
        ...state,
        deliverables: [],
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
      };
    default:
      return state;
  }
};
