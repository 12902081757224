import { RootActions } from 'store/redux';
import { DocumentsActions } from '../actions/documentsActions';
import { documentsState, DocumentsState } from '../states/documentsState';

const documentsReducer = (state = documentsState, action: RootActions): DocumentsState => {
  switch (action.type) {
    case DocumentsActions.LOAD_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case DocumentsActions.CHANGE_CURRENT_TAB_DOCUMENTS:
      return {
        ...state,
        currentTab: action.payload,
      };
    default:
      return state;
  }
};

export default documentsReducer;
