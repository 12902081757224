import React, { Dispatch, useReducer } from 'react';

import OrderWorkunitAffectationReducers from './_reducers/AffectationReducer';
import { PossiblesOrderWorkunitAffectationActions } from './OrderWorkunitAffectationReducers';
import { InitialStateType, initialValue } from './helperContext';

export type AffectationSearch = {
  workunit_name: string;
  workunit_reference: string;
  complexity_name: string;
  consultant_fullname: string;
  client_fullname: string;
  forecast_date: string;
  delivery_date: string;
  scope_id?: number;
};

const OrderWorkunitAffectationContext = React.createContext<{
  state: InitialStateType;
  dispatch: Dispatch<PossiblesOrderWorkunitAffectationActions>;
}>({
  state: initialValue,
  dispatch: () => null,
});
OrderWorkunitAffectationContext.displayName = 'OrderWorkunitAffectationContext';

const OrderWorkunitAffectationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(OrderWorkunitAffectationReducers, initialValue);

  return (
    <OrderWorkunitAffectationContext.Provider value={{ state, dispatch }}>
      {children}
    </OrderWorkunitAffectationContext.Provider>
  );
};
export { OrderWorkunitAffectationProvider, OrderWorkunitAffectationContext };
