import { createAction } from '@reduxjs/toolkit';
import { initialValue } from '../../helperContext';
import { AffectationSearch } from '../../OrderWorkunitAffectationContext';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const updateAffectationSearchAction = createAction(
  OrderWorkunitAffectationType.UpdateSearch,
  (field: keyof AffectationSearch, value: any) => ({
    payload: { field, value },
  })
);

export default (state = initialValue, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.UpdateSearch:
      return {
        ...state,
        search: {
          ...state.search,
          [action.payload.field]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
