import { GridCellParams, GridColDef, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid';
import React from 'react';
import { SelectedWorkunit } from 'types/entities/SelectedWorkunit';
import NameCell from '../../Cells/NameCell/NameCell';
import EditionCell from '../../Cells/EditionCell/EditionCell';
import PriceCell from '../../Cells/PriceCell/PriceCell';
import TotalCell from '../../Cells/TotalCell/TotalCell';
import ReferenceCell from '../../Cells/ReferenceCell/ReferenceCell';
import Scope from 'types/entities/Scope';
import ScopeCell from '../../Cells/ScopeCell/ScopeCell';
import WorkloadCell from '../../Cells/WorkloadCell/WorkloadCell';

type SelectedWorkunitDatagridCellParams<T> = GridCellParams<T, SelectedWorkunit, any>;

export const SelectedWorkunitDatagridColumnField = {
  REFERENCE: 'reference',
  NAME: 'name',
  WORKLOAD: 'charge',
  PRICE: 'price',
  SCOPE: 'scope',
  MONTHS: 'months',
  CONTENTS: 'contents',
  TOTAL: 'total',
};

const scopeEqualOperator: GridFilterOperator[] = [
  {
    label: 'isSame',
    value: 'isSame',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }

      return (params: GridCellParams<Scope>): boolean => {
        if (params.value) {
          return params.value?.id === filterItem.value;
        }
        return true;
      };
    },
  },
];

export const selectedWorkunitDatagridColumnsParams: GridColDef[] = [
  {
    headerName: SelectedWorkunitDatagridColumnField.REFERENCE,
    field: SelectedWorkunitDatagridColumnField.REFERENCE,
    minWidth: 110,
    renderCell: (params: SelectedWorkunitDatagridCellParams<string>) => (
      <ReferenceCell reference={params.value || ''} />
    ),
  },
  {
    headerName: SelectedWorkunitDatagridColumnField.NAME,
    field: SelectedWorkunitDatagridColumnField.NAME,
    minWidth: 170,
    renderCell: (params: SelectedWorkunitDatagridCellParams<string>) => <NameCell name={params.value || ''} />,
  },
  {
    field: SelectedWorkunitDatagridColumnField.WORKLOAD,
    headerName: 'Workload',
    minWidth: 80,
    renderCell: (params: SelectedWorkunitDatagridCellParams<number>) => <WorkloadCell charge={params.value || 0} />,
  },
  {
    field: SelectedWorkunitDatagridColumnField.PRICE,
    minWidth: 70,
    renderCell: (params: SelectedWorkunitDatagridCellParams<number>) => <PriceCell price={params.value || 0} />,
  },
  {
    field: SelectedWorkunitDatagridColumnField.SCOPE,
    minWidth: 120,
    filterOperators: scopeEqualOperator,
    renderCell: (params: SelectedWorkunitDatagridCellParams<SelectedWorkunit>) => <ScopeCell params={params} />,
  },
  {
    field: SelectedWorkunitDatagridColumnField.MONTHS,
  },
  {
    field: SelectedWorkunitDatagridColumnField.CONTENTS,
  },
  {
    field: SelectedWorkunitDatagridColumnField.TOTAL,
    align: 'center',
    headerAlign: 'center',
    minWidth: 60,
    renderCell: (params: SelectedWorkunitDatagridCellParams<number>) => (
      <TotalCell total={params.row.months.reduce((total, currentValue) => total + currentValue.quantity, 0)} />
    ),
  },
  {
    field: '',
    align: 'center',
    renderCell: (params) => <EditionCell params={params} />,
  },
];
