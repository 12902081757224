import { Tooltip, Typography } from '@mui/material';
import React from 'react';

const NameCell = React.memo(({ name }: { name: string }) => (
  <Tooltip arrow title={name}>
    <Typography variant="body2" noWrap>
      {name}
    </Typography>
  </Tooltip>
));

export default NameCell;
