import { PERMISSIONS } from 'helpers/permissions';
import useUserPermissions, { hasPermissions } from 'hooks/useUserPermissions';

/**
 * React hook which defines common rights to the application
 */
export default function useAppRights() {
  const userPermissions = useUserPermissions();
  return {
    canFetchAllDeviseForCatalog: hasPermissions([PERMISSIONS.FETCH_ALL_DEVISE_FOR_CATALOG], userPermissions),
  };
}
