import { Stack, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as AcceptationOk } from 'assets/img/icons/acceptation_ok.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ApproveConfirmation = () => {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" gap={2}>
      <SvgIcon fontSize="large">
        <AcceptationOk />
      </SvgIcon>
      <Typography fontWeight={500}>{t('Your approvement has been sent successfully')}</Typography>
    </Stack>
  );
};

export default ApproveConfirmation;
