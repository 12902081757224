import { Avatar, Box } from '@mui/material';
import { stringToColor } from 'helpers/format';
import React from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/entities/User';
// import './AvatarCustom.scss';

type AvatarCustomProps = {
  user: User | undefined;
};
const AvatarCustom = (props: AvatarCustomProps) => {
  const { user } = props;
  const [t] = useTranslation();

  function computeUserName() {
    return (
      <Box>
        {user?.last_name.toLocaleUpperCase()} {user?.first_name}
      </Box>
    );
  }

  const getUserAvatar = () => {
    if (user) {
      if (user?.avatar_uri) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={user.avatar_uri} alt={t('Active_user_avatar')} sx={{ mr: 1 }} />
            {computeUserName()}
          </Box>
        );
      }
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{
              backgroundColor: `${stringToColor(user?.first_name)}`,
              mr: 1,
              color: `${stringToColor(user?.first_name, 'color')}`,
            }}
          >{`${user.last_name.toLocaleUpperCase().charAt(0)}${user.first_name.charAt(0)}`}</Avatar>
          {computeUserName()}
        </Box>
      );
    }

    return <></>;
  };
  return <div className="avatar_custom_container">{getUserAvatar()}</div>;
};
export default AvatarCustom;
