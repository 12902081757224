import { DocumentsTabState } from 'types/enums/DocumentsTabState';

export const DocumentsActions = {
  LOAD_DOCUMENTS: 'LOAD_DOCUMENTS',
  CHANGE_CURRENT_TAB_DOCUMENTS: 'CHANGE_CURRENT_TAB_DOCUMENTS',
} as const;

export const loadDocumentsAction = (payload: string[]) => ({
  type: DocumentsActions.LOAD_DOCUMENTS,
  payload,
});

export const changeCurrentTabAction = (payload: DocumentsTabState) => ({
  type: DocumentsActions.CHANGE_CURRENT_TAB_DOCUMENTS,
  payload,
});

export const documentsActions = {
  loadDocumentsAction,
  changeCurrentTabAction,
};
