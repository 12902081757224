import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  IconButton,
  Slide,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useAppState } from 'store/Provider';

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

type ModalCustomProps = {
  open: boolean;
  onClose: React.DispatchWithoutAction;
  body?: JSX.Element;
  actions?: JSX.Element;
  onConfirm?: () => any;
  titleText: string;
  confirmText?: string;
  cancelText?: string;
  size?: DialogProps['maxWidth'];
  confirmLoad?: boolean;
  disabledConfirm?: boolean;
  textConfirmDisable?: string;
  cancelButtonVariant?: 'outlined' | 'contained' | 'text';
  modalName: string;
  dialogActionsCenter?: boolean;
  onCloseEvent?: () => void;
  sx?: SxProps<Theme> | undefined;
};
const ModalCustom = (props: ModalCustomProps) => {
  const {
    open,
    onClose,
    body,
    actions,
    onConfirm,
    confirmText,
    cancelText,
    titleText,
    size = 'xl',
    confirmLoad = false,
    disabledConfirm = false,
    textConfirmDisable,
    modalName,
    cancelButtonVariant = 'outlined',
    dialogActionsCenter = false,
    onCloseEvent,
    sx,
  } = props;
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { dispatch } = useAppState();

  const handleConfirmClick = async () => {
    if (!confirmLoad && onConfirm) onConfirm();
    if (confirmLoad && onConfirm) {
      setButtonLoading(true);
      await onConfirm();
      setButtonLoading(false);
    }
  };

  const handleClose = () => {
    if (onCloseEvent) onCloseEvent();
    else dispatch(closeDialogAction(modalName));
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      id="create_report_modal"
      aria-labelledby="create_report_modal-dialog-title"
      aria-describedby="create_report_modal-dialog-description"
      maxWidth={size}
      fullWidth
      TransitionComponent={Transition}
      sx={sx}
    >
      <DialogTitle sx={{ display: 'flex' }}>
        {titleText}
        <IconButton sx={{ ml: 'auto' }} color="primary" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>{' '}
      {body ? <>{body}</> : <></>}
      {actions ? (
        <>{actions}</>
      ) : (
        <>
          <DialogActions
            style={{ paddingBottom: 12 }}
            sx={{ justifyContent: dialogActionsCenter ? 'center' : 'flex-end' }}
          >
            {cancelText && (
              <Button variant={cancelButtonVariant} onClick={onClose} disabled={buttonLoading}>
                {cancelText}
              </Button>
            )}
            {disabledConfirm && textConfirmDisable ? (
              <Tooltip title={textConfirmDisable}>
                <LoadingButton
                  variant="contained"
                  disabled={disabledConfirm}
                  onClick={handleConfirmClick}
                  loading={buttonLoading}
                >
                  {confirmText}
                </LoadingButton>
              </Tooltip>
            ) : (
              <LoadingButton
                variant="contained"
                disabled={disabledConfirm}
                onClick={handleConfirmClick}
                loading={buttonLoading}
              >
                {confirmText}
              </LoadingButton>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ModalCustom;
