import { Box, Tab, Tabs } from '@mui/material';
import { ChartIcon, ChecklistIcon, ClipboardIcon } from 'components/CustomIcons';
import useUserRoles from 'hooks/useUserRoles';
import React from 'react';
import { changeTabAction, loadDeliverablesAction } from 'store/actions/deliverableActions';
import { openDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { DeliverableTabPage } from 'types/enums/DeliverableTabPage';

const DeliverableTab: React.FC = () => {
  const { dispatch } = useAppState();
  const state = useSelector((state) => state.deliverable);
  const isInReportMode = useSelector((state) => state.deliverable.isTableInReportMode);
  const userRoles = useUserRoles();

  const handleChange = (event: React.SyntheticEvent, newValue: DeliverableTabPage) => {
    if (isInReportMode) {
      dispatch(openDialogAction({ name: 'deliverableCancelReportMode', data: { confirm: () => confirmAction() } }));
    } else {
      confirmAction();
    }

    function confirmAction() {
      dispatch(changeTabAction(newValue));
      dispatch(
        loadDeliverablesAction({
          deliverables: null,
          totalItems: state?.totalItems ?? 0,
          totalPages: state?.totalPages ?? 0,
        })
      );
    }
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={state.currentTab} onChange={handleChange}>
        {!userRoles.isSupportBu && !userRoles.isButl && (
          <Tab
            icon={<ChecklistIcon />}
            value={DeliverableTabPage.TODO}
            label={DeliverableTabPage.TODO}
            iconPosition="start"
          />
        )}
        <Tab
          label={DeliverableTabPage.PRODUCTION}
          value={DeliverableTabPage.PRODUCTION}
          iconPosition="start"
          icon={<ChartIcon />}
        />

        <Tab
          label={DeliverableTabPage.REPORTS}
          value={DeliverableTabPage.REPORTS}
          iconPosition="start"
          icon={<ClipboardIcon />}
          disabled={!state.order?.id}
        />
      </Tabs>
    </Box>
  );
};

export default DeliverableTab;
