import React from 'react';
import { Box, Pagination } from '@mui/material';
import { Loader } from 'components';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import FinancialReport from 'types/entities/FinancialReport';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { changePageDeliverableSheetAction } from 'store/actions/deliverableSheetActions';
import DeliverableReportsItem from '../DeliverableReportsItem/DeliverableReportsItem';

type DeliverableReportsListProps = {
  items: DeliverableSheet[] | FinancialReport[];
};

const DeliverableReportsList = ({ items }: DeliverableReportsListProps) => {
  const { dispatch } = useAppState();
  const deliverableSheetState = useSelector((state) => state.deliverableSheet);
  const loadings = useSelector((state) => state.loadings);

  const changePage = (ev: React.ChangeEvent<unknown>, page: number) => {
    dispatch(changePageDeliverableSheetAction(page - 1));
  };
  return (
    <Box>
      {loadings.api ? <Loader /> : <></>}
      {!loadings.api ? (
        items.map((sheet) => (
          <DeliverableReportsItem sheet={sheet} order_id={sheet?.order_id as number} key={sheet?.id} />
        ))
      ) : (
        <></>
      )}
      <Pagination
        count={deliverableSheetState.totalPages}
        page={deliverableSheetState.currentPage + 1}
        onChange={changePage}
      />
    </Box>
  );
};

export default DeliverableReportsList;
