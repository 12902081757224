import { createAction } from '@reduxjs/toolkit';
import { initialValue } from '../../helperContext';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const openEditDrawerAction = createAction(OrderWorkunitAffectationType.OpenEditDrawer);

export const closeEditDrawerAction = createAction(OrderWorkunitAffectationType.CloseEditDrawer);

export default (state = initialValue, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.OpenEditDrawer:
      return {
        ...state,
        isEditDrawerOpen: true,
      };
    case OrderWorkunitAffectationType.CloseEditDrawer:
      return {
        ...state,
        isEditDrawerOpen: false,
      };
    default:
      return state;
  }
};
