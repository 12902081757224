import { SnackbarState } from '../states/snackbarState';

export const SnackbarActions = {
  SET_SNACKBAR: 'SET_SNACKBAR',
} as const;

export const setSnackbarAction = (payload: SnackbarState) => ({
  type: SnackbarActions.SET_SNACKBAR,
  payload,
});

export const snackbarActions = {
  setSnackbarAction,
};
