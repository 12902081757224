import { Tooltip, Typography } from '@mui/material';
import React from 'react';

const WorkloadCell = React.memo(({ charge }: { charge: number }) => (
  <Tooltip arrow title={charge ? charge.toFixed(2) : 0}>
    <Typography variant="body2" noWrap>
      {charge?.toFixed(2) || 0}
    </Typography>
  </Tooltip>
));

export default WorkloadCell;
