import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ComplexityCell = React.memo((row: any) => {
  const [t] = useTranslation();

  return (
    <Tooltip title={row.complexity_name ? row.complexity_name : t('Custom')}>
      <Typography variant="body2" noWrap>
        {row.complexity_name ? row.complexity_name : t('Custom')}
      </Typography>
    </Tooltip>
  );
});

export default ComplexityCell;
