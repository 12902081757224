import { BorderColor, Check, Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { DeleteIcon } from 'components/CustomIcons';
import useOrderWorkunitsService from 'features/Orders/OrderWorkunits/services/useOrderWorkunitsService';
import React from 'react';
import { useSelector } from 'store/hooks';

const EditionCell = ({ params }: { params: GridCellParams }) => {
  const apiRef = useGridApiContext();
  const { row } = params;
  const activeEditRowIds = useSelector((state) => state.catalogToOrder.selectedWorkunitsEditIds);
  const MAX_CONTENTS = 5;

  const { deleteSelectedWorkunit, setSelectedWorkunitToEdit, removeSelectedWorkunitToEdit } =
    useOrderWorkunitsService();

  if (!!row.randomId && activeEditRowIds.includes(row.randomId)) {
    return (
      <>
        <IconButton onClick={() => deleteSelectedWorkunit(row.randomId)} color="error">
          <DeleteIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={() => {
            for (let i = 1; i <= MAX_CONTENTS; i += 1) {
              const field = i > 1 ? `content${i}` : 'content';
              if (apiRef.current.getCellMode(row.randomId, field) === 'edit') {
                apiRef.current.stopCellEditMode({ id: row.randomId, field });
              }
            }
            removeSelectedWorkunitToEdit(row.randomId);
          }}
          color="success"
        >
          <Check />
        </IconButton>
      </>
    );
  }
  return (
    <IconButton
      onClick={() => {
        setSelectedWorkunitToEdit(row.randomId);
        if (apiRef.current.getRowMode(row.randomId) === 'view') apiRef.current.startRowEditMode({ id: row.randomId });
      }}
      sx={{ color: 'text.primary' }}
    >
      <BorderColor />
    </IconButton>
  );
};

export default EditionCell;
