import { ArrowLeft, ArrowRight, Save } from '@mui/icons-material';
import { IconButton, Table, TableBody, TableCell, TableHead } from '@mui/material';
import TenantWorkloadApiService, { UpdateTenantWorkload } from 'api/TenantWorkloadApiService';
import { Layout, Loader } from 'components';
import CheckboxSelection from 'components/CheckboxSelection/CheckboxSelection';
import { sortedTenantWorkload } from 'helpers/utils';
import useApi from 'hooks/useApi';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import AdminTenantSettingRow from './AdminTenantSettingRow/AdminTenantSettingsRow';
import './AdminTenantSettings.scss';

interface Props {
  is_daily_unit: boolean;
  is_monthly: boolean;
  workload: number;
  tenant_id: number | undefined;
  date: string;
  id: number;
  total_workload?: number;
  created_at: string;
  updated_at: string;
}

export type TenantMonthsWorkload = {
  is_daily_unit: boolean;
  is_monthly: boolean;
  workload: number;
  tenant_id: number | undefined;
  date: string;
  id: number;
  total_workload?: number;
  created_at: string;
  updated_at: string;
};

const AdminTenantSettings: React.FC<Props> = ({
  is_daily_unit,
  is_monthly,
  workload,
  tenant_id,
  date,
  id,
  total_workload,
  created_at,
  updated_at,
}) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const loadings = useSelector((state) => state.loadings);
  const [is_days] = useState(false);
  const { makeCall } = useApi();
  const [t] = useTranslation();
  const [year, setYear] = useState<{
    id?: number;
    is_daily_unit: boolean;
    is_monthly: boolean;
    workload: number;
    tenant_id: number | undefined;
    date: string;
  }>({
    is_daily_unit: true,
    is_monthly: false,
    workload: 12,
    tenant_id: appState.customer?.id ?? undefined,
    date: moment().startOf('year').format(),
  });
  const [currentYear, setCurrentYear] = useState<number>(moment().year());
  const [months, setMonths] = useState<TenantMonthsWorkload[]>(
    Array.from({ length: 12 }, (_, i) => ({
      is_daily_unit: true,
      is_monthly: true,
      workload: 0,
      tenant_id: appState.customer?.id ?? undefined,
      date: moment().startOf('year').add(i, 'month').format(),
      id: i,
      total_workload: 0,
      created_at: '',
      updated_at: '',
    }))
  );

  // eslint-disable-next-line no-template-curly-in-string
  useEffect(() => {
    dispatch(addLoadingAction('getTenantWorkloads'));
    const getTenantWorkloads = async () => {
      const yearsParams = `&date=${moment(new Date(`01/01/${currentYear}`))
        .format('YYYY-MM-DD')
        .toString()}&tenant_id=${appState.customer?.id}&is_monthly=0&join=tenant-workloads
      `;

      const res = await TenantWorkloadApiService.fetchAllWorkloads(yearsParams);
      if (res[0]) {
        setYear(res[0]);
      }
      if (res[0]?.['tenant-workloads'].length > 0) {
        setMonths(sortedTenantWorkload(res[0]['tenant-workloads']));
      } else {
        setMissionsMonths();
      }
      dispatch(removeLoadingAction('getTenantWorkloads'));
    };
    getTenantWorkloads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear]);

  const totalMonthEqualYearValue = () => {
    if (year.workload !== 0 && months) return months.reduce((a, b) => a + b.workload, 0) === year.workload;
    return false;
  };
  const [isTenantUpdateLoading, setIsTenantUpdateLoading] = useState(false);
  const updateTenantWorkload = async () => {
    setIsTenantUpdateLoading(true);
    try {
      if (totalMonthEqualYearValue()) {
        const monthsToSend = months.map(({ total_workload, ...m }) => m);
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          total_workload = 0,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          tenant_workload = [],
          ...yearToSend
        } = {
          ...year,
          date: moment(new Date(`01/01/${currentYear}`))
            .format('YYYY-MM-DD')
            .toString(),
        };
        const bodyUpdate: UpdateTenantWorkload = {
          ...yearToSend,
          total_workload: monthsToSend.reduce((a, b) => a + b.workload, 0),
          month_workloads: monthsToSend.map((m) => ({
            ...m,
            parent_id: year?.id,
            is_monthly: true,
            is_daily_unit: year?.is_daily_unit,
          })),
        } as unknown as UpdateTenantWorkload;
        const res = await makeCall(TenantWorkloadApiService.updateMany(bodyUpdate));
      }
      dispatch(
        setSnackbarAction({ message: 'Update tenant workload settings successfull', open: true, severity: 'success' })
      );
    } catch (error) {
      dispatch(
        setSnackbarAction({ message: 'Failed to update tenant workload settings', open: true, severity: 'error' })
      );
    } finally {
      setIsTenantUpdateLoading(false);
    }
  };

  async function setMissionsMonths() {
    const minDate = moment(`01-01-${currentYear}`, 'MM-DD-YYYY').startOf('year');
    const maxDate = moment(`01-01-${currentYear}`, 'MM-DD-YYYY').endOf('year');

    const interim = minDate.clone();
    const timeValues = [];

    while (maxDate >= interim) {
      timeValues.push({
        is_daily_unit: is_days,
        is_monthly: false,
        workload: parseFloat((12 / 12).toFixed(5)),
        tenant_id: appState.customer?.id,
        date: interim.endOf('month').format('MM-DD-YYYY'),
      });
      interim.add(1, 'month');
    }

    const res = await TenantWorkloadApiService.postTenantWorkload({
      ...{
        ...year,
        date: moment(new Date(`01/01/${currentYear}`))
          .format('YYYY-MM-DD')
          .toString(),
      },
      month_workloads: [...timeValues.map((m) => ({ ...m, is_monthly: true, is_daily_unit: year.is_daily_unit }))],
    });
    if (res.annual_workload) {
      setYear(res.annual_workload);
      setMonths(sortedTenantWorkload(res.months_workload));
    }
  }

  return (
    <Layout name="Admin" path="/administration/settings">
      <section className="admin_tenant_settings_container">
        <article>
          <h1>{appState.customer?.name}</h1>
        </article>
        <CheckboxSelection
          onChange={(e) => setYear({ ...year, is_daily_unit: e.target.checked })}
          label={t('Daily')}
          className="test"
          checked={year.is_daily_unit}
        />
        <article className="table_container">
          <Table>
            <TableHead>
              <TableCell className="cell_year_container" align="center" colSpan={2}>
                <IconButton onClick={() => setCurrentYear(currentYear - 1)} size="large">
                  <ArrowLeft />
                </IconButton>

                <IconButton
                  onClick={() => updateTenantWorkload()}
                  disabled={!totalMonthEqualYearValue() || isTenantUpdateLoading}
                  size="large"
                >
                  {isTenantUpdateLoading ? (
                    <Loader size={10} />
                  ) : (
                    <Save color={!totalMonthEqualYearValue() ? 'disabled' : 'primary'} />
                  )}
                </IconButton>
                {currentYear}
                <IconButton onClick={() => setCurrentYear(currentYear + 1)} size="large">
                  <ArrowRight />
                </IconButton>
              </TableCell>
              <TableCell align="right" className="inline">
                Total:
                <input
                  type="number"
                  dir="rtl"
                  min="0"
                  value={year.workload}
                  onChange={(e) => setYear({ ...year, workload: parseFloat(e.target.value) })}
                />{' '}
              </TableCell>
            </TableHead>
            <TableBody>
              {!loadings.getTenantWorkloads &&
                months &&
                months?.map((m) => (
                  <AdminTenantSettingRow
                    is_days={year.is_daily_unit}
                    m={m}
                    setMonthValue={(workload: number, id: number, month: string) =>
                      setMonths(months.map((date) => (date.id !== id ? date : { ...date, workload })))
                    }
                  />
                ))}
            </TableBody>
          </Table>
        </article>
      </section>
    </Layout>
  );
};

export default AdminTenantSettings;
