import { Box, Link } from '@mui/material';
import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './OrderKpiBadge.scss';

interface OrderKpiBadgeProps {
  label: string;
  icon: ReactElement;
  color: string;
  value: number | string;
  // redirectTo?: string;
  onClickLabel?: () => void;
  cursorPoint?: boolean;
}

const OrderKpiBadge = ({ label, icon, color, value, onClickLabel, cursorPoint }: OrderKpiBadgeProps) => (
  // redirectTo ? (
  //   <Box sx={{ mb: 1 }}>
  //     <Link component={RouterLink} to={redirectTo} className={`badge-kpi ${color}`} underline="none">
  //       <div className="badge-kpi-title">
  //         {icon} {label}
  //       </div>
  //       <span className="badge-kpi-value">{value}</span>
  //     </Link>
  //   </Box>
  // ) : (

  <Box sx={{ mb: 1, cursor: cursorPoint ? 'pointer' : 'default' }}>
    <div className={`badge-kpi ${color}`} role="none" onClick={onClickLabel} onKeyDown={onClickLabel}>
      <div className="badge-kpi-title">
        {icon} {label}
      </div>
      <span className="badge-kpi-value">{value}</span>
    </div>
  </Box>
);

// );

export default OrderKpiBadge;
