import React from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { useSelector } from 'store/hooks';

const AmountCell = React.memo((row: OrderWorkunit) => {
  let devise_symbol;
  if (row?.devise_name === 'Dollar' || row?.devise_name === 'Canadian Dollar') {
    devise_symbol = '$';
  } else if (row?.devise_name === 'Euro') {
    devise_symbol = '€';
  } else if (row?.devise_name === 'Pound') {
    devise_symbol = '£';
  }

  return (
    <>
      {row?.discount > 0 ? (
        <div>
          <span>
            {row?.price}
            {devise_symbol}
          </span>
          <span>
            {row?.final_price.toFixed(2)}
            {devise_symbol}
          </span>
        </div>
      ) : (
        <div>
          {row?.final_price}
          {devise_symbol}
        </div>
      )}
    </>
  );
});

export default AmountCell;
