import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  font?: string;
  value?: string;
  size?: number;
  color?: string;
  weight?: number;
  align?: string;
  overflow?: string;
  opacity?: number;
}

const TextBlock: React.FC<Props> = ({ value, size, color, weight, align, font, overflow, opacity }) => (
  <Container>
    <Text color={color} size={size} weight={weight} align={align} font={font} overflow={overflow} opacity={opacity}>
      {value}
    </Text>
  </Container>
);

export default TextBlock;

const Text = styled.span<Props>`
  display: flex;
  font-family: ${(props) => props.font};
  font-size: ${(props) => props.size || 14}px;
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color || '#FFFFFF'};
  text-align: ${(props) => props.align || 'start'};
  text-decoration: none !important;
  text-overflow: ${(props) => props.overflow || 'ellipsis'};
  white-space: nowrap;
  overflow: hidden;
  opacity: ${(props) => props.opacity || 1};
`;

const Container = styled.div`
  display: flex;
  min-width: 0;
  max-width: 350px;
`;
