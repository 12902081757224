import { TenantMonthsWorkload } from 'features/Admin/AdminDashboard/AdminDashboard';
import moment from 'moment';
import { OrderStatus } from 'types/enums/OrderStatus';
import Order from '../types/entities/Order';

export function base64toBlob(base64Data: string, contentType: string) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  // eslint-disable-next-line no-plusplus
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    // eslint-disable-next-line no-plusplus
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const sortedTenantWorkload = (arrayToSort: TenantMonthsWorkload[]) =>
  arrayToSort.sort((a, b) => moment(a.date).unix() - moment(b.date).unix());

export const getOrderStatusColor = (order: Order | null) => {
  if (!order) {
    return 'neutral.main';
  }
  switch (order.status) {
    case OrderStatus.DRAFTED:
      return 'warning.main';
    case OrderStatus.PENDING:
      return 'primary.main';
    case OrderStatus.VALIDATED:
      return 'success.main';
    case OrderStatus.PRODUCTION:
      return 'primary.main';
    case OrderStatus.CLOSED:
      return 'closed.main';
    default:
      return 'neutral.main';
  }
};
