import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Stack,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material';
import UserApiService from 'api/UserApiService';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import User from 'types/entities/User';

type ClientsDialogDeactivateUserProps = {
  activeUser: User;
  setClients: React.Dispatch<React.SetStateAction<User[]>>;
};

const ClientsDialogDeactivateUser: React.FC<ClientsDialogDeactivateUserProps> = ({ activeUser, setClients }) => {
  const [t] = useTranslation();
  const dialogs = useSelector((state) => state.dialogs);
  const [loading, setLoading] = useState(false);
  const [deactivationOption, setDeactivationOption] = useState('scheduleAnonymize');
  const { dispatch } = useAppState();
  const { makeCall } = useApi();

  const close = () => {
    dispatch(closeDialogAction('clientDeactivateUser'));
  };

  const deactivateUser = async () => {
    setLoading(true);

    const requestData = {
      schedule_anonymize: deactivationOption === 'scheduleAnonymize',
      immediate_anonymize: deactivationOption === 'anonymizeData',
      cognito_username: activeUser.cognito_username,
      mail: activeUser.mail,
    };

    const res = await makeCall(UserApiService.deactivateUser(activeUser.id, requestData), 'Error deactivating user');

    if (res.import === 'ok') {
      dispatch(setSnackbarAction({ message: 'User deactivated successfully', open: true, severity: 'success' }));
      dispatch(closeDialogAction('clientDeactivateUser'));
      setClients((prevClients) => (prevClients || []).filter((client) => client.id !== activeUser.id));
    } else {
      dispatch(
        setSnackbarAction({ message: 'Error! User is not deactivated. Try Again later', open: true, severity: 'error' })
      );
      dispatch(closeDialogAction('clientDeactivateUser'));
    }

    setLoading(false);
  };

  return (
    <Dialog style={{ maxHeight: 435 }} maxWidth="sm" open={dialogs.clientDeactivateUser || false}>
      <DialogTitle>{t('deactivate_user')}</DialogTitle>
      <DialogContent>
        <RadioGroup value={deactivationOption} onChange={(event) => setDeactivationOption(event.target.value)}>
          <FormControlLabel
            value="scheduleAnonymize"
            control={<Radio color="primary" />}
            title={t('schedule_anonymize_tooltip')}
            label="Deactivate Account & Schedule Anonymization"
          />
          <FormControlLabel
            value="anonymizeData"
            control={<Radio color="primary" />}
            label="Deactivate Account & Anonymize GDPR Data Now"
            title={t('anonymize_tooltip')}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={close}>
          {t('cancel')}
        </Button>
        <LoadingButton variant="contained" loading={loading} onClick={deactivateUser} color="primary">
          {t('submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClientsDialogDeactivateUser;
