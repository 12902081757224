import { PERMISSIONS } from 'helpers/permissions';
import useUserPermissions, { hasPermissions } from 'hooks/useUserPermissions';

/**
 * React hook that defines rights specific to the Client feature
 */
export default function useClientsRights() {
  const userPermissions = useUserPermissions();
  return {
    canEdit: hasPermissions([PERMISSIONS.EDIT_CLIENT], userPermissions),
  };
}
