/* eslint-disable react-hooks/exhaustive-deps */
import { LicenseInfo } from '@mui/x-data-grid-pro';
import AffairApiService from 'api/AffairApiService';
import AuthApiService from 'api/AuthApiService';
import CustomersApiService from 'api/CustomersApiService';
import ResourceAPI from 'api/ResourceAPI';
import UserApiService from 'api/UserApiService';
import findActiveCustomer from 'helpers/findActiveCustomer';
import useAppRights from 'hooks/rights/useAppRights';
import useApi from 'hooks/useApi';
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { adminRoutes, bmRoutes, clientRoutes, consultantRoutes, teamLeaderRoutes } from 'routes';
import AppRouter from 'routes/AppRouter';
import Token from 'types/models/Token';
import { RouteType } from 'types/RouteType';
import './App.scss';
import useUserRoles from './hooks/useUserRoles';
import './i18n';
import {
  loadAffairsAction,
  loadCustomerAction,
  loadCustomersAction,
  loadDevisesAction,
  loadRolesAction,
  loadUserAction,
  updateTermsOfUse,
} from './store/actions/appActions';
import { setSnackbarAction } from './store/actions/snackbarActions';
import { useSelector } from './store/hooks';
import { useAppState } from './store/Provider';

const App: React.FC = () => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const snackbar = useSelector((state) => state.snackbar);
  const userRoles = useUserRoles();
  const { canFetchAllDeviseForCatalog } = useAppRights();
  const [appIsReady, setAppIsReady] = useState<boolean>(false);
  const [authUri, setAuthUri] = useState<string>('');
  const ApiAuthService = AuthApiService;
  const muiProVersion = process.env.REACT_APP_MUI_PRO ?? '';
  LicenseInfo.setLicenseKey(muiProVersion);

  const { makeCall } = useApi();

  useEffect(() => {
    // Create the first script element for widget settings
    const script1 = document.createElement('script');
    script1.innerHTML = `
      window.fwSettings = {
        'widget_id': 203000000076
      };
      !function() {
        if ("function" !== typeof window.FreshworksWidget) {
          var n = function() { n.q.push(arguments) };
          n.q = [];
          window.FreshworksWidget = n;
        }
       
      }();
    `;
    document.body.appendChild(script1);

    // Create the second script element for the widget itself
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = 'https://euc-widget.freshworks.com/widgets/203000000076.js';
    script2.async = true;
    script2.defer = true;

    document.body.appendChild(script2);

    // Cleanup function to remove scripts when component unmounts
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  useEffect(() => {
    if (
      appState.token?.id_token &&
      appState.token.id_token !== '' &&
      appState.token?.id_token !== undefined &&
      !appIsReady
    ) {
      setAppIsReady(true);
    }
  }, [appIsReady, appState.token?.id_token]);

  useEffect(() => {
    if (appState.customer?.slug) {
      setActiveSlug(appState.customer?.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer?.id]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (appIsReady) {
      return;
    }
    // Run this after the sign-in

    let cognitoToken: string | null | Token = localStorage.getItem('cognito-token');
    if (cognitoToken) {
      cognitoToken = JSON.parse(cognitoToken) as Token;
    }

    const refreshCognitoToken = async (cognitoToken: Token) => {
      const { tokenUri } = await AuthApiService.getRefreshTokenUri(cognitoToken.refresh_token as string);
      const token = await AuthApiService.getAuthToken(tokenUri);
      localStorage.setItem('cognito-token', JSON.stringify({ ...token, refresh_token: cognitoToken.refresh_token }));

      return setAppIsReady(true);
    };
    if (
      cognitoToken &&
      Object.keys(cognitoToken).length &&
      isTokenStillValid(Number((cognitoToken as Token)?.expires_at))
    ) {
      return;
    }
    if (cognitoToken && Object.keys(cognitoToken).length) {
      refreshCognitoToken(cognitoToken as Token);
      return;
    }

    if (!authUri && !appState.token?.code) {
      const getUri = async () => {
        const uri = await ApiAuthService.getAuthAuthorization();
        setAuthUri(uri);
      };
      getUri();
    }
    if (authUri && !appState.token?.code && !appIsReady && !appState.token?.id_token) {
      window.location.href = authUri;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ApiAuthService, authUri, appState.token, appIsReady]);

  const isTokenStillValid = (expires_at: number) => expires_at - Date.now() > 0;
  // Fetch customers.

  useEffect(() => {
    if (appIsReady) {
      // eslint-disable-next-line consistent-return
      const fetchCustomers = async () => {
        try {
          const customersFetched = await makeCall(CustomersApiService.fetchAll({ size: -1 }));
          dispatch(loadCustomersAction(customersFetched));
          dispatch(loadCustomerAction(findActiveCustomer(customersFetched)));
        } catch (e) {
          const result = (e as Error).message;

          if (result) {
            dispatch(
              setSnackbarAction({
                message: `Fetch customers request failed : ${result}`,
                open: true,
                severity: 'error',
              })
            );
          } else {
            dispatch(setSnackbarAction({ message: `Fetch customers request failed :`, open: true, severity: 'error' }));
          }
        }
      };
      fetchCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appIsReady]);

  // Fetch affairs from active customer.
  useEffect(() => {
    const fetchAffairsByCustomer = async () => {
      try {
        if (appState.customer) {
          const affairsRetrieved = await makeCall(AffairApiService.fetchByCustomerId(appState.customer.id));
          dispatch(loadAffairsAction(affairsRetrieved));
        }
      } catch (e) {
        const result = (e as Error).message;
        if (result) {
          dispatch(
            setSnackbarAction({
              message: `Fetch affairs failed : ${result}`,
              open: true,
              severity: 'error',
            })
          );
        } else {
          dispatch(
            setSnackbarAction({
              message: `Fetch affairs failed : ${result}`,
              open: true,
              severity: 'error',
            })
          );
        }
      }
    };
    const fetchAllDeviseForCatalog = async () => {
      try {
        const {
          data: { datas },
        } = await ResourceAPI.fetchAll('devises');
        dispatch(loadDevisesAction(datas));
      } catch (error) {
        const result = (error as Error).message;
        if (result) {
          dispatch(
            setSnackbarAction({
              message: `Fetch complexities from active catalog failed  : ${result}`,
              open: true,
              severity: 'error',
            })
          );
        } else
          dispatch(
            setSnackbarAction({
              message: 'Fetch complexities from active catalog failed ',
              open: true,
              severity: 'error',
            })
          );
      }
    };
    if (appState.customer?.id && canFetchAllDeviseForCatalog) {
      fetchAllDeviseForCatalog();
      fetchAffairsByCustomer();
    }
  }, [appState.customer, canFetchAllDeviseForCatalog]);

  useEffect(() => {
    if (appIsReady) {
      const getActiveUser = async () => {
        const data = await makeCall(UserApiService.fetchMe(), 'Fetch your informations failed');
        if (data) {
          dispatch(updateTermsOfUse(data.acceptedTermsOfuse));
          dispatch(loadUserAction(data.me));
        }
      };
      getActiveUser();
    }
  }, [appIsReady]);
  useEffect(() => {
    const getActiveUserRole = async () => {
      try {
        if (appState.user) {
          const roles = !appState.user.is_client ? await UserApiService.fetchUserRole() : ['customer' as const];
          dispatch(loadRolesAction(roles));
        }
      } catch (error: any) {
        dispatch(
          setSnackbarAction({ message: `Fetch your roles failed : ${error.message}`, open: true, severity: 'error' })
        );
      }
    };
    if (appState.customer?.id && appIsReady && appState.user?.id && appState.acceptedTermsOfuse === true)
      getActiveUserRole();
  }, [appState.customer?.id, appIsReady, appState.user, appState.acceptedTermsOfuse]);

  const [activeSlug, setActiveSlug] = useState<string>('');

  const [availableRoutes, setAvailablesRoutes] = useState<RouteType[]>();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const getRoutes = () => {
      if (userRoles.isConsultant) return setAvailablesRoutes(consultantRoutes);
      if (userRoles.isClient) return setAvailablesRoutes(clientRoutes);
      if (userRoles.isAdmin) return setAvailablesRoutes(adminRoutes);
      if (userRoles.isTeamLeader) return setAvailablesRoutes(teamLeaderRoutes);
      if (userRoles.isManager) {
        return setAvailablesRoutes(bmRoutes);
      }
      return setAvailablesRoutes(consultantRoutes);
    };
    const delayDebounceFn = setTimeout(() => getRoutes(), 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [userRoles, appState.customers]);

  const progress = useRef(0);

  React.useEffect(() => {
    progress.current = 0;

    const timer = setInterval(
      () => {
        progress.current = progress.current >= 100 ? 100 : progress.current + 2;
      },
      snackbar?.duration ? snackbar?.duration / 100 : 50
    );
    return () => {
      clearInterval(timer);
      progress.current = 0;
    };
  }, [snackbar?.duration !== 0]);

  const locationForRedirect =
    (window.location.pathname.includes('dashboard') ||
      window.location.pathname.includes('management') ||
      window.location.pathname.includes('order') ||
      window.location.pathname.includes('catalogs') ||
      window.location.pathname.includes('clients') ||
      window.location.pathname.includes('administration') ||
      window.location.pathname.includes('deliverables')) &&
    window.location.pathname.includes(activeSlug);
  return (
    <Router>
      <AppRouter
        appIsReady={appIsReady}
        roles={appState.roles}
        availableRoutes={availableRoutes}
        activeSlug={activeSlug}
        progress={progress}
        locationForRedirect={locationForRedirect}
      />
    </Router>
  );
};

export default App;
