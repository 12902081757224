import { handleApiError } from 'api/errors';
import ResourceApi from 'api/ResourceAPI';
import BaseApiService from './BaseApiService';

export default class TermsOfUseApiService extends BaseApiService {
  public static async get(): Promise<any> {
    try {
      return await ResourceApi.get('terms-of-use', undefined, false);
    } catch (e) {
      handleApiError(e);
      return e;
    }
  }

  public static async accept(): Promise<any> {
    try {
      return await ResourceApi.get('me/accept-terms-of-use', undefined, false);
    } catch (e) {
      handleApiError(e);
      return e;
    }
  }
}
