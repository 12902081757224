import { CatalogToOrderActions } from 'store/actions/catalogToOrderActions';
import { RootActions } from 'store/redux';
import {
  addWorkunitToOrder,
  changeWorkunitMonthQuantity,
  changeWorkunitScope,
  clearWorkunitData,
  clearWorkunitScope,
  updateSearchParams,
  updateSelectedWorkunitData,
  updateSliceMonths,
  updateWorkunitData,
} from 'store/services/catalogToOrderServices';
import { catalogToOrderState, CatalogToOrderState } from 'store/states/catalogToOrderState';

const catalogToOrderReducer = (state = catalogToOrderState, action: RootActions): CatalogToOrderState => {
  switch (action.type) {
    case CatalogToOrderActions.INJECT_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case CatalogToOrderActions.INJECT_WORKUNITS:
      return {
        ...state,
        workunits: action.payload.workunits,
      };
    case CatalogToOrderActions.INJECT_SELECTED_WORKUNITS:
      return {
        ...state,
        selectedWorkunits: action.payload.selectedWorkunits,
      };
    case CatalogToOrderActions.INJECT_ORDER_WORKUNITS:
      return {
        ...state,
        orderWorkunits: action.payload.orderWorkunits,
      };
    case CatalogToOrderActions.INJECT_SCOPES:
      return {
        ...state,
        scopes: action.payload.scopes,
      };
    case CatalogToOrderActions.INJECT_TENANT_WORKLOAD:
      return {
        ...state,
        summary: {
          ...state.summary,
          tenantWorkload: action.payload.tenantWorkload,
        },
      };
    case CatalogToOrderActions.INJECT_CATALOG_COMPLEXITIES:
      return {
        ...state,
        summary: {
          ...state.summary,
          catalogComplexities: action.payload.catalogComplexities,
        },
      };
    case CatalogToOrderActions.INJECT_SUMMARY_MONTHS:
      return {
        ...state,
        summary: {
          ...state.summary,
          usedMonthsWorkunits: action.payload.summaryMonth,
        },
      };
    case CatalogToOrderActions.INJECT_SUMMARY_QUARTERS:
      return {
        ...state,
        summary: {
          ...state.summary,
          usedQuartersWorkunits: action.payload.summaryQuarter,
        },
      };
    case CatalogToOrderActions.INJECT_SCOPES_IN_ORDER:
      return {
        ...state,
        scopesInOrder: action.payload.scopesInOrder,
      };
    case CatalogToOrderActions.ADD_SCOPE:
      return {
        ...state,
        scopes: [...state.scopes, action.payload],
      };
    case CatalogToOrderActions.ADD_WORKUNIT_TO_ORDER:
      return addWorkunitToOrder(state, action);
    case CatalogToOrderActions.INJECT_QUARTERS:
      return {
        ...state,
        quarters: action.payload.quarters,
      };
    case CatalogToOrderActions.INJECT_MONTHS:
      return {
        ...state,
        months: action.payload.months,
      };
    case CatalogToOrderActions.CHANGE_IS_WORKUNITS_LOADED:
      return {
        ...state,
        isWorkunitsLoaded: action.payload.isWorkunitsLoaded,
      };
    case CatalogToOrderActions.CLEAR_WORKUNIT_SCOPE:
      return clearWorkunitScope(state, action);
    case CatalogToOrderActions.CHANGE_WORKUNIT_SCOPE:
      return changeWorkunitScope(state, action);
    case CatalogToOrderActions.RESET_ADDED_WORKUNIT:
      return clearWorkunitData(state, action);
    case CatalogToOrderActions.CHANGE_MONTH_QUANTITY_WORKUNIT:
      return changeWorkunitMonthQuantity(state, action, 'catalog');
    case CatalogToOrderActions.CHANGE_MONTH_QUANTITY_ORDER_WORKUNIT:
      return changeWorkunitMonthQuantity(state, action, 'selected');
    case CatalogToOrderActions.UPDATE_WORKUNIT:
      return updateWorkunitData(state, action);
    case CatalogToOrderActions.UPDATE_SELECTED_WORKUNIT:
      return updateSelectedWorkunitData(state, action);
    case CatalogToOrderActions.INCREASE_QUARTER_SLICE_SUMMARY:
      return {
        ...state,
        summaryHandler: {
          ...state.summaryHandler,
          quarterSlice: {
            endSlice: (state.summaryHandler?.quarterSlice?.endSlice || 0) + 1,
            startSlice: (state.summaryHandler?.quarterSlice?.startSlice || 0) + 1,
          },
        },
      };
    case CatalogToOrderActions.DECREASE_QUARTER_SLICE_SUMMARY:
      return {
        ...state,
        summaryHandler: {
          ...state.summaryHandler,
          quarterSlice: {
            endSlice: (state.summaryHandler?.quarterSlice?.endSlice || 0) - 1,
            startSlice: (state.summaryHandler?.quarterSlice?.startSlice || 0) - 1,
          },
        },
      };

    case CatalogToOrderActions.INCREASE_MONTH_SLICE_CATALOG:
    case CatalogToOrderActions.INCREASE_MONTH_SLICE_SELECTED:
    case CatalogToOrderActions.INCREASE_MONTH_SLICE_SUMMARY:
      return updateSliceMonths(state, action, true);
    case CatalogToOrderActions.DECREASE_MONTH_SLICE_SELECTED:
    case CatalogToOrderActions.DECREASE_MONTH_SLICE_CATALOG:
    case CatalogToOrderActions.DECREASE_MONTH_SLICE_SUMMARY:
      return updateSliceMonths(state, action, false);
    case CatalogToOrderActions.INCREASE_CATALOG_CONTENTS_NUMBER:
      return {
        ...state,
        catalogHandler: {
          ...state.catalogHandler,
          contentsNumber: state.catalogHandler.contentsNumber ? state.catalogHandler.contentsNumber + 1 : 1,
        },
      };
    case CatalogToOrderActions.DECREASE_CATALOG_CONTENTS_NUMBER:
      return {
        ...state,
        catalogHandler: {
          ...state.catalogHandler,
          contentsNumber: state.catalogHandler.contentsNumber ? state.catalogHandler.contentsNumber - 1 : 0,
        },
      };
    case CatalogToOrderActions.TOGGLE_IS_SUMMARY_DEPLOYED:
      return {
        ...state,
        summaryHandler: {
          ...state.summaryHandler,
          isOpen: !state.summaryHandler.isOpen,
        },
      };
    case CatalogToOrderActions.SET_SUMMARY_COLUMNS_MONTHS:
      return {
        ...state,
        summary: {
          ...state.summary,
          columnsMonths: action.payload.columns,
        },
      };
    case CatalogToOrderActions.SET_SUMMARY_COLUMNS_QUARTERS:
      return {
        ...state,
        summary: {
          ...state.summary,
          columnsQuarter: action.payload.columns,
        },
      };
    case CatalogToOrderActions.SET_SUMMARY_VALUES_MONTHS:
      return {
        ...state,
        summary: {
          ...state.summary,
          valuesMonths: action.payload.values,
        },
      };
    case CatalogToOrderActions.SET_SUMMARY_VALUES_QUARTERS:
      return {
        ...state,
        summary: {
          ...state.summary,
          valuesQuarters: action.payload.values,
        },
      };
    case CatalogToOrderActions.CHANGE_SUMMARY_DISPLAY_MODE:
      return {
        ...state,
        summary: {
          ...state.summary,
          displayMode: action.payload.displayMode,
        },
      };
    case CatalogToOrderActions.RESET_CATALOG_TO_ORDER_STATE:
      return {
        ...catalogToOrderState,
      };
    case CatalogToOrderActions.INCREASE_SELECTED_CONTENTS_NUMBER:
      return {
        ...state,
        orderHandler: {
          ...state.orderHandler,
          contentsNumber: state.orderHandler.contentsNumber ? state.orderHandler.contentsNumber + 1 : 1,
        },
      };
    case CatalogToOrderActions.DECREASE_SELECTED_CONTENTS_NUMBER:
      return {
        ...state,
        orderHandler: {
          ...state.orderHandler,
          contentsNumber: state.orderHandler.contentsNumber ? state.orderHandler.contentsNumber - 1 : 0,
        },
      };
    case CatalogToOrderActions.SET_SELECTED_WORKUNIT_EDIT:
      return {
        ...state,
        selectedWorkunitsEditIds: [...state.selectedWorkunitsEditIds, action.payload.selectedWorkunitId],
      };
    case CatalogToOrderActions.REMOVE_SELECTED_WORKUNIT_EDIT:
      return {
        ...state,
        selectedWorkunitsEditIds: state.selectedWorkunitsEditIds.filter(
          (id) => id !== action.payload.selectedWorkunitId
        ),
      };
    case CatalogToOrderActions.DELETE_SELECTED_WORKUNIT:
      return {
        ...state,
        selectedWorkunits: state.selectedWorkunits.filter(
          (workunit) => workunit.randomId !== action.payload.selectedWorkunitId
        ),
        selectedWorkunitsEditIds: state.selectedWorkunitsEditIds.filter(
          (id) => id !== action.payload.selectedWorkunitId
        ),
      };
    case CatalogToOrderActions.UPDATE_SEARCH_PARAMS:
      return updateSearchParams(state, action);
    default:
      return state;
  }
};

export default catalogToOrderReducer;
