/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Menu, TextField, Tooltip, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { loadCustomerAction, loadRolesAction } from 'store/actions/appActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Customer from 'types/entities/Customer';
import { useTranslation } from 'react-i18next';

interface Props {
  name?: string;
  path?: string;
  icon?: unknown;
  customers: Customer[];
}

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  borderColor: '#FFFFFF',
  boxShadow: '0px 3px 16px #00000029',
  color: '#FFFFFF',
  width: 56,
  height: 56,
  minWidth: 'auto',
  border: '2px solid #FFFFFF',
  '&:hover, &[aria-expanded="true"]': {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 30px #00000060',
    color: theme.palette.primary.dark,
  },
}));

const SidebarCustomerSelect: React.FC<Props> = ({ customers }) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const [value, setValue] = React.useState(appState.customer);
  const history = useHistory();
  const [t] = useTranslation();
  const handleChange = async (newValue: any) => {
    if (newValue.name) {
      localStorage.setItem('active_customer', JSON.stringify(newValue));
      dispatch(loadCustomerAction(newValue));
      dispatch(loadRolesAction());
      if (newValue?.slug) {
        history.push(`/${newValue?.slug}/dashboard`);
      }
    }
  };
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    let customer = localStorage.getItem('active_customer') || null;
    if (customer) {
      customer = JSON.parse(customer);
      if (!customers.map((c: Customer) => c.id).includes((customer as unknown as Customer)?.id)) {
        dispatch(loadCustomerAction(customers[0]));
        return;
      }
      dispatch(loadCustomerAction(customer as unknown as Customer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={appState.customer ? appState.customer.name : ''} placement="top">
        <CustomButton
          id="customer-button"
          aria-controls={open ? 'customer-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {`${capitalize(appState.customer?.name.charAt(0))}${capitalize(appState.customer?.name.charAt(1))}`}
        </CustomButton>
      </Tooltip>
      <Menu
        id="customer-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'customer-button',
        }}
        PaperProps={{ sx: { width: '200px', backgroundColor: 'transparent' } }}
      >
        <Autocomplete
          id="active-customer-selection"
          options={customers}
          defaultValue={appState.customer}
          getOptionLabel={(option) => (option as unknown as Customer).name}
          isOptionEqualToValue={(option: any, v: any) => option.id === v?.id}
          value={value}
          renderOption={(props, option) => (
            <li {...props} key={(option as unknown as Customer).id}>
              {(option as unknown as Customer).name}
            </li>
          )}
          disableClearable
          onChange={(event, newValue: any) => {
            handleChange(newValue);
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} variant="filled" />}
        />
        <Typography sx={{ position: 'absolute', left: 14, top: 7 }} variant="body2">
          {t('Clients')}
        </Typography>
      </Menu>
    </div>
  );
};
export default SidebarCustomerSelect;
