import { Add, FilterList, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import RolesApiService from 'api/RolesApiService';
import UserApiService from 'api/UserApiService';
import { Layout, Loader } from 'components/index';
import { stringToColor } from 'helpers/format';
import useApi from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Customer from 'types/entities/Customer';
import Role from 'types/entities/Role';
import User from 'types/entities/User';
import './AdminUserRole.scss';

const AdminUserRole: React.FC<RouteComponentProps> = ({ match }) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const loadings = useSelector((state) => state.loadings);
  const [t] = useTranslation();
  const [users, setUsers] = useState<User[]>([]);
  const [foundUsers, setFoundUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [roleSearch, setRoleSearched] = useState<Role | null>(null);
  const [openDialogAddUser, setOpenDialogAddUser] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { makeCall } = useApi();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsFilterOpen(() => !isFilterOpen);
  };
  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: Role) => {
    if (roleSearch && roleSearch.name === value.name) setRoleSearched(null);
    else setRoleSearched(value);
    setAnchorEl(null);
    setIsFilterOpen(() => !isFilterOpen);
  };

  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  useEffect(() => {
    const getUsersInCustomer = async () => {
      if (appState.customer) {
        dispatch(addLoadingAction('getUsersInCustomer'));
        const { data } = await makeCall(
          UserApiService.getUsersOnCustomer(appState.customer?.id),
          'Unable to retrieve users on customer'
        );
        const rolesRes = await makeCall(RolesApiService.fetchAll(), 'Unable to retrieve roles');
        setRoles(rolesRes);
        setUsers(
          data.results.map((result: { user: User; roles: Role[] }) => ({ ...result.user, roles: result.roles }))
        );
        setFoundUsers(
          data.results.map((result: { user: User; roles: Role[] }) => ({ ...result.user, roles: result.roles }))
        );
        setFoundUserLength(data.results.length);
        setPage(0);
        dispatch(removeLoadingAction('getUsersInCustomer'));
      }
    };
    getUsersInCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer?.id]);
  const [contacts, setContacts] = useState<User[]>([]);
  const [inputValueContact, setInputValueContact] = useState<string>('');

  const handleClickRole = (e: React.ChangeEvent<HTMLInputElement>, user: User, value: Role) => {
    if (e.currentTarget.checked) handleAddRole(user, value);
    if (!e.currentTarget.checked) handleRemoveRole(user, value);
  };
  const [isContactLoading, setIsContactLoading] = useState<boolean>(false);

  const validatePermissionsUser = async (user: User) => {
    const userRoles = user.roles ? user.roles.map((role) => role.name) : [];
    await makeCall(
      UserApiService.updateRoleOnUser({
        user_id: user.id,
        customer_id: appState.customer?.id,
        roles: userRoles,
      }),
      'Unable to update user on customer',
      setIsLoadingButton
    );
  };

  const handleChangeNewUser = (checked: boolean, role: Role) => {
    if (checked) setNewUser({ ...newUser, roles: role });
  };

  const handleAddRole = (user: User, role: Role) => {
    setUsers(users.map((u) => (u.id !== user.id ? u : { ...u, roles: [...u.roles, role] })));
    setFoundUsers(foundUsers.map((u) => (u.id !== user.id ? u : { ...u, roles: [...u.roles, role] })));
  };
  const handleRemoveRole = (user: User, role: Role) => {
    setUsers(users.map((u) => (u.id !== user.id ? u : { ...u, roles: u.roles.filter((r) => r.name !== role.name) })));
    setFoundUsers(
      foundUsers.map((u) => (u.id !== user.id ? u : { ...u, roles: u.roles.filter((r) => r.name !== role.name) }))
    );
  };

  const [searched, setSearched] = useState<string>('');

  useEffect(() => {
    searchFunction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searched, roleSearch]);
  const [foundUsersLength, setFoundUserLength] = React.useState(0);

  useEffect(() => {
    setFoundUserLength(foundUsersLength);
  }, [foundUsers, foundUsersLength]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function searchFunction() {
    const searchParam = searched.toLowerCase();
    if (searched !== '' && !roleSearch) {
      setFoundUsers(users.filter((user) => searchByName(user)));
    } else if (roleSearch && roleSearch.name && searched === '') {
      setFoundUsers(users.filter((user) => searchByRole(user)));
    } else if (roleSearch && roleSearch.name && searched !== '') {
      setFoundUsers(users.filter((user) => searchByRole(user) && searchByName(user)));
    } else if (searched === '' && roleSearch === null) {
      setFoundUsers(users);
    }
    setPage(0);

    function searchByRole(user: User) {
      return user.roles.some((role) => role.id === roleSearch?.id);
    }

    function searchByName(user: User): unknown {
      return user.first_name.toLowerCase().includes(searchParam) || user.last_name.toLowerCase().includes(searchParam);
    }
  }

  const handleCloseDialogAddUser = () => {
    setOpenDialogAddUser(false);
  };
  const handleOpenDialogAddUser = () => {
    setOpenDialogAddUser(true);
  };

  const saveNewUser = async () => {
    setIsLoadingButton(true);
    const res = await makeCall(
      UserApiService.updateRoleOnUser({
        user_id: newUser?.user_id,
        customer_id: appState.customer?.id,
        roles: newUser?.roles?.name,
      }),
      'Unable to update user on customer'
    );

    if (res) {
      const userWasAlreadyAssignedToCustomer = users.find((user) => user.id === newUser?.user_id);
      if (userWasAlreadyAssignedToCustomer && newUser?.roles && newUser?.roles?.id)
        setUsers(
          users.map((user) =>
            user.id !== userWasAlreadyAssignedToCustomer.id
              ? user
              : {
                  ...user,
                  roles: userWasAlreadyAssignedToCustomer.roles ? [userWasAlreadyAssignedToCustomer.roles?.[0]] : [],
                }
          )
        );
      if (userWasAlreadyAssignedToCustomer && !newUser?.roles && !newUser?.roles?.id)
        setUsers(users.filter((user) => user.id !== userWasAlreadyAssignedToCustomer.id));
      if (!userWasAlreadyAssignedToCustomer) {
        setUsers([...users, { ...(newUser?.user as User), roles: newUser?.roles ? [newUser?.roles] : [] }]);
      }
    }

    setOpenDialogAddUser(false);
    setIsLoadingButton(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setIsContactLoading(true);
      setContacts([]);
      if (inputValueContact !== '' && inputValueContact.length > 1) {
        const res = await makeCall(
          UserApiService.get({ 'last_name,first_name': `:${inputValueContact}:`, is_archived: 0, size: -1 }),
          'Failed to retrieve users'
        );

        setContacts(res.datas);
        setIsContactLoading(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueContact]);

  function setNewUserSelected(
    newUser: { roles?: Role | undefined; user_id?: number | undefined; customer_id?: number | undefined } | undefined,
    value: User | null,
    reason: string
  ): void {
    if (reason === 'clear') return setNewUser(undefined);
    const userIsAlreadyAssignedToCustomer = users.find((user) => user.id === value?.id);
    if (userIsAlreadyAssignedToCustomer) {
      return setNewUser({
        user_id: userIsAlreadyAssignedToCustomer.id,
        roles: userIsAlreadyAssignedToCustomer.roles[0],
      });
    }
    return setNewUser({
      ...newUser,
      user_id: value?.id as User['id'],
      user: value as User,
      roles: newUser?.roles ? newUser?.roles : undefined,
    });
  }

  const [newUser, setNewUser] =
    useState<{ roles?: Role; user_id?: User['id']; customer_id?: Customer['id']; user?: User }>();
  return (
    <Layout name="Admin" path="/administration">
      <div className="admin-container">
        <div className="filters-container">
          <div className="filters">
            <button type="button" className="submit-button" onClick={handleOpenDialogAddUser}>
              <Add />
              {t('Add user')}
            </button>
            <Dialog
              open={openDialogAddUser}
              onClose={handleCloseDialogAddUser}
              className="dialog_add_user"
              maxWidth="md"
            >
              <DialogTitle>{t('New_user')}</DialogTitle>
              <DialogContent style={{ minWidth: '480px' }}>
                <div className="label-input">{t('User')}</div>

                <Autocomplete
                  id="contact-autocomplete"
                  className="autocomplete-card-client"
                  options={contacts}
                  autoHighlight
                  getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                  renderOption={(boxprops, option) => (
                    <Box component="li" {...boxprops} className="box-consultant" key={option.id}>
                      {option?.avatar_uri ? (
                        <Avatar src={option.avatar_uri} alt={t('Active_user_avatar')} />
                      ) : (
                        <Avatar
                          style={{
                            backgroundColor: `${stringToColor(option?.first_name)}`,
                            color: `${stringToColor(option?.first_name, 'color')}`,
                          }}
                        >{`${option?.first_name.charAt(0)}${option?.last_name.charAt(0)}`}</Avatar>
                      )}
                      {option.last_name} {option.first_name}
                    </Box>
                  )}
                  inputValue={inputValueContact}
                  onInputChange={(event, valueContact) => {
                    setInputValueContact(valueContact);
                  }}
                  // open={contacts.length > 1}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value, reason) => setNewUserSelected(newUser, value as User, reason)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                  noOptionsText={
                    inputValueContact.length > 1 && contacts.length === 0
                      ? t('No contacts found')
                      : t('2_characters_Minimun')
                  }
                  loading={!contacts.length && isContactLoading}
                />

                <FormGroup>
                  {roles.map((role) => (
                    <FormControlLabel
                      key={role.name}
                      control={
                        <Checkbox
                          className="container_name_admin"
                          color="primary"
                          checked={newUser?.roles && newUser?.roles?.name === role.name}
                          onChange={(e) => handleChangeNewUser(e.currentTarget.checked, role)}
                        />
                      }
                      label={t(role.name) as string}
                    />
                  ))}
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <button type="button" className="submit-button" onClick={saveNewUser}>
                  {!isLoadingButton ? (
                    <div className="submit-button-icon-container">
                      <Add />
                      {t('Save_user')}
                    </div>
                  ) : (
                    <CircularProgress size="1rem" color="inherit" />
                  )}
                </button>
              </DialogActions>
            </Dialog>
            <button
              aria-controls="simple-menu"
              aria-haspopup="true"
              className="submit-button"
              onClick={handleClick}
              type="button"
            >
              <FilterList />
              {roleSearch && roleSearch.name ? roleSearch.name : t('Filter')}
            </button>
            <Popper open={isFilterOpen} anchorEl={anchorEl} transition>
              {({
                TransitionProps,
                placement = 'auto-start',
              }: {
                TransitionProps: { in: boolean; onEnter: () => void; onExited: () => void; id: string };
                placement: string;
              }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper className="menu-paper-container">
                    <MenuList>
                      {roles.map((role) => (
                        <MenuItem>
                          <button
                            type="button"
                            onClick={(e) => handleClose(e, role)}
                            className="filter-content-item-container"
                          >
                            {t(role?.name)}
                          </button>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Paper>
                </Grow>
              )}
            </Popper>
            {/* <Tooltip title={t('Reload_user_on_tenant_permissions') as string}>
              <button type="button" className="submit-button reload" onClick={() => renewUserOnTenantPermissions()}>
                <Autorenew />
              </button>
            </Tooltip> */}
            <TextField
              placeholder={t('Search')}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <IconButton size="large">
                    <Search />
                  </IconButton>
                ),
              }}
              value={searched}
              onChange={(e) => setSearched(e.currentTarget.value)}
              className="search_textfield"
            />
          </div>
        </div>
        <div className="table_container">
          {!loadings.getUsersInCustomer && (
            <Table style={{ width: '100%', overflow: 'auto', tableLayout: 'fixed' }}>
              <TableBody style={{ width: '100%' }}>
                {foundUsers
                  .sort((a, b) => a.last_name.localeCompare(b.last_name))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow key={user?.id}>
                      <TableCell component="td" style={{ width: '10%' }}>
                        <Tooltip arrow title={`${user.last_name} ${user.first_name}` as string}>
                          <div className="container_name_admin"> {`${user.last_name} ${user.first_name}`}</div>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="container_name_admin"
                                color="primary"
                                checked={user.roles.some((role) => role && role.name === 'bm')}
                                onChange={(e) =>
                                  handleClickRole(e, user, roles.find((role) => role.name === 'bm') as Role)
                                }
                              />
                            }
                            label="Business manager"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="container_name_admin"
                                color="primary"
                                checked={user.roles.some((role) => role && role.name === 'consultant')}
                                onChange={(e) =>
                                  handleClickRole(e, user, roles.find((role) => role.name === 'consultant') as Role)
                                }
                              />
                            }
                            label="Consultant"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="container_name_admin"
                                color="primary"
                                checked={user.roles.some((role) => role && role.name === 'customer')}
                                onChange={(e) =>
                                  handleClickRole(e, user, roles.find((role) => role.name === 'customer') as Role)
                                }
                              />
                            }
                            label="Client"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="container_name_admin"
                                color="primary"
                                checked={user.roles.some((role) => role && role.name === 'team_leader')}
                                onChange={(e) =>
                                  handleClickRole(e, user, roles.find((role) => role.name === 'team_leader') as Role)
                                }
                              />
                            }
                            label="Chef de mission"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="container_name_admin"
                                color="primary"
                                checked={user.roles.some((role) => role && role.name === 'admin')}
                                onChange={(e) =>
                                  handleClickRole(e, user, roles.find((role) => role.name === 'admin') as Role)
                                }
                              />
                            }
                            label="Administrateur"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="container_name_admin"
                                color="primary"
                                checked={user.roles.some((role) => role && role.name === 'support_bu')}
                                onChange={(e) =>
                                  handleClickRole(e, user, roles.find((role) => role.name === 'support_bu') as Role)
                                }
                              />
                            }
                            label="Support BU"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="container_name_admin"
                                color="primary"
                                checked={user.roles.some((role) => role && role.name === 'ddcc')}
                                onChange={(e) =>
                                  handleClickRole(e, user, roles.find((role) => role.name === 'ddcc') as Role)
                                }
                              />
                            }
                            label="DDCC"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="container_name_admin"
                                color="primary"
                                checked={user.roles.some((role) => role && role.name === 'bu')}
                                onChange={(e) =>
                                  handleClickRole(e, user, roles.find((role) => role.name === 'bu') as Role)
                                }
                              />
                            }
                            label="Directeur BU"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="container_name_admin"
                                color="primary"
                                checked={user.roles.some((role) => role && role.name === 'butl')}
                                onChange={(e) =>
                                  handleClickRole(e, user, roles.find((role) => role.name === 'butl') as Role)
                                }
                              />
                            }
                            label="BUTL"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        {!isLoadingButton ? (
                          <button
                            type="button"
                            className="submit-button validate_button"
                            onClick={() => validatePermissionsUser(user)}
                          >
                            {t('Validate')}
                          </button>
                        ) : (
                          <Loader size={20} />
                        )}
                      </TableCell>
                      {/* <TableCell>
                        {!isLoadingButton ? (
                          <Tooltip title={t('Reload_permissions') as string}>
                            <button
                              type="button"
                              className="submit-button reload_button"
                              onClick={() => renewUserPermissions(user)}
                            >
                              <Autorenew />
                            </button>
                          </Tooltip>
                        ) : (
                          <Loader size={20} />
                        )}
                      </TableCell> */}
                    </TableRow>
                  ))}
                <TablePagination
                  component={TableRow}
                  count={foundUsers.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdminUserRole;
