import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import DialogMarkAsSigned from 'features/Sheet/Components/Dialogs/DialogMarkAsSigned/DialogMarkAsSigned';
import DialogSendToClient from 'features/Sheet/Components/Dialogs/DialogSendToClient/DialogSendToClient';
import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { openDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import DeliverableReportsList from './DeliverableReportsList/DeliverableReportsList';
import DeliverableReportTabs from './DeliverableReportTabs/DeliverableReportTabs';

const DeliverableReports: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useAppState();
  const deliverableSheetState = useSelector((state) => state.deliverableSheet);
  const { canCreateReport } = useDeliverableRights();

  const openCreateReportModal = () => {
    dispatch(openDialogAction({ name: 'deliverableCreateReport' }));
  };

  return (
    <Box>
      <DeliverableReportTabs />
      {canCreateReport && (
        <Box sx={{ mb: 3 }}>
          <Button variant="contained" startIcon={<Add />} onClick={() => openCreateReportModal()}>
            {t('Create report')}
          </Button>
        </Box>
      )}
      <DeliverableReportsList items={deliverableSheetState.items} />
      <DialogMarkAsSigned />
      <DialogSendToClient />
    </Box>
  );
};

export default DeliverableReports;
