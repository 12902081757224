import React, { useState, useEffect, useRef, useCallback, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Avatar,
  Box,
  Checkbox,
  TextField,
  Tooltip,
  AvatarGroup,
  Popover,
} from '@mui/material';
import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';
import { stringToColor } from 'helpers/format';
import User from 'types/entities/User';
import { CustomAvatar } from 'components/index';
import { noop } from 'lodash'; // Import noop from lodash

type MultiSelectUsersProps = {
  users: User[];
  selectedUsers: User[];
  onSelectUsers: (users: User[], reason?: string) => void | Promise<void>;
  onUserArchived: (updatedUser: User) => void;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  id?: string;
  variant?: string;
  availableUsers: User[];
  onUserDeletionAttempt: () => void;
  maximumVisible: number;
};

const MultiSelectUser: React.FC<MultiSelectUsersProps> = ({
  users,
  selectedUsers,
  onSelectUsers,
  required = false,
  label,
  disabled = false,
  id,
  variant,
  onUserArchived,
  availableUsers,
  onUserDeletionAttempt = noop,
  maximumVisible,
}) => {
  const maxVisible = maximumVisible; // Adjust based on your max setting
  const inputRef = useRef<HTMLInputElement>(null);
  const [t] = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<User[]>(selectedUsers || []);

  useEffect(() => {
    if (selectedUsers) {
      setSelectedOptions(selectedUsers);
    }
  }, [selectedUsers]);

  const visibleItems = selectedOptions.slice(0, maxVisible);
  const overflowItems = selectedOptions.slice(maxVisible);

  const handleAvatarGroupClick = (event: any) => {
    if (selectedOptions.length > maxVisible) {
      setPopoverOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setInputValue(value);

    if (reason === 'reset' || reason === 'clear') {
      onSelectUsers([], reason);
    }
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (popoverOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [popoverOpen]);

  const handleDelete = (userId: number) => {
    let shouldArchiveUser = true;
    const userToDelete = selectedOptions.find((user) => user.id === userId);

    if (userToDelete) {
      if (availableUsers.some((user) => user.id === userToDelete.id)) {
        onUserDeletionAttempt();
        shouldArchiveUser = false;
        return;
      }

      const newSelectedOptions = selectedOptions.filter((user) => user.id !== userId);
      setSelectedOptions(newSelectedOptions);
      onSelectUsers(newSelectedOptions, 'remove-option');

      if (newSelectedOptions.length <= maxVisible) {
        setPopoverOpen(false);
      }

      if (shouldArchiveUser) {
        archiveUser(userId);
      }
    }
  };

  const archiveUser = (userId: number) => {
    const userToArchive = selectedOptions.find((user) => user.id === userId);
    if (userToArchive) {
      const user: Partial<User> = {
        id: userToArchive.id,
        first_name: userToArchive.first_name,
        last_name: userToArchive.last_name,
        is_archived: true,
        updated_at: new Date().toISOString(),
      };
      onUserArchived(user as User);
    }
  };

  const avatarsToRender: any = [];

  visibleItems.forEach((option, index) => {
    avatarsToRender.push(
      <CustomAvatar
        displayAvatar
        sx={{ marginRight: '10px', padding: '8px 1px' }}
        key={option.id}
        name={`${option.last_name} ${option.first_name}`}
        onClose={() => handleDelete(option.id)}
      />
    );

    if (index === maxVisible - 1 && selectedOptions.length > maxVisible) {
      const remainingCount = selectedOptions.length - maxVisible;
      avatarsToRender.push(
        <Box
          component="span"
          onClick={handleAvatarGroupClick}
          sx={{
            cursor: 'pointer',
            backgroundColor: '#808080',
            borderRadius: '50%',
            padding: '6px',
            display: 'inline-flex',
            color: 'white',
            width: '24px',
            height: '24px',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '12px',
            fontSize: '12px', // Smaller font size
          }}
          key="overflow"
        >
          +{remainingCount}
        </Box>
      );
    }
  });

  const handleOptionChange = useCallback(
    (event: SyntheticEvent, value: User[], reason: AutocompleteChangeReason) => {
      const uncheckedUser = selectedOptions.find((user) => !value.some((newUser) => newUser.id === user.id));
      if (uncheckedUser) {
        if (availableUsers?.some((user) => user.id === uncheckedUser.id)) {
          onUserDeletionAttempt();
          return;
        }
      }
      setSelectedOptions(value);
      onSelectUsers(value, reason);
    },
    [selectedOptions, availableUsers, onSelectUsers, onUserDeletionAttempt]
  );

  return (
    <>
      <Autocomplete
        disableCloseOnSelect
        multiple
        id={id}
        options={users}
        disabled={disabled}
        getOptionLabel={(option) => `${option.last_name.toLocaleUpperCase()} ${option.first_name}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <Box component="li" {...props}>
            <Checkbox checked={selected} style={{ marginRight: 8 }} />
            {option.avatar_uri ? (
              <Avatar src={option.avatar_uri} sx={{ mr: 1 }} />
            ) : (
              <Avatar
                sx={{
                  backgroundColor: stringToColor(option.first_name),
                  color: stringToColor(option.first_name, 'color'),
                  mr: 1,
                }}
              >{`${option.last_name.charAt(0)}${option.first_name.charAt(0)}`}</Avatar>
            )}
            <Box component="span" sx={{ fontWeight: 'normal', paddingRight: 2 }}>
              {`${capitalize(option.last_name)} ${capitalize(option.first_name)}`}
            </Box>
            {option.mail && (
              <Box component="span" sx={{ fontSize: 'small', color: 'text.secondary', paddingTop: 1 }}>
                {option.mail}
              </Box>
            )}
          </Box>
        )}
        value={selectedOptions} // Use selectedOptions directly
        onChange={handleOptionChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={(params: AutocompleteRenderInputParams) => {
          let newEndAdornment = params.InputProps.endAdornment;
          if (React.isValidElement(newEndAdornment)) {
            const children = React.Children.toArray(newEndAdornment.props.children);
            const filteredChildren = children.filter((child) => {
              if (React.isValidElement(child)) {
                return child.props['aria-label'] !== 'Clear';
              }
              return true;
            });
            newEndAdornment = React.cloneElement(newEndAdornment, {}, ...filteredChildren);
          }
          return (
            <TextField
              inputRef={inputRef}
              {...params}
              label={selectedOptions.length > 0 ? `${selectedOptions.length} selected` : label}
              required={required}
              disabled={disabled}
              variant={variant as any}
              InputProps={{
                ...params.InputProps,
                endAdornment: newEndAdornment,
              }}
            />
          );
        }}
        renderTags={() => null} // Do not render tags for selected items
        ListboxProps={{
          style: {
            maxHeight: '320px',
            overflow: 'auto',
          },
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Tooltip placement="bottom-start" title="">
          <AvatarGroup
            max={maxVisible + 1}
            sx={{ '& .MuiAvatar-root': { width: 30, height: 30, marginRight: 0.5 } }}
            onClick={handleAvatarGroupClick}
          >
            {avatarsToRender}
          </AvatarGroup>
        </Tooltip>
        <Popover
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          PaperProps={{
            style: {
              minWidth: 100,
              maxWidth: 300,
              minHeight: 100,
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
              backgroundColor: 'transparent',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              maxHeight: 200,
              minWidth: 100,
              maxWidth: 300,
              overflowY: 'auto',
              p: 2,
            }}
          >
            {overflowItems.map((option) => (
              <CustomAvatar
                displayAvatar
                key={option.id}
                name={`${option.last_name} ${option.first_name}`}
                onClose={() => handleDelete(option.id)}
                sx={{ marginBottom: 1 }}
              />
            ))}
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default MultiSelectUser;

function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
