import { Box, Drawer, IconButton, Typography } from '@mui/material';
import OrderEditDrawer from 'features/Orders/OrderEdit/OrderEditDrawer';
import React from 'react';
import { useAppState } from 'store/Provider';
import { useSelector } from 'store/hooks';
import { closeDrawerAction } from 'store/actions/drawerActions';
import { Close } from '@mui/icons-material';

const ManagementDrawers = () => {
  const { state, dispatch } = useAppState();
  const drawerState = useSelector((state) => state.drawer);
  const onClose = () => {
    dispatch(closeDrawerAction());
  };

  // TODO ICON TITLE

  const drawers: {
    [name: string]: {
      title: string;
      titleColor?: string;
      titleIcon?: JSX.Element;
      content?: React.FC<any>;
      props?: any;
    };
  } = {
    EditOrderDrawer: {
      title: 'Edition order',
      content: OrderEditDrawer,
      props: {
        order: drawerState?.data?.order,
        handleUpdateOrder: drawerState?.data?.handleUpdateOrder,
        isPartial: drawerState?.data?.isPartial,
      },
    },
  };

  const content = () =>
    drawerState.name && drawers[drawerState.name] !== undefined && drawers[drawerState.name].content
      ? React.createElement(drawers[drawerState.name].content || '', drawers[drawerState.name].props)
      : null;

  return (
    <>
      {drawerState.name && content() && (
        <Drawer
          anchor="right"
          open={!!drawerState.name}
          onClose={onClose}
          PaperProps={{
            sx: {
              width: {
                xs: '100%',
                lg: 600,
              },
            },
          }}
        >
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
              <Typography variant="h2" sx={{ mr: 1 }}>
                {drawers[drawerState.name].title}
              </Typography>
              <IconButton sx={{ ml: 'auto' }} color="primary" onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            {content()}
          </>
        </Drawer>
      )}
    </>
  );
};
export default ManagementDrawers;
