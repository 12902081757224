/* eslint-disable max-len */
import { Search } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Box, Divider, Fade, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { ReactComponent as AcceptationOk } from 'assets/img/icons/acceptation_ok.svg';
import { ReactComponent as AcceptationRefused } from 'assets/img/icons/acceptation_refused.svg';
import { ReactComponent as AcceptationReserves } from 'assets/img/icons/acceptation_reserves.svg';
import { ReactComponent as DeliveryIcon } from 'assets/img/icons/delivery_icon.svg';
import { ReactComponent as QualityKO } from 'assets/img/icons/quality_ko.svg';
import { ReactComponent as QualityOK } from 'assets/img/icons/quality_ok.svg';
import { ProgressBar } from 'components';
import useManagementHelper from 'features/Management/services/useManagementHelper';
import { stringToColor } from 'helpers/format';
import { forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import Order from 'types/entities/Order';
import OrderKpiBadge from '../OrderKpiBadge/OrderKpiBadge';
import { OrderType } from 'types/enums/OrderType';
import useUserRoles from 'hooks/useUserRoles';
import { useHistory } from 'react-router-dom';

export type OrderKpiBoxData = {
  consultants: OrderKpiBoxConsultant[];
  production: OrderKpiBoxProduction;
  delivery_manager: OrderKpiBoxDM;
  client: OrderKpiBoxClient;
};

type UpdateUrlParams = {
  mission_advancement_id?: string[];
  rating?: string[];
  label?: string[];
};

type OrderKpiBoxProduction = {
  to_deliver: number;
  submitted: number;
  reforcasted: number;
  canceled: number;
};

type OrderKpiBoxDM = {
  validated: number;
  validated_wreserve: number;
  refused: number;
  waiting_validation: number;
};

type OrderKpiBoxClient = {
  accepted: number;
  accepted_wreserve: number;
  refused: number;
  waiting_for_assesement: number;
  waiting_for_acceptance: number;
  average_note: string;
  rated_d: number;
  rated_e: number;
};

type OrderKpiBoxConsultant = {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  to_do: number;
  submitted: number;
  late: number;
};

type OrderKpiBoxProps = {
  order: Order;
  data?: OrderKpiBoxData;
  fromDate: string;
  toDate: string;
  scopeId?: number;
  isFromMain?: boolean;
};

const OrderKpiBox = (props: OrderKpiBoxProps) => {
  const DEFAULT_DASHBOARD_DATA: OrderKpiBoxData = {
    consultants: [],
    production: {
      to_deliver: 0,
      submitted: 0,
      reforcasted: 0,
      canceled: 0,
    },
    delivery_manager: {
      validated: 0,
      validated_wreserve: 0,
      refused: 0,
      waiting_validation: 0,
    },
    client: {
      accepted: 0,
      accepted_wreserve: 0,
      refused: 0,
      waiting_for_assesement: 0,
      waiting_for_acceptance: 0,
      average_note: 'N/A',
      rated_d: 0,
      rated_e: 0,
    },
  };
  const appState = useSelector((state) => state.app);
  const { getColorDisplayed } = useManagementHelper();
  const { order, data = DEFAULT_DASHBOARD_DATA, fromDate, toDate, scopeId, isFromMain = false } = props;
  const [t] = useTranslation();
  const loadings = useSelector((state) => state.loadings);
  const [searchConsultantQuery, setSearchConsultantQuery] = useState('');
  const userRoles = useUserRoles();
  const history = useHistory();
  const boolAttribute = true;

  const getAvatar = (user: OrderKpiBoxConsultant) => {
    if (user) {
      return (
        <Avatar
          sx={{
            backgroundColor: stringToColor(user.first_name),
            color: stringToColor(user.first_name, 'color'),
          }}
        >
          {`${user.last_name.toLocaleUpperCase().charAt(0).toUpperCase()}${user.first_name.charAt(0).toUpperCase()}`}
        </Avatar>
      );
    }
    return <Avatar />;
  };

  const [filteredConsultantList, setFilteredConsultantList] = useState<OrderKpiBoxConsultant[]>([]);

  useEffect(() => {
    if (searchConsultantQuery !== '' && data) {
      setFilteredConsultantList(
        data.consultants.filter((consultant) =>
          consultant.full_name.toLowerCase().includes(searchConsultantQuery.toLowerCase())
        )
      );
    }
  }, [data, searchConsultantQuery]);

  const consultantItem = (consultant: OrderKpiBoxConsultant, index: number) => (
    <Box key={consultant.id}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ mr: 1 }}>{getAvatar(consultant)}</Box>
        {consultant.last_name.toLocaleUpperCase()} {consultant.first_name}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <ProgressBar
            value={(100 * consultant.submitted) / consultant.to_do}
            color={getColorDisplayed((100 * consultant.submitted) / consultant.to_do)}
          />
        </Box>
        <div
          className={`${getColorDisplayed((100 * consultant.submitted) / consultant.to_do)} small`}
        >{`${consultant.submitted}/${consultant.to_do}`}</div>
      </Box>
      {consultant.late > 0 && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <WarningIcon color="error" sx={{ mr: 1, fontSize: '1rem' }} />
          <Typography color="error">{consultant.late} late deliverable</Typography>
        </Box>
      )}

      {data && index + 1 < data.consultants.length && <Divider sx={{ my: 1 }} />}
    </Box>
  );

  const getRoute = (params: { [key: string]: string[] }): string => {
    const searchParams = new URLSearchParams();
    forEach(params, (values, key) => {
      searchParams.set(key, values.join(','));
    });
    if (fromDate) {
      searchParams.set('forecast_date_start', fromDate);
    }
    if (toDate) {
      searchParams.set('forecast_date_end', toDate);
    }
    if (scopeId) {
      searchParams.set('scope_id', `${scopeId}`);
    }

    return `/${appState.customer?.slug}/deliverables/${order.id}?${searchParams}`;
  };

  const updateUrlAndDispatch = (params: UpdateUrlParams) => {
    const newUrl = getRoute(params);
    if (isFromMain) {
      window.history.pushState(null, '', newUrl);
    } else {
      window.history.replaceState(null, '', newUrl);
    }
    window.dispatchEvent(new PopStateEvent('popstate'));
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Fade in={!!loadings[`miniDashboard${order.id}`]} unmountOnExit>
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 1,
          }}
        />
      </Fade>
      <Box sx={{ p: '20px 25px' }}>
        <Grid container spacing={4} sx={{ flexWrap: { lg: 'nowrap' } }}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h2" color="neutral.main" sx={{ mb: 2 }}>
                  Backlog
                </Typography>
                <Typography variant="h3" sx={{ mb: 1 }}>
                  Production
                </Typography>
                <OrderKpiBadge
                  // redirectTo={getRoute({ mission_advancement_id: ['NOT_SUBMITTED'] })}
                  label="To deliver"
                  icon={<DeliveryIcon />}
                  color="blue"
                  value={data?.production?.to_deliver}
                  cursorPoint={boolAttribute}
                  onClickLabel={() => updateUrlAndDispatch({ mission_advancement_id: ['NOT_SUBMITTED'] })}
                />{' '}
                <OrderKpiBadge
                  // redirectTo={getRoute({ mission_advancement_id: ['SUBMITTED'] })}
                  label="Submitted"
                  icon={<DeliveryIcon />}
                  color="blue"
                  value={data?.production?.submitted}
                  cursorPoint={boolAttribute}
                  onClickLabel={() => updateUrlAndDispatch({ mission_advancement_id: ['SUBMITTED'] })}
                />{' '}
                <OrderKpiBadge
                  // redirectTo={getRoute({ mission_advancement_id: ['Reforcast'] })}
                  label="Reforcast"
                  icon={<DeliveryIcon />}
                  color="blue"
                  value={data?.production?.reforcasted}
                  cursorPoint={boolAttribute}
                  onClickLabel={() => updateUrlAndDispatch({ mission_advancement_id: ['Reforcast'] })}
                />{' '}
                <OrderKpiBadge
                  // redirectTo={getRoute({ label: ['cancel'] })}
                  label="Canceled"
                  icon={<BlockIcon />}
                  color="red"
                  value={data?.production?.canceled}
                  cursorPoint={boolAttribute}
                  onClickLabel={() => updateUrlAndDispatch({ label: ['cancel'] })}
                />{' '}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  placeholder={t('Search consultant')}
                  InputProps={{
                    endAdornment: <Search />,
                  }}
                  fullWidth
                  value={searchConsultantQuery}
                  onChange={(e) => setSearchConsultantQuery(e.currentTarget.value)}
                  className="search_consultant_textfield"
                  sx={{ mb: 2 }}
                />
                {searchConsultantQuery === '' &&
                  data &&
                  data.consultants.map((consultant, index) => consultantItem(consultant, index))}
                {searchConsultantQuery !== '' &&
                  filteredConsultantList.map((consultant, index) => consultantItem(consultant, index))}
              </Grid>
            </Grid>
          </Grid>
          {!userRoles.isClient && (
            <Grid item xs={12} lg={3}>
              <Typography variant="h2" color="neutral.main" sx={{ mb: 2 }}>
                Delivery Manager
              </Typography>
              <Typography variant="h3" sx={{ mb: 2 }}>
                Control
              </Typography>
              <OrderKpiBadge
                // redirectTo={getRoute({ mission_advancement_id: ['VALIDATED'] })}
                label="Validated"
                icon={<AcceptationOk />}
                color="green"
                value={data?.delivery_manager?.validated}
                cursorPoint={boolAttribute}
                onClickLabel={() => updateUrlAndDispatch({ mission_advancement_id: ['VALIDATED'] })}
              />{' '}
              {order.order_type_id === OrderType.CATALOG && (
                <OrderKpiBadge
                  // redirectTo={getRoute({ mission_advancement_id: ['VALIDATED_WITH_RESERVES'] })}
                  label="Validated with reservations"
                  icon={<AcceptationReserves />}
                  color="orange"
                  value={data?.delivery_manager?.validated_wreserve}
                  cursorPoint={boolAttribute}
                  onClickLabel={() => updateUrlAndDispatch({ mission_advancement_id: ['VALIDATED_WITH_RESERVES'] })}
                />
              )}
              <OrderKpiBadge
                // redirectTo={getRoute({ mission_advancement_id: ['REFUSED_DM'] })}
                label="Refused"
                icon={<AcceptationRefused />}
                color="red"
                value={data?.delivery_manager?.refused}
                cursorPoint={boolAttribute}
                onClickLabel={() => updateUrlAndDispatch({ mission_advancement_id: ['REFUSED_DM'] })}
              />{' '}
              <Typography variant="h3" sx={{ my: 2 }}>
                To do
              </Typography>
              <OrderKpiBadge
                // redirectTo={getRoute({ mission_advancement_id: ['NOT_VALIDATED'] })}
                label="Waiting for validation"
                icon={<AcceptationReserves />}
                color="orange"
                value={data?.delivery_manager?.waiting_validation}
                cursorPoint={boolAttribute}
                onClickLabel={() => updateUrlAndDispatch({ mission_advancement_id: ['NOT_VALIDATED'] })}
              />
            </Grid>
          )}
          <Grid item xs={12} lg={3}>
            <Typography variant="h2" color="neutral.main" sx={{ mb: 2 }}>
              Client
            </Typography>

            <Typography variant="h3" sx={{ mb: 2 }}>
              Control
            </Typography>
            {order.order_type_id === OrderType.TIMEANDMATERIAL && (
              <OrderKpiBadge
                // redirectTo={getRoute({ mission_advancement_id: ['ACCEPTED'] })}
                label="Accepted"
                icon={<AcceptationOk />}
                color="green"
                value={data?.client?.accepted}
                cursorPoint={boolAttribute}
                onClickLabel={() => updateUrlAndDispatch({ mission_advancement_id: ['ACCEPTED'] })}
              />
            )}
            <OrderKpiBadge
              // redirectTo={getRoute({ mission_advancement_id: ['ACCEPTED_RESERVES'] })}
              label="Accepted with reservation"
              icon={<AcceptationReserves />}
              color="orange"
              value={data?.client?.accepted_wreserve}
              cursorPoint={boolAttribute}
              onClickLabel={() => updateUrlAndDispatch({ mission_advancement_id: ['ACCEPTED_RESERVES'] })}
            />
            <OrderKpiBadge
              // redirectTo={getRoute({ mission_advancement_id: ['REFUSED_CLIENT'] })}
              label="Refused"
              icon={<AcceptationRefused />}
              color="red"
              value={data?.client?.refused}
              cursorPoint={boolAttribute}
              onClickLabel={() => updateUrlAndDispatch({ mission_advancement_id: ['REFUSED_CLIENT'] })}
            />

            <Typography variant="h3" sx={{ my: 2 }}>
              To do
            </Typography>
            {order.order_type_id === OrderType.TIMEANDMATERIAL && (
              <OrderKpiBadge
                label="Waiting for acceptance"
                icon={<AcceptationReserves />}
                color="orange"
                value={data?.client?.waiting_for_acceptance}
              />
            )}
            <OrderKpiBadge
              label="Waiting for assessment"
              icon={<AcceptationReserves />}
              color="orange"
              value={data?.client?.waiting_for_assesement}
            />
            <Typography variant="h3" sx={{ my: 2 }}>
              Quality
            </Typography>
            <OrderKpiBadge label="Average note " icon={<QualityOK />} color="blue" value={data?.client?.average_note} />
            <OrderKpiBadge
              // redirectTo={getRoute({ rating: ['D'] })}
              label="Rated D"
              icon={<QualityKO />}
              color="red"
              value={data?.client?.rated_d}
              cursorPoint={boolAttribute}
              onClickLabel={() => updateUrlAndDispatch({ rating: ['D'] })}
            />
            {order.order_type_id === OrderType.CATALOG && (
              <OrderKpiBadge
                // redirectTo={getRoute({ rating: ['E'] })}
                label="Rated E"
                icon={<QualityKO />}
                color="red"
                value={data?.client?.rated_e}
                cursorPoint={boolAttribute}
                onClickLabel={() => updateUrlAndDispatch({ rating: ['E'] })}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default OrderKpiBox;
