import { createAction } from '@reduxjs/toolkit';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { initialValue } from '../../helperContext';
import {
  OrderWorkunitAffectationType,
  PossiblesOrderWorkunitAffectationActions,
} from '../../OrderWorkunitAffectationReducers';

export const selectAffectationItemsAction = createAction(
  OrderWorkunitAffectationType.SelectAffectationItems,
  (orderWorkunitIds: OrderWorkunit['id'][]) => ({
    payload: { orderWorkunitIds },
  })
);

export default (state = initialValue, action: PossiblesOrderWorkunitAffectationActions) => {
  switch (action.type) {
    case OrderWorkunitAffectationType.SelectAffectationItems:
      return {
        ...state,
        selectedItems: action.payload.orderWorkunitIds,
      };
    default:
      return state;
  }
};
