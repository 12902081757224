import React from 'react';
import { Box, DialogContent, DialogContentText, Typography } from '@mui/material';
import ModalCustom from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useAppState } from 'store/Provider';
import { cancelSelectByHand } from 'store/actions/deliverableActions';
import { CoPresentOutlined } from '@mui/icons-material';
import { useSelector } from 'store/hooks';

type OrderActionRestrictionDialogProps = {
  message: string;
};

const OrderActionRestrictionDialog: React.FC<OrderActionRestrictionDialogProps> = ({ message }) => {
  const { state, dispatch } = useAppState();
  const { dialogs } = state;
  const dialogData = useSelector((state) => state.dialogs);
  const isOpen = dialogs.orderActionRestrictionDialog;
  const [t] = useTranslation();

  const handleClose = () => {
    dispatch(closeDialogAction('orderActionRestrictionDialog'));
  };

  const handleConfirm = () => {
    dispatch(cancelSelectByHand());
    if (state.dialogs.data.confirm) state.dialogs.data.confirm();
    handleClose();
  };

  const dialogBody = (
    <DialogContent>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <DialogContentText>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', textAlign: 'center' }}>
            {t(message)}
          </Typography>
        </DialogContentText>
      </Box>
    </DialogContent>
  );

  return (
    <ModalCustom
      open={isOpen}
      modalName="orderActionRestrictionDialog"
      onClose={handleClose}
      titleText="Deletion Restricted"
      confirmText="OK"
      onConfirm={handleConfirm}
      body={dialogBody}
      confirmLoad
      size="md"
    />
  );
};

export default OrderActionRestrictionDialog;
