import { Add, Close } from '@mui/icons-material';
import MomentUtils from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CatalogApiService from 'api/CatalogApiService';
import UserApiService from 'api/UserApiService';
import { Loader } from 'components';
import InputDate from 'components/InputDate';
import SelectUser from 'components/SelectUser';
import { stringToColor } from 'helpers/format';
import useCatalogsRights from 'hooks/rights/useCatalogsRights';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropzoneArea } from 'react-mui-dropzone';
import { loadCatalogsAction } from 'store/actions/appActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Catalog from 'types/entities/Catalog';
import User from 'types/entities/User';

type CatalogUpsertProps = {
  open: boolean;
  onClose: () => void;
  activeCatalog?: Catalog;
  submit: (catalog: Partial<Catalog>) => Promise<void>;
  catalogDetails: boolean;
};
const CatalogUpsert = (props: CatalogUpsertProps) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const loadings = useSelector((state) => state.loadings);
  const userRoles = useUserRoles();
  const { canCreate } = useCatalogsRights();
  const [t] = useTranslation();
  const { open, onClose, activeCatalog, submit, catalogDetails } = props;
  const [catalog, setCatalog] = useState<Partial<Catalog> | undefined>();
  const [clients, setClients] = useState<User[]>([]);
  const [contacts, setContacts] = useState<User[]>([]);
  const [inputValueClient, setInputValue] = useState<string>('');
  const [inputValueContact, setInputValueContact] = useState<string>('');
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const { makeCall } = useApi();
  const userLocale = navigator.language || 'fr-FR';
  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  // eslint-disable-next-line no-shadow
  enum TabsOptions {
    FORM = 'Form',
    TEMPLATE = 'Template',
  }

  const [currentTab, setCurrentTab] = useState<TabsOptions>(TabsOptions.FORM);

  useEffect(() => {
    if (activeCatalog) setCatalog(activeCatalog);
  }, [activeCatalog]);

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();

    return setCatalog({ ...catalog, [e.currentTarget.name]: e.currentTarget.value });
  };

  useEffect(() => {
    const getClients = async () => {
      if (appState.customer) {
        setIsDrawerLoading(true);
        const res = await makeCall(
          UserApiService.getClientsOnCustomer(appState.customer?.id, { is_archived: 0, size: '-1' }),
          'Failed to retrieve clients'
        );
        setClients(res);
        setIsDrawerLoading(false);
      }
    };

    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer]);

  const [isContactLoading, setIsContactLoading] = useState<boolean>(false);

  const getAvatarToDisplay = (user: User | undefined) => {
    if (user)
      return user?.avatar_uri ? (
        <Avatar src={user.avatar_uri} alt={t('Active_user_avatar')} />
      ) : (
        <Avatar
          style={{
            backgroundColor: stringToColor(user.first_name),
            color: stringToColor(user.first_name, 'color'),
          }}
        >{`${user.last_name.toLocaleUpperCase().charAt(0)}${user.last_name.charAt(0)}`}</Avatar>
      );
    return <></>;
  };

  useEffect(() => {
    setContacts([]);
    setIsContactLoading(true);

    const delayDebounceFn = setTimeout(async () => {
      if (appState.customer) {
        const searchParams: { [x: string]: any } = {
          is_archived: 0,
          size: 10,
        };
        if (inputValueContact?.length) {
          searchParams['last_name,first_name'] = `:${inputValueContact}:`;
        }
        const res = await makeCall(UserApiService.fetchPossibleContacts(searchParams), 'Failed to retrieve contacts');
        setContacts(res);
        setIsContactLoading(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueContact, appState.customer]);

  const handleSubmit = async () => {
    dispatch(addLoadingAction('handleSubmit'));
    setIsDrawerLoading(true);
    await submit(catalog as Catalog);
    dispatch(removeLoadingAction('handleSubmit'));
    setIsDrawerLoading(false);
    onClose();
  };

  const formatDate = (date: string | number | Date | Moment | null | undefined): string => {
    if (!date) return '';
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return formattedDate;
  };

  const [, setIsDownloadLoading] = useState(false);

  const [loadedDocuments, setLoadedDocuments] = useState<File[]>([]);
  const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader?.result?.toString().replace(/^data:(.*,)?/, '');
        if (encoded) {
          if (encoded?.length % 4 > 0) {
            encoded += '='.repeat(4 - (encoded?.length % 4));
          }
          resolve(encoded);
        }
      };
      reader.onerror = (error) => reject(error);
    });

  const importTemplateCatalog = async () => {
    setIsDownloadLoading(true);
    try {
      // let toSend: string | ArrayBuffer | null = null;
      // getBase64(loadedDocuments[0], (result) => {
      //   toSend = result;
      // });
      if (loadedDocuments?.[0]) {
        const formData = new FormData();
        const base64LoadedDocument = (await toBase64(loadedDocuments?.[0])) as any;
        formData.append('datas', base64LoadedDocument as unknown as Blob);
        formData.append('customer_id', (appState.customer?.id as number).toString());
        const catalogCreated = await makeCall(
          CatalogApiService.importCatalog({ datas: base64LoadedDocument, customer_id: appState.customer?.id })
        );
        const catalogFetched = await CatalogApiService.fetchById(catalogCreated.id as number, {
          join: ['complexities', 'workunits'],
        });
        dispatch(loadCatalogsAction([...(appState.catalogs as Catalog[]), catalogFetched]));
      }
    } catch (error) {
      dispatch(
        setSnackbarAction({ message: 'Error while proccessing imported catalog', severity: 'error', open: true })
      );
    } finally {
      setIsDownloadLoading(false);
      onClose();
    }
  };

  const isCatalogNamed = catalog?.reference === '' || !catalog?.reference;
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onClose}
      // ModalProps={{
      //   container: document.getElementById('container-breadcrumbs'),
      // }}
      PaperProps={{
        sx: {
          width: {
            xs: '100%',
            lg: 600,
          },
        },
      }}
    >
      <LocalizationProvider dateAdapter={MomentUtils}>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
            <Typography variant="h2">{t('Catalog')}</Typography>
            <IconButton sx={{ ml: 'auto' }} color="primary" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          {!catalogDetails && (
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              aria-label="disabled tabs example"
              onChange={(e, value) => setCurrentTab(value)}
              sx={{ mb: 3 }}
            >
              <Tab label={TabsOptions.FORM} value={TabsOptions.FORM} />
              <Tab label={TabsOptions.TEMPLATE} value={TabsOptions.TEMPLATE} />
            </Tabs>
          )}

          {!catalogDetails &&
            currentTab === TabsOptions.TEMPLATE &&
            (loadings.handleSubmit || isDrawerLoading ? (
              <Loader />
            ) : (
              <>
                <Box sx={{ mb: 3 }}>
                  <DropzoneArea
                    dropzoneText={t('DRAG_AND_DROP_TEXT_CATALOG')}
                    useChipsForPreview
                    onChange={(allDocs) => setLoadedDocuments(allDocs)}
                    filesLimit={1}
                    acceptedFiles={[
                      'text/csv,application/vnd.ms-excel',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ]}
                  />
                </Box>
                <a
                  target="_blank"
                  href={process.env.REACT_APP_CATALOG_TEMPLATE_URL as string}
                  className="download_template_container"
                  rel="noreferrer"
                >
                  <Button onClick={async () => null} sx={{ mb: 3 }} fullWidth variant="outlined">
                    {t('Download_template')}
                  </Button>
                </a>
                <Tooltip arrow title={isCatalogNamed ? (t('Provide_a_name') as string) : ''} style={{ zIndex: 1301 }}>
                  <>
                    {canCreate && (
                      <Button fullWidth variant="contained" startIcon={<Add />} onClick={() => importTemplateCatalog()}>
                        {activeCatalog?.id ? t('Update_catalog') : t('Create_catalog')}
                      </Button>
                    )}
                  </>
                </Tooltip>
              </>
            ))}

          {currentTab === TabsOptions.FORM &&
            (loadings.handleSubmit || isDrawerLoading ? (
              <Loader />
            ) : (
              <>
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="catalog_reference">
                    {t('Name')}{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="catalog_reference"
                    onChange={(e) => handleChange(e)}
                    value={catalog?.reference || ''}
                    placeholder={t('Name_catalog')}
                    name="reference"
                    fullWidth
                    data-testid="catalogupsert_catalog_reference"
                  />
                </Box>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Box sx={{ mb: 3 }}>
                      <InputDate
                        label={t('Validity_from')}
                        name="validity_from"
                        id="validity_from"
                        placeholder={dateFormat}
                        onchange={(name, date) => setCatalog({ ...catalog, [name]: moment(date).format('YYYY-MM-DD') })}
                        value={formatDate(catalog?.validity_from)}
                        required
                        data-testid="catalogupsert_catalog_validity_from"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ mb: 3 }}>
                      <InputDate
                        label={t('Validity_to')}
                        name="validity_to"
                        id="validity_to"
                        placeholder={dateFormat}
                        onchange={(name, date) => setCatalog({ ...catalog, [name]: moment(date).format('YYYY-MM-DD') })}
                        value={formatDate(catalog?.validity_to)}
                        required
                        min={catalog?.validity_from}
                        data-testid="catalogupsert_catalog_validity_to"
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* <div className="avatar-container">{getAvatarToDisplay(catalog?.client)}</div> */}
                <Box sx={{ mb: 3 }}>
                  <SelectUser
                    users={clients}
                    selectedUser={catalog?.client}
                    onSelectUser={(user) =>
                      setCatalog({
                        ...catalog,
                        client_id: user?.id,
                        client: user as User | undefined,
                      })
                    }
                    label={t('Client')}
                    data-testid="catalogupsert_catalog_client"
                  />
                </Box>
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="autocomplete_card_contact">{t('Contact')}</InputLabel>
                  {/* <div className="avatar-container">{getAvatarToDisplay(catalog?.contact)}</div> */}
                  <Autocomplete
                    id="autocomplete_card_contact"
                    options={contacts}
                    autoHighlight
                    getOptionLabel={(option) => `${option.last_name.toLocaleUpperCase()} ${option.first_name}`}
                    renderOption={(boxprops, option, { selected }) => (
                      <Box component="li" {...boxprops} sx={{ display: 'flex', alignItems: 'center' }}>
                        {option?.avatar_uri ? (
                          <Avatar src={option.avatar_uri} alt={t('Active_user_avatar')} sx={{ mr: 1 }} />
                        ) : (
                          <Avatar
                            sx={{
                              backgroundColor: `${stringToColor(option?.first_name)}`,
                              color: `${stringToColor(option?.first_name, 'color')}`,
                              mr: 1,
                            }}
                          >{`${option?.first_name.charAt(0)}${option?.last_name.charAt(0)}`}</Avatar>
                        )}
                        {option.last_name.toLocaleUpperCase()} {option.first_name}
                      </Box>
                    )}
                    inputValue={inputValueContact}
                    onInputChange={(event, valueContact) => {
                      setInputValueContact(valueContact);
                    }}
                    // open={contacts.length > 1}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={catalog?.contact?.id ? catalog.contact : null}
                    defaultValue={catalog?.contact}
                    onChange={(e, value) =>
                      setCatalog({ ...catalog, contact_id: (value as User)?.id, contact: value as User })
                    }
                    renderInput={(params) => <TextField {...params} />}
                    noOptionsText={
                      inputValueContact.length > 1 && contacts.length === 0
                        ? t('No contacts found')
                        : t('2_characters_Minimun')
                    }
                    loading={!contacts.length && isContactLoading}
                    data-testid="catalogupsert_catalog_contact"
                  />
                </Box>
                <Tooltip arrow title={isCatalogNamed ? (t('Provide_a_name') as string) : ''} style={{ zIndex: 1301 }}>
                  <>
                    {!userRoles.isManager && (
                      <Button
                        disabled={isCatalogNamed}
                        onClick={() => handleSubmit()}
                        variant="contained"
                        startIcon={<Add />}
                        fullWidth
                        color="success"
                        data-testid="catalogupsert_catalog_submit"
                      >
                        {activeCatalog?.id ? t('Update_catalog') : t('Create_catalog')}
                      </Button>
                    )}
                  </>
                </Tooltip>
              </>
            ))}
        </form>
      </LocalizationProvider>
    </Drawer>
  );
};
export default CatalogUpsert;
