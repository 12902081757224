/* eslint-disable class-methods-use-this */
import { IMissionCommentsApiService } from 'api/MissionCommentsApiService';
import MissionComment from 'types/entities/MissionComment';

export default class InMemoryMissionCommentsApiService implements IMissionCommentsApiService {
  public static Instance: InMemoryMissionCommentsApiService;

  async fetchByMissionId(missionId: number): Promise<MissionComment[]> {
    throw new Error('Method not implemented.');
  }

  async updateComment(id: number, data: MissionComment): Promise<MissionComment | undefined> {
    return { mission_comment: data } as unknown as MissionComment;
  }
}
