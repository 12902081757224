import { Edit } from '@mui/icons-material';
import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { DocumentMoreIcon } from 'components/CustomIcons';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'store/hooks';
import useOrderWorkunitsService from '../../../services/useOrderWorkunitsService';
import './NotEditableCellContent.scss';

const NotEditableCellContent = (props: { params: GridCellParams<any, any, any> }) => {
  const { params } = props;
  const apiRef = useGridApiContext();

  const [localText, setLocalText] = React.useState(params.row[params.field] ? params.row[params.field].trim() : '');

  const isEdit = useSelector(
    (state) => !!state.catalogToOrder.selectedWorkunitsEditIds.find((id) => id === params.row.randomId)
  );

  const { updateWorkunit } = useOrderWorkunitsService();

  useEffect(() => {
    if (params.row[params.field] === '' && params.row[params.field] !== localText)
      setLocalText(params.row[params.field]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.row[params.field]]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (localText !== params.row[params.field]) {
        updateWorkunit({ id: params.row.id, [params.field]: localText });
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localText]);

  return (
    <>
      {isEdit ? (
        <Box sx={{ minWidth: 0 }}>
          {!localText ? (
            <Link
              component="button"
              onClick={() => {
                if (apiRef.current.getCellMode(params.rowNode.id, params.field) === 'view')
                  apiRef.current.startCellEditMode({ id: params.rowNode.id, field: params.field });
              }}
            >
              <DocumentMoreIcon fontSize="large" sx={{ color: 'primary.dark' }} />
            </Link>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={localText}>
                <Typography variant="body2" sx={{ mr: 1 }} noWrap>
                  {localText}
                </Typography>
              </Tooltip>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  if (apiRef.current.getCellMode(params.rowNode.id, params.field) === 'view')
                    apiRef.current.startCellEditMode({ id: params.rowNode.id, field: params.field });
                }}
              >
                <Edit />
              </IconButton>
            </Box>
          )}
        </Box>
      ) : (
        <span className="bold-darker-blue mar-auto">{params.row[params.field]}</span>
      )}
    </>
  );
};

export default memo(NotEditableCellContent);
