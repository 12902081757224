import useManagementService from 'features/Management/services/useManagementService';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Order from 'types/entities/Order';
import OrderKpiBox, { OrderKpiBoxData } from './OrderKpiBox/OrderKpiBox';
import OrderKpiFilters from './OrderKpiFilters/OrderKpiFilters';
import { OrderType } from 'types/enums/OrderType';

type OrderKpiProps = {
  order: Order;
  isFromMain?: boolean;
};
const OrderKpi: React.FC<OrderKpiProps> = ({ order, isFromMain }) => {
  const [filterToDate, setFilterToDate] = useState(moment(order?.end_date).format('YYYY-MM-DD'));
  const [filterFromDate, setFilterFromDate] = useState(moment(order?.start_date).format('YYYY-MM-DD'));
  const [selectedScope, setSelectedScope] = useState<number | undefined>(undefined);
  const { fetchMiniDashboardData } = useManagementService();
  const [miniDashboardData, setMiniDashboardData] = useState<OrderKpiBoxData | undefined>();

  const getMiniDashboardData = async () => {
    if (moment(filterFromDate).isValid() && moment(filterToDate).isValid()) {
      const data = await fetchMiniDashboardData(
        order.id,
        selectedScope && selectedScope > 0
          ? [selectedScope]
          : (order.order_type_id === OrderType.TIMEANDMATERIAL
              ? order.tmscopes?.map((i) => i.id)
              : order.scopes?.map((i) => i.id)) ?? [],
        { from: filterFromDate ?? order?.start_date, to: filterToDate ?? order?.end_date }
      );
      setMiniDashboardData(data);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      getMiniDashboardData();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [selectedScope, filterFromDate, filterToDate]);

  return (
    <>
      <OrderKpiFilters
        order={order}
        scopes={(order.order_type_id === OrderType.TIMEANDMATERIAL ? order.tmscopes : order.scopes) ?? []}
        setFilterFromDate={setFilterFromDate}
        setFilterToDate={setFilterToDate}
        updateSelectedScope={setSelectedScope}
      />

      <OrderKpiBox
        order={order}
        data={miniDashboardData}
        fromDate={filterFromDate}
        toDate={filterToDate}
        scopeId={selectedScope}
        isFromMain={isFromMain}
      />
    </>
  );
};
export default OrderKpi;
