import { Tooltip, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const ModificationReasonCell = React.memo((row: OrderWorkunit) => (
  <Tooltip title={row?.modification_reason ? `${capitalize(row?.modification_reason)}` : ''}>
    <Typography variant="body2" noWrap>
      {row?.modification_reason ? `${capitalize(row?.modification_reason)}` : ''}
    </Typography>
  </Tooltip>
));

export default ModificationReasonCell;
