import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import FinancialReport from 'types/entities/FinancialReport';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useDeliverableReportService from '../../hooks/useDeliverableReportService';
import { DeleteIcon, FileArrowIcon, FileCheckIcon } from 'components/CustomIcons';
import useDeliverableReportsRights from '../../hooks/useDeliverableReportsRights';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

interface Props {
  report: DeliverableSheet | FinancialReport;
}

const DeliverableReportMenu = ({ report }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [t] = useTranslation();
  const loadings = useSelector((state) => state.loadings);
  const { archiveReport, sendToClient, openMarkAsSignedModal, downloadReport, cancelSending } =
    useDeliverableReportService();
  const { canArchiveReport, canSendToClient, canMarkAsSigned, canDownloadReport, canCancelSending } =
    useDeliverableReportsRights(report);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = useMemo(
    () => [
      {
        action: sendToClient,
        permission: canSendToClient,
        label: 'Send to client',
        color: 'primary.main',
        icon: <FileArrowIcon sx={{ color: 'primary.main' }} fontSize="small" />,
        isLoading: (loadings[`send_to_client_${report.id}`] ?? 0) !== 0,
      },
      {
        action: openMarkAsSignedModal,
        permission: canMarkAsSigned,
        label: 'Mark as signed',
        color: 'primary.main',
        icon: <FileCheckIcon viewBox="0 0 32 32" sx={{ color: 'primary.main' }} fontSize="small" />,
        isLoading: (loadings[`mark_as_signed_${report.id}`] ?? 0) !== 0,
      },
      {
        action: downloadReport,
        permission: canDownloadReport,
        label: 'Download document',
        color: 'primary.main',
        icon: <Download sx={{ color: 'primary.main' }} fontSize="small" />,
        isLoading: (loadings[`download_report_${report.id}`] ?? 0) !== 0,
      },
      {
        action: archiveReport,
        permission: canArchiveReport,
        label: 'Delete report',
        color: 'error.main',
        icon: <DeleteIcon sx={{ color: 'error.main' }} fontSize="small" />,
        isLoading: (loadings[`archive_report_${report.id}`] ?? 0) !== 0,
      },

      {
        action: cancelSending,
        permission: canCancelSending,
        label: 'Cancel the sending',
        color: 'error.main',
        icon: <DeleteIcon sx={{ color: 'error.main' }} fontSize="small" />,
        isLoading: (loadings[`cancel_sending_${report.id}`] ?? 0) !== 0,
      },
    ],
    [
      sendToClient,
      canSendToClient,
      loadings,
      openMarkAsSignedModal,
      canMarkAsSigned,
      report.id,
      downloadReport,
      canDownloadReport,
      archiveReport,
      canArchiveReport,
      cancelSending,
      canCancelSending,
    ]
  );

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
        data-testid={`menu_order_${report?.id}`}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {menuItems
          .filter((item) => item.permission)
          .map((item) => (
            <MenuItem
              key={item.label}
              onClick={() => item.action(report)}
              disabled={item.isLoading}
              sx={{ color: item.color, p: 0 }}
            >
              <LoadingButton loading={item.isLoading} startIcon={item.icon}>
                <Typography color={item.color}>{t(item.label)}</Typography>
              </LoadingButton>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
export default DeliverableReportMenu;
