import { BorderColor } from '@mui/icons-material';
import { Card, CardContent, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { getOrderStatusColor } from 'helpers/utils';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { openDrawerAction } from 'store/actions/drawerActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Order from 'types/entities/Order';
import useOrderWorkunitApi from '../services/useOrderWorkunitApi';

const TopBannerOrderWorkunits = () => {
  const { dispatch } = useAppState();
  const order = useSelector((state) => state.catalogToOrder.order);
  const [t] = useTranslation();
  const { handleUpdateOrderAction } = useOrderWorkunitApi();
  const userLocale = navigator.language || 'fr-FR';
  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
  const formattedStartDate = moment(order?.start_date).format(dateFormat);
  const formattedEndDate = moment(order?.end_date).format(dateFormat);

  return (
    <Card sx={{ borderLeft: '10px solid', borderColor: getOrderStatusColor(order), mb: 4 }}>
      <CardContent>
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="space-between"
          sx={{ flexWrap: { lg: 'nowrap' } }}
        >
          <Grid item xs={12} lg="auto" sx={{ position: 'relative', pr: { xs: 0, lg: 4 } }}>
            <Typography variant="h4" color="neutral.main">
              {t('Affair_parameters')}
            </Typography>
            <Divider
              orientation="vertical"
              sx={{
                position: 'absolute',
                height: 'auto',
                top: '10px',
                bottom: '-23px',
                right: 0,
                display: { xs: 'none', lg: 'block' },
                borderColor: 'neutral.main',
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <Typography>{t('Affair_name')}</Typography>
            <Tooltip
              arrow
              title={
                <>
                  <Typography>{order?.affair_name || ''}</Typography>
                  <Typography>{order?.affair_otp || ''}</Typography>
                </>
              }
            >
              <Typography variant="h6" color="primary.main">
                {order?.affair_name || ''}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Typography>{t('Client')}</Typography>
            <Tooltip
              title={
                <Typography>
                  {order?.client?.last_name.toLocaleUpperCase() ?? ''} {order?.client?.first_name ?? ''}
                </Typography>
              }
            >
              <Typography variant="h6" color="primary.main">
                {`${order?.client?.last_name.toLocaleUpperCase() ?? ''} ${order?.client?.first_name ?? ''}` || ''}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Typography>{t('Name_spec')}</Typography>
            <Tooltip arrow title={<Typography> {order?.name_spec || ''}</Typography>}>
              <Typography variant="h6" color="primary.main" noWrap>
                {order?.name_spec || ''}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Typography>{t('Ref_spec')}</Typography>
            <Tooltip arrow title={<Typography> {order?.ref_spec || ''}</Typography>}>
              <Typography variant="h6" color="primary.main" noWrap>
                {order?.ref_spec || ''}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Typography>{t('Engagement_date')}</Typography>
            <Tooltip
              title={
                <Typography>
                  {formattedStartDate || ''} - {formattedEndDate || ''}
                </Typography>
              }
            >
              <Typography variant="h6" color="primary.main">
                {formattedStartDate || ''} -{formattedEndDate || ''}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} lg="auto">
            <IconButton
              onClick={() =>
                dispatch(
                  openDrawerAction({
                    name: 'EditOrderDrawer',
                    data: {
                      order,
                      handleUpdateOrder: (order: Order) => handleUpdateOrderAction(order),
                    },
                  })
                )
              }
              size="large"
              color="primary"
            >
              <BorderColor />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default TopBannerOrderWorkunits;
