import DeliverableSheetWorkunit from 'types/entities/DeliverableSheetWorkunit';
import Order from 'types/entities/Order';
import DeliverableSheetWorkunitDetailsList from 'types/models/DeliverableSheetWorkunitDetailsList';
import DeliverableSheetWorkunitFormatted from 'types/models/DeliverableSheetWorkunitFormatted';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import { TabSheet } from './SheetContext.helper';

export type InitialDeliverableSheetType = {
  deliverables: DeliverableSheetWorkunit[];
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  currentTab: TabSheet;
  order: Order | null;
  currentDeliverableSheet: DeliverableSheet | null;
  deliveredList: DeliverableSheetWorkunitFormatted[] | null;
  deviationList: DeliverableSheetWorkunit[] | null;
  detailsDeliverableList: DeliverableSheetWorkunitDetailsList | null;
  totalWorkunitsNumber: number;
  isDeliverablesLoading: boolean;
  pdfBlobUrl?: string;
  pdfFileName?: string;
  isAssesSignModalOpen: boolean;
  shouldRefresh: boolean;
};

export const initialValue: InitialDeliverableSheetType = {
  deliverables: [],
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  pageSize: 50,
  currentTab: TabSheet.DELIVERABLE,
  order: null,
  currentDeliverableSheet: null,
  deliveredList: null,
  deviationList: null,
  detailsDeliverableList: null,
  totalWorkunitsNumber: 0,
  isDeliverablesLoading: false,
  pdfBlobUrl: undefined,
  pdfFileName: undefined,
  isAssesSignModalOpen: false,
  shouldRefresh: true,
};
