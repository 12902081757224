import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Layout } from 'components';
import ToggleButton from 'components/ToggleButton';
import _ from 'lodash';
import React, { useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { resetCatalogToOrderStateAction } from 'store/actions/catalogToOrderActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import OrderWorkunitsDrawer from './Drawers';
import CatalogWorkunitDataGrid from './OrderWorkunitsDataGrids/CatalogWorkunitDataGrid/CatalogWorkunitDataGrid';
import SelectedWorkunitsDataGrid from './OrderWorkunitsDataGrids/SelectedWorkunitDatagrid/SelectedWorkunitsDataGrid';
import SummaryDataGrid from './OrderWorkunitsDataGrids/SummaryDataGrid/SummaryDataGrid';
import useOrderWorkunitApi from './services/useOrderWorkunitApi';
import useOrderWorkunitsService from './services/useOrderWorkunitsService';
import TopBannerOrderWorkunits from './TopBannerOrderWorkunits/TopBannerOrderWorkunits';

interface RouteParams {
  catalog_id: string;
  order_id: string;
}

const OrderWorkunits: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { dispatch } = useAppState();
  const state = useSelector((state) => state.catalogToOrder);
  const currentOrder = useSelector((state) => state.catalogToOrder.order);
  const selectedScope: number = useSelector(
    (state) => (state.catalogToOrder.orderHandler.searchParams?.scope as number) ?? 0
  );
  const { fetchWorkunitsInCatalog, getOrderWorkunits, getScopes, fetchOrder } = useOrderWorkunitApi();
  const { createSelectedWorkunits, updateSelectedWorkunitsMonthChanged, updateSearchFilters, getUniqScopesInOrder } =
    useOrderWorkunitsService();
  const [t] = useTranslation();
  const scopesInOrder = useMemo(() => getUniqScopesInOrder(), [getUniqScopesInOrder]);
  const [isCatalogWorkunitsOpen, setIsCatalogWorkunitsOpen] = useReducer((prev) => !prev, true);
  const [isOrderWorkunitOpen, setIsOrderWorkunitOpen] = useReducer((prev) => !prev, true);

  const {
    params: { catalog_id, order_id },
  } = match;

  useEffect(() => {
    const unloadCallback = (event: Event) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true;
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => {
      dispatch(resetCatalogToOrderStateAction());
      window.removeEventListener('beforeunload', unloadCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentOrder) return;
    fetchWorkunitsInCatalog(Number(catalog_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder]);

  useEffect(() => {
    if (state.orderWorkunits.length > 0 && state.months.length > 0 && !state.isWorkunitsLoaded) {
      createSelectedWorkunits();
    }
  }, [state.orderWorkunits, state.months, createSelectedWorkunits, state.isWorkunitsLoaded]);

  useEffect(() => {
    if (state.selectedWorkunits.length === 0 && state.months.length === 0) return;
    updateSelectedWorkunitsMonthChanged(state.months);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.months.length, state.selectedWorkunits.length]);

  useEffect(() => {
    if (!!Number(order_id) && !!Number(catalog_id)) Promise.all([fetchOrder(Number(order_id)), getScopes()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id, catalog_id]);

  useEffect(() => {
    if (state.months.length === 0) return;
    getOrderWorkunits(Number(order_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id, state.months]);

  const handleScopeFilterChange = (event: SelectChangeEvent<number>) => {
    updateSearchFilters({ scope: event.target.value ?? 0 }, 'selected');
  };

  const orderWorkunitsContent = (
    <>
      {state?.order && <TopBannerOrderWorkunits />}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" color="neutral.main" sx={{ mr: 1 }}>
          {t('Catalog')}
        </Typography>
        <ToggleButton isOpen={isCatalogWorkunitsOpen} handleClick={() => setIsCatalogWorkunitsOpen()} />
      </Box>
      {isCatalogWorkunitsOpen && <CatalogWorkunitDataGrid />}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" color="neutral.main" sx={{ mr: 1 }}>
          {t('My_order')}
        </Typography>
        <ToggleButton isOpen={isOrderWorkunitOpen} handleClick={() => setIsOrderWorkunitOpen()} />
      </Box>
      {isOrderWorkunitOpen && (
        <>
          <Box sx={{ maxWidth: '200px', mb: 2 }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="filled"
                onChange={handleScopeFilterChange}
                value={selectedScope}
                size="small"
              >
                <MenuItem value={0}>{t('All_project')}</MenuItem>
                {scopesInOrder.length &&
                  scopesInOrder?.map((scopeInOrder) => (
                    <MenuItem key={scopeInOrder?.id} value={scopeInOrder?.id}>
                      {scopeInOrder?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <SelectedWorkunitsDataGrid />
        </>
      )}
    </>
  );

  const orderWorkunitsSummary = <SummaryDataGrid />;

  return (
    <Layout path="order" name="Workunits">
      {orderWorkunitsContent}
      <OrderWorkunitsDrawer />
      <Box sx={{ m: 'auto -30px -30px', p: '30px', backgroundColor: '#FFFFFF', boxShadow: '0px 3px 16px #B2BCD571' }}>
        {orderWorkunitsSummary}
      </Box>
    </Layout>
  );
};
export default OrderWorkunits;
