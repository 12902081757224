import { PERMISSIONS } from 'helpers/permissions';
import useUserPermissions, { hasPermissions } from 'hooks/useUserPermissions';

/**
 * React hook that defines rights specific to the Catalog feature
 */
export default function useCatalogsRights() {
  const userPermissions = useUserPermissions();
  return {
    canCreate: hasPermissions([PERMISSIONS.CREATE_CATALOG], userPermissions),
  };
}
