import { ReportProblem } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { TenantMonthsWorkload } from 'features/Admin/AdminDashboard/AdminDashboard';
import { sortedTenantWorkload } from 'helpers/utils';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  increaseMonthSliceSummaryAction,
  decreaseMonthSliceSummaryAction,
  decreaseQuarterSliceSummaryAction,
  increaseQuarterSliceSummaryAction,
  setSummaryValuesMonths,
  setSummaryValuesQuarters,
} from 'store/actions/catalogToOrderActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import CatalogComplexity from 'types/entities/CatalogComplexity';
import { DisplaySummaryMode } from 'types/enums/DisplaySummaryMode';
import { generateRandomId, getColumnsParamsDef } from '../../helpersOrderWorkunits';
import useOrderWorkunitsService from '../../services/useOrderWorkunitsService';
import {
  shouldDisplayYear,
  defaultColumnParams,
  headerWithArrow,
  defaultCellHeader,
} from '../Columns/MonthsColumns/monthsColumnsHelper';
import {
  DisplayCellMonthSummaryDataGrid,
  DisplayCellQuarterSummaryDataGrid,
} from './DisplayCellSummaryDataGrid/DisplayCellSummaryDataGrid';
import { ComputedWorkunits } from './SummaryDataGrid';

const useSummaryDatagridHelper = () => {
  const max_displayed_months = 6;
  const max_displayed_quarter = 4;
  const { dispatch } = useAppState();
  const order = useSelector((state) => state.catalogToOrder.order);
  const sliceMonth = useSelector((state) => state.catalogToOrder.summaryHandler.monthSlice);
  const sliceQuarter = useSelector((state) => state.catalogToOrder.summaryHandler.quarterSlice);
  const months = useSelector((state) => state.catalogToOrder.months);
  const quarters = useSelector((state) => state.catalogToOrder.quarters);
  const usedMonthsWorkunits = useSelector((state) => state.catalogToOrder.summary.usedMonthsWorkunits);
  const tenantWorkload = useSelector((state) => state.catalogToOrder.summary.tenantWorkload);
  const usedQuarterWorkunits = useSelector((state) => state.catalogToOrder.summary.usedQuartersWorkunits);
  const catalogComplexities = useSelector((state) => state.catalogToOrder.summary.catalogComplexities);
  const isOpen = useSelector((state) => state.catalogToOrder.summaryHandler.isOpen);
  const displayMode = useSelector((state) => state.catalogToOrder.summary.displayMode);
  const { getDisplayedWorkunits } = useOrderWorkunitsService();
  const [t] = useTranslation();
  const renderCellTotal = useCallback(
    (params: GridCellParams) => {
      if (params.row.title === 'fte' && tenantWorkload?.length !== months.length) {
        return (
          <Tooltip arrow title={t('CONTACT_ADMIN_CONFIG_TENANT_WORKMONTH') as string} className="cell_total_problem">
            <span>
              <ReportProblem /> {params.row.total}
            </span>
          </Tooltip>
        );
      }
      return <div>{params.row.total}</div>;
    },
    [months.length, t, tenantWorkload?.length]
  );
  const renderCellTitle = useCallback(
    (params: GridCellParams) => {
      if (params.row.title === 'fte' && tenantWorkload?.length !== months.length) {
        return (
          <Tooltip
            arrow
            title={t('CONTACT_ADMIN_CONFIG_TENANT_WORKMONTH') as string}
            className="cell_total_problem bold-darker-blue"
          >
            <span>
              <ReportProblem /> {params.row.title}
            </span>
          </Tooltip>
        );
      }
      return (
        <Tooltip title={params.row.title}>
          <Typography sx={{ fontWeight: 600 }} variant="body2" noWrap>
            {params.row.title}
          </Typography>
        </Tooltip>
      );
    },
    [months.length, t, tenantWorkload?.length]
  );
  const [effectiveTotalWorkload, setTotalWorkload] = useState<{
    complexities: {
      [key: string]: {
        total: number;
        id: number;
      };
    };
    total: number;
  }>({ complexities: {}, total: 0 });
  React.useEffect(() => {
    const displayedWorkunits = getDisplayedWorkunits();
    const workloadTotal: {
      complexities: {
        [key: string]: {
          total: number;
          id: number;
        };
      };
      total: number;
    } = {
      complexities: {},
      total: 0,
    };
    // eslint-disable-next-line no-return-assign
    catalogComplexities?.forEach((c) => {
      if (c.id && c.name) {
        workloadTotal.complexities[c.name] = { total: 0, id: c.id };
      }
    });
    displayedWorkunits.forEach((wu) => {
      wu.months.forEach((m) => {
        if (wu.complexity && wu.complexity.name) {
          if (workloadTotal.complexities[wu.complexity.name]) {
            const newTotal = workloadTotal.complexities[wu.complexity.name].total + m.quantity * wu.charge;
            workloadTotal.complexities[wu.complexity.name].total = newTotal;
          }
        }

        // eslint-disable-next-line no-return-assign
        return (workloadTotal.total += m.quantity * wu.charge);
      });
    });
    setTotalWorkload(workloadTotal);
  }, [getDisplayedWorkunits, catalogComplexities]);

  const getMonthsDisplayed = useCallback(() => {
    if (months.length > max_displayed_months - 1) {
      return months.slice(sliceMonth.startSlice, sliceMonth.endSlice).map((month, index) => {
        const isYearDisplayed = shouldDisplayYear(index, month, months, sliceMonth.startSlice);
        if (index === 0 && sliceMonth.startSlice !== 0) {
          return {
            ...defaultColumnParams(month.month, 'center', isYearDisplayed),
            renderHeader: () =>
              headerWithArrow(
                {
                  increase: () => dispatch(increaseMonthSliceSummaryAction()),
                  decrease: () => dispatch(decreaseMonthSliceSummaryAction()),
                },
                month,
                'left',
                isYearDisplayed
              ),
            renderCell: (params: GridCellParams) => (
              <DisplayCellMonthSummaryDataGrid
                usedWorkunits={usedMonthsWorkunits}
                month={month}
                propertyName={params.row.title.toLowerCase()}
                randomId={params.row.randomId}
              />
            ),
          };
        }
        if (index === max_displayed_months - 1 && sliceMonth.endSlice < months.length) {
          return {
            ...defaultColumnParams(month.month, 'center', isYearDisplayed),
            renderHeader: () =>
              headerWithArrow(
                {
                  increase: () => dispatch(increaseMonthSliceSummaryAction()),
                  decrease: () => dispatch(decreaseMonthSliceSummaryAction()),
                },
                month,
                'right',
                isYearDisplayed
              ),
            renderCell: (params: GridCellParams) => (
              <DisplayCellMonthSummaryDataGrid
                usedWorkunits={usedMonthsWorkunits}
                month={month}
                propertyName={params.row.title.toLowerCase()}
                randomId={params.row.randomId}
              />
            ),
          };
        }
        return {
          ...defaultColumnParams(month.month, 'center', isYearDisplayed),
          renderHeader: () => defaultCellHeader(month, isYearDisplayed),
          renderCell: (params: GridCellParams) => (
            <DisplayCellMonthSummaryDataGrid
              usedWorkunits={usedMonthsWorkunits}
              month={month}
              propertyName={params.row.title.toLowerCase()}
              randomId={params.row.randomId}
            />
          ),
        };
      });
    }
    return months.map((month, index) => {
      const isYearDisplayed = shouldDisplayYear(index, month, months, sliceMonth.startSlice);

      return {
        ...defaultColumnParams(month.month, 'center', isYearDisplayed),
        renderHeader: () => defaultCellHeader(month, isYearDisplayed),
        renderCell: (params: GridCellParams) => (
          <DisplayCellMonthSummaryDataGrid
            usedWorkunits={usedMonthsWorkunits}
            month={month}
            propertyName={params.row.title.toLowerCase()}
            randomId={params.row.randomId}
          />
        ),
      };
    });
  }, [dispatch, months, sliceMonth.endSlice, sliceMonth.startSlice, usedMonthsWorkunits]);

  const getQuartersDisplayed = useCallback(() => {
    if (quarters.length > max_displayed_quarter - 1) {
      return quarters.slice(sliceQuarter?.startSlice || 0, sliceQuarter?.endSlice).map((quarter, index) => {
        const isYearDisplayed = shouldDisplayYear(index, quarter, quarters, sliceQuarter?.startSlice || 0);

        if (index === 0 && sliceQuarter?.startSlice !== 0) {
          return {
            ...defaultColumnParams(quarter.month, 'center', isYearDisplayed),
            renderHeader: () =>
              headerWithArrow(
                {
                  increase: () => dispatch(increaseQuarterSliceSummaryAction()),
                  decrease: () => dispatch(decreaseQuarterSliceSummaryAction()),
                },
                quarter,
                'left',
                isYearDisplayed
              ),
            renderCell: (params: GridCellParams) => (
              <DisplayCellQuarterSummaryDataGrid
                usedWorkunits={usedQuarterWorkunits}
                month={quarter}
                propertyName={params.row.title.toLowerCase()}
                randomId={params.row.randomId}
              />
            ),
          };
        }
        if (index === max_displayed_quarter - 1 && (sliceQuarter?.endSlice || 0) < quarters.length) {
          return {
            ...defaultColumnParams(quarter.month, 'center', isYearDisplayed),
            renderHeader: () =>
              headerWithArrow(
                {
                  increase: () => dispatch(increaseQuarterSliceSummaryAction()),
                  decrease: () => dispatch(decreaseQuarterSliceSummaryAction()),
                },
                quarter,
                'right',
                isYearDisplayed
              ),
            renderCell: (params: GridCellParams) => (
              <DisplayCellQuarterSummaryDataGrid
                usedWorkunits={usedQuarterWorkunits}
                month={quarter}
                propertyName={params.row.title.toLowerCase()}
                randomId={params.row.randomId}
              />
            ),
          };
        }

        return {
          ...defaultColumnParams(quarter.month, 'center', isYearDisplayed),
          renderHeader: () => defaultCellHeader(quarter, isYearDisplayed),
          renderCell: (params: GridCellParams) => (
            <DisplayCellQuarterSummaryDataGrid
              usedWorkunits={usedQuarterWorkunits}
              month={quarter}
              propertyName={params.row.title.toLowerCase()}
              randomId={params.row.randomId}
            />
          ),
        };
      });
    }

    return quarters.map((quarter, index) => {
      const isYearDisplayed = shouldDisplayYear(index, quarter, quarters, sliceMonth.startSlice);

      return {
        ...defaultColumnParams(quarter.month, 'center', isYearDisplayed),
        renderHeader: () => defaultCellHeader(quarter, isYearDisplayed),
        renderCell: (params: GridCellParams) => (
          <DisplayCellQuarterSummaryDataGrid
            usedWorkunits={usedQuarterWorkunits}
            month={quarter}
            propertyName={params.row.title.toLowerCase()}
            randomId={params.row.randomId}
          />
        ),
      };
    });
  }, [
    dispatch,
    quarters,
    sliceMonth.startSlice,
    sliceQuarter?.endSlice,
    sliceQuarter?.startSlice,
    usedQuarterWorkunits,
  ]);
  const getQuarterColumns = useCallback(
    () => [
      {
        ...(getColumnsParamsDef({
          headerName: '',
          field: '',
          align: 'center',
          flex: 0.5,
        }) as GridColDef),
        renderCell: (params: GridCellParams) => renderCellTitle(params),
      },
      {
        ...(getColumnsParamsDef({
          headerName: 'Total',
          field: 'Total',
          align: 'center',
          flex: 0.3,
        }) as GridColDef),

        headerClassName: 'blue-background ',
        cellClassName: 'blue-background',
        renderCell: (params: GridCellParams) => renderCellTotal(params),
      },
      ...getQuartersDisplayed(),
    ],
    [getQuartersDisplayed, renderCellTitle, renderCellTotal]
  );
  const getColumnsMonths = useCallback(
    () => [
      {
        ...(getColumnsParamsDef({
          headerName: '',
          field: '',
          align: 'center',
          flex: 0.3,
        }) as GridColDef),
        renderCell: (params: GridCellParams) => renderCellTitle(params),
      },
      {
        ...(getColumnsParamsDef({
          headerName: 'Total',
          field: 'Total',
          align: 'center',
          flex: 0.3,
        }) as GridColDef),

        headerClassName: 'blue-background header',
        cellClassName: 'blue-background',
        renderCell: (params: GridCellParams) => renderCellTotal(params),
      },
      ...getMonthsDisplayed(),
    ],
    [getMonthsDisplayed, renderCellTitle, renderCellTotal]
  );

  const getRowComplexities = useCallback((): ComputedWorkunits[] => {
    if (catalogComplexities) {
      return catalogComplexities.map((complexity: CatalogComplexity) => {
        if (
          effectiveTotalWorkload.complexities[complexity.name] &&
          effectiveTotalWorkload.complexities[complexity.name].total
        )
          return {
            title: `%${complexity.name}`,
            total:
              parseFloat(
                (
                  (effectiveTotalWorkload.complexities[complexity.name].total / effectiveTotalWorkload.total) *
                  100
                ).toFixed(2)
              ) || 0,
            randomId: complexity.id as number,
          };

        return {
          title: `%${complexity.name}`,
          randomId: complexity.id as number,
          total: 0,
        };
      });
    }
    return [];
  }, [catalogComplexities, effectiveTotalWorkload]);

  const getValuesByMonths = useCallback(() => {
    const displayedWorkunits = getDisplayedWorkunits();
    let totalWorkload = 0;
    let totalPrice = 0;
    const usedTotalMonths = new Set();
    displayedWorkunits.forEach((workunit) => {
      workunit.months.forEach((m) => {
        if (m.quantity) {
          usedTotalMonths.add(moment(m.date).format('MM-YYYY').toString());
        }
        totalPrice += m.quantity * workunit.price;
        totalWorkload += m.quantity * workunit.charge;
      });
    });
    const usedTenantWorkload: number[] = [];
    Array.from(usedTotalMonths).forEach((month) => {
      const workloadMonth = tenantWorkload?.find(
        (monthWorkload) => moment(monthWorkload.date).format('MM-YYYY').toString() === month
      );
      if (workloadMonth?.workload) {
        const orderDate = moment(order?.start_date).isBetween(
          moment(workloadMonth.date).startOf('month'),
          moment(workloadMonth.date).endOf('month'),
          'day',
          '[]'
        )
          ? order?.start_date
          : order?.end_date;
        const prorata = workloadMonth?.workload / moment(orderDate).daysInMonth();
        usedTenantWorkload.push(prorata || workloadMonth.workload);
      }
    });
    const getDailyRate = (totalPrice: number, totalWorkload: number, tenantWorkload: TenantMonthsWorkload[]) => {
      if (!totalPrice || !totalWorkload || !tenantWorkload[0]) return 0;
      return tenantWorkload && tenantWorkload[0] && !sortedTenantWorkload(tenantWorkload)[0].is_daily_unit
        ? parseFloat(
            (
              parseFloat((parseFloat(totalPrice.toFixed(2)) / parseFloat(totalWorkload.toFixed(2))).toFixed(2)) * 7.35
            ).toFixed(2)
          )
        : parseFloat((parseFloat(totalPrice.toFixed(2)) / parseFloat(totalWorkload.toFixed(2))).toFixed(2));
    };

    const getSummaryQuartersValues = () => [
      {
        title: 'price'.toLocaleUpperCase(),
        total: parseFloat(totalPrice.toFixed(2)),
        randomId: generateRandomId(),
      },
      {
        title: 'workload'.toLocaleUpperCase(),
        total: parseFloat(totalWorkload.toFixed(2)),
        randomId: generateRandomId(),
      },
      {
        title: 'dailyRate'.toLocaleUpperCase(),
        total: getDailyRate(totalPrice, totalWorkload, tenantWorkload),
        randomId: generateRandomId(),
      },
      ...getRowComplexities(),
    ];
    const getSummaryMonthValues = () => [
      {
        title: 'price'.toLocaleUpperCase(),
        total: parseFloat(totalPrice.toFixed(2)),
        randomId: generateRandomId(),
      },
      {
        title: 'workload'.toLocaleUpperCase(),
        total: parseFloat(totalWorkload.toFixed(2)),
        randomId: generateRandomId(),
      },
      {
        title: 'dailyRate'.toLocaleUpperCase(),
        total: getDailyRate(totalPrice, totalWorkload, tenantWorkload),
        randomId: generateRandomId(),
      },
      {
        title: 'fte'.toLocaleUpperCase(),
        total: parseFloat(
          (usedMonthsWorkunits.map((m) => m.fte).reduce((a, b) => a + b, 0) / usedMonthsWorkunits.length).toFixed(2)
        ),
        randomId: generateRandomId(),
      },
      ...getRowComplexities(),
    ];

    const getSummaryQuartersValuesClosed = () => [
      {
        title: 'price'.toLocaleUpperCase(),
        total: parseFloat(totalPrice.toFixed(2)),
        randomId: generateRandomId(),
      },
      {
        title: 'workload'.toLocaleUpperCase(),
        total: parseFloat(totalWorkload.toFixed(2)),
        randomId: generateRandomId(),
      },
      {
        title: 'dailyRate'.toLocaleUpperCase(),
        total: getDailyRate(totalPrice, totalWorkload, tenantWorkload),
        randomId: generateRandomId(),
      },
    ];
    const getSummaryMonthValuesClosed = () => [
      {
        title: 'price'.toLocaleUpperCase(),
        total: parseFloat(totalPrice.toFixed(2)),
        randomId: generateRandomId(),
      },
      {
        title: 'workload'.toLocaleUpperCase(),
        total: parseFloat(totalWorkload.toFixed(2)),
        randomId: generateRandomId(),
      },
      {
        title: 'dailyRate'.toLocaleUpperCase(),
        total: getDailyRate(totalPrice, totalWorkload, tenantWorkload),
        randomId: generateRandomId(),
      },
      {
        title: 'fte'.toLocaleUpperCase(),
        total: parseFloat(
          (usedMonthsWorkunits.map((m) => m.fte).reduce((a, b) => a + b, 0) / usedMonthsWorkunits.length).toFixed(2)
        ),
        randomId: generateRandomId(),
      },
    ];
    if (displayMode === DisplaySummaryMode.MONTHLY) {
      const summaryMonth = isOpen ? getSummaryMonthValues() : getSummaryMonthValuesClosed();
      dispatch(setSummaryValuesMonths(summaryMonth));
    }
    if (displayMode === DisplaySummaryMode.QUARTERLY) {
      const summaryQuarters = isOpen ? getSummaryQuartersValues() : getSummaryQuartersValuesClosed();
      dispatch(setSummaryValuesQuarters(summaryQuarters));
    }
  }, [
    dispatch,
    displayMode,
    getDisplayedWorkunits,
    getRowComplexities,
    isOpen,
    order?.end_date,
    order?.start_date,
    tenantWorkload,
    usedMonthsWorkunits,
  ]);

  return {
    getMonthsDisplayed,
    getColumnsMonths,
    getQuarterColumns,
    getQuartersDisplayed,
    getValuesByMonths,
  };
};
export default useSummaryDatagridHelper;
