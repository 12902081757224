import { DrawerActions } from '../actions/drawerActions';
import { RootActions } from '../redux';
import { DrawerState, drawerState } from '../states/drawerState';

const drawerReducer = (state = drawerState, action: RootActions): DrawerState => {
  switch (action.type) {
    case DrawerActions.OPEN_DRAWER:
      return {
        ...state,
        name: action.payload.name,
        data: action.payload.data,
      };
    case DrawerActions.CLOSE_DRAWER:
      return {
        ...state,
        name: undefined,
        data: undefined,
      };
    default:
      return state;
  }
};

export default drawerReducer;
