import FolderIcon from '@mui/icons-material/Folder';
import {
  AdminIcon,
  CatalogsIcon,
  CloudTempleLogo,
  CustomerIcon,
  DashboardIcon,
  ManagementIcon,
  OrdersIcon,
} from 'components/Sidebar/icons';
import {
  AdminDashboard,
  CatalogDetails,
  Catalogs,
  Clients,
  CreationOrder,
  TimeMaterialCreationOrder,
  Dashboard,
  Deliverables,
  Management,
  NotAffilied,
  OrderWorkunits,
  OrderWorkunitsAffectation,
  OrderWorkunitsSummary,
} from 'features';
import AdminTenantSettings from 'features/Admin/AdminTenantSettings/AdminTenantSettings';
import AdminUsersPermissions from 'features/Admin/AdminUsersPermissions/AdminUsersPermissions';
import AdminUserRole from 'features/Admin/AdminUsersRoles/AdminUserRole';
import DeliverableSheet from 'features/Sheet/DeliverableSheet';
import WorkUnitsList from 'features/Workunits/Workunits';
import React from 'react';
import Documents from '../features/Documents/Documents';

/**
 * Lists of all routes split by roles
 */
export const teamLeaderRoutes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    Component: Dashboard,
    icon: <DashboardIcon />,
    isExact: true,
  },
  {
    name: 'Time_Material_Order_creation',
    path: '/orders/production/create_order',
    Component: TimeMaterialCreationOrder,
    isExact: true,
  },
  {
    name: 'Deliverables',
    path: '/orders/production',
    Component: Management,
    icon: <ManagementIcon />,
    isExact: true,
  },
  {
    name: 'Deliverables',
    path: '/deliverables/:orderId',
    Component: Deliverables,
  },
  {
    name: 'DeliverableSheet',
    path: '/deliverablesheet/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },
  {
    name: 'FinancialReport',
    path: '/financialreport/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },
  {
    name: 'Order Workunits Affectation',
    path: '/orders/workunits/affectation/order/:order_id',
    Component: OrderWorkunitsAffectation,
    isExact: true,
  },
  {
    name: 'Client',
    path: '/clients',
    Component: Clients,
    icon: <CustomerIcon />,
    isExact: true,
  },
  {
    name: 'Management',
    path: '/orders/production',
    Component: Management,
    icon: <ManagementIcon />,
    isExact: true,
  },
  {
    name: 'Order Workunits Summary',
    path: '/orders/workunits/summary/:order_id',
    Component: OrderWorkunitsSummary,
  },
  {
    name: 'CloudTempleLogo',
    path: '/',
    Component: Dashboard,
    icon: <CloudTempleLogo />,
  },
  {
    name: 'Docs',
    path: '/documents',
    Component: Documents,
    icon: <FolderIcon />,
  },
  {
    name: 'Not affilied',
    path: '*',
    Component: NotAffilied,
    icon: <CloudTempleLogo />,
  },
];

export const index = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    Component: Dashboard,
    icon: <DashboardIcon />,
    isExact: true,
  },
  // {
  //   name: 'Administration',
  //   path: '/administration',
  //   Component: AdminDashboard,
  //   icon: <AdminIcon />,
  //   isExact: true,
  // },
  // {
  //   name: 'Administration user roles',
  //   path: '/administration/roles',
  //   Component: AdminUserRole,
  //   icon: <AdminIcon />,
  //   authorizedRoles: ['admin'],

  //   isExact: true,
  // },
  // {
  //   name: 'Administration user permissions',
  //   path: '/administration/permissions',
  //   Component: AdminUsersPermissions,
  //   authorizedRoles: ['admin'],

  //   icon: <AdminIcon />,
  //   isExact: true,
  // },
  // {
  //   name: 'Administration tenant settings',
  //   path: '/administration/settings',
  //   authorizedRoles: ['admin'],

  //   Component: AdminTenantSettings,
  //   icon: <AdminIcon />,
  //   isExact: true,
  // },
  {
    name: 'Management',
    path: '/orders/production',
    Component: Management,
    icon: <ManagementIcon />,
    isExact: true,
  },

  {
    name: 'Client',
    path: '/clients',
    Component: Clients,
    icon: <CustomerIcon />,
    isExact: true,
  },
  {
    name: 'Quotes',
    path: '/orders/quotes',
    Component: Management,
    icon: <OrdersIcon />,
    isExact: true,
  },

  {
    name: 'Order_creation',
    path: '/catalog/:catalog_id/new-order',
    Component: CreationOrder,
  },
  {
    name: 'DeliverableSheet',
    path: '/deliverablesheet/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },

  {
    name: 'Financial Report',
    path: '/financialreport/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },
  {
    name: 'Order_creation',
    path: '/catalog/:catalog_id/order/:order_id',
    Component: OrderWorkunits,
  },
  {
    name: 'Time_Material_Order_creation',
    path: '/orders/production/create_order',
    Component: TimeMaterialCreationOrder,
  },
  {
    name: 'Order Workunits Affectation',
    path: '/orders/workunits/affectation/order/:order_id',
    Component: OrderWorkunitsAffectation,
  },

  {
    name: 'Order Workunits Summary',
    path: '/orders/workunits/summary/:order_id',
    Component: OrderWorkunitsSummary,
  },
  {
    name: 'Catalog Workunits',
    path: '/catalogs/:catalogId/workunits',
    Component: WorkUnitsList,
  },
  {
    name: 'View Catalog',
    path: '/catalogs/:catalogId',
    Component: CatalogDetails,
    icon: <CatalogsIcon />,
  },

  {
    name: 'Catalogs',
    path: '/catalogs',
    Component: Catalogs,
    icon: <CatalogsIcon />,
  },
  {
    name: 'Deliverables',
    path: '/deliverables/:orderId',
    Component: Deliverables,
  },

  {
    name: 'CloudTempleLogo',
    path: '/dashboard',
    Component: Dashboard,
    icon: <CloudTempleLogo />,
  },
  {
    name: 'Docs',
    path: '/documents',
    Component: Documents,
    icon: <FolderIcon />,
  },
  {
    name: 'Not affilied',
    path: '*',
    Component: NotAffilied,
    icon: <CloudTempleLogo />,
  },
];

export const consultantRoutes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    Component: Dashboard,
    icon: <DashboardIcon />,
    isExact: true,
  },
  {
    name: 'DeliverableSheet',
    path: '/deliverablesheet/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },

  // {
  //   name: 'Management',
  //   path: '/management',
  //   Component: Management,
  //   icon: <ManagementIcon />,
  //   isExact: true,
  // },
  {
    name: 'Deliverables',
    path: '/orders/production',
    Component: Management,
    icon: <ManagementIcon />,
    isExact: true,
  },
  {
    name: 'Deliverables',
    path: '/deliverables/:orderId',
    Component: Deliverables,
  },

  {
    name: 'CloudTempleLogo',
    path: '/dashboard',
    Component: Dashboard,
    icon: <CloudTempleLogo />,
  },
  {
    name: 'Docs',
    path: '/documents',
    Component: Documents,
    icon: <FolderIcon />,
  },
  {
    name: 'Not affilied',
    path: '*',
    Component: NotAffilied,
    icon: <CloudTempleLogo />,
  },
];

export const clientRoutes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    Component: Dashboard,
    icon: <DashboardIcon />,
    isExact: true,
  },
  {
    name: 'DeliverableSheet',
    path: '/deliverablesheet/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },
  {
    name: 'FinancialReport',
    path: '/financialreport/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },
  // {
  //   name: 'Management',
  //   path: '/management',
  //   Component: Management,
  //   icon: <ManagementIcon />,
  //   isExact: true,
  // },
  {
    name: 'Deliverables',
    path: '/orders/production',
    Component: Management,
    icon: <ManagementIcon />,
    isExact: true,
  },
  {
    name: 'Deliverables',
    path: '/deliverables/:orderId',
    Component: Deliverables,
  },

  {
    name: 'CloudTempleLogo',
    path: '/dashboard',
    Component: Dashboard,
    icon: <CloudTempleLogo />,
  },
  {
    name: 'Docs',
    path: '/documents',
    Component: Documents,
    icon: <FolderIcon />,
  },
  {
    name: 'Not affilied',
    path: '*',
    Component: NotAffilied,
    icon: <CloudTempleLogo />,
  },
];

export const bmRoutes = [
  {
    name: 'Order_creation',
    path: '/catalog/:catalog_id/new-order',
    Component: CreationOrder,
    isExact: true,
  },
  {
    name: 'Time_Material_Order_creation',
    path: '/orders/production/create_order',
    Component: TimeMaterialCreationOrder,
    isExact: true,
  },
  {
    name: 'DeliverableSheet',
    path: '/deliverablesheet/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },
  {
    name: 'FinancialReport',
    path: '/financialreport/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },
  {
    name: 'Order_creation',
    path: '/catalog/:catalog_id/order/:order_id',
    Component: OrderWorkunits,
    isExact: true,
  },
  {
    name: 'Client',
    path: '/clients',
    Component: Clients,
    icon: <CustomerIcon />,
    isExact: true,
  },
  {
    name: 'Orders',
    path: '/orders/quotes',
    Component: Management,
    icon: <OrdersIcon />,
    isExact: true,
  },
  {
    name: 'Order Workunits Affectation',
    path: '/orders/workunits/affectation/order/:order_id',
    Component: OrderWorkunitsAffectation,
    isExact: true,
  },

  // {
  //   name: 'Order Preview',
  //   path: '/order/preview/:order_id',
  //   Component: QuotePreview,
  //   isExact: true,
  // },
  {
    name: 'Order Workunits Summary',
    path: '/orders/workunits/summary/:order_id',
    Component: OrderWorkunitsSummary,
    isExact: true,
  },
  {
    name: 'Management',
    path: '/orders/production',
    Component: Management,
    icon: <ManagementIcon />,
    isExact: true,
  },
  {
    name: 'Catalog Workunits',
    path: '/catalogs/:catalogId/workunits',
    Component: WorkUnitsList,
    isExact: true,
  },
  {
    name: 'View Catalog',
    path: '/catalogs/:catalogId',
    Component: CatalogDetails,
    icon: <CatalogsIcon />,
    isExact: true,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    Component: Dashboard,
    icon: <DashboardIcon />,
    isExact: true,
  },
  {
    name: 'Catalogs',
    path: '/catalogs',
    Component: Catalogs,
    icon: <CatalogsIcon />,
    isExact: true,
  },
  {
    name: 'Deliverables',
    path: '/deliverables/:orderId',
    Component: Deliverables,
  },
  {
    name: 'Docs',
    path: '/documents',
    Component: Documents,
    icon: <FolderIcon />,
  },
  {
    name: 'Not affilied',
    path: '*',
    Component: NotAffilied,
    icon: <CloudTempleLogo />,
  },
];

export const adminRoutes = [
  {
    name: 'Administration User roles',
    path: '/administration/roles',
    authorizedRoles: ['admin'],
    Component: AdminUserRole,
    icon: <AdminIcon />,
  },
  {
    name: 'Administration users permissions',
    path: '/administration/permissions',
    authorizedRoles: ['admin'],
    Component: AdminUsersPermissions,
    icon: <AdminIcon />,
  },
  {
    name: 'Administration tenant settings',
    path: '/administration/settings',
    authorizedRoles: ['admin'],

    Component: AdminTenantSettings,
    icon: <AdminIcon />,
    isExact: true,
  },
  {
    name: 'Administration',
    path: '/administration',
    authorizedRoles: ['admin'],
    Component: AdminDashboard,
    icon: <AdminIcon />,
    isExact: true,
  },
  {
    name: 'Order_creation',
    path: '/catalog/:catalog_id/new-order',
    Component: CreationOrder,
    isExact: true,
  },
  {
    name: 'Time_Material_Order_creation',
    path: '/orders/production/create_order',
    Component: TimeMaterialCreationOrder,
    isExact: true,
  },
  {
    name: 'Order_view',
    path: '/catalog/:catalog_id/order/:order_id',
    Component: OrderWorkunits,
    isExact: true,
  },
  {
    name: 'Client',
    path: '/clients',
    Component: Clients,
    icon: <CustomerIcon />,
    isExact: true,
  },

  {
    name: 'Order Workunits Affectation',
    path: '/orders/workunits/affectation/order/:order_id',
    Component: OrderWorkunitsAffectation,
    isExact: true,
  },

  // {
  //   name: 'Order Preview',
  //   path: '/order/preview/:order_id',
  //   Component: QuotePreview,
  //   isExact: true,
  // },
  {
    name: 'Order Workunits Summary',
    path: '/orders/workunits/summary/:order_id',
    Component: OrderWorkunitsSummary,
    isExact: true,
  },
  {
    name: 'Orders',
    path: '/orders/quotes',
    Component: Management,
    icon: <OrdersIcon />,
    isExact: true,
  },
  {
    name: 'Management',
    path: '/orders/production',
    Component: Management,
    icon: <ManagementIcon />,
    isExact: true,
  },
  {
    name: 'Catalog Workunits',
    path: '/catalogs/:catalogId/workunits',
    Component: WorkUnitsList,
    isExact: true,
  },
  {
    name: 'View Catalog',
    path: '/catalogs/:catalogId',
    Component: CatalogDetails,
    icon: <CatalogsIcon />,
    isExact: true,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    Component: Dashboard,
    icon: <DashboardIcon />,
    isExact: true,
  },
  {
    name: 'Catalogs',
    path: '/catalogs',
    Component: Catalogs,
    icon: <CatalogsIcon />,
    isExact: true,
  },
  {
    name: 'DeliverableSheet',
    path: '/deliverablesheet/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },
  {
    name: 'FinancialReport',
    path: '/financialreport/:order_id/:deliverable_sheet_id',
    Component: DeliverableSheet,
  },
  {
    name: 'Deliverables',
    path: '/deliverables/:orderId',
    Component: Deliverables,
  },
  {
    name: 'Docs',
    path: '/documents',
    Component: Documents,
    icon: <FolderIcon />,
  },
  {
    name: 'Not affilied',
    path: '/',
    Component: NotAffilied,
    icon: <CloudTempleLogo />,
  },
];
