import { Stack, SvgIcon, Typography } from '@mui/material';
import ModalCustom from 'components/Modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { ReactComponent as AcceptationOk } from 'assets/img/icons/acceptation_ok.svg';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';

export default function DialogSendToClient() {
  const dialogs = useSelector((state) => state.dialogs);
  const { dispatch } = useAppState();
  const [t] = useTranslation();
  const dialogBody = (
    <Stack alignItems="center" gap={2} sx={{ mt: 3 }}>
      <SvgIcon fontSize="large">
        <AcceptationOk />
      </SvgIcon>
      <Typography fontWeight={500}>{t('Your report has been sent to your client')}</Typography>
    </Stack>
  );

  const handleCloseDialog = () => {
    dispatch(closeDialogAction('sendToClient'));
  };

  const handleConfirm = () => {
    dispatch(closeDialogAction('sendToClient'));
  };

  return (
    <ModalCustom
      size="sm"
      modalName="sendToClient"
      titleText={t('Report sent')}
      open={dialogs.sendToClient}
      onClose={handleCloseDialog}
      onConfirm={handleConfirm}
      confirmText={t('Close')}
      body={dialogBody}
      confirmLoad
      dialogActionsCenter
    />
  );
}
