import DeliverableSheetApiService from 'api/DeliverableSheetApiService';
import { base64toBlob } from 'helpers/utils';
import useApi from 'hooks/useApi';
import { useHistory } from 'react-router-dom';
import {
  deleteDeliverableSheetAction,
  updateCurrentDeliverableSheetAction,
  updateDeliverableSheetAction,
} from 'store/actions/deliverableSheetActions';
import { openDialogAction } from 'store/actions/dialogsActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import { ReportStatus } from 'types/enums/ReportStatus';
import { setShouldRefreshAction } from '../../../Sheet/SheetContext/_reducers/ShouldRefresh/ShouldRefresh';
import { useSheetContext } from '../../../Sheet/SheetContext/SheetContext';

export default function useDeliverableReportService() {
  const { dispatch } = useAppState();
  const { dispatch: dispatchSheetContext } = useSheetContext();
  const { makeCall } = useApi();
  const history = useHistory();
  const currentSheetType = useSelector((state) => state.deliverable.currentTableType);
  const archiveReport = async (report: DeliverableSheet) => {
    dispatch(addLoadingAction('archive_report'));
    try {
      await DeliverableSheetApiService.archive(report.id);
      dispatch(deleteDeliverableSheetAction(report.id));
      dispatch(setSnackbarAction({ message: 'Report deleted with success', open: true, severity: 'info' }));
    } catch (error) {
      dispatch(setSnackbarAction({ message: 'Unable to delete report', open: true, severity: 'error' }));
    } finally {
      dispatch(removeLoadingAction('archive_report'));
    }
  };
  const sendToClient = async (report: DeliverableSheet) => {
    const newReport: Pick<DeliverableSheet, 'id'> & Partial<DeliverableSheet> = {
      id: report.id,
      state: ReportStatus.WAITING_FOR_SIGNATURE,
    };
    await makeCall(
      DeliverableSheetApiService.update(newReport),
      'Unable to send report to client',
      `send_to_client_${report.id}`
    );
    dispatch(updateDeliverableSheetAction(newReport));
    dispatch(updateCurrentDeliverableSheetAction(newReport));
    dispatch(openDialogAction({ name: 'sendToClient' }));
  };
  const markAsSigned = async (reportId: DeliverableSheet['id'], dataUpdated: Partial<DeliverableSheet>) => {
    const reportUpdated = {
      id: reportId,
      ...dataUpdated,
      signed_by_DM: true,
      state: ReportStatus.FINISHED,
      is_signed: true,
    };
    await makeCall(
      DeliverableSheetApiService.update(reportUpdated),
      'Unable to mark report as signed',
      `mark_as_signed_${reportId}`
    );
    await makeCall(
      DeliverableSheetApiService.fetchDeliverableSheetPdf(reportId),
      'Unable to generate pdf',
      `mark_as_signed_${reportId}`
    );

    dispatch(updateDeliverableSheetAction(reportUpdated));
    dispatch(updateCurrentDeliverableSheetAction(reportUpdated));
  };

  const updateLocation = async (reportId: DeliverableSheet['id'], location: string) => {
    try {
      await makeCall(DeliverableSheetApiService.update({ id: reportId, location }), 'Unable to update location');
      dispatch(updateDeliverableSheetAction({ id: reportId, location }));
      dispatch(updateCurrentDeliverableSheetAction({ id: reportId, location }));
    } catch (error) {
      dispatch(setSnackbarAction({ message: 'Unable to update location', open: true, severity: 'error' }));
    }
  };

  const clientSigned = async (
    reportId: DeliverableSheet['id'],
    dataUpdated: Partial<DeliverableSheet>,
    signatureBase64: string
  ) => {
    const reportUpdated = { id: reportId, ...dataUpdated, state: ReportStatus.FINISHED, is_signed: true };
    try {
      const signatureUploaded = await uploadSignature(signatureBase64, reportId);
      if (signatureUploaded) {
        const updateDone = await makeCall(
          DeliverableSheetApiService.update(reportUpdated),
          'Unable to sign the report',
          `client_signature_${reportId}`
        );
        if (updateDone) {
          await makeCall(DeliverableSheetApiService.generatePdf(reportId), 'Unable to generate pdf', 'regenerate_pdf');
          dispatch(updateDeliverableSheetAction(updateDone.deliverable_sheet));
          dispatch(updateCurrentDeliverableSheetAction(updateDone.deliverable_sheet));
          dispatchSheetContext(setShouldRefreshAction(true));
        }
        return updateDone;
      }
      return null;
    } catch (error) {
      dispatch(setSnackbarAction({ message: 'Unable to sign the report', open: true, severity: 'error' }));
      return null;
    }
  };

  const downloadReport = async (report: DeliverableSheet) => {
    const pdfUrl = await makeCall(
      DeliverableSheetApiService.fetchDeliverableSheetPdf(report.id),
      'Unable to download report',
      `download_report_${report.id}`
    );
    if (pdfUrl) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', pdfUrl, true);
      xhr.responseType = 'blob';

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', report.title.replace(' ', '_') ?? `${currentSheetType}_report.pdf`);
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          URL.revokeObjectURL(url);
        }
      };

      xhr.send();
    }
  };
  const openMarkAsSignedModal = async (report: DeliverableSheet) => {
    dispatch(openDialogAction({ name: 'markAsSigned', data: { report } }));
  };

  const openAssessSignModal = async (report: DeliverableSheet) => {
    dispatch(openDialogAction({ name: 'assessSignModal', data: { report } }));
  };

  const openApproveSignModal = async (report: DeliverableSheet) => {
    dispatch(openDialogAction({ name: 'approveSignModal', data: { report } }));
  };

  const cancelSending = async (report: DeliverableSheet) => {
    const res = await makeCall(
      DeliverableSheetApiService.cancelSending(report.id),
      'Unable to cancel the sending of the document',
      `cancel_sending${report.id}`
    );
    if (res.deliverable_sheet) {
      dispatch(updateDeliverableSheetAction(res.deliverable_sheet));
      dispatch(updateCurrentDeliverableSheetAction(res.deliverable_sheet));
    }
  };

  const changeReportTitle = async (reportId: DeliverableSheet['id'], title: string) => {
    if (!reportId || !title) return;
    const reportUpdated = await DeliverableSheetApiService.update({
      id: reportId,
      title,
    });
    if (reportUpdated) {
      await makeCall(DeliverableSheetApiService.generatePdf(reportId), 'Unable to generate pdf', 'regenerate_pdf');

      dispatch(updateDeliverableSheetAction(reportUpdated));
      dispatch(updateCurrentDeliverableSheetAction(reportUpdated));
    }
  };

  const uploadSignature = async (signatureB64: string, deliverableSheetId: DeliverableSheet['id']) => {
    try {
      const signatureUpdateUrl = await makeCall(
        DeliverableSheetApiService.getUrlToUploadSignature(deliverableSheetId),
        'Unable to upload signature',
        'upload_signature'
      );
      if (signatureUpdateUrl) {
        await makeCall(
          DeliverableSheetApiService.saveContent(
            signatureUpdateUrl,
            base64toBlob(signatureB64.split(',')[1], 'image/png'),
            'multipart/form-data'
          ),
          'Unable to upload signature',
          'upload_signature'
        );
        return true;
      }
      return null;
    } catch (error) {
      dispatch(setSnackbarAction({ message: 'Unable to upload signature', open: true, severity: 'error' }));
      return null;
    }
  };

  return {
    archiveReport,
    sendToClient,
    markAsSigned,
    clientSigned,
    downloadReport,
    updateLocation,
    openMarkAsSignedModal,
    openAssessSignModal,
    openApproveSignModal,
    uploadSignature,
    changeReportTitle,
    cancelSending,
  };
}
