import { GridCellParams, GridValueGetterParams, GridValueSetterParams } from '@mui/x-data-grid-pro';
import User from 'types/entities/User';

export function isWorkunitResetable(params: GridCellParams<any, any, any>) {
  return params.row.mission_advancement_id && params.row.mission_advancement_id >= 1;
}

export function getFullName(params: GridValueGetterParams, property: 'consultant' | 'client') {
  const user: User | null = params.row?.[property];
  if (user) return `${params.row?.[property]?.last_name || ''} ${params.row?.[property]?.first_name || ''}`;
  return '';
}

export function setFullName(params: GridValueSetterParams, property: 'consultant' | 'client') {
  const user: User | null = params.row?.[property];

  if (user) {
    const full_name = `${user?.last_name || ''} ${user?.last_name || ''}`;
    if (full_name) return { ...params.row, [`${property}_fullname`]: full_name };
  }
  return { ...params.row, [`${property}_fullname`]: '' };
}
