import RateComponent from 'components/Rating/RateComponent';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import React from 'react';
import { selectDeliverableAction } from 'store/actions/deliverableActions';
import { openDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { Rating } from 'types/enums/Rating';

const RatingCell = React.memo((row: any) => {
  const { dispatch } = useAppState();
  const { setRatingDeliverables } = useDeliverableService();
  const clientConnection = useSelector((state) => state.deliverable.order?.client_connection);
  const { canRate, canManagerRate } = useDeliverableRights([row]);
  // TODO remove prop in rate component

  const setRating = async (orderWorkunit: OrderWorkunit, rate: string) => {
    if (rate === Rating.D || rate === Rating.E) {
      dispatch(selectDeliverableAction(orderWorkunit.id));
      dispatch(openDialogAction({ name: 'deliverableRate', data: { rate } }));

      return;
    }
    await setRatingDeliverables([orderWorkunit], rate, null);
    /*     new Promise(() => {
      setRatingDeliverables(row, '', null);
    });
 */
  };

  return (
    <RateComponent
      row={row}
      setRating={setRating}
      disabled={clientConnection ? !canRate : !(canManagerRate || canRate)}
    />
  );
});

export default RatingCell;
