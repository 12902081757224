import Order from 'types/entities/Order';
import User from 'types/entities/User';
import { OrdersTabParentState } from 'types/enums/OrdersTabParentState';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { SearchParams } from '../../api/ResourceAPI';

export const ManagementActions = {
  EDIT_ORDER: 'EDIT_ORDER',
  INJECT_ORDERS: 'INJECT_ORDERS',
  DUPLICATE_ORDER: 'DUPLICATE_ORDER',
  DELETE_ORDER_MANAGEMENT: 'DELETE_ORDER_MANAGEMENT',
  CHANGE_PARENT_TAB: 'CHANGE_PARENT_TAB',
  CHANGE_CURRENT_TAB: 'CHANGE_CURRENT_TAB',
  CHANGE_PAGE_MANAGEMENT: 'CHANGE_PAGE_MANAGEMENT',
  RESET_ORDERS: 'RESET_ORDERS',
  SET_ACTIVE_ORDERID: 'SET_ACTIVE_ORDERID',
  INJECT_CLIENTS: 'INJECT_CLIENTS',
  CHANGE_SEARCH_PARAMS_MANAGEMENT: 'CHANGE_SEARCH_PARAMS_MANAGEMENT',
} as const;

export const injectClientsAction = (clients: User[]) => ({
  payload: { clients },
  type: ManagementActions.INJECT_CLIENTS,
});

export const deleteOrderAction = (orderId: Order['id']) => ({
  payload: { orderId },
  type: ManagementActions.DELETE_ORDER_MANAGEMENT,
});

export const editManagementOrderAction = (order: Order) => ({
  payload: { order },
  type: ManagementActions.EDIT_ORDER,
});

export const changeParentTabAction = (tab: OrdersTabParentState) => ({
  payload: { tab },
  type: ManagementActions.CHANGE_PARENT_TAB,
});

export const changeCurrentTabAction = (tab: OrdersTabState) => ({
  payload: { tab },
  type: ManagementActions.CHANGE_CURRENT_TAB,
});

export const changePageAction = (newPage: number) => ({
  payload: { newPage },
  type: ManagementActions.CHANGE_PAGE_MANAGEMENT,
});

export const injectOrdersAction = ({
  orders,
  totalItems,
  totalPages,
  currentPage,
}: {
  orders: Order[] | null;
  totalItems: number;
  totalPages: number;
  currentPage?: number;
}) => ({
  payload: { orders, totalItems, totalPages, currentPage },
  type: ManagementActions.INJECT_ORDERS,
});

export const resetOrdersAction = () => ({
  payload: null,
  type: ManagementActions.RESET_ORDERS,
});

export const duplicateOrderAction = (order: Order) => ({
  payload: order,
  type: ManagementActions.DUPLICATE_ORDER,
});

export const setActiveOrderIdAction = (orderId: Order['id'] | null) => ({
  payload: { orderId },
  type: ManagementActions.SET_ACTIVE_ORDERID,
});

export const changeSearchParamsAction = (payload: SearchParams | null) => ({
  type: ManagementActions.CHANGE_SEARCH_PARAMS_MANAGEMENT,
  payload,
});

export const managementActions = {
  deleteOrderAction,
  editManagementOrderAction,
  changeParentTabAction,
  changeCurrentTabAction,
  changePageAction,
  injectOrdersAction,
  resetOrdersAction,
  duplicateOrderAction,
  setActiveOrderIdAction,
  injectClientsAction,
  changeSearchParamsAction,
};
