import { Avatar, Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { stringToColor } from 'helpers/format';
import React from 'react';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import './DeliverableReportsCreateReportModalFormLabels.scss';

type DeliverableReportsCreateReportModalFormLabelsProps = {
  targetParam: 'scopes_ids' | 'all_production_clients_ids' | 'assigned_production_clients_ids';
  body: {
    scopes_ids: Set<number>;
    all_production_clients_ids: Set<number>;
    assigned_production_clients_ids: Set<number>;
    dates: Set<string>;
    title: string;
  };
  item: Scope | { client: User; workunits: number };
  setBody: React.Dispatch<
    React.SetStateAction<{
      scopes_ids: Set<number>;
      all_production_clients_ids: Set<number>;
      assigned_production_clients_ids: Set<number>;
      dates: Set<string>;
      title: string;
    }>
  >;
};

const DeliverableReportsCreateReportModalFormLabels: React.FC<DeliverableReportsCreateReportModalFormLabelsProps> = ({
  body,
  item,
  setBody,
  targetParam,
}) => {
  const avatarWithName = (option: Partial<{ name: string; workunits: number }>, type?: string) => (
    <Box component="li" key={option?.name} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {/* eslint-disable-next-line react/destructuring-assignment */}

      <Avatar
        sx={{
          backgroundColor: `${stringToColor(option?.name as string)}`,
          color: `${stringToColor(option?.name as string, 'color')}`,
          mr: 1,
          mb: 1,
        }}
      >{`${(option?.name as string).charAt(0)}
      `}</Avatar>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography variant="body2" fontWeight="bold">
          {option?.name}
        </Typography>
        {type === 'all_production_clients_ids' ? (
          <Typography color="text.secondary"> (All production) </Typography>
        ) : (
          <Typography color="text.secondary">({option?.workunits} deliverables)</Typography>
        )}
      </Box>
    </Box>
  );

  function getLabel(): React.ReactNode {
    switch (targetParam) {
      case 'scopes_ids':
        return (item as Scope).name;
      case 'all_production_clients_ids':
        return avatarWithName(item as unknown as { name: string; workunits: number }, 'all_production_clients_ids');

      case 'assigned_production_clients_ids':
        return avatarWithName(item as unknown as { name: string; workunits: number });
      default:
        return '';
    }
  }

  const handleOnChange = (e: React.SyntheticEvent<Element, Event>, checked: boolean): void => {
    if (checked) {
      addIdToBody();
    }
    if (!checked) {
      removeIdFromBody();
    }
  };

  const addIdToBody = () => {
    if (targetParam === 'all_production_clients_ids') {
      setBody((prevBody) => ({
        ...prevBody,
        all_production_clients_ids: new Set<number>().add('client' in item ? item.client.id : (item as Scope).id),
        assigned_production_clients_ids: new Set<number>(),
      }));
    } else if (targetParam === 'assigned_production_clients_ids') {
      setBody((prevBody) => ({
        ...prevBody,
        assigned_production_clients_ids: new Set<number>(prevBody.assigned_production_clients_ids).add(
          'client' in item ? item.client.id : (item as Scope).id
        ),
        all_production_clients_ids: new Set<number>(),
      }));
    } else if (targetParam === 'scopes_ids') {
      setBody((prevBody) => ({
        ...prevBody,
        scopes_ids: prevBody[targetParam].add((item as Scope).id),
      }));
    }
  };

  const removeIdFromBody = () => {
    if (targetParam === 'all_production_clients_ids') {
      setBody((prevBody) => {
        if ('id' in item && item.id) {
          const updatedSet = new Set<number>([...prevBody[targetParam]]);
          updatedSet.delete(item.id);
          return { ...prevBody, [targetParam]: updatedSet };
        }
        return prevBody;
      });
    } else if (targetParam === 'assigned_production_clients_ids') {
      setBody((prevBody) => {
        if ('id' in item && item.id) {
          const updatedSet = new Set<number>([...prevBody[targetParam]]);
          updatedSet.delete(item.id);
          return { ...prevBody, [targetParam]: updatedSet };
        }
        return prevBody;
      });
    } else if (targetParam === 'scopes_ids') {
      setBody((prevBody) => ({
        ...prevBody,
        dates: new Set<string>(),
        [targetParam]: new Set<number>([...prevBody[targetParam]].filter((x) => x !== (item as Scope).id)),
      }));
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      label={<Box sx={{ display: 'flex', width: '100%' }}>{getLabel()}</Box>}
      className="form_controller_deliverables"
      onChange={handleOnChange}
      checked={body[targetParam] && body[targetParam].has('client' in item ? item.client.id : item.id)}
    />
  );
};

export default DeliverableReportsCreateReportModalFormLabels;
