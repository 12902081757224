import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import MissionComment from 'types/entities/MissionComment';

type QuickActions = {
  cancellation: boolean;
  cancelsubmission: boolean;
  cancelvalidation: boolean;
  submission: boolean;
  validation: boolean;
  acceptation: boolean;
  delete: boolean;
  eval?: 'A' | 'B' | 'C' | 'D' | 'S' | null;
  comment?: MissionComment | null;
};

interface RatingPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  setQuickActions: React.Dispatch<React.SetStateAction<QuickActions>>;
}

const RatingPopup: React.FC<RatingPopupProps> = ({ open, onClose, onSubmit, setQuickActions }) => {
  const [rating, setRating] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    if (!open) {
      setRating('');
      setComment('');
    }
  }, [open]);

  const handleRatingChange = (value: string) => {
    setRating(value);
    if (value !== 'D') {
      setComment('');
    }
  };

  const handleSubmit = () => {
    if (rating === 'D' && !comment) {
      return;
    }

    const constructComment = () => {
      if (comment !== '' && comment !== null) {
        return {
          comment,
          is_private: false,
          rating: rating || undefined,
        };
      }
      return null;
    };

    setQuickActions((prevActions) => ({
      ...prevActions,
      eval: rating as 'A' | 'B' | 'C' | 'D' | 'S' | null,
      comment: constructComment() as MissionComment | null,
    }));

    onSubmit();
    onClose();
  };

  const handleSkip = () => {
    setQuickActions((prevActions) => ({
      ...prevActions,
      eval: 'S',
      comment: null,
    }));

    onSubmit();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Deliverables Assessment</DialogTitle>
      <DialogContent dividers>
        <div style={{ marginBottom: '1.5rem' }}>
          <p style={{ marginBottom: '1rem', fontSize: 14 }}>Share your valuable ratings & feedback below</p>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
            <Button
              size="small"
              variant={rating === 'A' ? 'contained' : 'outlined'}
              onClick={() => handleRatingChange('A')}
            >
              A
            </Button>
            <Button
              size="small"
              variant={rating === 'B' ? 'contained' : 'outlined'}
              onClick={() => handleRatingChange('B')}
            >
              B
            </Button>
            <Button
              size="small"
              variant={rating === 'C' ? 'contained' : 'outlined'}
              onClick={() => handleRatingChange('C')}
            >
              C
            </Button>
            <Button
              size="small"
              variant={rating === 'D' ? 'contained' : 'outlined'}
              onClick={() => handleRatingChange('D')}
            >
              D
            </Button>
          </div>
        </div>
        <TextField
          autoFocus
          margin="dense"
          label={rating === 'D' ? 'Enter your comment to enable submit' : 'Enter your comment'}
          type="text"
          fullWidth
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <p style={{ marginTop: '1.5rem', fontSize: 14 }}>Note: Clicking &quot;skip&quot; will accept the deliverable</p>
        <p style={{ fontSize: 14 }}>without a rating. Status will be &quot;Not Assessed&quot;</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSkip}>Skip</Button>
        <Button onClick={handleSubmit} disabled={!rating || (rating === 'D' && comment === '')}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RatingPopup;
