import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  vertical?: boolean;
  size?: number;
  minWidth?: string;
  color?: string;
  className?: string;
}

const Divider: React.FC<Props> = ({ vertical, size, minWidth, color, className }) => (
  <Container className={className}>
    {vertical ? (
      <VerticalBorder size={size} color={color} minWidth={minWidth} />
    ) : (
      <HorizontalBorder size={size} color={color} minWidth={minWidth} />
    )}
  </Container>
);

export default Divider;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* margin: 0px 34px; */
`;

const HorizontalBorder = styled.div<Props>`
  display: flex;
  border-bottom: thin solid ${(props) => props.color || '#1E90FF'};
  width: ${(props) => props.size || 20}px;
  min-width: ${(props) => props.minWidth || '100%'};
`;

const VerticalBorder = styled.div<Props>`
  display: flex;
  border-right: thin solid ${(props) => props.color || '#1E90FF'};
  height: ${(props) => props.size || 81}px;
`;
