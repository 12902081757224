import { IconButton, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rating as Rate } from 'types/enums/Rating';

type DcumentRatingCommentStepProps = {
  disableRating: boolean;
  rating: Rate | null;
  comment: string;
  setRate: (rate: Rate) => void;
  setComment: (comment: string) => void;
};

const DocumentRatingCommentStep = ({
  disableRating,
  rating,
  comment,
  setRate,
  setComment,
}: DcumentRatingCommentStepProps) => {
  const { t } = useTranslation();
  const rateList = [Rate.A, Rate.B, Rate.C, Rate.D];
  return (
    <Stack>
      {!disableRating && (
        <>
          <Typography fontWeight={500}>{t("Report client's service performance assessment")}</Typography>
          <Stack direction="row" spacing={2} sx={{ paddingBottom: 3 }}>
            {rateList.map((rate, index) => (
              <IconButton
                key={rate}
                onClick={() => {
                  setRate(rate);
                }}
                size="large"
                sx={{ pl: 0 }}
              >
                <span className="rate-assess-container small">
                  <div className={`rate small ${rating === rate ? 'active' : ''}`}>{rate}</div>
                </span>
              </IconButton>
            ))}
          </Stack>
        </>
      )}
      <Typography fontWeight={500}>{t("Report client's service performance comment")}</Typography>
      <TextField
        name="comment"
        id="comment"
        rows={11}
        className="active-blue no-border deliverables-input "
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder={t('Your text here')}
        multiline
        fullWidth
      />
      <Typography sx={{ color: '#b2bcd5' }}>{`${t('Number of characters')}: ${comment.length}/700`}</Typography>
    </Stack>
  );
};

export default DocumentRatingCommentStep;
