import { Box, Chip, Tab, Tabs } from '@mui/material';
import { BookCloseIcon, DraftIcon, PendingIcon, UsersIcon, DeleteIcon } from 'components/CustomIcons';
import useUserRoles from 'hooks/useUserRoles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import useManagementHelper from '../../services/useManagementHelper';

type ManagementTabsProps = {
  currentToAssignCount: number;
};

const ManagementTabs: React.FC<ManagementTabsProps> = ({ currentToAssignCount }: { currentToAssignCount: number }) => {
  const [t] = useTranslation();
  const { isPathForProduction, isPathForQuotes, handleManagementTabChange } = useManagementHelper();
  const { state } = useAppState();
  const appState = useSelector((state) => state.app);
  const userIsCustomer = appState.user?.is_client;
  const currentTab = useSelector((state) => state.management.pageHandler.currentTab);
  const userRoles = useUserRoles();
  const currentTabs = useCallback(() => {
    const tabs = [
      {
        label: OrdersTabState.DRAFTED,
        value: OrdersTabState.DRAFTED,
        icon: <DraftIcon fontSize="small" />,
        disabled: !isPathForQuotes(),
      },
      {
        label: OrdersTabState.PENDING,
        value: OrdersTabState.PENDING,
        icon: <PendingIcon fontSize="small" />,
        disabled: !isPathForQuotes(),
      },
      {
        label: OrdersTabState.TO_ASSIGN,
        value: OrdersTabState.TO_ASSIGN,
        icon: <UsersIcon fontSize="small" />,
        disabled: !(userRoles.isMigsoManager && isPathForProduction()),
        badge: currentToAssignCount,
      },
      {
        label: OrdersTabState.ON_GOING,
        value: OrdersTabState.ON_GOING,
        icon: <PendingIcon fontSize="small" />,
        disabled: !isPathForProduction(),
      },
      {
        label: OrdersTabState.CLOSED,
        value: OrdersTabState.CLOSED,
        icon: <BookCloseIcon fontSize="small" />,
        disabled: !isPathForProduction(),
      },
      {
        label: OrdersTabState.ARCHIVED,
        value: OrdersTabState.ARCHIVED,
        icon: <DeleteIcon fontSize="small" />,
        disabled: !!userRoles.isClient || !!userRoles.isConsultant,
      },
    ];

    return tabs.filter((tab) => !tab.disabled);
  }, [currentToAssignCount, isPathForProduction, isPathForQuotes, userRoles.isMigsoManager]);

  const badgeContent = (tab: {
    label: OrdersTabState;
    value: OrdersTabState;
    disabled: boolean;
    badge?: number | undefined;
  }) =>
    tab.badge ? (
      <>
        <Box sx={{ mr: 1 }}>{t(tab.label)}</Box>
        <Chip label={currentToAssignCount} color="error" size="small" sx={{ fontWeight: 500 }} />
      </>
    ) : (
      t(tab.label)
    );
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
      {currentTab && (
        <Tabs value={currentTab}>
          {currentTabs().map((tab) => (
            <Tab
              data-testid={`management_tab_${tab.value}`}
              label={badgeContent(tab)}
              value={tab.value}
              icon={tab.icon}
              iconPosition="start"
              onClick={() => handleManagementTabChange(tab.value)}
              disabled={state.management.pageHandler.currentTab === tab.value}
            />
          ))}
        </Tabs>
      )}
    </Box>
  );
};

export default ManagementTabs;
