import { Button } from '@mui/material';
import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { OrderWorkunitsCustomWorkunit } from 'features/Orders/OrderWorkunits/helpersOrderWorkunits';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AddButton(props: {
  handleAddWorkunitToOrder: (id: OrderWorkunitsCustomWorkunit['id']) => void;
  params: GridCellParams;
}): JSX.Element {
  const [t] = useTranslation();
  const { handleAddWorkunitToOrder, params } = props;
  const {
    row: { months },
  } = params;
  const apiRef = useGridApiContext();
  const contents = ['content', 'content2', 'content3', 'content4', 'content5'];

  const handleClickAddRow = () => {
    handleAddWorkunitToOrder(params.row.id);
    contents.forEach((content) => {
      if (apiRef.current.getCellValue(params.rowNode.id, content) !== '') {
        apiRef.current.setEditCellValue({ id: params.rowNode.id, field: content, value: null });
      }
    });
  };

  const commitContentsChange = () => {
    contents.forEach((content) => {
      if (apiRef.current.getCellMode(params.rowNode.id, content) === 'edit') {
        apiRef.current.stopCellEditMode({ id: params.rowNode.id, field: content });
      }
    });
  };
  return (
    <Button
      variant="contained"
      color="success"
      size="small"
      onClick={handleClickAddRow}
      disabled={!months.find((m: { quantity: number; date: string; price: number }) => m.quantity > 0)}
      onMouseEnter={commitContentsChange}
    >
      {t('Add')}
    </Button>
  );
}
