import { Box, Chip, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import useUserRoles from 'hooks/useUserRoles';
import React from 'react';

type StatusReportProps = {
  state: string;
};

const StatusReport = (props: StatusReportProps) => {
  const { state } = props;
  const userRoles = useUserRoles();

  const renderStatusByState = () => {
    if (state === 'To be sent')
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip sx={{ backgroundColor: 'primary.main', width: '15px', height: '15px', mr: 1 }} />
          <Typography data-testid="to_be_send" sx={{ color: 'primary.main' }}>
            To be sent
          </Typography>
        </Box>
      );
    if (state === 'Waiting for signature')
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip sx={{ backgroundColor: yellow[800], width: '15px', height: '15px', mr: 1 }} />
          <Typography sx={{ color: yellow[800] }} data-testid="waiting_for_signature">
            {userRoles.isCustomer ? 'To be signed' : 'Waiting for signature'}
          </Typography>
        </Box>
      );
    if (state === 'Error reported')
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip sx={{ backgroundColor: 'error.main', width: '15px', height: '15px', mr: 1 }} />
          <Typography data-testid="error_reported" sx={{ color: 'error.main' }}>
            Error reported
          </Typography>
        </Box>
      );
    if (state === 'Finished')
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip sx={{ backgroundColor: 'success.main', width: '15px', height: '15px', mr: 1 }} />
          <Typography data-testid="finished" sx={{ color: 'success.main' }}>
            Finished
          </Typography>
        </Box>
      );
    return <></>;
  };

  return <>{renderStatusByState()}</>;
};

export default (props: StatusReportProps) => React.useMemo(() => <StatusReport {...props} />, [props]);
