import { Close, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGridPro as DataGrid, GridColDef } from '@mui/x-data-grid-pro';
import { Loader } from 'components';
import ButtonSwitcher from 'components/ButtonSwitcher';
import ToggleButton from 'components/ToggleButton';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  changeDisplayModeAction,
  injectSummaryMonthAction,
  injectSummaryQuarterAction,
  setSummaryColumnsMonths,
  setSummaryColumnsQuarters,
  toggleIsSummaryDeployedAction,
} from 'store/actions/catalogToOrderActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { RootState } from 'store/redux';
import { DisplaySummaryMode } from 'types/enums/DisplaySummaryMode';
import { WorkunitMonth } from '../../helpersOrderWorkunits';
import useOrderWorkunitApi from '../../services/useOrderWorkunitApi';
import useOrderWorkunitsService from '../../services/useOrderWorkunitsService';

import './SummaryDataGrid.scss';
import useSummaryDatagridHelper from './useSummaryDatagridHelper';

export type ComputedWorkunits = {
  date?: string;
  total: number;
  randomId: number;
  title: string;
};

export interface WorkunitComplexityMonths extends WorkunitMonth {
  [x: number]: number;
}

const SummaryDataGrid = () => {
  const [t] = useTranslation();
  const loadings = useSelector((state) => state.loadings);

  const { state, dispatch } = useAppState();
  const { getColumnsMonths, getQuarterColumns, getValuesByMonths } = useSummaryDatagridHelper();
  const { computeSummaryMonths, redirectToPreview, computeSummaryQuarters } = useOrderWorkunitsService();
  const { updateOrderStatus, bulkCreateOrderWorkunits, exportExcel, getCatalogComplexities, getTenantWorkloads } =
    useOrderWorkunitApi();
  const selectedOrderWorkunits = useSelector((state: RootState) => state.catalogToOrder.selectedWorkunits);
  const order = useSelector((state) => state.catalogToOrder.order);
  const sliceMonth = useSelector((state) => state.catalogToOrder.summaryHandler.monthSlice);
  const sliceQuarter = useSelector((state) => state.catalogToOrder.summaryHandler.quarterSlice);
  const selectedScope = useSelector((state) => state.catalogToOrder.selectedScope);
  const months = useSelector((state) => state.catalogToOrder.months);
  const isOpen = useSelector((state) => state.catalogToOrder.summaryHandler.isOpen);
  const displayMode = useSelector((state) => state.catalogToOrder.summary.displayMode);
  const columnsMonths = useSelector((state) => state.catalogToOrder.summary.columnsMonths);
  const columnsQuarter = useSelector((state) => state.catalogToOrder.summary.columnsQuarter);
  const buttonsLoading = useSelector((state) => state.loadings.summary);
  const valuesMonths = useSelector((state) => state.catalogToOrder.summary.valuesMonths);
  const valuesQuarters = useSelector((state) => state.catalogToOrder.summary.valuesQuarters);
  const isDisplayModeMonthly = useCallback(() => displayMode === DisplaySummaryMode.MONTHLY, [displayMode]);

  useEffect(() => {
    if (order?.catalog_id) {
      getCatalogComplexities(order?.catalog_id);
      getTenantWorkloads(order);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.catalog_id, months.length]);

  useEffect(() => {
    getValuesByMonths();
  }, [getValuesByMonths]);

  useEffect(() => {
    if (order) {
      if (displayMode === DisplaySummaryMode.MONTHLY) {
        dispatch(injectSummaryMonthAction(computeSummaryMonths() ?? []));
      }
      if (displayMode === DisplaySummaryMode.QUARTERLY) {
        dispatch(injectSummaryQuarterAction(computeSummaryQuarters() ?? []));
      }
    }
    // setUsedQuartersWorkunits(() => computeSummaryMonths);
  }, [order, dispatch, computeSummaryMonths, computeSummaryQuarters, displayMode]);

  useEffect(() => {
    if (isDisplayModeMonthly()) {
      dispatch(setSummaryColumnsMonths(getColumnsMonths() as GridColDef[]));
    }
    if (!isDisplayModeMonthly()) {
      dispatch(setSummaryColumnsQuarters(getQuarterColumns() as GridColDef[]));
    }
  }, [
    sliceMonth,
    months,
    sliceQuarter,
    selectedScope,
    dispatch,
    getColumnsMonths,
    getQuarterColumns,
    isDisplayModeMonthly,
  ]);

  const handleValidationOrder = async (is_submitted: boolean, is_rogue = false) => {
    try {
      if (order) {
        if (!is_rogue) {
          if (is_submitted && order.status === 'drafted') {
            await updateOrderStatus(order);
          }
        }

        if (selectedOrderWorkunits.length) {
          await bulkCreateOrderWorkunits();
        }

        if (is_submitted && !is_rogue) {
          setIsExtractModalOpen(true);
        }
      }
    } catch (e) {
      const result = (e as Error).message;
      if (result) {
        dispatch(
          setSnackbarAction({
            message: `error while saving order with workunits: ${result}`,
            open: true,
            severity: 'error',
          })
        );
      } else {
        dispatch(
          setSnackbarAction({ message: `error while saving order with workunits :${e}`, open: true, severity: 'error' })
        );
      }
    }
  };

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    if (!gridWrapperRef?.current) return;
    const gridDiv = gridWrapperRef?.current;
    if (!gridDiv) return;
    if (gridDiv) {
      const gridEl: HTMLDivElement = gridDiv.querySelector('div')!;
      if (!gridEl) return;
      gridEl.style.height = '';
    }
  });

  const dimensions = useWindowDimensions();

  const [isExtractModalOpen, setIsExtractModalOpen] = useState(false);
  const isExtractLoading = useSelector((state) => state.loadings.extract_excel_summary);
  const handleExtractModalClose = () => {
    setIsExtractModalOpen(false);
  };

  const downloadExtractAndRedirect = async () => {
    if (order) {
      await exportExcel();
      redirectToPreview(order?.status === 'pending');
    }
  };

  return (
    <Grid container spacing={4} sx={{ flexWrap: { lg: 'nowrap' } }}>
      <Grid item xs={12} lg="auto" xl={2}>
        <ToggleButton isOpen={!isOpen} handleClick={() => dispatch(toggleIsSummaryDeployedAction())} sx={{ mb: 2 }} />
        <Typography color="neutral.main" variant="h2" sx={{ mb: 2 }}>
          {t('Quote_summary')}
        </Typography>
        <ButtonSwitcher size="small">
          <Button
            variant={displayMode === 'MONTHLY' ? 'contained' : 'text'}
            color={displayMode === 'MONTHLY' ? 'primary' : 'neutral'}
            onClick={() => dispatch(changeDisplayModeAction(DisplaySummaryMode.MONTHLY))}
          >
            {t('Monthly')}
          </Button>
          <Button
            variant={displayMode === 'QUARTERLY' ? 'contained' : 'text'}
            color={displayMode === 'QUARTERLY' ? 'primary' : 'neutral'}
            onClick={() => dispatch(changeDisplayModeAction(DisplaySummaryMode.QUARTERLY))}
          >
            {t('Quarterly')}
          </Button>
        </ButtonSwitcher>
      </Grid>
      {isDisplayModeMonthly() && (
        <Grid item xs={12} lg={8} ref={gridWrapperRef} sx={{ my: { lg: '-30px' } }}>
          <DataGrid
            rows={valuesMonths}
            columns={columnsMonths as GridColDef[]}
            checkboxSelection={false}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            disableColumnResize
            hideFooter
            autoHeight
            disableExtendRowFullWidth
            headerHeight={70}
            rowHeight={dimensions.currentHeight > 800 ? 37 : 26}
            getRowId={(row) => row.randomId}
            className="summary-datagrid"
          />
        </Grid>
      )}
      {!isDisplayModeMonthly() && (
        <Grid item xs={12} lg={8} ref={gridWrapperRef} sx={{ my: { lg: '-30px' } }}>
          <DataGrid
            rows={state.catalogToOrder.summary.valuesQuarters}
            columns={columnsQuarter as GridColDef[]}
            checkboxSelection={false}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            hideFooter
            disableExtendRowFullWidth
            autoHeight
            headerHeight={70}
            rowHeight={dimensions.currentHeight > 800 ? 45 : 32}
            pageSize={100}
            getRowId={(row) => row.randomId}
            className="summary-datagrid"
          />
        </Grid>
      )}
      {!buttonsLoading && (
        <Grid item xs={12} lg={2} alignSelf="center">
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ mb: 2 }}>
              <Tooltip className="tooltip_summary_download" title={t('TRIGGER_VALIDATION_SUMMARY') as string}>
                <LoadingButton
                  loading={!!loadings.api}
                  variant="contained"
                  color="success"
                  onClick={() => handleValidationOrder(true)}
                >
                  {t('Validate_order')}
                </LoadingButton>
              </Tooltip>
            </Box>
            <Box>
              <LoadingButton
                loading={!!loadings.api}
                startIcon={<Save color="primary" />}
                onClick={() => handleValidationOrder(false)}
              >
                {t('Save')}
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
      )}
      {buttonsLoading && (
        <Grid className=" buton_container ">
          <Loader size={40} className="loader_container" />
        </Grid>
      )}
      <Dialog
        open={isExtractModalOpen}
        onClose={handleExtractModalClose}
        id="excel-export-dialog"
        aria-labelledby="excel-export-dialog-title"
        aria-describedby="excel-export-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="excel-export-dialog-title">
          <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
            <h3>{t('Excel extraction')} </h3>{' '}
            <IconButton onClick={handleExtractModalClose} color="primary">
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="excel-export-dialog-description">
            {t('Do you want to extract excel files ?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => redirectToPreview(order?.status === 'pending')} variant="text">
            {t("Don't extract")}
          </Button>
          {!isExtractLoading && (
            <Button onClick={downloadExtractAndRedirect} autoFocus variant="contained">
              {t('Extract')}
            </Button>
          )}
          {!!isExtractLoading && (
            <div className=" buton_container ">
              <Loader size={40} className="loader_container" />
            </div>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default SummaryDataGrid;
