import { CancelTokenSource } from 'axios';
import Affair from 'types/entities/Affair';
import Catalog from 'types/entities/Catalog';
import CatalogComplexity from 'types/entities/CatalogComplexity';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import Customer from 'types/entities/Customer';
import Devise from 'types/entities/Devise';
import User from 'types/entities/User';
import Alert from 'types/models/Alert';
import Token from 'types/models/Token';
import { RoleType } from 'types/RoleType';

export const AppActions = {
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  LOAD_SIDEBAR_LINK: 'LOAD_SIDEBAR_LINK',
  LOAD_DEVISES: 'LOAD_DEVISES',
  LOAD_CATALOG: 'LOAD_CATALOG',
  LOAD_CATALOGS: 'LOAD_CATALOGS',
  LOAD_CATALOGS_ARCHIVED: 'LOAD_CATALOGS_ARCHIVED',
  LOAD_CATALOG_COMPLEXITIES: 'LOAD_CATALOG_COMPLEXITIES',
  LOAD_WORKUNIT: 'LOAD_WORKUNIT',
  LOAD_WORKUNITS: 'LOAD_WORKUNITS',
  LOAD_ROLES: 'LOAD_ROLES',
  LOAD_USER: 'LOAD_USER',
  LOAD_CONSULTANTS: 'LOAD_CONSULTANTS',
  LOAD_CUSTOMER: 'LOAD_CUSTOMER',
  LOAD_CUSTOMERS: 'LOAD_CUSTOMERS',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  LOAD_AFFAIRS: 'LOAD_AFFAIRS',
  LOAD_TOKEN: 'LOAD_TOKEN',
  INJECT_ALERTS: 'INJECT_ALERTS',
  ADD_CANCEL_TOKEN: 'ADD_CANCEL_TOKEN',
  REMOVE_CANCEL_TOKEN: 'REMOVE_CANCEL_TOKEN',
  UPDATE_TERMS_OF_USE: 'UPDATE_TERMS_OF_USE',
} as const;

export const toggleSidebarAction = () => ({
  type: AppActions.TOGGLE_SIDEBAR,
});

export const loadSidebarLinkAction = (payload: { name: string; path?: string; icon?: unknown }) => ({
  type: AppActions.LOAD_SIDEBAR_LINK,
  payload,
});

export const loadDevisesAction = (payload: Devise[]) => ({
  type: AppActions.LOAD_DEVISES,
  payload,
});

export const loadCatalogAction = (payload?: Catalog) => ({
  type: AppActions.LOAD_CATALOG,
  payload,
});

export const loadCatalogsAction = (payload: Catalog[]) => ({
  type: AppActions.LOAD_CATALOGS,
  payload,
});

export const loadCatalogArchivedAction = (payload: Catalog[]) => ({
  type: AppActions.LOAD_CATALOGS_ARCHIVED,
  payload,
});

export const loadCatalogComplexitiesAction = (payload: CatalogComplexity[]) => ({
  type: AppActions.LOAD_CATALOG_COMPLEXITIES,
  payload,
});

export const loadWorkunitAction = (payload?: CatalogWorkunit) => ({
  type: AppActions.LOAD_WORKUNIT,
  payload,
});

export const loadWorkunitsAction = (payload: CatalogWorkunit[]) => ({
  type: AppActions.LOAD_WORKUNITS,
  payload,
});

export const loadRolesAction = (payload?: RoleType[]) => ({
  type: AppActions.LOAD_ROLES,
  payload,
});

export const loadUserAction = (payload?: User) => ({
  type: AppActions.LOAD_USER,
  payload,
});

export const loadConsultantsAction = (payload: User[]) => ({
  type: AppActions.LOAD_CONSULTANTS,
  payload,
});

export const loadCustomerAction = (payload?: Customer) => ({
  type: AppActions.LOAD_CUSTOMER,
  payload,
});

export const updateCustomerAction = (payload: Customer) => ({
  type: AppActions.UPDATE_CUSTOMER,
  payload,
});

export const loadCustomersAction = (payload: Customer[]) => ({
  type: AppActions.LOAD_CUSTOMERS,
  payload,
});

export const loadAffairsAction = (payload: Affair[]) => ({
  type: AppActions.LOAD_AFFAIRS,
  payload,
});

export const loadTokenAction = (payload?: Token) => ({
  type: AppActions.LOAD_TOKEN,
  payload,
});

export const injectAlertsAction = (payload: Alert[]) => ({
  type: AppActions.INJECT_ALERTS,
  payload,
});

export const addCancelToken = (payload: { name: string; source: CancelTokenSource }) => ({
  type: AppActions.ADD_CANCEL_TOKEN,
  payload,
});

export const removeCancelToken = (payload: string) => ({
  type: AppActions.REMOVE_CANCEL_TOKEN,
  payload,
});

export const updateTermsOfUse = (payload: boolean) => ({
  type: AppActions.UPDATE_TERMS_OF_USE,
  payload,
});

export const appActions = {
  toggleSidebarAction,
  loadSidebarLinkAction,
  loadDevisesAction,
  loadCatalogAction,
  loadCatalogsAction,
  loadCatalogComplexitiesAction,
  loadWorkunitAction,
  loadWorkunitsAction,
  loadRolesAction,
  loadUserAction,
  loadConsultantsAction,
  loadCustomerAction,
  loadCustomersAction,
  updateCustomerAction,
  loadAffairsAction,
  loadTokenAction,
  loadCatalogArchivedAction,
  injectAlertsAction,
  addCancelToken,
  removeCancelToken,
  updateTermsOfUse,
};
