/* eslint-disable consistent-return */
import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { RouteType } from 'types/RouteType';

const PrivateRoute = (availableRoutes: RouteType[], activeSlug: string, userRoles: string[]) => {
  const routes = availableRoutes.map(({ path, name, Component, authorizedRoles, isExact }, key) => {
    if (authorizedRoles) {
      const userHaveRequiredRole = authorizedRoles.filter((element) => userRoles.includes(element));
      if (userHaveRequiredRole[0])
        return <Route exact={isExact} path={`/${activeSlug}${path}`} component={Component} key={name} />;
    }

    if (!authorizedRoles) return <Route path={`/${activeSlug}${path}`} component={Component} key={name} exact />;
    return (
      <Route
        exact={isExact}
        path={`/${activeSlug}${path}`}
        key={name}
        render={() => <Redirect to={`/${activeSlug}/dashboard`} />}
      />
    );
  });
  return routes;
};
export default PrivateRoute;
