/* eslint-disable react/destructuring-assignment */
import { Save } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField } from '@mui/material';
import DeliverableSheetApiService from 'api/DeliverableSheetApiService';
import useUserRoles from 'hooks/useUserRoles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useAppState } from 'store/Provider';
import { formatOrderWorkunitForPdf, getOrderInformationData } from '../helper';
import { usePdfGeneration } from '../hook/usePdfGeneration';
import { editCurrentDeliverableTitleNameAction } from 'features/Sheet/SheetContext/_reducers/EditDeliverableSheet/EditDeliverableSheet';
import { setPdfBlobUrlAction } from '../SheetContext/_reducers/PdfBlobUrl/PdfBlobUrl';
import { setPdfFileNameAction } from '../SheetContext/_reducers/PdfFileName/PdfFileName';
import { useSheetContext } from '../SheetContext/SheetContext';
import SheetTechnicalTable from './SheetTechnicalTable/SheetTechnicalTable';
import useDeliverableReportService from 'features/Deliverables/DeliverableReports/hooks/useDeliverableReportService';
import { setShouldRefreshAction } from '../SheetContext/_reducers/ShouldRefresh/ShouldRefresh';

const SheetDeliverable = () => {
  const userRoles = useUserRoles();
  return (
    <div id="deliverable-sheet-container" className="deliverable-table-container">
      {!userRoles.isCustomer && <DeliverableSheetTitleInput />}
      <SheetTechnicalTable />
    </div>
  );
};

function DeliverableSheetTitleInput() {
  const { dispatch } = useAppState();
  const [t] = useTranslation();
  const { state, dispatch: dispatchSheetContext } = useSheetContext();
  const [saveLoading, setSaveLoading] = useState(false);

  const { changeReportTitle } = useDeliverableReportService();

  const saveNewPdfTitle = async () => {
    setSaveLoading(true);
    try {
      await changeReportTitle(state.currentDeliverableSheet?.id || 0, state.currentDeliverableSheet?.title || '');
      dispatch(setSnackbarAction({ message: 'Updated report title', open: true, severity: 'info' }));
      dispatchSheetContext(setShouldRefreshAction(true));
    } catch (error) {
      dispatch(setSnackbarAction({ message: 'Unable to update report title', open: true, severity: 'error' }));
    } finally {
      setSaveLoading(false);
    }
  };
  return (
    <Box sx={{ display: 'flex', mb: 3 }}>
      <TextField
        onChange={(event) => {
          dispatchSheetContext(editCurrentDeliverableTitleNameAction(event.target.value));
        }}
        id="input_title"
        placeholder={t('document_title')}
        value={state.currentDeliverableSheet?.title || ''}
        sx={{
          mr: 2,
          minWidth: '300px',
        }}
        variant="filled"
      />
      <LoadingButton variant="contained" onClick={() => saveNewPdfTitle()} startIcon={<Save />} loading={saveLoading}>
        {t('Save')}
      </LoadingButton>
    </Box>
  );
}

export default SheetDeliverable;
