/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Avatar, Box, Card, CardContent, CardHeader, Link, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { MailIcon } from 'components/CustomIcons';
import ClientMenuActions from 'features/Clients/ClientMenuActions';
import { stringToColor } from 'helpers/format';
import useClientsRights from 'hooks/rights/useClientsRights';
import { capitalize } from 'lodash';
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/entities/User';

interface Props {
  user: User;
  name: string;
  position: string;
  email: string;
  avatar?: string;
  dispatchEditOpen: React.DispatchWithoutAction;
  isArchived?: boolean;
  isEditable?: boolean;
  dispatchOpen?: React.DispatchWithoutAction;
  handleDeleteclient: (userId: number) => void;
  setActiveClient: (user: User) => void;
  setClients: React.Dispatch<React.SetStateAction<User[]>>;
}

const ClientCard: React.FC<Props> = ({
  isEditable = false,
  isArchived = false,
  email,
  name,
  position,
  user,
  avatar,
  dispatchOpen,
  dispatchEditOpen,
  handleDeleteclient,
  setActiveClient,
  setClients,
}) => {
  const [t] = useTranslation();
  const { canEdit } = useClientsRights();
  return (
    <Card
      elevation={3}
      sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      data-testid={`user_client_card_${user.id}`}
    >
      <CardHeader
        action={
          (canEdit || !isArchived) && (
            <ClientMenuActions
              activeClient={user}
              dispatchEditOpen={dispatchEditOpen}
              handleDeleteclient={handleDeleteclient}
              setActiveClient={(userActive) => {
                setActiveClient(userActive);
              }}
              setClients={setClients}
            />
          )
        }
      />
      <CardContent
        onClick={dispatchOpen}
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', pt: 0 }}
      >
        {avatar ? (
          <Avatar src={avatar} alt={t('Active_user_avatar')} sx={{ width: 80, height: 80, mb: 1 }} />
        ) : (
          <Avatar
            sx={{
              width: 80,
              height: 80,
              mb: 1,
              backgroundColor: `${stringToColor(capitalize(user?.last_name))}`,
              color: `${stringToColor(capitalize(user?.last_name), 'color')}`,
            }}
          >
            {`${capitalize(user?.first_name.charAt(0))}${capitalize(user?.last_name.charAt(0))}`}
          </Avatar>
        )}
        <Typography sx={{ color: grey[800], fontWeight: '600', textAlign: 'center' }} variant="h5" component="div">
          {capitalize(user?.first_name)} {capitalize(user?.last_name)}
        </Typography>
        <Typography sx={{ color: grey[500], fontWeight: '600', textAlign: 'center' }}>{position}</Typography>
      </CardContent>
      {!isArchived && (
        <Box
          sx={{
            padding: '0 25px 25px',
            mt: 'auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link
            href={`mailto:${email}`}
            target="_blank"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
            }}
          >
            <MailIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography>Mail</Typography>
          </Link>
        </Box>
      )}
    </Card>
  );
};
export default ClientCard;
