import DeliverableSheet from 'types/entities/DeliverableSheet';
import DeliverableSheetWorkunit from 'types/entities/DeliverableSheetWorkunit';
import MissionComment from 'types/entities/MissionComment';
import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import User from 'types/entities/User';
import DeliverableSheetWorkunitDetailsList from 'types/models/DeliverableSheetWorkunitDetailsList';
import DeliverableSheetWorkunitFormatted from 'types/models/DeliverableSheetWorkunitFormatted';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum SheetActions {
  ChangePageSize = 'CHANGE_PAGE_SIZE',
  ChangeTab = 'CHANGE_TAB',
  ChangePage = 'CHANGE_PAGE_DELIVERABLES',
  InjectDeliverable = 'INJECT_DELIVERABLES',
  LoadDeliverable = 'LOAD_DELIVERABLE',
  CommentDeliverable = 'COMMENT_DELIVERABLE',
  CommentDeliverableWithoutDisplay = 'COMMENT_DELIVERABLE_WITHOUT_DISPLAY',
  InjectDeliveredList = 'INJECT_DELIVERED',
  InjectDeviationsList = 'INJECT_DEVIATIONS',
  InjectDetailsList = 'INJECT_DETAILS',
  SetTotalWorkunitNumber = 'SET_TOTAL_WORKUNIT_NB',
  InjectOrder = 'INJECT_ORDER',
  InjectDeliverableSheet = 'INJECT_DELIVERABLE_SHEET',
  OpenDeleteModal = 'OPEN_MODAL_DELETE_DELIVERABLE',
  RestoreDeliverable = 'RESTORE_DELIVERABLE',
  SetDeliverableLoading = 'SET_DELIVERABLE_LOADING',
  EditDeliverableSheetTitleName = 'EDIT_DELIVERABLE_SHEET_TITLE_NAME',
  EditDeliverableSheetState = 'EDIT_DELIVERABLE_SHEET_STATE',
  UpdateDeliverableSheet = 'UPDATE_DELIVERABLE_SHEET',
  Reset = 'RESET',
  SetPdfBlobUrl = 'SET_PDF_BLOB_URL',
  SetPdfFileName = 'SET_PDF_FILE_NAME',
  SetIsAssessSignModalOpen = 'SET_IS_ASSESS_SIGN_MODAL_OPEN',
  OpenDrawerDetails = 'OPEN_DRAWER_DETAILS',
  ShouldRefreshAction = 'SHOULD_REFRESH_ACTION',
}

type ChangeCommentPayload = {
  orderWorkunit: OrderWorkunit;
  user: User;
  comment: MissionComment;
};

export enum TabSheet {
  DELIVERABLE = 'Deliverable',
  PREVIEW = 'Preview',
}

type SheetPayload = {
  [SheetActions.InjectDeliverable]: {
    deliverables: OrderWorkunit[];
    totalItems: number;
    totalPages: number;
    currentPage: number;
  };
  [SheetActions.OpenDeleteModal]: boolean;
  [SheetActions.ChangePage]: number;

  [SheetActions.CommentDeliverable]: ChangeCommentPayload;
  [SheetActions.CommentDeliverableWithoutDisplay]: ChangeCommentPayload;
  [SheetActions.RestoreDeliverable]: OrderWorkunit['id'];
  [SheetActions.OpenDrawerDetails]: OrderWorkunit['id'];
  [SheetActions.ChangePageSize]: number;
  [SheetActions.ChangeTab]: TabSheet;

  [SheetActions.InjectOrder]: Order | null;
  [SheetActions.LoadDeliverable]: null;

  [SheetActions.InjectDeliverableSheet]: DeliverableSheet;
  [SheetActions.InjectDeliveredList]: DeliverableSheetWorkunitFormatted[];
  [SheetActions.InjectDeviationsList]: DeliverableSheetWorkunit[];
  [SheetActions.InjectDetailsList]: DeliverableSheetWorkunitDetailsList;
  [SheetActions.SetTotalWorkunitNumber]: number;
  [SheetActions.EditDeliverableSheetTitleName]: { title: string };
  [SheetActions.EditDeliverableSheetState]: { state: string };
  [SheetActions.UpdateDeliverableSheet]: DeliverableSheet;
  [SheetActions.Reset]: null;
  [SheetActions.SetPdfBlobUrl]: { pdfBlobUrl: string };
  [SheetActions.SetPdfFileName]: string;
  [SheetActions.SetDeliverableLoading]: boolean;

  [SheetActions.SetIsAssessSignModalOpen]: boolean;
  [SheetActions.ShouldRefreshAction]: boolean;
};

export type PossibleSheetActions = ActionMap<SheetPayload>[keyof ActionMap<SheetPayload>];
