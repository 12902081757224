import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import './SignStep.scss';

interface SignStepProps {
  setSignature: (signature: string) => void;
}

const SignStep = ({ setSignature }: SignStepProps) => {
  const { t } = useTranslation();
  const [signatureCanvasRef, setSignatureCanvasRef] = useState<SignatureCanvas | null>(null);
  const [signatureDrawed, setSignatureDrawed] = useState(false);
  const [isSignatureValid, setIsSignatureValid] = useState(true);

  const updateSignatureDrawedState = () => {
    updateSignature();
    setSignatureDrawed(!signatureCanvasRef?.isEmpty());
  };

  const clearCanvas = () => {
    setSignatureCanvasRef(() => {
      signatureCanvasRef?.clear();
      return null;
    });
    setSignatureDrawed(false);
  };

  const getDate = () => moment().format('DD MMMM YYYY - HH[h]mm');

  const updateSignature = () => {
    if (signatureCanvasRef) {
      const signaturePng = signatureCanvasRef.getTrimmedCanvas().toDataURL();
      const signatureThreshold = 1000;
      if (signaturePng.length < signatureThreshold) {
        setIsSignatureValid(false);
        clearCanvas();
      } else {
        setIsSignatureValid(true);
        setSignature(signaturePng);
      }
    }
  };
  return (
    <Stack>
      <Typography fontWeight={500}>{`${t('Date')} : ${getDate()}`}</Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight={500}>{t('Sign document')}</Typography>
        {signatureDrawed && (
          <Button className="text-button" color="error" variant="text" onClick={clearCanvas}>
            {t('Erase')}
          </Button>
        )}
        {!isSignatureValid && (
          <Typography fontWeight={500} color="red">
            {' '}
            Please provide a valid signature.{' '}
          </Typography>
        )}
      </Stack>
      <Box sx={{ border: '1px solid #B2BCD571', borderRadius: 3, width: 840, height: 200 }}>
        <SignatureCanvas
          ref={(ref) => {
            setSignatureCanvasRef(ref);
          }}
          penColor="black"
          onEnd={updateSignatureDrawedState}
          canvasProps={{ width: 840, height: 200, className: 'sigCanvas' }}
        />
      </Box>
    </Stack>
  );
};

export default SignStep;
