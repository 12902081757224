import ModalCustom from 'components/Modal/Modal';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';

const DeliverableCreateReportConfirm = () => {
  const { createDeliverableSheet } = useDeliverableService();
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const open = useSelector((state) => state.dialogs.deliverableCreateReportConfirm);
  const handleClose = () => {
    dispatch(closeDialogAction('deliverableCreateReportConfirm'));
  };

  const submit = async () => {
    await createDeliverableSheet();
    handleClose();
  };
  return (
    <ModalCustom
      open={open}
      modalName="deliverableCreateReportConfirm"
      onClose={handleClose}
      onConfirm={() => submit()}
      confirmText={t('Create report')}
      cancelText={t('Cancel')}
      textConfirmDisable={t('Missing required fields')}
      titleText="Create report"
      confirmLoad
    />
  );
};
export default DeliverableCreateReportConfirm;
