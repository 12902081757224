import { Edit } from '@mui/icons-material';
import { IconButton, Box, Link, Typography, Tooltip } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { DocumentMoreIcon } from 'components/CustomIcons';
import useOrderWorkunitsService from 'features/Orders/OrderWorkunits/services/useOrderWorkunitsService';
import React, { memo, useEffect } from 'react';

type ContentCellProps = {
  params: GridCellParams;
  apiRef: React.MutableRefObject<GridApiPro>;
};

const ContentCell = ({ params, apiRef }: ContentCellProps) => {
  const [localText, setLocalText] = React.useState(params.row[params.field]);

  const { updateWorkunit } = useOrderWorkunitsService();

  useEffect(() => {
    if (params.row[params.field] === '' && params.row[params.field] !== localText)
      setLocalText(params.row[params.field]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.row[params.field]]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (localText !== params.row[params.field]) {
        updateWorkunit({ id: params.row.id, [params.field]: localText });
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localText]);

  return (
    <Box sx={{ minWidth: 0 }}>
      {!localText ? (
        <Link
          component="button"
          onClick={() => {
            if (apiRef.current.getCellMode(params.rowNode.id, params.field) === 'view')
              apiRef.current.startCellEditMode({ id: params.rowNode.id, field: params.field });
          }}
        >
          <DocumentMoreIcon fontSize="large" sx={{ color: 'primary.dark' }} />
        </Link>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={localText}>
            <Typography variant="body2" sx={{ mr: 1 }} noWrap>
              {localText}
            </Typography>
          </Tooltip>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              if (apiRef.current.getCellMode(params.rowNode.id, params.field) === 'view')
                apiRef.current.startCellEditMode({ id: params.rowNode.id, field: params.field });
            }}
          >
            <Edit />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default memo(ContentCell);
