import { Box, Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
// import styled from 'styled-components';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { loadSidebarLinkAction } from 'store/actions/appActions';
import { useAppState } from 'store/Provider';

interface Props {
  name: string;
  path?: string;
  icon?: unknown;
  subItems?: any[];
  search?: string;
}

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#FFFFFF',
  padding: '10px',
  marginBottom: '10px',
  '&:hover': {
    backgroundColor: '#408aec',
  },
  '&.active': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SidebarItem: React.FC<{ item: Props; isExpanded?: boolean }> = ({ item, isExpanded }) => {
  const { dispatch } = useAppState();
  const path = item.path || `/${item.name}`;
  const history = useHistory();
  useEffect(() => {
    if (history.location.pathname === path) dispatch(loadSidebarLinkAction(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, item]);
  const handleChangeLocation = () => {
    if (item.search) {
      history.push({ pathname: item?.path as string, search: item.search });
    } else history.push({ pathname: item?.path as string });
  };
  return (
    <CustomButton
      data-testid={`navigate_${item.name}`}
      onClick={handleChangeLocation}
      className={`${history.location.pathname === path ? 'active' : ''} `}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {item.icon}
        <Box sx={{ fontWeight: 500, fontSize: { xs: '0.85rem', xl: '0.938rem' }, mt: 1 }}>{item.name}</Box>
      </Box>
    </CustomButton>
  );
};
export default SidebarItem;
