import BlobUrl from './BlobUrl/BlobUrl';
import CurrentTab from './CurrentTab/CurrentTab';
import { EditDeliverableSheet } from 'features/Sheet/SheetContext/_reducers/EditDeliverableSheet/EditDeliverableSheet';
import Inject from './Inject/Inject';
import PdfFileName from './PdfFileName/PdfFileName';
import SetIsAssessSignModalOpen from './SetIsAssessSignModalOpen/SetIsAssessSignModalOpen';
import ShouldRefreshActions from './ShouldRefresh/ShouldRefresh';

const ReduceReducers =
  (...reducers: any[]) =>
  (prevState: any, value: any, ...args: any) =>
    reducers.reduce((newState, reducer) => reducer.val(newState, value, ...args), prevState);
export default ReduceReducers(
  { name: 'CurrentTab', val: CurrentTab },
  { name: 'Edit', val: EditDeliverableSheet },
  { name: 'Inject', val: Inject },
  { name: 'PdfBlobUrl', val: BlobUrl },
  { name: 'PdfFileName', val: PdfFileName },
  { name: 'SetIsAssessSignModalOpen', val: SetIsAssessSignModalOpen },
  { name: 'ShouldRefreshActions', val: ShouldRefreshActions }
);
